import React, { useState } from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import OpcionesLiquidacion from './OpcionesLiquidacion';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import CambiarLiquidacion from './CambiarLiquidacion';
import Placa from '../../../../Core/Html/Placa';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';
import Liquidacion from '../AutoLiquidarServicios/Liquidacion';
import { DocumentProvider, documentReducer, initialDocument } from '../../../Document/context/ContextDocument';
import { _liquidacion } from './core/Controlador';

const TablaLiquidacion = ({ cargarServicios, servicios, terceroId }) => {
    const [seleccionados, setSeleccionados] = useState([]);
    const [seleccionarTodos, setSeleccionarTodos] = useState(false);
    const [cambiarLiquidacionModal, setCambiarLiquidacionModal] = useState(false);
    const user = fetchUSer();     // PERMISOS DEL USUARIO

    const MODO_PARQUEDAERO = (user.CONFIGURACIONES_TABLE.MODO_parqueadero) ? user.CONFIGURACIONES_TABLE.MODO_parqueadero : 0;

    const abrirModalCambiarLiquidacion = () => {
        setCambiarLiquidacionModal(true);
    };

    const handleCerrarModales = () => {
        setCambiarLiquidacionModal(false);
    };

    const clicSeleccion = (servicio) => {
        setSeleccionados((prev) => {
            const yaSeleccionado = prev.some((s) => s.id === servicio.id);

            if (yaSeleccionado) {
                return prev.filter((s) => s.id !== servicio.id);
            } else {
                return [...prev, servicio];
            }
        });
    };

    const clicSeleccionarTodo = () => {
        if (seleccionarTodos) {
            setSeleccionados([]);
        } else {
            setSeleccionados(servicios);
        }
        setSeleccionarTodos(!seleccionarTodos);
    };


    // Acumular el valor de todos los elementos y suma  
    const valorALiquidar = seleccionados.reduce((total, servicio) => {
        const subtotal = Number(servicio.SUBTOTAL_1) || 0;
        const porcentaje = Number(servicio.aux_serv_porcentaje_liquidar) || 0;

        if (servicio.switch_liquidacion == 1) {
            return total + (subtotal * (porcentaje / 100));
        } else {
            return total + porcentaje;
        }
    }, 0);






    return (

        <div className=" table-responsive">

            <table className="table table-sm table-bordered table-hover  border-translucent">
                <thead>
                    <tr>
                        <th className="text-nowrap"></th>
                        <th className="text-nowrap"><input p7-data-bs-toggle="tooltip" data-bs-placement="top" title="Seleccionar todos" className='form-check-input' style={{ width: "1.2rem", height: "1.2rem", cursor: 'pointer' }} type="checkbox" checked={seleccionados.length === servicios.length && servicios.length > 0} onChange={clicSeleccionarTodo} /></th>
                        <th className="text-nowrap">Fecha</th>
                        <th className="text-nowrap">Tipo</th>

                        {MODO_PARQUEDAERO == 1 && <>
                            <th className="text-nowrap">Placa</th>
                        </>}

                        <th className="text-nowrap">Nombre Servicio</th>
                        <th className="text-nowrap">Precio</th>
                        <th className="text-nowrap">Liquidacion</th>
                        <th className="text-nowrap">Devengado</th>
                        <th className="text-nowrap">Descripcion servicio</th>
                        <th className="text-nowrap">Descripcion Placa</th>
                        <th className="text-nowrap">Observacion Factura</th>
                    </tr>
                </thead>
                <tbody>
                    {servicios.map((servicio) => (
                        <tr key={servicio.id}>
                            <td className='align-middle'>
                                <OpcionesLiquidacion document_id={servicio.ct_documento_id} terceroId={terceroId} item_id={servicio.id} id_producto={servicio.id_producto} switch_liquidacion={servicio.switch_liquidacion} actualizarTabla={cargarServicios} user={user} />
                            </td>
                            <td className='align-middle'><input className='form-check-input' style={{ width: "1.2rem", height: "1.2rem", cursor: 'pointer' }} type="checkbox" checked={seleccionados.some((s) => s.id === servicio.id)} onChange={(e) => { e.stopPropagation(); clicSeleccion(servicio); }} /></td>

                            <td className='align-middle text-nowrap'>{__fecha.formatoFecha({ $fecha: servicio.FECHA, $icon: 0 })}</td>
                            <td className='align-middle'>{servicio.etiqueta_corta}</td>
                            {MODO_PARQUEDAERO == 1 && <>
                                <td className='align-middle'> <Placa placa={servicio.placa} /></td>
                            </>}
                            <td className='align-middle'>{servicio.nombre}</td>
                            <td className='text-success text-nowrap align-middle'>$ {__app.formato_decimal({ total: servicio.SUBTOTAL_1 })}</td>
                            <td className='align-middle text-nowrap '>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {_liquidacion.estados_liquidacion({ estado: servicio.switch_liquidacion, aux_serv_porcentaje_liquidar: servicio.aux_serv_porcentaje_liquidar })}
                                </div>

                            </td>
                            <td className="text-success text-nowrap align-middle">
                                $ {_liquidacion.liquidacion({ estado: servicio.switch_liquidacion, SUBTOTAL_1: servicio.SUBTOTAL_1, aux_serv_porcentaje_liquidar: servicio.aux_serv_porcentaje_liquidar })}
                            </td>
                            <td className='align-middle'>{__app.rellenarVacios(servicio.descripcion)}</td>
                            <td className='align-middle'>{__app.rellenarVacios(servicio.placa_descripcion)}</td>
                            <td className='align-middle'>{__app.rellenarVacios(servicio.periodo_observacion)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="row g-2">
                <div class="col-auto mb-2">
                    <button className='btn btn-sm btn-subtle-secondary' onClick={() => abrirModalCambiarLiquidacion()} ><i class="fa-solid fa-dollar-sign  me-1 fa-lg"></i>Cambiar Liquidacion Global</button>
                </div>
            </div>

            {/* CAMBIAR LIQUIDACION */}
            <StandarModal keyboard={true} headerShow={true} size="m" titulo="Cambiar Liquidacion." desactivar={handleCerrarModales} mostrar={cambiarLiquidacionModal}>
                <CambiarLiquidacion terceroId={terceroId} desactivar={handleCerrarModales} actualizarTabla={cargarServicios} global={true} />
            </StandarModal>


            <DocumentProvider initialState={initialDocument} reducer={documentReducer}>
                <Liquidacion cargarServicios={cargarServicios} items={seleccionados} tercero_id={terceroId} valorALiquidar={valorALiquidar} />
            </DocumentProvider>

        </div>
    );
};

export default TablaLiquidacion;