
import React from 'react';
import { __app } from '../../../../utils/myApp';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';

 /***  */
//Jose Fernando

const ListaPagosTirilla = ({documento}) => {
    
    console.log(documento)

    const  totalFactura = documento.MATEMATICAS.SUBTOTAL_3;
   
    let saldoPendiente = 0;

    let saldoAFavor = 0;
   
    if(documento?.pagos_tiempo_real?.PAGOS ||  documento?.pagos_tiempo_real?.PAGOS_CONSUMIDOS  ){
       
        let totalPagado = 0;
        const  pagado  =  documento.pagos_tiempo_real.PAGOS.filter(b => b.estado == 1).reduce( (a ,  b ) => a + parseFloat(b.total) , 0 )
        const  consumos  =  documento.pagos_tiempo_real.PAGOS_CONSUMIDOS?.reduce( (a ,  b ) => a + parseFloat(b.tt) , 0 )
        totalPagado = pagado + consumos;
       
        console.log(totalPagado);
        //                      10.0000          8.000 = 
        const resultado =  totalFactura - totalPagado;

      

        if(resultado > 0 ){
            saldoPendiente = resultado; 
        }
        if(resultado < 0 ){
            saldoAFavor = Numpositivo(resultado); 
        }
        return (
            
            <div>
         
                {documento?.pagos_tiempo_real?.PAGOS.map( (pago,index) =>                          
                    <span key={index}>                                
                    {
                        pago.estado == 1   &&   
                        <div key={index} className="lineapagos">
                            <span className="font12" > {pago.CREADO}</span>
                            <span className="font12" > {pago.forma_pagos_nombre}</span>
                            <span className="font12" > {pago.nombre_user}</span>
                            <span className="font12" >$ { __app.formato_decimal({total : pago.total}) } </span>
                        </div>
                    }
                    </span>                                
                )} 

                {documento?.pagos_tiempo_real?.PAGOS_CONSUMIDOS.map( (pago,index) =>                          
                    <span key={index}>                                
                        <div key={index} className="lineapagos">
                            <span className="font12" > {pago.cr}</span>
                            <span className="font12" >Cruze de pago </span>
                            <span className="font12" > </span>
                            <span className="font12" >$ { __app.formato_decimal({total : pago.tt}) } </span>
                        </div>
                    </span>                                
                )}     
                
               <p>
                <span>Total de Factura  :  { __app.formato_decimal({total :totalFactura}) }  </span> <br/>
                <span>Total Pagado :  { __app.formato_decimal({total :totalPagado}) }  </span>  <br/>
                {saldoPendiente > 0 && <span>Pendiente :  { __app.formato_decimal({total :saldoPendiente}) }   <br/></span>}
                {saldoAFavor > 0 &&    <span>Saldo a Favor  :  { __app.formato_decimal({total :saldoAFavor}) }   </span> }
               </p>


               {documento?.saldo_a_favor_vigente > 0 && <p>
                    ---------------------- <br/>
                    Nuevo Saldo a Favor :   { __app.formato_decimal({total :documento.saldo_a_favor_vigente}) }  
                    <br/>---------------------
                </p>}
            </div>
        )

    }


    return (
        <div>
           {documento?.FORMAS_DE_PAGO && documento.FORMAS_DE_PAGO.map( (pago,index) =>                          
                <span key={index}>                                
                {
                    pago.total != 0   &&   
                    <div key={index} className="lineapagos">
                        <span className="font12" > {pago.nombre}</span>
                        <span className="font12" >Codigo Caja : {pago.banco_id}</span>
                        <span className="font12" >$ { __app.formato_decimal({total : pago.total}) } </span>
                    </div>
                }
                </span>                                
            )} 
        </div>
    );
};

export default ListaPagosTirilla;