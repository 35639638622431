
import React, { useState } from 'react';
import { enviarPeticion } from '../../../Core/ActionDatabase';
import InfoPago from './InfoPago';
import { NotificationManager } from 'react-notifications';
import StandarModal from '../../Document/PartesDocument/StandarModal';

const ViewPago = ({pago_id}) => {

    const [pago , setPago] = useState(null);
    const [pagoform , setPagoform] = useState(null);

    const cargarPago = async  () => {


        const httpResponse = await enviarPeticion("pagos/ver_pago" ,pago_id );
        console.log(httpResponse.data)
        if(httpResponse.data && httpResponse.data.id){
            setPago(httpResponse.data);
            setPagoform(true);
        }else{
            
            NotificationManager.error("Sin red")
        }
        
    }

    return (
        <div>  
            
            <button className='btn btn-xs btn-primary text-nowrap p-1' onClick={cargarPago}>
                <i className='fa-solid fa-eye fa-lg me-1'></i>
                Ver Pago
            </button>
            <StandarModal size="xl" titulo="Informacion del Pago"  desactivar={() => setPagoform(!pagoform) } mostrar={pagoform}  > 
                <InfoPago pago={pago}  boton={true} />
            </StandarModal>
        </div>
    );
};

export default ViewPago;