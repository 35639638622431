import { enviarPeticion } from "../../../../Core/ActionDatabase";



export const registrarVehiculo = async (formData) => {

    let responseHttp = await enviarPeticion('objetos/registrarObjeto', null, {
        body: {
            POST: formData
        }
    });
    console.log(responseHttp)
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

}

export const perfilVehiculo = async (id) => {

    let responseHttp = await enviarPeticion('objetos/view', id);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

}


export const estadoObjeto = ({ estado }) => {
    if (estado == 1) {
        return (
            <span className="badge rounded-pill bg-info fs-10">Tiene una mensualidad</span>
        );
    } else {
        return (
            <span className="badge rounded-pill bg-warning fs-10">No tiene mensualidad</span>
        );
    }
};
