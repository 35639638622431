/******

Arhivos de sincronizacion, Se encarga de revisar la version Actual de las Actuales tablas


 Productos
 Vehiculos
 terceros



**/

import { NotificationManager } from "react-notifications";
import { enviarPeticion } from "./ActionDatabase";
import { deleteIdsDixie, reloadDatabases } from "./Dixie";
import { getLocalstore, GetNombre_firebaseLastKeyClientes, GetNombre_firebaseLastKeyObjetos, GetNombre_firebaseLastKeyProducto } from "./fetchLocalStoreData";
import { __app } from "../utils/myApp";
import { cargarProductosByPagina, cargarUltimaKey, combinarProductos, extraerCantidadDeProductosBD, ExtraerComponentedeProductos, SincronizarTodo } from "../components/Productos/Controlador";


/*****
 * 
 *  SINCRONIZACION DE CLIENTES
 * 
*/

export const verificarClientes   = async () =>{

    const lastKey = GetNombre_firebaseLastKeyClientes();

    let lastIdactual = getLocalstore(lastKey);


    if(lastIdactual !== null && Number(lastIdactual) >=  0 ){

        __app.AddLogs({ divID: '__p7_logsClientes', data: 'Su Versión actual de Base de Datos de clientes es : <b>'  + lastIdactual  + '</b> No Nesecitas Sincronización' , clase:'text-success'  });
        
        return 1;

    }else{
        __app.AddLogs({ divID: '__p7_logsClientes', data: 'Sus Clientes no estan sincronizados, por favor espere...'  });
        let data =  await extraerClientesPrimeraVez();
        return data;
    }

}

export const extraerClientesPrimeraVez = async () => {

    const httpResponse = await extraerClientesBaseDatos();

    if (httpResponse.data && Array.isArray(httpResponse.data.clientes)) {

        __app.AddLogs({ divID: '__p7_logsClientes', data: 'Peticion completada con Exito' });
        __app.AddLogs({ divID: '__p7_logsClientes', data: 'Clientes encontrados: '  + httpResponse.data.clientes.length });
    
        let respuestaReload = await reloadDatabases(httpResponse.data.clientes, 'terceros', 1);
        __app.AddLogs({ divID: '__p7_logsClientes', data: respuestaReload.message });
    
        if (respuestaReload.success === 1) {
            __app.AddLogs({ divID: '__p7_logsClientes', data: 'Su versión de base de datos para terceros sera: ' + httpResponse.data.lastKey });
            localStorage.setItem(GetNombre_firebaseLastKeyClientes(), httpResponse.data.lastKey);
            __app.AddLogs({ divID: '__p7_logsClientes', data: ' ✅  Terceros actualizados con exito' ,  clase:'text-success'  });
        }
    
        return respuestaReload.success;
    } else {
        __app.AddLogs({ divID: '__p7_logsClientes', data: 'Error Http: No se encontraron clientes o la respuesta es inválida.' ,  clase:'text-danger'  });
        return -1;
    }
    
    
}


export const  extraerClientesBaseDatos = async () => {
    const httpResponse = await enviarPeticion("terceros/listar");
    if(httpResponse.respuesta == 1){  
        return httpResponse;
    }else{
        return -1;
    }

}
































/*****
 * 
 *  SINCRONIZACION DE VEHICULOS
 * 
 */
export const  verificarVehiculos = async () => {




    const LastKeyVehiculo = GetNombre_firebaseLastKeyObjetos();

    let lastIdactual = getLocalstore(LastKeyVehiculo);

    if(lastIdactual !== null && Number(lastIdactual) >=  0 ){
        __app.AddLogs({ divID: '__p7_logsVehiculos', data: 'Su Versión actual de Base de Datos de <b>Vehículos</b> es : <b>'  + lastIdactual  + '</b> No Nesecitas Sincronización' , clase:'text-success'  });
        return 1      
    }else{

        __app.AddLogs({ divID: '__p7_logsVehiculos', data: 'Sus <b>Vehículos</b> no estan sincronizados, por favor espere...'  });
        let data =  await extraerObjetosPrimeraVez();
        return data;
    }
 

}

export const extraerObjetosPrimeraVez = async () => {

    const httpResponse = await extraerObjetosBaseDatos();

    if (httpResponse.data && Array.isArray(httpResponse.data.objetos)) {

        __app.AddLogs({ divID: '__p7_logsVehiculos', data: 'Peticion completada con Exito' });
        __app.AddLogs({ divID: '__p7_logsVehiculos', data: 'Objetos encontrados: '  + httpResponse.data.objetos.length });
    
        let respuestaReload = await reloadDatabases(httpResponse.data.objetos, 'objetos', 1);
        __app.AddLogs({ divID: '__p7_logsVehiculos', data: respuestaReload.message });
    
        if (respuestaReload.success === 1) {
            __app.AddLogs({ divID: '__p7_logsVehiculos', data: 'Su versión de base de datos para objetos sera: ' + httpResponse.data.lastKey });
            localStorage.setItem(GetNombre_firebaseLastKeyObjetos(), httpResponse.data.lastKey);
            __app.AddLogs({ divID: '__p7_logsVehiculos', data: ' ✅  Objetos  actualizados con exito' ,  clase:'text-success'  });
        }
    
        return respuestaReload.success;
    } else {
        __app.AddLogs({ divID: '__p7_logsVehiculos', data: 'Error Http: No se encontraron Objetos  o la respuesta es inválida.' ,  clase:'text-danger'  });
        return -1;
    }
    
    
}







export const  extraerObjetosBaseDatos = async () => {
    const httpResponse = await enviarPeticion("objetos/lista_de_objetos");
    if(httpResponse.respuesta == 1){  
        return httpResponse;
    }else{
        return -1;
    }

}

export const contarNumerodeObjetos = async () => {

    const httpResponse = await enviarPeticion("objetos/contar_objetos");
    if(httpResponse.respuesta == 1){
        
        return httpResponse.data.cantidad;
    }else{

        return -1;
    }

}



















/*****
 * 
 *  SINCRONIZACION DE PRODUCTOS
 * 
*/

export const verificarProductos = async () => {
    const lastKey = GetNombre_firebaseLastKeyProducto();
    let lastIdactual = getLocalstore(lastKey);

    if (lastIdactual !== null && Number(lastIdactual) >= 0) {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Su Versión actual de Base de Datos de Productos es : <b>' + lastIdactual + '</b> No necesita Sincronización', clase: 'text-success' });
        return 1; // Devuelve 1 si no es necesario sincronizar.
    } else {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Sus Productos no están sincronizados, por favor espere...' });
        let data = await extraerProductosPrimeraVez();
        return data; // Devuelve lo que retorne extraerProductosPrimeraVez (1 o -1)
    }
}

export const extraerProductosPrimeraVez = async () => {
    const cantidadDeProductos = await extraerCantidadDeProductosBD();

    if (cantidadDeProductos > 0) {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Encontramos ' + cantidadDeProductos + ' para sincronizar, por favor espere...' });

        if (Number(cantidadDeProductos) > 2000) {
            __app.AddLogs({ divID: '__p7_logsProductos', data: 'Usted cuenta con más de 5000 productos, por favor espera.. ' });
            return await sincronizacionPorBloques(cantidadDeProductos);
        } else {
            return await extraerProuctosRapidos();
        }
    } else {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'No se encontraron productos para sincronizar.' });
        return -1; // Si no hay productos, devuelve -1
    }
}

const extraerProuctosRapidos = async () => {
    const productosInfo = await SincronizarTodo();

    if (productosInfo.newProducto && Array.isArray(productosInfo.newProducto)) {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Petición completada con Éxito' });
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Productos descargados: ' + productosInfo.newProducto.length });

        let respuestaReload = await reloadDatabases(productosInfo.newProducto, 'productos', 1);
        __app.AddLogs({ divID: '__p7_logsProductos', data: respuestaReload.message });

        if (respuestaReload.success === 1) {
            __app.AddLogs({ divID: '__p7_logsProductos', data: 'Su versión de base de datos para Productos será: ' + productosInfo.lastKey });
            localStorage.setItem(GetNombre_firebaseLastKeyProducto(), productosInfo.lastKey);
            __app.AddLogs({ divID: '__p7_logsProductos', data: '✅ Productos actualizados con éxito', clase: 'text-success' });
        }

        return respuestaReload.success;
    } else {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Error HTTP: No se encontraron productos o la respuesta es inválida.', clase: 'text-danger' });
        return -1; // Devuelve -1 si hay un error
    }
}

const sincronizacionPorBloques = async (cantidadDeProductos) => {
    let ComponenteProductos = null;
    let data = await ExtraerComponentedeProductos();

    if (data) {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Se descargaron los Componentes de productos' });
        ComponenteProductos = data;
        return await EjecutarPaso2(cantidadDeProductos, ComponenteProductos);
    } else {
        return -1; // Devuelve -1 si no se descargaron los componentes
    }
}

// Las siguientes funciones se mantienen igual, ya que son parte del proceso de sincronización por bloques.

const EjecutarPaso2 = async (cantidadDeProductos, ComponenteProductos) => {
    return await EjecutarPaso3(cantidadDeProductos, ComponenteProductos);
}

const EjecutarPaso3 = async (cantidadDeProductos, ComponenteProductos) => {
    const numero_de_productos_permitidos = 2000;
    const totalProductos = parseFloat(cantidadDeProductos);
    const productosPorBloque = numero_de_productos_permitidos;
    const totalBloques = Math.ceil(totalProductos / productosPorBloque);

    __app.AddLogs({ divID: '__p7_logsProductos', data: 'Realizaremos ' + totalBloques + ' consultas a la base de datos' });

    let todos = [];

    for (let i = 0; i < totalBloques; i++) {
        const inicio = i * productosPorBloque;
        const fin = Math.min((i + 1) * productosPorBloque, totalProductos);

        const porcentaje = ((i + 1) / totalBloques) * 100;
        __app.AddLogs({ divID: '__p7_logsProductos', data: Math.round(porcentaje) + '% Completado, por favor espere...' });

        try {
            const respuesta = await cargarProductosByPagina(inicio, numero_de_productos_permitidos);
            todos = todos.concat(respuesta);
        } catch (error) {
            console.error(`Error al cargar el bloque ${i + 1}: ${error.message}`);
        }

        await new Promise(resolve => setTimeout(resolve, 1000)); // Delay entre bloques
    }

    __app.AddLogs({ divID: '__p7_logsProductos', data: 'Todos los productos fueron cargados satisfactoriamente' });

    return await EjecutarPaso4(todos, ComponenteProductos);
}

const EjecutarPaso4 = async (todos, ComponenteProductos) => {
    __app.AddLogs({ divID: '__p7_logsProductos', data: 'Extrayendo productos, por favor espere..' });

    let productos = combinarProductos(ComponenteProductos, todos);

    __app.AddLogs({ divID: '__p7_logsProductos', data: 'Los productos fueron extraídos con éxito' });

    let ultimaKey = await cargarUltimaKey();

    if (ultimaKey == -1) {
        __app.AddLogs({ divID: '__p7_logsProductos', data: '❌ Error HTTP al consultar la versión de productos', clase: 'text-danger' });
        return -1; // Error al obtener la última clave
    }

    let respuestaReload = await reloadDatabases(productos, 'productos', 1);
    __app.AddLogs({ divID: '__p7_logsProductos', data: respuestaReload.message });

    if (respuestaReload.success === 1) {
        __app.AddLogs({ divID: '__p7_logsProductos', data: 'Su versión de base de datos para Productos será: ' + ultimaKey });
        localStorage.setItem(GetNombre_firebaseLastKeyProducto(), ultimaKey);
        __app.AddLogs({ divID: '__p7_logsProductos', data: '✅ Productos actualizados con éxito', clase: 'text-success' });
    }

    return respuestaReload.success;
}


























export const  extraerProductosDB = async () => {
    const httpResponse =  await enviarPeticion( "productos/listar_productos");
    if(httpResponse.respuesta == 1){  
        return httpResponse;
    }else{
        return -1;
    }

}




































/************ ESTRAER ULTIMO OBJETOS  ****************/

/*
*  Esta Funcion solicita al servidor los ultimos cambios de los productos
*  @param Por ahora el I
*/
export const SincronizarNivelBajo = async( {lastKey , obj }) => {
    
    
    let respuesta = null;
    
    if (lastKey != undefined){
        
        respuesta = await enviarPeticion( "sincronizaciones/sincronizar_nivel_bajo"   , lastKey  , {
            body : obj
        });
        
      
        if(respuesta.respuesta == 1){
           
            if(respuesta.data.lastKey){
            
                if(respuesta.data.tabla == "productos"){
                    localStorage.setItem(GetNombre_firebaseLastKeyProducto() , respuesta.data.lastKey )
                }

                if(respuesta.data.tabla == "terceros"){
                    localStorage.setItem(GetNombre_firebaseLastKeyClientes() , respuesta.data.lastKey )
                }

                if(respuesta.data.tabla == "objetos"){
                    localStorage.setItem(GetNombre_firebaseLastKeyObjetos() , respuesta.data.lastKey )
                }
            
            }

            if(respuesta.data.infoData.length > 0 ){
                NotificationManager.success("Nueva actualizacion de base de datos realizada. Tu version de "+respuesta.data.tabla +" es : " + respuesta.data.lastKey)
                
                reloadDatabases(respuesta.data.infoData, respuesta.data.tabla);
                logdeProducotosSincronizados(respuesta.data.infoData);
            }




            
            if(respuesta.data.idsDelete.length > 0 ){
                NotificationManager.info("ELIMINADOS:   Nueva actualizacion de base de datos realizada. Tu version "+respuesta.data.tabla +"  es : " + respuesta.data.lastKey)
                let  refIds = respuesta.data.idsDelete.map(registro => registro.ref_id);
                deleteIdsDixie(refIds , respuesta.data.tabla );
                let copy = respuesta.data.idsDelete.map( (data) => {
                    return {
                        ...data,
                        nombre : 'Tabla eliminada : ' +  respuesta.data.tabla,
                        id_2 : data.ref_id
                    }
                })
               logdeProducotosSincronizados(copy);
            }


            return respuesta;
        }

    }else{
        
        NotificationManager.error("Le Recomendamos Sincronizar manualmente.")
        return -1;
    }  

    
}





const logdeProducotosSincronizados = (productos) => {

    productos.forEach((producto) => {
        producto.fechaSincronizacion = __app.getFecha() // Agregar la propiedad con la fecha actual
        producto.fechanumero = __app.numeroFecha(producto.fechaSincronizacion);
    })

    console.log(productos);
    reloadDatabases( productos , 'actualizaciones');
}
