import React from 'react';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';

const Footer = ({ estilos }) => {
    const user = fetchUSer();
    return (
        <div className="mt-5  TirillaTextCenter text-center">

            {(user.CONFIGURACIONES_TABLE.factura_texto_pequeno && user.CONFIGURACIONES_TABLE.factura_texto_pequeno.trim().length > 0) &&
                <div className="text-center mt-2">
                    <br />
                    <br />
                    <center> <span className="text-center d-block">{user.CONFIGURACIONES_TABLE.factura_texto_pequeno}</span></center>
                </div>
            }


            {(user.CONFIGURACIONES_TABLE.factura_texto_pie_pagina && user.CONFIGURACIONES_TABLE.factura_texto_pie_pagina.trim().length > 0) &&
                <div className="text-center mt-2">
                    <center> <b style={estilos.informacion.font9} className="text-center d-block mt-1">{user.CONFIGURACIONES_TABLE.factura_texto_pie_pagina}</b></center>
                </div>
            }
        </div>
    );
};

export default Footer;