import React from 'react';
import Selectt from 'react-select'

const Select = ({ filtro, formData, setFormData }) => {


    if (!Array.isArray(filtro.options) || filtro.options === null || filtro.options.length === 0) {
        return false;
    }




    const cambiosSelect = (data) => {
        if (filtro.isMulti) {
            setFormData({
                ...formData,
                [filtro.name]: data ? data.map(op => op.id) : []
            });
        } else {
            setFormData({
                ...formData,
                [filtro.name]: data ? data.id : ""
            });
        }

    }

    const opciones = filtro.options


    const getLabel = (opciones) => {

        if (filtro.ShowOptions && filtro.ShowOptions.nombre) {
            return opciones[filtro.ShowOptions.nombre]
        }
        return opciones.nombre
    }



    const getValue = (opciones) => {

        return opciones.id
    }

    return (
        <div>
            <label htmlFor="" className='form-label'>{filtro.label}</label>
            <Selectt
                isMulti={filtro.isMulti}
                name={filtro.name}
                getOptionLabel={(opciones) => getLabel(opciones)}
                getOptionValue={(opciones) => getValue(opciones)}
                placeholder={filtro.placeholder}
                options={opciones}
                onChange={cambiosSelect}
                // value={formData[filtro.name]}
                value={filtro.isMulti ? opciones.filter(op => formData[filtro.name]?.includes(op.id)) : opciones.find(op => op.id === formData[filtro.name])}
            />
            {/*
          cambiosSelect 
            onChange={handleTaxSelection}
            value={formData.IMPUESTOS_RAIZ} // Establecer las opciones seleccionadas por defecto
            */
            }
        </div>
    );
};

export default Select;