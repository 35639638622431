import React, { useEffect, useState } from 'react';

import { __app, __pluma7 } from '../../../utils/myApp';
import { enviarPeticion } from '../../../Core/ActionDatabase';
import { fetchEmpresa, fetchUSer, getNit } from '../../../Core/fetchLocalStoreData';
import { NotificationManager } from 'react-notifications';
import { HelpHttp } from '../../../Core/https';
import RenviarDian from './Dian/RenviarDian';

import ReimprimirTirilla from './ReimprimirTirilla';
import EliminarFactura from './EliminarFactura';
const Botones = ({ document  , recargarDocumento}) => {


    const nitEmpresa = getNit();


    const empresa = fetchEmpresa();

    console.log(empresa.httpserver);

    const [xxx, setXxx] = useState();

    useEffect(() => {
        setXxx(document)
    }, [document])
    const user = fetchUSer();

    const ADMINISTRADOR = (user.USER.type_user_id) ? user.USER.type_user_id : 0;

    const enviarCorreoFacturaElectronica = async () => {

        let numeroFactura = (xxx &&
            xxx.DOCUMENTO.DIAN_factureroelectronico_id &&
            !isNaN(parseInt(xxx.DOCUMENTO.DIAN_factureroelectronico_id)) &&
            parseInt(xxx.DOCUMENTO.DIAN_factureroelectronico_id) > 0)
            ? parseInt(xxx.DOCUMENTO.DIAN_factureroelectronico_id)
            : 0;


        if (numeroFactura == 0) {

            alert("Esta factura no esta Aceptada. No puedes enviarla");
            return false;
        }
        var correo = prompt("Por favor, ingresa el correo Electrónico:", xxx.DOCUMENTO.correoelectronico);

        // Muestra el valor capturado en un alert
        if (correo !== null) {

            let httpResponse = await HelpHttp().post('https://fe3.nubep7.com/apis/reenviar_correo_electronico/' + nitEmpresa + '/' + numeroFactura + '/' + correo);

            if (httpResponse.respuesta == 1) {
                console.log(typeof httpResponse.data)

                let resultadoEmail = null;

                if (typeof httpResponse.data == 'object') {
                    httpResponse.data.forEach((data, index) => {
                        if (data === 'RESPUESTA_CORREO:') {
                            resultadoEmail = httpResponse.data[index + 1];
                        }
                    });

                }

                console.log(resultadoEmail)
                if (resultadoEmail === '--1--') {
                    NotificationManager.success("Email enviado con Éxito")
                } else {
                    NotificationManager.warning("Error al intentar enviar el correo.")
                }
            } else {
                NotificationManager.error("Error de red")
            }
        }
    }

    const [reenviarDian, SetreenviarDian] = useState();

    const HandlereenviarDian = () => {
        var numero = xxx.DOCUMENTO.CtDocumento_ct_numero_factura; // Número actual
        var mensaje = "La factura se enviará con el número: " + numero +
            ". Si deseas mantener este número presiona Aceptar. " +
            "Si deseas el siguiente número presiona Cancelar.";

        if (window.confirm(mensaje)) {
            // El usuario quiere mantener el número actual
            alert("Se mantendrá el número: " + numero);
            SetreenviarDian(1);
        } else {

            alert("La factura se generará con un número nuevo.");
            SetreenviarDian(2);
        }

        // Continuar con la lógica después de la selección del número

        console.log("Número de factura seleccionado:", numero);

        return false;
    }

    if(!xxx){
        return false;
    }

    return (
        <div className='row d-flex aling-items-center mb-2 g-0'>


            {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_repuesta_dian && xxx.DOCUMENTO.DIAN_repuesta_dian == 4) &&
                <div className="col-auto">
                    <button onClick={enviarCorreoFacturaElectronica} className="btn btn-sm btn-secondary me-2">
                        <i className="fa fa-envelope"></i> Enviar por Correo
                    </button>
                </div>

            }

            {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_documento_electornico && xxx.DOCUMENTO.DIAN_repuesta_dian != 4) &&
                <div className="col-auto">
                    <button onClick={HandlereenviarDian} className="btn btn-warning btn-sm me-2">
                        <i className="fa fa-refresh"></i> Re-enviar DIAN
                    </button>
                </div>

            }

            {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_documento_electornico && xxx.DOCUMENTO.DIAN_documento_electornico == 1) &&
                <div className="col-auto">
                    <a target='_blank' href={'https://fe3.nubep7.com/ventas/PDF_original/' + xxx.DOCUMENTO.DIAN_factureroelectronico_id + '/' + nitEmpresa} className="btn  btn-sm btn-primary me-2">
                        <i class="fa-solid fa-file-pdf"></i> PDF Electronico
                    </a>
                </div>
                ||
               
                <div className="col-auto">
                    <a target='_blank' href={  empresa.httpserver + 'documentos/facura_PDF/' + nitEmpresa  + "/" + xxx.DOCUMENTO.CtDocumento_id} className="btn  btn-sm btn-primary me-2">
                        <i class="fa-solid fa-file-pdf"></i> Factura en PDF 
                    </a>
                </div>
            }

            <div className="col-auto">
                <a target='_blank' href={  empresa.httpserver + 'ventas/exportar_productos_excel/'  + nitEmpresa + "/" + xxx.DOCUMENTO.CtDocumento_id} className="btn  btn-sm btn-success me-2">
                    <i class="fa-solid fa-file-excel"></i> Exportar a excel 
                </a>
            </div>

            {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_documento_electornico && xxx.DOCUMENTO.DIAN_documento_electornico == 1 && xxx.DOCUMENTO.DIAN__codigoQr) &&
                <div className="col-auto">
                    <a target='_blank' href={xxx.DOCUMENTO.DIAN__codigoQr} className="btn btn-sm btn-success me-2">
                        <i className="fa fa-qrcode"></i> Validación de la existencia en la DIAN
                    </a>
                </div>

            }




            {xxx && <ReimprimirTirilla documento={xxx} />}

            <RenviarDian reenviarDian={reenviarDian} documento={xxx} />


            {ADMINISTRADOR == 1 && <>
                {xxx && <EliminarFactura  recargarDocumento={recargarDocumento} documento={xxx.DOCUMENTO.CtDocumento_id} />}
            </>}

        </div>
    );
};

export default Botones;