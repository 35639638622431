import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import { __pluma7 } from '../../../../Core/pluma7';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';

const Pagos = ({ infoPago }) => {


    const suma = (a, b) => a + Numpositivo(b.total);

    const sumarTotal = infoPago.pagos.reduce(suma, 0);

    return (
        <div >
            <p style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold', margin: '1px' }}>DOCUMENTOS ABONADOS</p>
            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '13px', background: '#fff', border: '1px solid #ccc' }}>
                <thead>
                    <tr style={{ background: '#f0f0f0' }}>
                        <th style={{ padding: '4px', textAlign: 'left', border: '1px solid #ccc', fontWeight: 'bolder' }}>#Pago</th>
                        <th style={{ padding: '4px', textAlign: 'left', border: '1px solid #ccc', fontWeight: 'bolder' }}>Num Factura</th>
                        <th style={{ padding: '4px', textAlign: 'left', border: '1px solid #ccc', fontWeight: 'bolder' }}>Comprobante</th>
                        <th style={{ padding: '4px', textAlign: 'right', border: '1px solid #ccc', fontWeight: 'bolder' }}>Total Abonado</th>
                    </tr>
                </thead>
                <tbody>
                    {infoPago.pagos.map((pag, index) => (
                        <tr key={index} style={{ border: '1px solid #ccc' }}>
                            <td style={{ padding: '4px', border: '1px solid #ccc' }}>{pag.id}</td>
                            <td style={{ padding: '4px', fontWeight: 'bold', border: '1px solid #ccc' }}>
                                {__pluma7.numeroFactura({ numero: pag.ct_numero_factura, id: pag.ct_documento_id })}
                            </td>
                            <td style={{ padding: '4px', border: '1px solid #ccc' }}>{pag.etiqueta_nombre}</td>
                            <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ccc' }}>
                                $ {__app.formato_decimal({ total: Numpositivo(pag.total), numerosPositivos: true })}
                            </td>
                        </tr>
                    ))}
                    <tr style={{ fontWeight: 'bold' }}>
                        <td colSpan={3} style={{ textAlign: 'right', padding: '4px'}}>Total Pagado:</td>
                        <td style={{ textAlign: 'right', padding: '4px', border: '1px solid #ccc' }}>
                            $ {__app.formato_decimal({ total: Numpositivo(sumarTotal), numerosPositivos: true })}
                        </td>
                    </tr>


                    {infoPago.saldo_pendiente && infoPago.saldo_pendiente.pendiente > 0 && (
                        <tr style={{ fontWeight: 'bold', color: 'red' }}>
                            <td colSpan={3} style={{ textAlign: 'right', padding: '4px' }}>
                                Nuevo saldo pendiente:
                            </td>
                            <td style={{ textAlign: 'right', padding: '4px', border: '1px solid #ccc' }}>
                                $ {__app.formato_decimal({ total: Numpositivo(infoPago.saldo_pendiente.pendiente), numerosPositivos: true })}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Pagos;