import React from 'react';
import { __app, __fecha } from '../../../utils/myApp';
import { Numpositivo } from '../../../Core/MatematicasGlobales';
import { useContextLocalValue } from './Core/ContextTercero';


const DatosPersonales = ({infoTercero , mostrarFormAbonoGlobal}) => {

    const [ data ,  dispatch ]  = useContextLocalValue();

    const actualizarDatos  = () => {

        console.log({...data.info  , actualizarPerfil: __app.numeroFecha() })

        dispatch({
            type : 'SET_DATA',
            data : {...data.info  , actualizarPerfil: __app.numeroFecha()  }, 
        })
    }
    return (
        
        <div className="sticky-leads-sidebar">
            
            <div className="lead-details-offcanvas bg-body scrollbar">
                <div className="d-flex justify-content-between align-items-center mb-2 d-md-none">
                <h3 className="mb-0">Detalles de Tercero</h3>
                </div>
                <div className="mb-3 card">
                    <div className="card-body">        
                        <div className="align-items-center g-3 text-center text-xxl-start row">
                        
                            { (infoTercero && infoTercero.ct_persona ) &&  
                               <>
                                    {false && 
                                    <div className="col-xxl-auto col-12">
                                        <div className="text-center d-inline-block avatar avatar-5xl"> <i className="text-center fa fs-2 fa-user"></i></div>
                                    </div>}
                                        
                                    <div className="flex-1 col-sm-auto col-12">
                                        <h3 className="fw-bolder mb-2">{infoTercero.ct_persona.CtPersona_razonSocial}</h3>
                                        <p className="mb-0"> Identificacion</p>
                                        <span className="fw-bold" >{infoTercero.ct_persona.CtPersona_identificacion}</span>
                                        <p>Tipo : <span className='badge bg-primary'>{infoTercero.ct_persona.categoria_persona}</span> </p>
                                    </div>
                               </>
                               ||
                               <>Cargando...</>
                            }
                        </div> 
                    </div>
                </div>
                
     


                {(infoTercero && infoTercero.ct_persona ) && 
                <div className="mb-3 card">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                            <h3>Información</h3>
                            {/**<button type="button" className="px-3 btn btn-link">Editar</button>*/}
                        </div>
                        


                        <div className="mb-3">
                            <div className="d-flex  align-items-center mb-0">
                                <i className="fa-solid fa-envelope me-1"></i>
                                <span className="text-body-highlight fw-bold mb-0">Email</span>
                            </div>
                            <span>{infoTercero.ct_persona.CtPersona_correoelectronico}</span>
                        </div>


                        <div className="mb-3">
                            <div className="d-flex  align-items-center mb-0">
                            <i class="fa-solid fa-route"></i>
                                <span className="text-body-highlight fw-bold mb-0">Dirección</span>
                            </div>
                            <span>{infoTercero.ct_persona.CtPersona_direccion}</span>
                        </div>


                        <div className="mb-3">
                            <div className="d-flex  align-items-center mb-0">
                            <i class="fa-solid fa-location-pin"></i>
                                <span className="text-body-highlight fw-bold mb-0">Municipio</span>
                            </div>
                            <span>{infoTercero.ct_persona.municipio}</span>
                        </div>



                        <div className="mb-3">
                            <div className="d-flex  align-items-center mb-0">
                                <i class="fa-solid fa-phone"></i>
                                <span className="text-body-highlight fw-bold mb-0">Teléfono</span>
                            </div>
                            <span>{infoTercero.ct_persona.CtPersona_telefono}</span>
                        </div>


                        <div className="mb-3">
                            <div className="d-flex  align-items-center mb-0">
                            <i class="fa-solid fa-people-roof"></i>
                                <span className="text-body-highlight fw-bold mb-0">Grupo de Precio</span>
                            </div>
                            <span>{infoTercero.ct_persona.lista_precio}</span>
                        </div>


                        <div className="mb-3">
                            <div className="d-flex  align-items-center mb-0">
                                
                                <span className="text-body-highlight fw-bold mb-0">Fecha de Registro</span>
                            </div>
                            <span>{ __fecha.formatoFecha({$fecha : infoTercero.ct_persona.CtPersona_creado }) }</span>
                        </div>
                        
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default DatosPersonales;