import React from "react";

const calcularProgreso = (fecha1, fecha2) => {
  const date1 = new Date(fecha1);
  const date2 = new Date(fecha2);
  const fechaActual = new Date();

  const diasOtorgados =  Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));


  const diasRestantes =  Math.max(0, Math.ceil((date2 - fechaActual) / (1000 * 60 * 60 * 24)));
  console.log(diasRestantes)
  const faltantePorcentaje = Math.round((diasRestantes * 100) / diasOtorgados);

  let clase = "bg-secondary"; // Clase por defecto
  if (faltantePorcentaje <= 10) clase = "bg-danger";
  else if (faltantePorcentaje <= 30) clase = "bg-warning";
  else if (faltantePorcentaje >= 60) clase = "bg-success";

  return { diasOtorgados ,diasRestantes ,  faltantePorcentaje, clase };
};

const MensualidadBar = ({ fecha1, fecha2 }) => {
  const { diasOtorgados , diasRestantes , faltantePorcentaje, clase } = calcularProgreso(fecha1, fecha2);
  console.log()
  return (
    <div className="w-100 mx-auto p-4">
      <p className="text-center mb-2">Tiempo restante: {faltantePorcentaje}%</p>
      <div className="progress" style={{ height: "20px" }}> 
        <div
          className={`progress-bar ${clase}`}
          role="progressbar"
          style={{ width: `${faltantePorcentaje}%` }}
          aria-valuenow={faltantePorcentaje}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
       
      </div>
      <p>Desde {fecha1} hasta {fecha2}</p>
    </div>
  );
};

export default MensualidadBar;