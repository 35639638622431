
import React from 'react';
import { __app } from '../../../../utils/myApp';
import ListaPagosTirilla from './ListaPagosTirilla';

const FormaPagoTirilla = ({documento , cambio }) => {
    return (
        <div>
            
            
            {/* Formas de Pagos */}         
            { Number(documento?.TIPO_DOCUMENTO?.ct_master_id) >= 1
                &&  
                
                <div className="contenedorPagos" >            
                    <span className="font12 TirillaTextCenter block">
                        Formas de pagos
                    </span>
                        
                    
                    
                    <ListaPagosTirilla  documento={documento} />






                    {cambio &&  cambio > 0 && 
                        <span className="">      
                            <h4>
                                PAGO CON : { Number(documento.ESTRAS.PAGO_CON ) } <br/>
                                <span>Cambio : {cambio} </span>
                            </h4>
                        </span> 
                    }

                    { /* <div className="lineapagos">    
                        {   
                        SaldoPendiente != 0   &&
                        <div>
                            <span className="font12">Saldo Pendiente</span>
                            <span className="font12">$ 1000.000 </span>
                        </div>
                        }

                        {
                        TotalPagado != 0   &&
                            <div>          
                                <span className="font12">Total Pagado</span>
                                <span className="font12">$ 1000.000 </span>
                            </div>
                        }
                    </div> */}
                </div>}
        </div>
    );
};

export default FormaPagoTirilla;