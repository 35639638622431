

import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';


import { BrowserRouter as Router } from "react-router-dom";


import App from './App';


import { StateProvider } from './context/StateProvider';
import { StateObservadoresProvider } from './context/StateObservadores';
import { initialState , initialStateObservador } from './context/InitialState';
import reducer from './context/reducer';
import reducerObservador from './context/reducerObservador';



/** NOTIFICACIONES   **/
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   
    <Router>
      <StateProvider  initialState={initialState} reducer={reducer}  >


            <StateObservadoresProvider   initialState={initialStateObservador}  reducer={reducerObservador}  >
              <App /> 
            </StateObservadoresProvider>
            
            <NotificationContainer/>
            
        </StateProvider>
    </Router>
   
    
);



  
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

