import React, { useEffect, useRef, useState } from 'react';
import { fetchNombreDispositivo, getLocalstore, GetNombre_firebaseLastKeyClientes } from '../../../../Core/fetchLocalStoreData';
import OptionSelect from '../../../../Core/Html/OptionSelect';
import { calcularDigitoVerificacion, registrarTercero } from '../../Core/Core';
import { nanoid } from 'nanoid';
import { ExtraerTablabyIds, reloadDatabases } from '../../../../Core/Dixie';
import { NotificationManager } from 'react-notifications';
import { registrarVehiculo } from '../../../Parqueadero/Vehiculos/Core/Controlador';
import { insertarCambioFirebase } from '../../../Productos/Controlador';
import { useStateValue } from '../../../../context/StateProvider';
import { __app } from '../../../../utils/myApp';
import { TipoEmpleado } from '../../../Elementos/Etiquetas';
const NuevoTercero = ({ handleSubmitPadre, cerrar, estado_ajax, modoForm = 'rapido', defaultValues, registroConExito }) => {

    const [{ KeyObservadores }, dispatch] = useStateValue();


    if (defaultValues) {
        defaultValues.identificacion_editar = defaultValues.identificacion;
    }

    const refIdentidicacion = useRef();

    useEffect(() => {



        setTimeout(() => {
            // refIdentidicacion.current.focus();
        }, 100);


    }, [])


    /***
     * 
     *  modo 'full'  : Despliega todos los campos.
     *  modo 'rapido'   : Solo despliega los mas importante
     * 
     */

    const [modo, setModo] = useState(modoForm);

    const database = getLocalstore("__Ah#d7aWF___");

    if (database.datos_precarga) {


    }

    const user = getLocalstore("user");

    const [dvnit, setDvnit] = useState();

    const [btnbmitLoad, setBtnbmitLoad] = useState();



    const plantillaForm = {

        "fe_tipodocumento_id": "",
        "fe_tipopersona_id": "",
        "identificacion": "",
        "dvnit": "",
        "razonSocial": "",
        "nombrecomercial": "",
        "direccion": "",
        "telefono": "",
        "correoelectronico": "",
        "fe_municipio_id": "",
        "fe_regimenfiscal_id": "",
        "per_categoria_persona_id": "1"
    }




    const initialState = defaultValues ?? plantillaForm;


    const identificacionInicial = initialState.identificacion;

    const [formData, setFormData] = useState(initialState);

    // INICIALMENTE UNA CADENA DE TEXTO
    if (typeof initialState.per_categoria_persona_id === 'string') {
        initialState.per_categoria_persona_id = initialState.per_categoria_persona_id.split(", ");
    }

    const [selectedCheckboxes, setSelectedCheckboxes] = useState(initialState.per_categoria_persona_id || []);
    useEffect(() => {

        setBtnbmitLoad(estado_ajax);

    }, [estado_ajax])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleCheckboxChange = (e) => {
        const { checked, value } = e.target;
        const stringValue = String(value);

        console.log('cambiando valor', { checked, value });

        setSelectedCheckboxes((prevSelected) => {
            const currentValues = Array.isArray(prevSelected) ? prevSelected : [];

            console.log('valores actuales', currentValues);

            const updatedValues = checked
                ? [...currentValues, stringValue]
                : currentValues.filter((item) => item !== stringValue);

            console.log('valores reales:', updatedValues);

            return updatedValues;
        });
    };
    // EFECTO PARA ACTUALIZAR LA FORMDATA
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            per_categoria_persona_id: selectedCheckboxes,
        }));
    }, [selectedCheckboxes]);








    const ocultarForm = () => {
        if (cerrar) {
            cerrar();
        }

    }

    const generarCedulaAutomatica = () => {

        setFormData((prevData) => ({ ...prevData, "identificacion": generateAlphanumericId() }));
    }

    const generateAlphanumericId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const length = 8;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return result;
    }












    const handleSubmit = (e) => {
        e.preventDefault();


        console.log(formData);
        if (selectedCheckboxes.length === 0) {
            NotificationManager.error("Debes seleccionar al menos un tipo de contacto");
            return;
        }



        // Validación de los campos obligatorios
        if (!formData.identificacion || !formData.razonSocial) {
            NotificationManager.error("La identificacion y la razonSocial son obligatorias")
            return;
        }


        if (formData.identificacion.length <= 2) {
            NotificationManager.error("La identificacion minimo debe contener 3 caracteres")
            return;
        }


        if (formData.razonSocial.length <= 2) {
            NotificationManager.error("El nombre  minimo debe contener 3 caracteres")
            return;
        }

        guardarItem();

    };


    const guardarItem = async () => {
        // convertir a una cadena de texto para guardar en la base de datos
        const categoriasString = selectedCheckboxes.join(", ");


        let data = await ExtraerTablabyIds("terceros", [formData.identificacion], 'identificacion');


        console.log(data)

        if (data[0] && data[0].identificacion != identificacionInicial) {

            NotificationManager.error("Esta identificacion ya Existe")
            return false;
        }

        let copy = {
            ...formData,
            per_categoria_persona_id: categoriasString,
            config: {
                nombre_de_dispositivo: fetchNombreDispositivo()
            }
        }
        console.log(copy)
        console.log(copy)

        let ressHttp = await registrarTercero(copy);


        if (ressHttp.error == 0) {



            NotificationManager.success("Registrado con exito.");

            if (ressHttp.data?.tercero?.id) {

                if (registroConExito) {
                    registroConExito(ressHttp.data.tercero);
                }
                reloadDatabases([ressHttp.data.tercero], 'terceros');
                localStorage.setItem(GetNombre_firebaseLastKeyClientes(), ressHttp.data.versionDatabase.aux.lastId)
            }



            insertarCambioFirebase(ressHttp.data.versionDatabase.aux)


            dispatch({
                type: 'SET_KeyObservadores',
                KeyObservadores: { ...KeyObservadores, TERCEROS: __app.numeroRandom() },
            })


        } else {
            NotificationManager.error("Error al Crear Vehiculo");
        }

    }


    const HandleMasCampos = () => {

        if (modo == "full") {
            setModo("rapido");
        } else {
            setModo("full");
        }
    }

    const botonTexto = modo === "full" ? "Ver menos campos" : "Más campos";
    const botonColor = modo === "full" ? "btn-subtle-warning" : "btn-subtle-info";
    const botonIcono = modo === "full" ? "fa-minus" : "fa-plus";
















    return (
        <>
            <form onChange={handleChange} onSubmit={handleSubmit} method="POST">
                <div className="row g-2">
                    <div className='text-center'>
                        <h3>✍️Formulario de Registro</h3>
                        <p className='text-muted fs-9'>
                            <i className='text-warning fas fa-exclamation-circle me-1'></i>
                            <i className='text-danger fas fa-asterisk me-1'></i>
                            Indica un campo obligatorio.
                        </p>
                    </div>





                    <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-2">
                            <label htmlFor="nit">Identificación <span className='text-danger fs-7 fw-bold m-0'>*</span></label>
                            <div className="input-group">
                                <input ref={refIdentidicacion} onChange={() => { }} value={formData.identificacion} autoComplete="off" disabled={btnbmitLoad} type="text" id="nit" className="form-control" name="identificacion" placeholder="Cédula o Nit" required />
                                {!formData.id && (
                                    <span onClick={generarCedulaAutomatica} className="btn btn-outline-success btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title={'Si el tercero no comparte su identificacion, el sistema puede generar una automaticamente (No recomendado) '} >Auto</span>
                                )}
                            </div>
                        </div>
                    </div>



                    <div className="col-xl-4  col-md-6">
                        <div className="form-group mb-2">
                            <label htmlFor="">Nombre completo o Ranzón Social <span className='text-danger fs-7 fw-bold m-0'>*</span></label>
                            <input onChange={() => { }} value={formData.razonSocial} autoComplete='off' disabled={btnbmitLoad} type="text" class="form-control" name="razonSocial" placeholder="Razon Social" required />
                        </div>
                    </div>


                    {modo == "full" && <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-2">
                            <label htmlFor="">Nombre Comercial</label>
                            <input onChange={() => { }} value={formData.nombrecomercial} autoComplete='off' disabled={btnbmitLoad} type="text" class="form-control" name="nombrecomercial" placeholder="Nombre Comercial" />
                        </div>
                    </div>
                    }




                    <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-2">
                            <label htmlFor="">Teléfono</label>
                            <input onChange={() => { }} value={formData.telefono} autoComplete='off' disabled={btnbmitLoad} type="text" class="form-control" name="telefono" placeholder="Teléfono" />
                        </div>

                    </div>


                    <div className="col-xl-4 col-md-6" >
                        <div className="form-group mb-2">
                            <label htmlFor="">Correo electrónico</label>
                            <input onChange={() => { }} value={formData.correoelectronico} autoComplete='off' disabled={btnbmitLoad} type="email" className="form-control" name="correoelectronico" placeholder="E-mail" />
                        </div>
                    </div>


                    <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-2">
                            <label htmlFor="">Dirección</label>
                            <input onChange={() => { }} value={formData.direccion} autoComplete='off' disabled={btnbmitLoad} type="text" className="form-control" name="direccion" placeholder="Dirección Fisica" />
                        </div>
                    </div>



                    <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-2">
                            <label htmlFor="">Tipo de Contacto <span className='text-danger fs-7 fw-bold'>*</span></label>
                            <div class="mt-2">
                                {database.datos_precarga.per_categoria_personas.map((item) => (
                                    <div key={item.id} className="form-check form-check-inline">
                                        <TipoEmpleado id={item.id} check={1} />
                                        <input  type="checkbox" className="form-check-input"   name="per_categoria_persona_id"  value={item.id}  onChange={handleCheckboxChange} checked={selectedCheckboxes.includes(item.id)} disabled={btnbmitLoad} id={item.id}  />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>





                    {user.CONFIGURACIONES_TABLE?.listadeprecio == 1 &&
                        <div className="col-xl-4 col-md-6">
                            <div className="form-group  mb-2">
                                <label htmlFor="">Lista de Precios</label>
                                <select onChange={() => { }} value={formData.ct_lista_precio_id} autoComplete='off' className="form-control" name="ct_lista_precio_id" >
                                    <OptionSelect datos={user.DATABASE.ct_lista_precios} />
                                </select>
                            </div>
                        </div>
                    }



                    {modo == 'full' && <>

                        <div className="col-xl-4  col-md-6">
                            <div className="form-group ">
                                <label>Tipo de Documento</label>
                                <select onChange={() => { }} value={formData.fe_tipodocumento_id} autoComplete='off' disabled={btnbmitLoad} class="form-control" name="fe_tipodocumento_id" required>
                                    <OptionSelect datos={database.datos_precarga.fe_tipodocumentos} />
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-4  col-md-6">
                            <div className="form-group ">
                                <label>Tipo de Persona</label>
                                <select onChange={() => { }} value={formData.fe_tipopersona_id} autoComplete='off' disabled={btnbmitLoad} class="form-control" name="fe_tipopersona_id" required>
                                    <OptionSelect datos={database.datos_precarga.fe_tipopersonas} />
                                </select>
                            </div>
                        </div>






                        <div className="col-xl-4  col-md-6">
                            <div className="form-group mb-2">
                                <label htmlFor="">Regimén Fiscal</label>
                                <select onChange={() => { }} value={formData.fe_regimenfiscal_id} autoComplete='off' disabled={btnbmitLoad} className="form-control" name="fe_regimenfiscal_id" required>
                                    <OptionSelect datos={database.datos_precarga.fe_regimenfiscals} />
                                </select>
                            </div>
                        </div>

                        <div className="col-xl-4  col-md-6">
                            <div class="form-group mb-2">
                                <label htmlFor="">Municipio</label>
                                <select onChange={() => { }} value={formData.fe_municipio_id} autoComplete='off' disabled={btnbmitLoad} className="form-control" name="fe_municipio_id" required>
                                    <OptionSelect datos={database.datos_precarga.fe_municipios} />
                                </select>
                            </div>
                        </div>


                    </>
                    }
                </div>

                <div className='text-center'>

                    <span className={`btn ${botonColor} me-2`} onClick={HandleMasCampos}>
                        <i className={`fa-solid ${botonIcono} fa-lg`}></i> {botonTexto}
                    </span>

                    <button disabled={estado_ajax} type="submit" value="" class="btn btn-success"  > Guardar
                        {
                            estado_ajax &&
                            <span className="ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span>
                        }
                    </button>
                </div>
            </form>

        </>

    );
};

export default NuevoTercero;