
import React , {useEffect , useState } from 'react';
import { getLocalstore , setLocalStore } from '../../../Core/fetchLocalStoreData';
import {enviarPeticion} from '../../../Core/ActionDatabase'
import OptionSelect  from '../../../Core/Html/OptionSelect';
import InputValidation from './InputValidation';


const CategoriasV1 = ({formData, btnbmitLoad  , formDataValidations }) => {

    const [categorias, setcategorias] = useState([]);

    useEffect( () => {

        let data = getLocalstore("Listcategorias")
       
        if(!data){
            traerCategorias(); 
        }else{
            setcategorias(data)
        }
    } , [] )


    const traerCategorias = async() => {

        const HttpResponse = await enviarPeticion("productos/get_tabla_aux_productos");
        if(HttpResponse.respuesta == 1 ){            
            setLocalStore("Listcategorias" ,HttpResponse.data.grupos );
            setcategorias(HttpResponse.data.grupos);
        }else{
            alert("Error de Red")
        }
    }

    return (
        <div className="form-group mb-3">
           
            <InputValidation formData={formData}  options={categorias} TipoElementoForm={'select'} label={"Categoría"}  formDataValidations={formDataValidations}   defaultValue={formData.productogrupo_id}  btnbmitLoad={btnbmitLoad} id="productogrupo_id" className="form-control" nameForm="productogrupo_id"   />   
        </div>
    );
};

export default CategoriasV1;