import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getReporteByTurno , getDocumentoByTipoTurno} from './Controlador';
import {__app, __fecha} from "../../utils/myApp"

import Acordiones from './ViewTurnoid/Acordiones';
import AcordionCuentasPendientes from './ViewTurnoid/AcordionCuentasPendientes';
import AcordionConsolidado from './ViewTurnoid/AcordionConsolidado';
import VentasVendedor from './ViewTurnoid/VentasVendedor';
import VentasItems from './ViewTurnoid/VentasItems';
import CuentasAbiertasEliminadas from './ViewTurnoid/CuentasAbiertasEliminadas';
import Facturaseliminadas from './ViewTurnoid/Facturaseliminadas';
import ItemsCtaEliminados from './ViewTurnoid/ItemsCtaEliminados';
import { fetchUSer } from '../../Core/fetchLocalStoreData';
import ConsumoPagos from './ViewTurnoid/ConsumoPagos';
import { Numpositivo } from '../../Core/MatematicasGlobales';


function TurnoView(props) {

    let  { id } = useParams();
    
    const [ tipoDocumentos  , setTipoDocumentos ] = useState([]);
    const [ tipoOperaciones , settipoOperaciones ] = useState([]);
    const [ consolidadoCaja , setConsolidadoCaja ] = useState([]);
    const [usoSaldoFavor , SetusoSaldoFavor]       = useState([]);
    const [cierresParciales , setCierresParciales ]       = useState([]);


    const [pagosDocumentoOtrosTurnos , SetpagosDocumentoOtrosTurnos ]       = useState([]);

    const [ totalConsolidado , setTotalConsolidado] = useState();
    const [ totalSaldoFavorConsumido , settotalSaldoFavorConsumido] = useState(0);

    const [ aux  , setaux ] = useState([]);
    
    const [precarga , SetPrecarga ] = useState(1);
    
    
    const user = fetchUSer();

    
    useEffect( () => {

        optenerDetalles(id);
        
        
        





        // Limpiar el cambio cuando el componente se desmonta
        return () => {
            document.body.style.backgroundColor = '';
        };



    } , [] )




    const optenerDetalles = async () => {
        let data = await getReporteByTurno(id);

        if(data){
            SetPrecarga(null);

              //setTipoDocumentos(data);
            // settipoOperaciones(data.operacionestipo)
            combinarDatos(data);

            
            setCierresParciales(data.cierresParciales)
        
            organizarconsolidado(data.consolidado_de_caja , data.formas_de_pago , data.uso_saldo_favor )


            SetpagosDocumentoOtrosTurnos(data.pagos_otros_turno);     

            SetusoSaldoFavor({
                    
                comprobante : data.uso_saldo_favor,
                nombre : "SALDO A FAVOR CONSUMIDO" 
            });

        }
        
    
      
    }


    const organizarconsolidado = (consolidado_de_caja , formas_de_pago ,uso_saldo_favor ) => {



        console.log(consolidado_de_caja)
        console.log(formas_de_pago)
    
        let totalGlobal = 0;



       // Iterar sobre formasPago y agregar la propiedad "comprobante"
       formas_de_pago.forEach((formaPago) => {



            formaPago.comprobante = consolidado_de_caja.filter(

                (comprobante) => comprobante.forma_pago_id === formaPago.id
            );
            




            formaPago.TOTAL = formaPago.comprobante.reduce(
                
                (total, comprobante) => {
                    // Verificar el valor de ct_master_id y ajustar el signo
                    let amount = parseFloat(comprobante.total);
                    amount = Numpositivo(amount)
                    if (comprobante.ctmid == 2) {



                        amount = amount * - 1; // Convertir a número negativo si ct_master_id es 2
                    }
                    console.log(amount)
                    return total + amount;
                },

                0
            );


            totalGlobal  +=   formaPago.TOTAL;
        });
        
        console.log(formas_de_pago)

        let totalSaldoAFavor = 0;
        console.log(uso_saldo_favor);
        if (uso_saldo_favor) {
            totalSaldoAFavor = uso_saldo_favor.reduce(
                (total, consumo) => total + parseFloat(consumo.tt),
                0
            );
        }
        settotalSaldoFavorConsumido(totalSaldoAFavor);


        setTotalConsolidado(totalGlobal );


        setConsolidadoCaja(formas_de_pago);
        
    }



    const combinarDatos = (data) => {
        
        data.operacionestipo.forEach(function(operacion) {
            
            operacion.DOCUMENTOS = data.tipodc.filter(function(documento) {
                return documento.ct_operacion_id === operacion.id;
            });

            operacion.CUENTASPENDIENTES = data.cuentas_pendientes.filter(function(docu) {
                return docu.ct_operacione_id === operacion.id;
            });


    
            operacion.TOTAL   = operacion.DOCUMENTOS.reduce(function(acumulador, objeto) {
                console.log(objeto);
                if(objeto.ct_master_id > 0 ){
                    return acumulador +  parseFloat(objeto.SUBTOTAL_3);
                }
            }, 0);


        });
        




        data.operacionestipo.forEach(function(operacion) {
            operacion.X_PAGAR  = sumarCuentasporPagar(operacion.CUENTASPENDIENTES) ;
            operacion.X_COBRAR = sumarCuentasporCobrar(operacion.CUENTASPENDIENTES);
        });


      

        
        settipoOperaciones(data.operacionestipo)
    }



    const sumarCuentasporPagar = (cuentasPendientes) => {
        let numero = 0;
        cuentasPendientes.forEach(objeto => {
            if (objeto.total < 0) {
                numero += parseFloat(objeto.total);
            } 
        });
        return numero;
    }


    const sumarCuentasporCobrar = (cuentasPendientes) => {
        let numero = 0;
        cuentasPendientes.forEach(objeto => {
            if (objeto.total > 0) {
                numero += parseFloat(objeto.total);
            } 
        });
        return numero; 
    }






    const cargarDocumentos = async (key) => {
        
      
        
        if (tipoDocumentos[key].DOCUMENTO ){
    
            return false
        }



        let data = await getDocumentoByTipoTurno(id ,tipoDocumentos[key].id );
        const aux =  [...tipoDocumentos];
        aux[key].DOCUMENTO = data


        console.log(aux);
        setTipoDocumentos(aux);

    
    }

    const cargarDocumentos2 = async (keyOperaciones,  index) => {


        if (tipoOperaciones[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP &&  tipoOperaciones[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP == 1 ){
            return false
        }
        
    

        if (tipoOperaciones[keyOperaciones].DOCUMENTOS[index].DOCUMENTOS ){
            return false
        }
        

        /*** Iniciamos una CARGA */
        const aux2 =  [...tipoOperaciones];
        aux2[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP = 1;
        settipoOperaciones(aux2)



        let data = null;
        data = await getDocumentoByTipoTurno(id ,tipoOperaciones[keyOperaciones].DOCUMENTOS[index].id);
        if(data){

            const aux =  [...tipoOperaciones];
            aux[keyOperaciones].DOCUMENTOS[index].DOCUMENTOS = data;
            aux[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP = null;
            settipoOperaciones(aux)
        }
    
    }
    



    
    if ( !(user.PERMISOS.verTurno && user.PERMISOS.verTurno == 1) ){

        return (<h3 className='text-white'>No tienes permisos para ver turno</h3>); 

    }else{
      
    }


    if(precarga == 1){
        return (<h1 className='text-white'>Cargando...</h1>);
    }
    
    return (
        <div>
            <h3 className='text-dark'>Informe de Turno #{id}</h3>



            
            {cierresParciales?.length > 0  &&  <div    className='mt-5 card pt-5 pb-5 pe-3 ps-3' >

                <h3>Cierres Parciales del Turno</h3>

                <table className='table table-bordered'>
                    <tr>
                        <th>Fecha</th>
                        <th>Usuario</th>
                        <th>EFECTIVO DEL SISTEMA</th>
                        <th>Descruadres Anteriores</th>
                        <th>Efectivo a Reportar</th>
                        <th>Efectivo Reportado</th>
                        <th>Descuadre</th>
                    </tr>
                {cierresParciales.map((data) => 

                    <tr key={data.id}>
                        <td>{__fecha.formatoFecha({$fecha :  data.fecha_ingreso })}</td>
                        <td>{data.user_nombre}</td>
                        <td> $ { __app.formato_decimal({total: data.EFECTIVO_SISTEMA_ACTUAL })  } </td>
                        <td> $ { __app.formato_decimal({total: parseInt(data.efectivo_recibe) -  parseInt(data.EFECTIVO_SISTEMA_ACTUAL) })  }</td>
                        <td>  $ { __app.formato_decimal({total: data.efectivo_recibe })  } </td>
                        <td> $ { __app.formato_decimal({total: data.efectivo_entrega })  }</td>
                        <td>
                            <span dangerouslySetInnerHTML={{ __html:  __app.formato_decimal({total:  parseInt( data.efectivo_entrega) -  parseInt(data.efectivo_recibe)  , color : true , MasterId : 1})  }  } />
                             </td>
                    </tr>
                    
                )}
                </table>

            </div> }  


            { tipoOperaciones.map((ope , index)  => 
               
               
               <div  key={index}>
              
                {
                    (ope.DOCUMENTOS.length > 0 ) && 
                        <div   key={index} className='mt-5 card pt-5 pb-5 pe-3 ps-3' >
                            <h3>{ope.nombre}</h3>

                            <Acordiones tipoDocumentos={ope.DOCUMENTOS}  cargarDocumentos={cargarDocumentos2}  indexx={index}  />
                            
                            
                            { ( ope.TOTAL > 0 || ope.TOTAL < 0 ) && <h2 className='mt-2'> TOTAL  : $ {  __app.formato_decimal({total: ope.TOTAL })  }</h2> }




                           



                            {
                                ( ope.X_PAGAR != 0   ||  ope.X_COBRAR != 0   ) &&

                                <div>
                                    <h4 className='mt-5'>Cuentas pendientes</h4>
                                    <AcordionCuentasPendientes signo="+" cuentasPendientes={ope.CUENTASPENDIENTES} id={'ctpcc'} indexx={index}  titulo="Cuentas por Cobrar" operacion={ope} /> 
                                    <AcordionCuentasPendientes   signo="-"  cuentasPendientes={ope.CUENTASPENDIENTES}   id={'ctppc'} indexx={index}  titulo="Cuentas por Pagar " operacion={ope}  /> 
                                
                                </div>

                            }



                           


                          


                        </div>
                }
               </div>
            )}
            
            
            <div className='mt-5 card pt-5 pb-5 pe-3 ps-3' >
                 <h4 className='mt-5'>Recuperación de Creditos de otros Turnos.</h4>
                <AcordionCuentasPendientes   signo="null" cuentasPendientes={pagosDocumentoOtrosTurnos} id={'ctpcc'} indexx={5454545}  titulo="Abonos de facturas de otros turnos"  />                
            </div>

            <div className='mt-5 card pt-5 pb-5 pe-3 ps-3' >

                <h3 className='mt-5' >OTROS DATOS  </h3>
            
                <VentasVendedor turno_id={id} />
                <VentasItems turno_id={id} />

                <CuentasAbiertasEliminadas turno_id={id} />
                <ItemsCtaEliminados turno_id={id} />
                {
                    /*
                    <Facturaseliminadas turno_id={id} />
                    <ItemsCtaEliminados turno_id={id} />
                    */
                }
            </div>
          
            






            <div className='mt-5 card pt-5 pb-5 pe-3 ps-3' >

                <h1>Consolidado de Caja</h1>    

                { consolidadoCaja.map( (consolidado , index2 ) => 

                    <div key={index2}>
                        {
                            ( consolidado.comprobante.length > 0 ) && 

                            <AcordionConsolidado   key={index2}  consolidado={consolidado} indexx={index2} />
                        }
                    </div>


                       

                )}

                

                <h2 className='mt-5 text-success text-center'>Total Consolidado <b> $ { __app.formato_decimal( { total : totalConsolidado})  }  </b></h2>
                
                
                <div className='mt-5'>
                
                    <ConsumoPagos consolidado={usoSaldoFavor} saldoFavor={totalSaldoFavorConsumido}   /> 
                </div>


            </div>   
            
        

        </div>
    );
}





export default TurnoView;