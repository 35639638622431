import React, { useState } from 'react';


import Dropdown from 'react-bootstrap/Dropdown';

import { useStateValue } from '../../../context/StateProvider';
import { actionType } from "../../../context/reducer";
import { useEffect } from 'react';

import { useContextDocumentValue, actionTypeDocument } from "../context/ContextDocument.js";
import { resetearDocumentoGlobal } from "../../Document/controlador";

import { DocumentL } from "../DocumentCore";
import { showAlert } from '../../Layaut/LayautDefault.jsx';

const TipoDocumento = ({ usuario, LogicaDocumento, actualizarTab, TabIndexActual = null }) => {


    const [{ user }] = useStateValue();


    const [tipodocumentos, setTipodocumentos] = useState(null);



    const [{ documento }, dispatch] = useContextDocumentValue();


    const [default_value, setDefault_value] = useState(1);





    useEffect(() => {

        setTipodocumentos(user.DATABASE.tipo_documento);

        if (documento) {
            if (!documento.TIPO_DOCUMENTO) {
                if (tipodocumentos) {
                    let tipoDocumento = tipodocumentos[0];
                    dispatch({
                        type: actionType.SET_DOCUMENTO,
                        documento: {
                            ...documento, "TIPO_DOCUMENTO": tipoDocumento
                        },
                    })
                }
            } else {
                setDefault_value(documento.TIPO_DOCUMENTO.id)
            }
        }

    }, [documento])





    const handleChange = (nuevo) => {

        console.log(nuevo)
        if(  
            
            !(
                
                // Tiene que ser el mismo Tipo de documento y operacion
                (nuevo.ct_master_id == documento.TIPO_DOCUMENTO.ct_master_id  &&   nuevo.ct_operacion_id  == documento.TIPO_DOCUMENTO.ct_master_id ) 
            
                
                ||

                // Podemos pasar a Cotizacion
                nuevo.ct_master_id == 0 && documento.TIPO_DOCUMENTO.cuenta_abierta == 0 &&  documento.TIPO_DOCUMENTO.ct_operacion_id == 1

                || 

                // No podemos pasar de cotizacion a Compra 
                documento.TIPO_DOCUMENTO.ct_master_id == 0 &&  nuevo.ct_operacion_id == 1


               ||
               
               //Que no tenga productos
               documento.PRODUCTOS.length == 0 



               || 

               //De factura a cuenta abierta
               (documento.TIPO_DOCUMENTO.ct_master_id == 1  &&  documento.TIPO_DOCUMENTO.ct_operacion_id == 1  && nuevo.cuenta_abierta == 1 )
            )
        
        ){
            
            showAlert("Estos documentos no son Compatibles,  Elimina todos los items del documento para poder cambiarte a : " + nuevo.nombre );
            return false;
        }
        

        

        if (actualizarTab) {
            actualizarTab(nuevo);
        }

        LogicaDocumento.despachador("cambiar_tipo_documento", {
            valor: nuevo
        })
    }

    const cancelar = () => {


        resetearDocumentoGlobal(TabIndexActual, dispatch, actionTypeDocument, tipodocumentos[0]);
        actualizarTab(tipodocumentos[0]);
    }

    if (!tipodocumentos) {
        return false;
    }


    /*
    { documento.TIPO_DOCUMENTO &&  }
    */

    if (!documento.TIPO_DOCUMENTO) {
        return false;
    }





    return (

        <div className='d-none d-sm-block'>
            <Dropdown>
                <Dropdown.Toggle variant="null" className={'tipo_de_documento   p7-btn-' + documento.TIPO_DOCUMENTO.color_documento + ' '}  >
                <i className={documento.TIPO_DOCUMENTO.icono_documento}></i>{documento.TIPO_DOCUMENTO.nombre}

                </Dropdown.Toggle>

                {(documento.TIPO_DOCUMENTO.bloquear && documento.TIPO_DOCUMENTO.bloquear == true) &&

                    <Dropdown.Menu>

                        <Dropdown.Item onClick={cancelar} > Cancelar  </Dropdown.Item>

                    </Dropdown.Menu>

                    ||


                    // ANDRESSS
                    // <Dropdown.Menu>
                    //     {/* {tipodocumentos.map((tpd, index) =>
                    //         <Dropdown.Item onClick={() => handleChange(tpd)} key={index} > {tpd.nombre}</Dropdown.Item>
                    //     )} */}

                    //     {tipodocumentos.map((tpd, index) =>
                    //         <span p7-data-bs-toggle="tooltip" data-bs-placement="right" title="Seleccionar" className={`badge p7-badge-${documento.TIPO_DOCUMENTO.color_documento}`}>
                    //             <i className={`badge p7-badge-${documento.TIPO_DOCUMENTO.color_documento}`}></i>{tpd.nombre}
                    //         </span>
                    //     )}

                    // </Dropdown.Menu>

                    // HECHO YULII
                    <Dropdown.Menu className='overflow-auto scrollbar' style={{ maxHeight: '28rem'  }}>
                        {tipodocumentos.map((tpd, index) => (
                            <Dropdown.Item onClick={() => handleChange(tpd)} key={index} className="d-flex align-items-center" data-bs-toggle="tooltip" data-bs-placement="right" title="Seleccionar"   >
                                <span className={`badge p7-badge-${tpd.color_documento} me-2`} style={{width: "20px", height: "20px",display: "inline-flex",  justifyContent: "center", alignItems: "center"   }} >
                                    <i className={`${tpd.icono_documento} fs-9`}></i>
                                </span>
                                {tpd.nombre}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>

                }




            </Dropdown>
        </div >
    );
}

export default TipoDocumento;