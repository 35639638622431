import React, { useState } from 'react';
import { __app } from '../../../../utils/myApp';
import ListaPagos from './ListaPagos';
import AgregarPago from '../../../Pagos/AgregarPago/AgregarPago';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';

const Pagos = ({document , recargarDocumento }) => {

    const [ menu , setMenu  ] = useState(1);

    const {   PAGOS  } = document; 
    const {   DOCUMENTO  } = document;  


    const totalDocumento = parseFloat(DOCUMENTO.CtDocumento_SUBTOTAL_3);
    const totalPagado = PAGOS
    .filter((item) => item.estado == 1) // Seleccionar elementos con estado igual a 1
    .reduce((acumulador, item) => acumulador + parseFloat(item.total), 0); // Sumar el valor de 'total'

    const pendiente = totalDocumento - totalPagado;

    const cerrarModalPagos = (recargar_documento = 0) => {
      
        setMenu(1); 

    }


    let configuraciones = {
        
        total : Numpositivo(pendiente), 
        document : document, 
        /// Fijo
        categoria_pago_id : 1, 

        master_id : document.CtTipoComprobanteFiscal_ct_master_id, 
        ct_operacione_id  : document.CtTipoComprobanteFiscal_ct_operacion_id, 


    } ;

    const pagoExito = (aux) => {
        recargarDocumento(aux);
    }



    return (
        <div className="col-12 mb-2">
        
            <div className={`card shadow rounded-4 border-opacity-25 border-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`}>
                <div className="card-body table-responsive   ">
                    {menu == 1 && <ListaPagos document={document}  setMenu={setMenu} recargarDocumento={pagoExito}/>}
                    <div className='justify-content-center d-flex'>
                        {menu == 2 && <div className='col-12 shadow  col-lg-4 col-sm-12 card py-4 px-2' style={{maxWidth:500}} >
                        
                        <AgregarPago  configuraciones={configuraciones} cancelar={ cerrarModalPagos }  pagoExito={pagoExito}     /> 

                    </div>}
                    </div>
                </div>
            
            </div>
        </div>
      );
    };
    

export default Pagos;