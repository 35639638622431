

import React from 'react';
import {__app , __fecha } from "../../../utils/myApp"
const ConsumoPagos = ({consolidado , saldoFavor }) => {



    if( !consolidado.comprobante ){
        return false;
    }

    
    let idAcordion = "consumosPagos";
    let titulo = consolidado.nombre;
    
   

    return (
        <div className="accordion" id="accordionPanelsStayOpenExampleCxP">
        <div  className="accordion-item">
               
            <h2 className="accordion-header">
            
                <button  className={'bg-opacity-10  accordion-button collapsed bg-dark' } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + idAcordion } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            
                    <span className={'fw-bold'}>  {titulo}  </span> <br></br>
                   
                    
                    <span className={ 'bg-opacity-75  m-3 badge text-bg-primary '  }> {consolidado.comprobante.length} </span>



                    <small className={"d-inline-flex fw-bold  fs-6 px-2 py-1 fw-semibold  border  rounded-2"}>
                        
                         { __app.formato_decimal( {total :saldoFavor })}

                    </small>

                </button>

            </h2>
         
            <div id={'panelsStayOpen-collapse' + idAcordion } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                Nota:    Cambios, Devoluciones, Anticipos, Bonos
                <div className="accordion-body">
                    
                       
                <table className='table'>
                   <thead>
                   <tr>
                        <th>Fecha</th>
                        <th>Pago Consumido</th>
                        <th>Documento Aplicado</th>   
                        <th>Total consumido</th>
                    </tr>

                   </thead>
                    
                   <tbody>
                    {consolidado.comprobante.map( (pago, index) =>  
                        
                        <tr key={index}>
                           
                            <td>{ __fecha.formatoFecha({$fecha : pago.cr } ) }</td>
                            <td>#{pago.pid}</td>
                            <td>#{pago.ctd_cun}</td>
                            <td>{pago.tt}</td>
                         


                        </tr>
                        
                    )}
                    </tbody>
                </table>

                </div>
            </div>
    </div>

     </div>
    );
};

export default ConsumoPagos;