import React from 'react';
import {__app , __fecha } from "../../../utils/myApp"
import {BadgeCustom } from "../../../Core/Html/ComponenteHtml"
import BotonVerDocumento from '../../Documentos/VerDocumento/BotonVerDocumento';
const CuerpoCtaPendientes = ({cuenta}) => {
    return (    
        <tr>
            
            <td> <BotonVerDocumento id={cuenta.iddocumentos} /> </td>
            <td>{ __fecha.formatoFecha({$fecha : cuenta.CREADO } ) }</td>
            <td>{cuenta.usernombre}</td>
            <td>{cuenta.clienteNombre}</td>
            <td>  <span className={'badge rounded-pill p7-badge-' + cuenta.color_documento }> {cuenta.tipocomprobante} </span> </td>
            <td> <BadgeCustom colores={cuenta.color_tipoCredito} etiqueta={cuenta.tipocreditoNombre} />  </td>
            <td>{cuenta.numero_factura}/{cuenta.iddocumentos}</td>
            <td>
               
                <span> 
                    <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal( {total : cuenta.total  , color : true  ,  MasterId : cuenta.ctmid })  }} />
                </span>   
                                                
                                        
            </td>
            
            
        </tr>
    );
};

export default CuerpoCtaPendientes;