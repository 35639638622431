


import React, { useEffect, useState } from 'react';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import Add from '../../Add';



const Boton = ({ itemEdit, setItemEdit  , successFunction = null  , textoBoton="Agregar Contacto"  , showBotonAdd=true } ) => {


    const [showForm, seshowForm] = useState(null);

    const [switchEditar , setSwitchEditar] = useState(0);


    const mostrarFormulario = () => {

        if(setItemEdit){
            setItemEdit(null);
        }
        
        seshowForm(true);
    }

    useEffect(() => {

        if (itemEdit) {
            seshowForm(true);
            setSwitchEditar(1)
        }

    }, [itemEdit])


    const cerrarModal = () => {
        seshowForm(!showForm);
        if(setItemEdit){
            setItemEdit(null);
        }
        setSwitchEditar(0);
    }

    const registroConExito = (tercero) => {

        if(successFunction){
            successFunction(tercero)   
        }
        
        cerrarModal()
    }

  

    return (
        <div>

            {showBotonAdd &&  <button className='btn btn-success btn-sm' onClick={mostrarFormulario}><i class="fa-solid fa-plus fa-lg me-1"></i>{textoBoton}</button>} 

            <StandarModal size="xl" titulo="Formulario de Registro" desactivar={cerrarModal} mostrar={showForm}  >

                {/*<NuevoTercero modoForm="full" defaultValues={itemEdit}  registroConExito={registroConExito} /> */}
                <Add item={itemEdit} editar={switchEditar}  cerrar={cerrarModal} />

            </StandarModal>
        </div>
    );
};

export default Boton;