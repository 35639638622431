import { enviarPeticion } from "../../../../Core/ActionDatabase";


export const registrarTarifa = async (formData) => {

    let responseHttp = await enviarPeticion('parqueaderos/registrar_tarifa', null, {
        body: {
            POST: formData
        }
    });
    console.log(responseHttp)
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

    

}



export const extraerTarifas = async () => {

    let responseHttp = await enviarPeticion('parqueaderos/listar_tarifas', null);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

}


export const eliminarTarifa = async (id) => {

    let responseHttp = await enviarPeticion('parqueaderos/eliminar_tarifa', id);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp
    } else {
        return -1;
    }

}
