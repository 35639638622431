import React, {useState , useRef , useEffect} from 'react';
import { ExtraerTabla , reloadDatabases } from '../../../../Core/Dixie';

import { NotificationManager} from 'react-notifications';

import {validarFormtercero } from './CoreTercero';
import {fetchEmpresa} from "../../../../Core/fetchLocalStoreData";
import {enviarPeticion} from "../../../../Core/ActionDatabase";
import StandarModal from '../StandarModal';

import NuevoTercero from '../../../Terceros/Add/Formulario/NuevoTercero';

import OptionSelect  from '../../../../Core/Html/OptionSelect';
import { getLocalstore } from '../../../../Core/fetchLocalStoreData';

let tercerosDixie = null;


const FormTercero = ({ handleSubmitPadre , terceroDefaul , accionForm , handleClickCerrar}) => {



 
    const TituloForm = accionForm == 1  ? "Registrar Contacto" : "Editar Contacto" ;
    const textButton = accionForm == 1  ? "Guardar Contacto" : "Confirmar edición" ;
 


    const mystyle = {
        "marginTop": "30px"  
    };








    
    const registroConExito = (tercero) => {
       
        handleSubmitPadre(tercero)

    }


    


    return (
        <div className='contanedor-form shadow p-3 mb-5 bg-light rounded' >
            <div  className="form-width">

                <br /> 
                <p className='col-md-12 text-center'><b>{TituloForm}</b> </p>
                <div style={mystyle}  >
                  
                    <NuevoTercero  defaultValues={terceroDefaul} registroConExito={registroConExito} cerrar={handleClickCerrar} />
                    
                    
                   
        
                    
                </div>
               
               
           

            </div>
      
        </div>
    );
}

export default FormTercero;