import React, { useEffect, useState } from 'react';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import OptionSelect from '../../../Core/Html/OptionSelect';
import { __app, __fecha } from '../../../utils/myApp';
import { guardarPagoHttp } from '../Core/Controlador';
import { NotificationManager } from 'react-notifications';

const AlertaConBotonDeCierre = ({ tipo, mensaje, tipo_credito_nombre = null }) => {


    return (

        <div className={`alert alert-${tipo} alert-dismissible fade show py-2`} role="alert">
            <h3 >
                Registro de pago
            </h3>
            {mensaje}

            {tipo_credito_nombre && <p>Tipo de Credito a pagar : <b>{tipo_credito_nombre}</b> </p>}
        </div>




    );
};



const AgregarPago = ({ pagoExito, cancelar, configuraciones }) => {


    let {
        tipo_credito_nombre,
        document,
        categoria_pago_id,
        total = 0,
        tipo_de_pago = 0,
        master_id = 0,
        ct_operacione_id = 0,
        tipo_credito_id = 0,
        ct_persona_id = 0,
        tercero_id = 0
    } = configuraciones;


    const user = fetchUSer();

    const { bancos, forma_pagos } = user.DATABASE;

   



    /* Si no es un abono Global, usuaremos el master del documento */
    if (tipo_de_pago == 0) {
        master_id = (document) ? document.DOCUMENTO.CtTipoComprobanteFiscal_ct_master_id : 0;
    }


    const initialValue = {


        ct_documento_id: (document && document.DOCUMENTO.CtDocumento_id) ? document.DOCUMENTO.CtDocumento_id : 0,

        categoria_pago_id: categoria_pago_id,
        fecha: __app.getFecha('dia'),
        forma_pago_id: 0,
        banco_id: 0,
        observacion: "",
        total: total,
        tipo_de_pago: tipo_de_pago,
        ct_persona_id: ct_persona_id,
        ct_operacione_id: ct_operacione_id,
        tipo_credito_id: tipo_credito_id,
        master_id: master_id,

    }
    const [formData, setFormData] = useState(initialValue);



    const handleChange = (e) => {
        let { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const [cargando, serCargando] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        serCargando(1);
        setError(null)

        e.preventDefault();

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {

            alert("Por favor llene todos los campos para continuar")
            serCargando(null);
            setError(null)
            return;
        }

        let data = await guardarPagoHttp(formData);
        console.log(data);
        console.log("------------");
        if (data) {
            serCargando(null);

            if (data !== -1) {

                if (data.resultado.error == 1) {
                    NotificationManager.warning("Error Interno.")
                    setError(data.resultado.mensaje)
                }


                if (data.resultado.error == 0) {
                    NotificationManager.success("Pago guardado correctamente.")
                    cancelar(1);

                    console.log({ ...formData, serverInfoPago: data.infoPago });
                    pagoExito({ ...formData, serverInfoPago: data.infoPago });

                }

            } else {
                NotificationManager.error("Error en la Red. revise su conexión a internet")
            }
        }
        return false;
    }



    const validateForm = () => {
        const errors = {};

        // Validar campo de fecha
        if (!formData.fecha) {
            errors.fecha = "La fecha es obligatoria";
        }

        // Validar forma de pago
        if (!formData.forma_pago_id || formData.forma_pago_id === "0") {
            errors.forma_pago_id = "Selecciona una forma de pago";
        }

        if (!formData.banco_id || formData.banco_id === "0") {
            errors.banco_id = "Selecciona un banco o una Caja";
        }

        // Validar total
        if (!formData.total || formData.total <= 0) {
            errors.total = "El total debe ser mayor a 0";
        }

        return errors;
    };

    // FILTRAR POR CADA FORMA DE PAGO SELECCIONADA 
    const [bancosFiltrados, setBancosFiltrados] = useState(bancos);

    useEffect(() => {
        const formaPagoSeleccionada = forma_pagos.find(fp => fp.id == formData.forma_pago_id);
        if (formaPagoSeleccionada) {
            const tipoBancoId = formaPagoSeleccionada.tipo_de_banco_id;
            setBancosFiltrados(bancos.filter(banco => banco.tipo_de_banco_id == tipoBancoId));
        } else {
            setBancosFiltrados(bancos);
        }
    }, [formData.forma_pago_id]);




    return (
        <div className=''     >



            <div className=''  >
                . {!cargando && <button onClick={cancelar} type="button" className="float-end btn-close " style={{ marginTop: -8 }} data-bs-dismiss="alert" aria-label="Close"></button>}

                <div className='mb-3'>

                    {master_id == 1 && (
                        <AlertaConBotonDeCierre tipo="success" mensaje="Ingreso" tipo_credito_nombre={tipo_credito_nombre} />
                    )}

                    {master_id == 2 && (
                        <AlertaConBotonDeCierre tipo="danger" mensaje="Egreso" />
                    )}
                </div>


                {formData.ct_documento_id > 0 && <p className='alert alert-warning'>El siguiente pago se aplicara al documento : <b>#{formData.ct_documento_id}</b> </p>}





                <form onChange={handleChange} onSubmit={handleSubmit} method="POST">





                    {true && <div className="mb-3">

                        <label for="" className="form-label">Fecha de pago  </label>
                        <input value={formData.fecha} name='fecha' type="date" className="form-control" id="fecha" />
                    </div>}



                    <div className="mb-3">
                        <label for="exampleInputEmail1" className="form-label">Forma de pago</label>
                        <select required={true} value={formData.forma_pago_id} className='form-control' name='forma_pago_id'  >
                            <OptionSelect datos={forma_pagos} />
                        </select>
                    </div>


                    <div className="mb-3">
                        <label for="banco_id" className="form-label">Banco o Caja </label>
                        <select required={true} value={formData.banco_id} className='form-control' name='banco_id'>
                            <OptionSelect datos={bancosFiltrados} />
                        </select>
                    </div>

                    <div className="mb-3">
                        <label for="observacion" className="form-label">Observaciones  </label>
                        <input value={formData.observacion} name='observacion' className="form-control" id="observacion" />
                    </div>


                    <div className="mb-3">
                        <label for="total" className=" form-label"> Total a pagar  </label>
                        <input required={true} value={formData.total} type="number" className="p7-fa-total form-control" id="total" name='total' onWheel={(e) => e.target.blur()} />
                    </div>


                    <div className='mt-5 mb-3'> {error && <div className="alert alert-danger" >
                        <p> <b>ERROR :</b> {error}</p>
                    </div>}</div>


                    <button disabled={cargando} type="submit" className="float-end btn-lg btn btn-principal">
                        {!cargando && <span> Guardar Pago</span>}

                        {cargando && <span> Cargando ... </span>}
                    </button>






                </form>
                {/* fuera del fomulario para que cierre el modal y no me valide los campos y cierre sin problemas */}
                {!cargando && <button onClick={cancelar} className="me-2 float-end btn-lg btn btn-light"> Cancelar </button>}




            </div>





        </div>
    );
};

export default AgregarPago;