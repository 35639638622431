


import React, { useEffect, useState } from 'react';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import Form from './Form';

const Add = ({ itemEdit, SetItemEdit  , successFunction = null }) => {


    const [showForm, seshowForm] = useState(null);



    const mostrarFormulario = () => {
        SetItemEdit(null);
        seshowForm(true);
    }

    useEffect(() => {
        if (itemEdit) {
            seshowForm(true);
        }

    }, [itemEdit])


    const cerrarModal = () => {
        seshowForm(!showForm);
        SetItemEdit(null);
    }


    const textoBoton = itemEdit ? "Actualizar Vehículo" : "Agregar Vehículo";

    return (
        <div>

            <button className='btn btn-success btn-sm' onClick={mostrarFormulario}><i class="fa-solid fa-plus fa-lg me-1"></i> Agregar Vehiculo</button>

            <StandarModal size="xl" titulo="Formulario de Registro" desactivar={cerrarModal} mostrar={showForm}  >
                <Form mostrar={seshowForm} SetItemEdit={SetItemEdit} item={itemEdit} textoBoton={textoBoton}  successFunction={successFunction}/>
            </StandarModal>
        </div>
    );
};

export default Add;