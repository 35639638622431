

import React from 'react';

const NumeroFactura = ({id , numfact , prioridad ="id"} ) => {
    

   
    if(numfact && prioridad == "numfact"){
        return (
            <span className='bg-opacity-75 badge bg-primary'>
               {numfact}
            </span>
        );
    }

    return (
        <span className=''>
           -----
        </span>
    );
};

export default NumeroFactura;