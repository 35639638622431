import React, { useState, useEffect } from 'react';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';
import { registrarTarifa } from '../Core/Controlador';
import { NotificationManager } from 'react-notifications';

const Form = ({ mostrar, SetItem, item, textoBoton, cargarTarifas }) => {
    const [categoria, setCategoria] = useState(item?.objeto_categoria_id || '');
    const [algoritmo, setAlgoritmo] = useState(item?.paqueadero_algoritmo_id || '');
    const [nombre, setNombre] = useState(item?.nombre || '');
    const [auxValues, setAuxValues] = useState({});

    const user = fetchUSer();
    const categorias = user.DATABASE.objetos_categorias;
    const algoritmos = user.DATABASE.parqueadero_algoritmos;

    useEffect(() => {
        if (item) {
            const selectedAlgoritmo = algoritmos.find(algo => algo.id === item.paqueadero_algoritmo_id);

            if (selectedAlgoritmo) {
                const newAuxValues = Object.fromEntries(
                    Object.entries(selectedAlgoritmo)
                        .filter(([key, value]) => key.startsWith("aux") && value.trim() !== "")
                        .map(([key]) => [key, item?.[key] || ''])
                );
                setAuxValues(newAuxValues);
            }
        }
    }, [item]);

    const handleInpust = (e) => {
        const selectedId = e.target.value;
        setAlgoritmo(selectedId);

        const newAlgoritmo = algoritmos.find(algo => algo.id === selectedId);
        if (newAlgoritmo) {
            setAuxValues((prevAuxValues) => ({
                ...Object.fromEntries(
                    Object.entries(newAlgoritmo)
                        .filter(([key, value]) => key.startsWith("aux") && value.trim() !== "")
                        .map(([key]) => [key, prevAuxValues[key] || ''])
                )
            }));
        } else {
            setAuxValues({});
        }
    };

    const handleAuxChange = (e) => {
        setAuxValues({
            ...auxValues,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!algoritmo || !categoria || !nombre) {
            NotificationManager.error('Porfavor rellene todos los campos', 'Error');
            return;
        }

        if (auxValues.aux1 === '') {
            NotificationManager.error('Al menos un campo es requerido en el algoritmo seleccionado', 'Error');
            return;
        }
        guardarTarifa();
    };

    const guardarTarifa = async () => {
        const selectedAlgoritmo = algoritmos.find(algo => algo.id === algoritmo);
        const nombreAlgoritmo = selectedAlgoritmo ? selectedAlgoritmo.nombre : '';

        const formData = {
            id: item?.id,
            objeto_categoria_id: categoria,
            paqueadero_algoritmo_id: algoritmo,
            nombre,
            nombre_algoritmo: nombreAlgoritmo,
            ...auxValues
        };

        console.log("Datos del formulario:", formData);
        let ressHttp = await registrarTarifa(formData);

        if (ressHttp.estado == 1 || ressHttp.estado == 2) {
            SetItem(null);
            mostrar(false);
            NotificationManager.success(ressHttp.mensaje);
            cargarTarifas();
        } else {
            NotificationManager.error("Error");
        }
    };

    return (
        <div className="container mt-2">
            <h3 className="mb-2 text-center">✍️Formulario de Registro</h3>
            <form onSubmit={handleSubmit} className="rounded">
                <div className='row'>
                    <div className="col-md-6 mb-2">
                        <label htmlFor="categoria" className="form-label">Categoría</label>
                        <select id="categoria" className="form-select" value={categoria} onChange={(e) => setCategoria(e.target.value)} required>
                            <option value="">Selecciona una categoría</option>
                            {categorias.map((cat) => (
                                <option key={cat.id} value={cat.id}>
                                    {cat.nombre}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label htmlFor="algoritmo" className="form-label">Algoritmo</label>
                        <select id="algoritmo" className="form-select" value={algoritmo} onChange={handleInpust} required>
                            <option value="">Selecciona un algoritmo</option>
                            {algoritmos.map((algo) => (
                                <option key={algo.id} value={algo.id}>
                                    {algo.nombre}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='mb-2'>
                        <label htmlFor="nombre" className='form-label'>Nombre de la Tarifa</label>
                        <input type="text" id='nombre' placeholder='Escribe el nombre de la Tarifa' className="form-control" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                    </div>
                </div>

                <div className='row'>
                    {Object.entries(auxValues).map(([key, value]) => (
                        <div className='col-md-3 mb-2' key={key}>
                            <label htmlFor={key} className='form-label'>{key}</label>
                            <input id={key} name={key} type="text" className="form-control" placeholder={key} value={value} onChange={handleAuxChange} />
                        </div>
                    ))}
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" className="btn btn-primary">{textoBoton}</button>
                </div>
            </form>
        </div>
    );
};

export default Form;



