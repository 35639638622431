import React, { useState } from 'react';

const Sincronizacion = ({ statusSincronizacion, statusSincronizacionFinalizado, location, sincronizar }) => {
    const [isVisible, setIsVisible] = useState(true); // Estado para controlar la visibilidad del componente

    const handleClose = () => {
        setIsVisible(false); // Cambiar el estado a false para ocultar el componente
    };

    if (!isVisible) {
        return null; // Si no es visible, no se renderiza el componente
    }

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                {/* Card única para mostrar toda la información */}
                <div className="col-md-8 mb-3">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5>Estado de la Sincronización</h5>
                            { (statusSincronizacionFinalizado == 1 || statusSincronizacionFinalizado === -1) && <button 
                                type="button" 
                                className="btn-close" 
                                aria-label="Close" 
                                onClick={handleClose} 
                            />}
                        </div>
                        <div className="card-body">
                            <div className="mb-3">
                                <p>
                                    <span className='d-block'><strong>Objetos:</strong> {statusSincronizacion.objetos}</span>
                                    <span className='d-block'><strong>Clientes:</strong> {statusSincronizacion.clientes}</span>
                                    <span className='d-block'><strong>Productos:</strong> {statusSincronizacion.productos}</span>
                                </p>
                            </div>
                            
                            {/* Logs */}
                            <div className="mb-3">
                                <h6>Logs de Sincronización</h6>
                                <div id="__p7_logsClientes" >
                                    <h6>Logs de Clientes</h6>
                                </div>
                                <div id="__p7_logsVehiculos" >
                                    <h6>Logs de Vehículos</h6>
                                </div>
                                <div id="__p7_logsProductos" >
                                    <h6>Logs de Productos</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sincronizacion;
