import React, { createContext, useContext, useState, useEffect } from "react";

// Crear el contexto de notificaciones
const NotificationContext = createContext();

// Hook para acceder a las alertas desde cualquier componente
export const useNotification = () => useContext(NotificationContext);

// Proveedor global de notificaciones
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  // Escuchar eventos globales para llamadas desde archivos .js
  useEffect(() => {
    const handleGlobalAlert = (event) => {
      const { type, message, onConfirm } = event.detail;
      showNotification(type, message, onConfirm);
    };
    window.addEventListener("global-alert", handleGlobalAlert);
    return () => window.removeEventListener("global-alert", handleGlobalAlert);
  }, []);

  // Función para mostrar la notificación
  const showNotification = (type, message, onConfirm = null) => {
    setNotification({ type, message, onConfirm });
  };

  // Función para cerrar la notificación
  const closeNotification = () => setNotification(null);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}

      {/* MODAL DE ALERTA / CONFIRMACIÓN */}
      {notification && (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <p style={styles.message}>{notification.message}</p>

            {notification.type === "alert" && (
              <button style={styles.button} onClick={closeNotification}>
                OK
              </button>
            )}

            {notification.type === "confirm" && (
              <div style={styles.buttonContainer}>
                <button style={styles.buttonCancel} onClick={closeNotification}>
                  Cancelar
                </button>
                <button
                  style={styles.buttonConfirm}
                  onClick={() => {
                    if (notification.onConfirm) notification.onConfirm();
                    closeNotification();
                  }}
                >
                  Aceptar
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </NotificationContext.Provider>
  );
};

// Estilos en JavaScript (CSS en JS)
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo oscuro semi-transparente
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    width: "300px",
  },
  message: {
    fontSize: "18px",
    marginBottom: "20px",
    fontWeight: "bold",
    color: "#333",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#007bff",
    color: "white",
    cursor: "pointer",
    width: "100%",
  },
  buttonCancel: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#ccc",
    color: "#333",
    cursor: "pointer",
    flex: 1,
    marginRight: "5px",
  },
  buttonConfirm: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#28a745",
    color: "white",
    cursor: "pointer",
    flex: 1,
    marginLeft: "5px",
  },
};
