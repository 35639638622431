import React, { useRef } from 'react';
import { useEffect } from 'react';
import { eliminarCuentaAbiertaFireBase, getLocalStorageDocument, eliminarCuentaAbiertaDB, listaNegra, cargarInventarioItem, extraerUltimoDocumentoByCache } from "./controlador";

import { NotificationManager } from 'react-notifications';

/**** Vistas del Documento */
import { Items, FormasPago, FormDocumento, Tercero } from "./PartesDocument/index.js";
import ItemsAll from './ItemsAll';



import StandarModal from './PartesDocument/StandarModal';
import TipoDocumento from "./PartesDocument/TipoDocumento";
import { useState } from 'react';




import { useContextDocumentValue, actionTypeDocument } from "./context/ContextDocument.js";

import { DocumentL } from "./DocumentCore"
import { useStateValue } from '../../context/StateProvider';
import CampoFullCantidad from './PartesDocument/PartesItems/CampoFullCantidad';




import "./estilos/documentos.css";
import "./estilos/colorDocumento.css";
import GuardarDocumento from './PartesDocument/GuardarDocumento';
import ListarDocumentos from './PartesDocument/ListarDocumentos';

import LogsItems from './PartesDocument/LogsItems';

import { __app, __fecha } from "../../utils/myApp";
import Mesas from '../Mesas/Mesas';
import MesasSeleccion from './PartesDocument/MesasSeleccion';
import SeleccionProductosCompuesto from './PartesDocument/PartesItems/SeleccionProductosCompuesto';
import StandarOffCanvas from './PartesDocument/OffCanvas';
import FacturaTirilla from './PlantillasImpresion/FacturaTirilla.jsx';
import prindiv from 'easyprintand';
import FormTercero from './PartesDocument/Tercero/FormTercero.jsx';
import CargarFacturaDevolucion from './PartesDocument/CargarFacturaDevolucion.jsx';
import PinVendedor from './PartesDocument/PinVendedor.jsx';
import { getLocalstore } from '../../Core/fetchLocalStoreData.js';

import Keyboard from 'keyboard-css'; // Importa el paquete keyboard-css


import CargarCotizacion from './PartesDocument/CargarCotizacion.jsx';
import VariantesIventario from './PartesDocument/PartesItems/SubPartesItems/VariantesIventario.jsx';
import CargarCuentaAbierta from './PartesDocument/CargarCuentaAbierta.jsx';
import { FiltrarImpuestosByOperacion } from '../../Core/MatematicasGlobales.js';
import Moment from 'react-moment';
import CargarRetencion from './PartesDocument/CargarRetencion.jsx';
import ValidacionesDocumento from './PartesDocument/ValidacionesDocumento.jsx';




const ejecutarUnaVez = () => {

}
ejecutarUnaVez();



const Documento = ({ TabIndexActual, actualizarTab, dispatchGlobal, showmesa, funshowmesa, CodigoSincronizacion }) => {

    const [mostrarCompleto, setMostrarCompleto] = useState(false);
    const handleOpen = () => {setMostrarCompleto(1) };
    
    const handleClose = () => {setMostrarCompleto(0) };





    const [COMANDO, SETCOMANDO] = useState();

    const [{ documento }, dispatch] = useContextDocumentValue();


    const [switchshowformpago, Setswitchshowformpago] = useState(false);



    const USerConfigLocal = getLocalstore('user_localConfig');




    const [{ user, KeyObservadores }] = useStateValue();




    const LogicaDocumento = new DocumentL(documento, TabIndexActual.keyLocalStogere, { dispatch, actionTypeDocument }, user);


    const [showCampoCantidad, setShowCampoCantidad] = useState();


    const [auxitem, setAuxitem] = useState();
    const [auxIndex, setAuxIndex] = useState();



    const [showFormGuardarDocumento, SetshowFormGuardarDocumento] = useState(false);


    const [showFormCargarDocumento, SetShowFormCargarDocumento] = useState(false);


    const [showListCompuesto, SetshowListCompuesto] = useState(false);


    const [showFormcargarDevolucion, SetshowFormcargarDevolucion] = useState(false);

    
    
    const [showFormcargarCotizacion, SetshowFormcargarcotizacion] = useState(false);

    const [showFormAplicarRetencion, SetshowFormAplicarRetencion] = useState(false);

    

    const [showFormcargarCuentaAbierta, SetshowFormcargarCuentaAbierta] = useState(false);


    /**  --- ...- -.. -- ---Andres  Lopez  --------  .....   */
    const [showCanvasMisPededios, SetshowCanvasMisPededios] = useState(false);

    /*** solo controla el focus del mesero en las mesas para optimizar tiempo */
    const [focusMesa, setFocusMesa] = useState(null);


    const [showModalVariantesInventario, SetshowModalVariantesInventario] = useState(null);


    const [showErroresValidacion, SetshowErroresValidacion] = useState(null);


    const [ erroresDocumento, setErroresDocumento] = useState(null);
    



    const [showPinVendedor, SetShowPinvendedor] = useState(false);




    const handleOpenCampoCantidad = () => setShowCampoCantidad(true);


    /** En esta variable guardaremos algunas funciones de otros componente para ejecutarlas aqui  */
    const [focoTeclado, setFocoTeclado] = useState();


    const listaCategorias = getLocalstore("Listcategorias");

    const handleCloseCampoCantidad = () => {

        setShowCampoCantidad(false)

    };

    const [alertaInventario, setAlertaInventario] = useState(false);

    /*** Observador Tabs */
    useEffect(() => {

        console.log("Cargando la key : " + TabIndexActual.keyLocalStogere)
        let auxDc = getLocalStorageDocument(TabIndexActual.keyLocalStogere);

        if (auxDc) {
            auxDc.KEYDOCUMENT = TabIndexActual;

            dispatch({
                type: actionTypeDocument.SET_DOCUMENTO,
                documento: auxDc,
            })
        } else {
            console.log("No encontramos dopucmento")
        }



        //listaNegra();


    }, [TabIndexActual])



    /*****   __   */
    const [tipodocumentos, setTipodocumentos] = useState(null);

    useEffect(() => {



        if (documento) {

            if (!documento.TIPO_DOCUMENTO) {

                if (tipodocumentos && tipodocumentos.length > 0) {
                    let tipoDocumento = tipodocumentos[0];
                    dispatch({
                        type: actionTypeDocument.SET_DOCUMENTO,
                        documento: {
                            ...documento, "TIPO_DOCUMENTO": tipoDocumento
                        },
                    })
                }
            }



            const elem = document.getElementById("TOTAL_" + documento.KEYDOCUMENT.keyLocalStogere);
            if (elem) elem.innerHTML = __app.formato_decimal({ "total": documento.MATEMATICAS.SUBTOTAL_3 })

        }



    }, [documento])

    useEffect(() => {
        setTipodocumentos(user.DATABASE.tipo_documento);
        //vertificadorDeInventario()
    }, [])








    const TeclaF3 = useRef();

    const TeclaF4 = useRef();




    /** Imprime el utlimo codumento generado */
    const imprimirUltimoDocumento = async () => {

        let data = await extraerUltimoDocumentoByCache();
        if (data) {

            setimprimirDocumento(data);

            setTimeout(() => {
                prindiv('EasyPrintAnd', { extraCss: './tirilla.css' });
            }, 200)

            setTimeout(() => {
                console.log("listo")
                setimprimirDocumento(null);
            }, 2000)

        }
    }

    const SwitchCambioPrecio = () => {


        if (documento.TERCERO) {
            LogicaDocumento.actualizar_lista_precio(0, null, 'quitarCliente');
            setFocoTeclado('F1')
            return false;
        }


        const tercero = {
            "id": "1",
            "fe_tipopersona_id": "2",
            "fe_regimenfiscal_id": "49",
            "fe_municipio_id": "0",
            "per_categoria_persona_id": "2",
            "ct_lista_precio_id": "2",
            "fe_tipodocumento_id": "2",
            "identificacion": "111111111",
            "razonSocial": "....",
            "nombrecomercial": ".....",
            "dvnit": "0",
            "obligaciones": null,
            "direccion": "",
            "telefono": "",
            "correoelectronico": "",
            "JSON_responsabilidadesFiscales": "[{\"nombre\":\"IVA\",\"codigo\":\"01\"}]",
            "creado": "2023-10-23 16:40:33",
            "modificado": null,
            "aux_vendedor_estaciones_id": "1",
            "estado_mano_obra": "0",
            "pin_vendedor": null
        }

        LogicaDocumento.AgregarTerceroDirecto(tercero);
        setFocoTeclado('F1')
    }



    useEffect(() => {
        const handleKeyPress = (event) => {
            switch (event.key) {

                case 'F1':
                    setFocoTeclado('F1')
                    event.preventDefault();
                    break;


                case 'F2':
                    setFocoTeclado('F2')
                    event.preventDefault();
                    break;

                case 'F3':
                    event.preventDefault();
                    setFocoTeclado('F3');
                    TeclaF3.current.click();

                    break;

                case 'F4':
                    setFocoTeclado('F4')
                    event.preventDefault();
                    TeclaF4.current.click();
                    break;


                case 'F5':
                    setFocoTeclado('F5')
                    event.preventDefault();

                    break;

                case 'F12':
                    event.preventDefault();
                    break;





                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [])















    const HandleContinuar = () => {

        LogicaDocumento.validacionesAntesDeContinuar();

        if(LogicaDocumento.ERROR_MENSAJE_VISTA.length > 0){

            SetshowErroresValidacion(true)
            setErroresDocumento(LogicaDocumento.ERROR_MENSAJE_VISTA)
        }else{
            mostrarFormPagos();
        }
        
    }



    const mostrarFormPagos = () => {


        SetDevuelta(null)
        console.log(user.CONFIGURACIONES_TABLE);
        if (
            (user.CONFIGURACIONES_TABLE.habilitar_vendedor_x_pin && user.CONFIGURACIONES_TABLE.habilitar_vendedor_x_pin == 1)
        ) {
            //alert("Falta vendedor")
            SetShowPinvendedor(true);
        } else {

            Setswitchshowformpago(true);

        }
    }






    /**
     * {
    
     */


    /** Logica para insertar Imtes */
    const agregarItemPadre = async (item, options = null) => {

        setAuxitem(null);
        setAuxIndex(null);

        //SERVICIO SUGERIDO

        let itemCopy = LogicaDocumento.clonarObjeto(item);



        let cantidadObligatoria = USerConfigLocal?.GENERALES?.PreguntarSiempreCantidad == 1 ? 1 : null;


        itemCopy.fechaid = documento.fechaid;

        /*** Si nel Documento no viene con una cantidad EsTablecidad debemos de preguntar */
        if ((itemCopy.cantidad_default == 0 || cantidadObligatoria == 1) && (!options || !options.cantidad)) {
            setAuxitem(itemCopy);
            setShowCampoCantidad(1);
            return false;
        }




        if (itemCopy.auxRestauranteListCheck == 1 && itemCopy.COMPUESTO) {
            setAuxitem(itemCopy);
            SetshowListCompuesto(1);
            return false;
        }


        /*** De lo contraria agregaremos 1, tambine podemos la cantidad establecidad */
        /**
         * 
         *  Nueva actualizacion:
         *  Devido a la insercion de variables de inventario.
         * la cantidad se marcara en 0 cuando el item sea variable
         * 
         */
        let auxCantidad = (itemCopy.if_variante && itemCopy.if_variante == 1) ? 0 : 1
        if (!options) {
            options = {
                cantidad: auxCantidad
            }
        }







        /*** Esta funcion se llama primero */
        if (documento.ESTRAS && documento.ESTRAS.SET_DEFAULT_PRECIO && documento.ESTRAS.SET_DEFAULT_PRECIO > 0) {

            let { mewprecio } = LogicaDocumento.extraerPreCiobyLista(documento.ESTRAS.SET_DEFAULT_PRECIO, 2, itemCopy);

            if (mewprecio > 0) {
                options = { ...options, PRECIO: mewprecio }
            }
        }



        /*** Lista de precio AcTIVADA   SWITCH_LISTA_PRECIO === 2 
        * 
        *  SI la lista de precio esta Acivada debemos cambiar el precio ya que estamos indicando 
        * el precio de venta directo.
        */
        if (documento.ESTRAS && documento.ESTRAS.SWITCH_LISTA_PRECIO && documento.ESTRAS.SWITCH_LISTA_PRECIO == 2) {

            let { mewprecio } = LogicaDocumento.extraerPreCiobyLista(documento.ESTRAS.LISTA_PRECIO_ACTIVA, 2, itemCopy);

            if (mewprecio > 0) {
                options = { ...options, PRECIO: mewprecio }
            }
        }



        if (documento.TIPO_DOCUMENTO.ct_operacion_id == 1 && user.CONFIGURACIONES_TABLE.revisar_descuento_categoria && user.CONFIGURACIONES_TABLE.revisar_descuento_categoria == 1) {

            if (listaCategorias && listaCategorias.length > 0) {
                let descuento = listaCategorias.find(data => data.id == item.productogrupo_id);
                console.log(descuento)
                if (descuento && descuento.descuento && parseFloat(descuento.descuento) > 0) {

                    options = { ...options, DESCUENTO_UNITARIO_PORCENTUAL: parseFloat(descuento.descuento) }
                }
            }
        }








        /*** PROPONA */
        if (itemCopy.nombre == "SERVICIO SUGERIDO") {

            if (user.ESTACION.nit == "901587312") {

                itemCopy.PRECIO_PRINCIPAL = documento.MATEMATICAS.SUBTOTAL_2 * 0.10;

            } else {
                itemCopy.PRECIO_PRINCIPAL = documento.MATEMATICAS.SUBTOTAL_3 * 0.10;
            }
        }





        /**** Variantes de inventario 
         * 
         *  Si el producto ya existe activamos variante de inventario.
         * Ojo si el producto ya existe y es variante
         * 
        */
        if (itemCopy.if_variante && itemCopy.if_variante == 1) {
            const ItemFind = documento.PRODUCTOS.find(data => data.id == itemCopy.id)
            const ItemIndex = documento.PRODUCTOS.findIndex(data => data.id == itemCopy.id)
            if (ItemIndex >= 0) {
                setAuxitem(ItemFind);
                setAuxIndex(ItemIndex);
                SetshowModalVariantesInventario(true);
                return false;
            } else {
                setAuxitem(itemCopy);
                SetshowModalVariantesInventario(true);
                return false;
            }
        }




        try {
            /**** Limpiar Impuestos  */
            /** 
            Para aplicar el impuesto solo cuando estamos en la misma operación, el código debe verificar para cada elemento de COPY_IMPUESTOS_RAIZ si su operación es compatible con OperacionActual. Si se encuentra una coincidencia, debes conservar el impuesto; de lo contrario, excluirlo.
            Aquí está una versión corregida y optimizada del código:*/
            if (itemCopy?.IMPUESTOS_RAIZ) {
                console.log(itemCopy.IMPUESTOS_RAIZ)
                let data = FiltrarImpuestosByOperacion(documento.TIPO_DOCUMENTO.ct_operacion_id, itemCopy.IMPUESTOS_RAIZ)
                console.log(data)
                itemCopy.IMPUESTOS_RAIZ = data;
            }

        } catch (error) {
            console.error(error);
        }




        console.log(itemCopy)
        let send = {
            item: itemCopy,
            options: options
        }


        LogicaDocumento.despachador('insertarItem', send);






    }




    /**  
     * Cargar Documento 
     * 
     */
    const cargarDocumento = (documentoAux) => {

 
        documentoAux = { 
            ...documentoAux, 
            KEYDOCUMENT : TabIndexActual, 
            fechaid: __app.numeroFecha(__app.getFecha()), 
            FORMAS_DE_PAGO : null,  
             
        };


        dispatch({
            type: actionTypeDocument.SET_DOCUMENTO,
            documento: documentoAux
        })

        SetShowFormCargarDocumento(false);
        funshowmesa(false)
        SetshowCanvasMisPededios(false);
    }

    const seleccionarMesa = (mesa) => {


        funshowmesa(false)
        var porciones = mesa.split('/');
        let valor = {
            renderizar_total: false,
            mesa_id: porciones[0],
            mesanombre: porciones[1],

        };


        LogicaDocumento.despachador('actualizar_mesa', { valor });
    }




    /*** Agrega una Cantidad o Suma una Cantidad  ***/
    const handleChangeCantidad = ({ cantidad, precio }) => {
        let options = {
            cantidad: cantidad
        }

        if (precio > 0) {
            options = { ...options, PRECIO: precio }
        }


        setShowCampoCantidad(false);
        let item = auxitem;
        agregarItemPadre(item, options)
        setAuxitem(false);


        const inputBuscador = document.getElementById("inputBuscador");


        setTimeout(() => {
            console.log("Ejecutado")
            inputBuscador.focus();
            inputBuscador.select();
        }, 200)


    }






    const handleBlurPrincipal = ({ indexx, valor, accion }) => {
        LogicaDocumento.despachador(accion, {
            indexx, valor
        })
    }



    const hanldleComandos = (comando) => {

        if (comando === "+") {

            return Setswitchshowformpago(!switchshowformpago);
        }

        if (comando === "-") {

            LogicaDocumento.despachador("eliminar_todos_productos")
        }
    }





    const [panelActual, setPanelactual] = useState({
        'items': 'col',
        'venta': 'col-0',
        'aux': 'items',
    });

    const OcultarMostrarPanel = (show = null) => {

        let copy = {};

        if (panelActual.aux == 'items') {

            copy = {
                'items': 'col-0',
                'venta': 'col-12',
                'aux': 'venta',
            }

        } else {

            copy = {
                'items': 'col-12',
                'venta': 'col-0',
                'aux': 'items',
            }
        }


        if (show === 'venta') {

            let copy2 = {
                'items': 'col-0',
                'venta': 'col-12',
                'aux': 'venta',
            }
            setFocusMesa(1);
            setPanelactual(copy2);
        } else {
            setPanelactual(copy);
        }

        // setPanelactual(copy);
    }



    const eliminarCuentaAbierta = async (documentoLocal) => {

        console.log(documentoLocal)

        let resultado = await eliminarCuentaAbiertaDB(documentoLocal, user);

        if (resultado.data > 0) {

            NotificationManager.success('Eliminado con Exito', 'Borrado');
            eliminarCuentaAbiertaFireBase(documentoLocal.id, user);
        } else {
            alert("El codigo " + documentoLocal.id + "No fue eliminado")
        }

    }



    /*** Funcion invocada desde otro componente, Para guardar los cambios de las composiciones */
    const productosCompuestoPaso2 = (item) => {

        let options = {
            cantidad: 1
        }

        LogicaDocumento.despachador('insertarItem', { item, options });
        setAuxitem(false);
        SetshowListCompuesto(false);
    }


    ///Extrae las variantes del inventario y las agrega al docuemento *//
    const extraerVariantes = (variantes, cantidad) => {
        SetshowModalVariantesInventario(false);
        if (cantidad == 0) {

            return false;

        }
        // en caso de que el index exista entonces vamos hacer un Eddiccion.

        if (auxIndex !== null && auxIndex >= 0) {
            alert("Entra por que :" + auxIndex + ": es >= que  0")
            let indexx = auxIndex;
            LogicaDocumento.despachador("cambiar_variante_item", {
                indexx, variantes
            })



            /// Cambiar Cantidad
            let valor = cantidad;
            LogicaDocumento.despachador("aplicar_cambio_cantidad", {
                indexx, valor
            })

            setAuxitem(null);
            setAuxIndex(null);

            return false;
        }


        let copy = auxitem;
        copy = { ...auxitem, VARIANTES: variantes }



        let options = {
            cantidad: cantidad
        }

        let send = {
            item: copy,
            options: options
        }
        LogicaDocumento.despachador('insertarItem', send);
        setAuxitem(null);
        setAuxIndex(null);
    }





    /*** Esta variable resibe un documento  y un porcentaje diractamente desde firebase  */
    const [imprimirDocumento, setimprimirDocumento] = useState(null);

    const imprimirPreFactura = (doc, porcentaje_propina) => {



        const objetoNuevo = JSON.parse(JSON.stringify(doc));
        objetoNuevo.MATEMATICAS.PROPINA = objetoNuevo.MATEMATICAS.SUBTOTAL_3 * (porcentaje_propina / 100);

        setimprimirDocumento(objetoNuevo)

        setTimeout(() => {
            prindiv('EasyPrintAnd', { extraCss: './tirilla.css' });
        }, 200)

        setTimeout(() => {
            console.log("listo")
            setimprimirDocumento(null);
        }, 2000)
    }


    const FinalizarVenta = () => {
        Setswitchshowformpago(false);
    }



    const [devuelta, SetDevuelta] = useState(null);

    const asignarDevuelta = ({ pagocon, cambio }) => {
        SetDevuelta(1);

        setTimeout(() => {
            // Obtener elementos por ID
            const pagoconElement = document.getElementById('pagocon');
            const cambioElement = document.getElementById('cambio');

            // Asignar valores a los elementos
            if (pagoconElement) {
                pagoconElement.textContent = `$${__app.formato_decimal({ total: pagocon })}`;
            }

            if (cambioElement) {
                cambioElement.textContent = `$${__app.formato_decimal({ total: cambio })}`;
            }

        }, 250)
    };


















    if (!documento || !documento.TIPO_DOCUMENTO) {
        return (<h1>Cargando... </h1>)
    }


    let devulta = {
        'position': 'absolute',
        'bottom': '25px',
        'right': '10px',
        'backgroundColor': '#ffc000',
        'color': '#372b09',
        'padding': '1px',
        'borderRadius': '5px',
        'fontSize': '12px',
        'z-index': '10000'
    }


    if (!(tipodocumentos && tipodocumentos.length > 0)) return (<div className='card p-3 m-5 bg-warning '>No hay Menu de selección en el Usuario </div>)

    return (



        <div className="flexbox-container">

            {devuelta && <div id='devulta' style={devulta}>
                Ultima venta : Pago con  <b id='pagocon'></b> |
                Devuelta <b id='cambio'></b>
                <span onClick={() => SetDevuelta(null)} className='ms-2 click' style={{ 'fontSize': 15 }}  >&times;</span>
            </div>}

            {user.CONFIGURACIONES_TABLE.estado_aplicacion == 2
                &&
                <div className={" d-none  d-xxl-block  col-xxl-2 " + panelActual.items}>
                    <LogsItems />
                </div>
            }




            <div className={"columna-1  col-sm-7 col-md-6	col-lg-8	col-xl-8	col-xxl-8 " + panelActual.venta}>


                {!showmesa &&
                    // ---------------------------------------------------- ANDRES --------------------
                    // <div id='documento '>
                    //     <div className='documento-body'>



                    //         { /**** CAMVAS #1 */}
                    //         <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    //             <div className="offcanvas-header">
                    //                 <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Datos extras de la factura.</h5>
                    //                 <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    //             </div>
                    //             {

                    //                 <div className="offcanvas-body">
                    //                     <FormDocumento tab={TabIndexActual} />
                    //                 </div>
                    //             }
                    //         </div>


                    //         { /**** CAMVAS MOVIL OPCIONES EXTRAS#1 */}
                    //         {
                    //             __app.TipoMovil() &&
                    //             <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offCanvasMenuMovil" aria-labelledby="offCanvasMenuMovilLabel">
                    //                 <div className="offcanvas-header">
                    //                     <h5 className="offcanvas-title" id="offCanvasMenuMovilLabel">Menu Movíl</h5>
                    //                     <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    //                 </div>
                    //                 <div className="offcanvas-body">

                    //                     <div style={{ 'z-index': '3000' }}>
                    //                         <Tercero tab={TabIndexActual} />
                    //                     </div>

                    //                     <ul class="list-group  fixed-bottom list-group-flush mt-4" >

                    //                         <li onClick={() => window.location.href = '/'} class="list-group-item-xs list-group-item">Menu </li>
                    //                         <li onClick={() => window.location.href = '/Logout'} class="list-group-item-xs list-group-item">Cerrar Sesión</li>
                    //                     </ul>


                    //                 </div>
                    //             </div>
                    //         }


                    //         {user.CONFIGURACIONES_TABLE.estado_aplicacion == 2 && <b className='d-none d-sm-block'>#{documento.id}</b>}

                    //         <div className='mt-2 bg-dak d-flex flex-row  justify-content-between' >
                    //             {
                    //                 __app.TipoMovil() &&
                    //                 <div className=' d-flex d-flex justify-content-evenly'>
                    //                     {user.CONFIGURACIONES_TABLE.restaurante == 1 &&
                    //                         <>
                    //                             <MesasSeleccion focusMesa={focusMesa} user={user} guardarMesa={handleBlurPrincipal} mesaActual={documento.FORM} />

                    //                             <button className="btn btn-principal float-end " onClick={() => SetshowCanvasMisPededios(true)} > Mis Pedidos </button>
                    //                         </>
                    //                     }
                    //                 </div>
                    //             }


                    //             {/* TERCERO DE DOCUMENTO */}
                    //             {!__app.TipoMovil() && documento.TIPO_DOCUMENTO && <div className='d-none d-sm-block'> <Tercero tab={TabIndexActual} /> </div>}

                    //             {showCampoCantidad && <CampoFullCantidad show={showCampoCantidad} handleClose={handleCloseCampoCantidad} accion={handleChangeCantidad} item={auxitem} />}

                    //             {/* MENU DE DOCUMENTO */}
                    //             <div className='d-flex flex-row d-none d-sm-block' >
                    //                 <div className="dropdown">
                    //                     <button className="btn  dropdown-toggle after-dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    //                         <i className="fa-solid fa-ellipsis-vertical"></i>
                    //                     </button>
                    //                     <ul className="dropdown-menu">
                    //                         <li><span role="button" className="dropdown-item">Aplicar Descuento</span></li>
                    //                         <li><span role="button" className="link dropdown-item link" onClick={() => SetshowFormGuardarDocumento(!showFormGuardarDocumento)} >Guardar Tempotalmente</span></li>
                    //                         <li><span role="button" className="link dropdown-item link" onClick={() => SetShowFormCargarDocumento(!showFormCargarDocumento)} >Cargar Documento</span></li>
                    //                         <li><span role="button" className="link dropdown-item link" onClick={() => SetshowFormcargarDevolucion(!showFormcargarDevolucion)} > Devolver Factura </span></li>
                    //                         <li><span role="button" className="link dropdown-item link" onClick={() => SetshowFormcargarcotizacion(true)} > Cargar Cotización  </span></li>
                    //                         <li><span role="button" className="link dropdown-item link" onClick={() => SetshowFormcargarCuentaAbierta(true)} > Cargar Cuenta Abierta  </span></li>
                    //                     </ul>
                    //                 </div>
                    //             </div>


                    //             {
                    //                 (documento.cuentAbiertaFirebaseId || documento.id_server) ? (
                    //                     <span className='bg-light float-end card border-3 border-primary p-3 mt-2 box-shadow-effect parpadeo'>
                    //                         <b className='text-primary'>CUENTA ABIERTA# {documento.cuentAbiertaFirebaseId || documento.id_server}</b>
                    //                         <br />
                    //                         Hora de Entrada: {__fecha.formatoFecha({ $fecha: documento?.patio_entrada_fecha })}
                    //                         <br />
                    //                         <Moment fromNow interval={3000}>
                    //                             {__app.formatoFechaGTM5(documento?.patio_entrada_fecha)}
                    //                         </Moment>
                    //                     </span>

                    //                 ) : (
                    //                     <span style={{ height: '23px' }} className='badge float-end bg-primary'>
                    //                         Cuenta Nueva
                    //                     </span>)
                    //             }

                    //             <span className=" btn-primary btn-small " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"> Más </span>



                    //             <TipoDocumento TabIndexActual={TabIndexActual} usuario={user} LogicaDocumento={LogicaDocumento} actualizarTab={actualizarTab} />


                    //         </div>

                    //         <FormDocumento ocultarTodo={1} tab={TabIndexActual} />


                    //         <Items setFocoTeclado={setFocoTeclado} focoTeclado={focoTeclado} OcultarMostrarPanel={OcultarMostrarPanel} mostrarFormPagos={mostrarFormPagos} activeTab={TabIndexActual} usuario={user} />
                    //     </div>
                    // </div>

                    // ---------------------------------------------------- YULI --------------------
                    <div id='documento '>
                        
                        
                        <div className='documento-body'>
                            { /**** CAMVAS #1 DATOS EXTRAS DE LA FACTURA */}
                            <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Datos extras de la factura.</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleClose}></button>
                                </div>
                                {

                                    <div className="offcanvas-body">
                                        <FormDocumento tab={TabIndexActual} ocultarTodo={mostrarCompleto} />
                                    </div>
                                }
                            </div>

                           
                            { /**** CAMVAS MOVIL OPCIONES EXTRAS#1 */}
                            {
                                __app.TipoMovil() &&
                                <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offCanvasMenuMovil" aria-labelledby="offCanvasMenuMovilLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="offCanvasMenuMovilLabel">Menu Movíl</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="offcanvas-body">

                                        <div style={{ 'z-index': '3000' }}>
                                            <Tercero tab={TabIndexActual} />
                                        </div>

                                        <ul class="list-group  fixed-bottom list-group-flush mt-4" >

                                            <li onClick={() => window.location.href = '/'} class="list-group-item-xs list-group-item">Menu </li>
                                            <li onClick={() => window.location.href = '/Logout'} class="list-group-item-xs list-group-item">Cerrar Sesión</li>
                                        </ul>


                                    </div>
                                </div>
                            }




                            <div className="row m-1">
                                {user.CONFIGURACIONES_TABLE.estado_aplicacion == 2 && <b className='d-none d-sm-block'>#{documento.id}</b>}
                                {(documento.cuentAbiertaFirebaseId || documento.id_server) && (
                                    <div className="border border-2 border-primary rounded-3 ps-2 bg-light box-shadow-effect">
                                        <span className="text-primary fw-bold fs-8 me-2 parpadeo">
                                            CUENTA ABIERTA #{documento.cuentAbiertaFirebaseId || documento.id_server}
                                        </span>
                                        <span className="me-2 fs-9">
                                            Hora de Entrada: {__fecha.formatoFecha({ $fecha: documento?.patio_entrada_fecha })}
                                        </span>
                                        <span className="fs-9 fw-bold">
                                            <Moment fromNow interval={3000}>
                                                {__app.formatoFechaGTM5(documento?.patio_entrada_fecha)}
                                            </Moment>
                                        </span>
                                    </div>
                                )}

                            </div>



                            {/*** INFORMACION DE DEVOLUCION INFO EXTRA */}    
                            { !documento.FORM?.numero_devolucion || <div className="row m-1">
                                <div className="border border-2 border-danger rounded-3 ps-2 bg-light box-shadow-effect">
                                   
                                    <span className="text-danger fw-bold fs-8 me-2 ">
                                        APLICAREMOS ESTA DEVOLUCIÓN A LA SIGUIENTE FACTURA  #{documento.FORM?.numero_devolucion }
                                    </span>
                                        
                                </div>
                            </div>}




                            <div className='bg-dak d-flex flex-row aling-items-center justify-content-between'>
                                {
                                    __app.TipoMovil() &&
                                    <div className=' d-flex d-flex justify-content-evenly'>
                                        {user.CONFIGURACIONES_TABLE.restaurante == 1 &&
                                            <>
                                                <MesasSeleccion focusMesa={focusMesa} user={user} guardarMesa={handleBlurPrincipal} mesaActual={documento.FORM} />

                                                <button className="btn btn-principal float-end " onClick={() => SetshowCanvasMisPededios(true)} > Mis Pedidos </button>
                                            </>
                                        }
                                    </div>
                                }

                                {!__app.TipoMovil() && documento.TIPO_DOCUMENTO &&
                                    <div className='d-none d-sm-block'>
                                        <Tercero tab={TabIndexActual} />
                                    </div>
                                }

                                {showCampoCantidad && <CampoFullCantidad show={showCampoCantidad} handleClose={handleCloseCampoCantidad} accion={handleChangeCantidad} item={auxitem} />}

                                <div className='d-flex flex-row d-none d-sm-block' >
                                    <div className="dropdown">
                                        <button className="btn btn-sm btn-subtle-secondary  dropdown-toggle after-dropdown-toggle" title='Opciones para este documento' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li >
                                                <a className='dropdown-item' href="#" onClick={() => SetshowFormcargarCuentaAbierta(true)}>
                                                    <span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-book-open fs-9"></i></span>
                                                    Cargar Cuenta Abierta
                                                </a>
                                            </li>
                                            <li>
                                                <a className='dropdown-item' href="#" onClick={() => SetshowFormcargarDevolucion(!showFormcargarDevolucion)}>
                                                    <span className={`badge badge-phoenix badge-phoenix-danger me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-delete-left fs-9"></i></span>
                                                    Devolver Factura
                                                </a>
                                            </li>
                                            <li>
                                                <a className='dropdown-item' href="#" onClick={() => SetshowFormcargarcotizacion(true)}>
                                                    <span className={`badge badge-phoenix badge-phoenix-secondary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-pen-to-square fs-9"></i></span>
                                                    Cargar Cotización
                                                </a>
                                            </li>

                                            <li>
                                                <a className='dropdown-item' href="#" onClick={() => SetshowFormAplicarRetencion(true)}>
                                                    <span className={`badge badge-phoenix badge-phoenix-secondary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-tags fs-9"></i></span>
                                                    Aplicar Retención
                                                </a>
                                            </li>
                                            
                                            <li>
                                                <a className='dropdown-item' href="#" onClick={() => SetshowFormGuardarDocumento(!showFormGuardarDocumento)}>
                                                    <span className={`badge badge-phoenix badge-phoenix-secondary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-cloud-arrow-down fs-9"></i></span>
                                                    Guardar Tempotalmente
                                                </a>
                                            </li>
                                            <li>
                                                <a className='dropdown-item' href="#" onClick={() => SetShowFormCargarDocumento(!showFormCargarDocumento)}>
                                                    <span className={`badge badge-phoenix badge-phoenix-secondary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-cloud-arrow-up fs-9"></i></span>
                                                    Cargar Documento Cache
                                                </a>
                                            </li>
                                            <li>
                                                <button className='dropdown-item' href='#' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" onClick={handleOpen}>
                                                    <span className={`badge badge-phoenix badge-phoenix-secondary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}><i className="fa-solid fa-plus fs-9"></i></span>
                                                    Datos extras de la factura.
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className='me-1'>
                                    <FormDocumento ocultarTodo={1} tab={TabIndexActual} />
                                </div>

                                <TipoDocumento TabIndexActual={TabIndexActual} usuario={user} LogicaDocumento={LogicaDocumento} actualizarTab={actualizarTab} />
                            </div>








                            <Items HandleContinuar={HandleContinuar} setFocoTeclado={setFocoTeclado} focoTeclado={focoTeclado} OcultarMostrarPanel={OcultarMostrarPanel} mostrarFormPagos={mostrarFormPagos} activeTab={TabIndexActual} usuario={user} />
                        </div>
                    </div>
                }

                {(!__app.TipoMovil() && showmesa) && <Mesas clasesEstilos={'cuentasAbiertas'} funshowmesa={funshowmesa} imprimirPreFactura={imprimirPreFactura} user={user} seleccionarMesa={seleccionarMesa} cargarDocumento={cargarDocumento} eliminarCuentaAbierta={eliminarCuentaAbierta} />}





                {/***
                 * Formulario Mesas Apaa
                */}
                {
                    __app.TipoMovil() &&

                    <StandarOffCanvas titulo="Mis Pedidos" desactivar={() => SetshowCanvasMisPededios(!showCanvasMisPededios)} mostrar={showCanvasMisPededios}  >
                        <Mesas user={user} seleccionarMesa={seleccionarMesa} cargarDocumento={cargarDocumento} eliminarCuentaAbierta={eliminarCuentaAbierta} />
                    </StandarOffCanvas>
                }


                {/***
                 * Formulario Guardad Documento
                */}
                <StandarModal size="xl" titulo="Guardar Documento" desactivar={() => SetshowFormGuardarDocumento(!showFormGuardarDocumento)} mostrar={showFormGuardarDocumento}  >
                    <GuardarDocumento documento={documento} accion={handleBlurPrincipal} desactivar={SetshowFormGuardarDocumento} />
                </StandarModal>

                {/***
                 *  Lista Documento a Guardar
                */}
                <StandarModal size="xl" titulo="Guardar Documento" desactivar={() => SetShowFormCargarDocumento(!showFormCargarDocumento)} mostrar={showFormCargarDocumento}  >
                    <ListarDocumentos desactivar={SetShowFormCargarDocumento} cargarDocumento={cargarDocumento} />
                </StandarModal>



                {/***
                 * Formulario Formas de Pago
                */}
                <StandarModal colorHeader={'p7-dropdown-' + documento.TIPO_DOCUMENTO.color_documento} size="xl" titulo={"Formas de Pago de " + documento.TIPO_DOCUMENTO.nombre} desactivar={() => Setswitchshowformpago(false)} mostrar={switchshowformpago}  >
                    <FormasPago asignarDevuelta={asignarDevuelta} COMANDO={COMANDO} desactivar={FinalizarVenta} tab={TabIndexActual} documento={documento} LogicaDocumento={LogicaDocumento} dispatchGlobal={dispatchGlobal} ModoAbonar={0} />
                </StandarModal>




                {/***
                * Formulario Lista Compuesto, solo para restaurante.
                */}
                <StandarModal headerShow={false} size="md" title="Productos Compuestos" desactivar={() => SetshowListCompuesto(!showListCompuesto)} mostrar={showListCompuesto}  >
                    <SeleccionProductosCompuesto desactivar={() => SetshowListCompuesto(!showListCompuesto)} accion={productosCompuestoPaso2} auxitem={auxitem} />
                </StandarModal>



                {/***
                * Formulario Lista Compuesto, solo para restaurante.
                */}
                <StandarModal headerShow={false} size="md" title="Productos Compuestos" desactivar={() => SetshowFormcargarDevolucion(!showFormcargarDevolucion)} mostrar={showFormcargarDevolucion}  >
                    <CargarFacturaDevolucion usuario={user} LogicaDocumento={LogicaDocumento} actualizarTab={actualizarTab} desactivar={() => SetshowFormcargarDevolucion(!showFormcargarDevolucion)} accion={null} />
                </StandarModal>



                {/***
                * Formulario Lista Compuesto, solo para restaurante.
                */}
                <StandarModal headerShow={false} size="md" title="Productos Compuestos" desactivar={() => SetshowFormcargarcotizacion(false)} mostrar={showFormcargarCotizacion}  >
                    <CargarCotizacion usuario={user} LogicaDocumento={LogicaDocumento} actualizarTab={actualizarTab} desactivar={() => SetshowFormcargarcotizacion(false)} accion={null} />
                </StandarModal>

                
                {/***
                * Cargar Retención.
                */}
                <StandarModal headerShow={false} size="md" title="Productos Compuestos" desactivar={() => SetshowFormAplicarRetencion(false)} mostrar={showFormAplicarRetencion}  >
                    <CargarRetencion usuario={user} LogicaDocumento={LogicaDocumento} actualizarTab={actualizarTab} desactivar={() => SetshowFormAplicarRetencion(false)} accion={null} />
                </StandarModal>



                


                {/***
                * Cargar una cuenta Abierta
                */}
                <StandarModal headerShow={true} size="xl" title="Productos Compuestos" desactivar={() => SetshowFormcargarCuentaAbierta(false)} mostrar={showFormcargarCuentaAbierta}  >
                    <CargarCuentaAbierta cargarDocumento={cargarDocumento} usuario={user} LogicaDocumento={LogicaDocumento} actualizarTab={actualizarTab} desactivar={() => SetshowFormcargarCuentaAbierta(false)} accion={null} />
                </StandarModal>





                {/***
                * Pin de vendedor
                */}
                <StandarModal keyboard={true} headerShow={true} size="md" titulo="Pin  de Vendedor" desactivar={() => SetShowPinvendedor(false)} mostrar={showPinVendedor}  >
                    <PinVendedor guardarVendedor={handleBlurPrincipal} desactivar={SetShowPinvendedor} modalformadePago={Setswitchshowformpago} extra={showPinVendedor} />
                </StandarModal>






                <StandarModal desactivar={() => SetshowModalVariantesInventario(false)} mostrar={showModalVariantesInventario}>
                    <VariantesIventario auxitem={auxitem} extraerVariantes={extraerVariantes} />
                </StandarModal>

                


                
                <StandarModal size="xl" desactivar={() => SetshowErroresValidacion(false)} mostrar={showErroresValidacion}>
                    <ValidacionesDocumento  mensaje_errores={erroresDocumento} />
                </StandarModal>



                {/*
                * Platilla de Reimpresion Solo para cuenta Abierta
                */}
                {imprimirDocumento && <FacturaTirilla documento={imprimirDocumento} user={user} />}
            </div>



            <div className={"columna-2  col-sm-5 col-md-6	col-lg-4	col-xl-4	col-xxl-4 " + panelActual.items}>
                <ItemsAll KeyObservadores={KeyObservadores} CodigoSincronizacion={CodigoSincronizacion} setFocoTeclado={setFocoTeclado} focoTeclado={focoTeclado} agregarItemPadre={agregarItemPadre} user={user} hanldleComandos={hanldleComandos} />
            </div>

            {/* OCULTAS POR EL MOMENTO YULII ESTAS SON LAS TECLAS DEL TECLADO */}
            <div style={{ display: 'none' }}>
                <button ref={TeclaF3} onClick={SwitchCambioPrecio}></button>
                <button ref={TeclaF4} onClick={imprimirUltimoDocumento}>F4</button>
            </div>
        </div>




    );
}


export default React.memo(Documento);