import React, { useState, useEffect } from 'react';
import { useContextDocumentValue, actionTypeDocument } from "../context/ContextDocument.js";



// ESTABA ASI FormDocumento(props, ocultarTodo = 0)
function FormDocumento({ ocultarTodo = 0 }) {
    const [{ documento }, dispatch] = useContextDocumentValue();

    const Initialform = {
        fecha_factura: "",
        plazo: "",
        fecha_vencimiento: "",
        observacion: "",
        numero_factura: "",
        vendedor: "",
        numero_devolucion: ""
    };

    const [form, setForm] = useState(Initialform);

    useEffect(() => {
        if (documento && documento.FORM) {
            setForm({ ...Initialform, ...documento.FORM });
        } else {
            setForm(Initialform);
        }
    }, [documento]);

    useEffect(() => {
        calcularPlazo();
    }, [form.fecha_factura, form.fecha_vencimiento]); // CUANDO LAS FECHAS CAMBIAN

    useEffect(() => {
        ajustarFechasPorPlazo();
    }, [form.plazo]); // CUANDO CAMBIA EL PLAZO

    const handleBlur = () => {
        dispatch({
            type: actionTypeDocument.SET_DOCUMENTO,
            documento: {
                ...documento,
                FORM: form
            },
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => {
            const updatedForm = { ...prevForm, [name]: value || "" };

            if (name === "fecha_factura" || name === "fecha_vencimiento") {
                setTimeout(() => calcularPlazo(updatedForm), 0);
            }
            if (name === "plazo") {
                setTimeout(() => ajustarFechasPorPlazo(updatedForm), 0);
            }

            return updatedForm;
        });
    };

    const calcularPlazo = (updatedForm = form) => {
        if (updatedForm.fecha_factura && updatedForm.fecha_vencimiento) {
            const inicio = new Date(updatedForm.fecha_factura);
            const fin = new Date(updatedForm.fecha_vencimiento);
            
            const diferencia = Math.abs(Math.ceil((fin - inicio) / (1000 * 60 * 60 * 24))); //CALCULAR LA DIFERENCIA DE DIAS ENTRE LAS FECHAS SIN IMPORTAR NEGATIVOS

            setForm((prev) => ({
                ...prev,
                plazo: diferencia
            }));
        }
    };

    const ajustarFechasPorPlazo = (updatedForm = form) => {
        if (!updatedForm.plazo) return;

        const plazoDias = parseInt(updatedForm.plazo, 10);
        if (isNaN(plazoDias) || plazoDias < 0) return;

        let fechaInicio = updatedForm.fecha_factura ? new Date(updatedForm.fecha_factura) : new Date();
        let fechaVencimiento = new Date(fechaInicio);
        fechaVencimiento.setDate(fechaInicio.getDate() + plazoDias);

        setForm((prev) => ({
            ...prev,
            fecha_factura: fechaInicio.toISOString().split("T")[0],
            fecha_vencimiento: fechaVencimiento.toISOString().split("T")[0]
        }));
    };





    return (
        <form className=" " onChange={handleChange} onBlur={handleBlur}>
            {!ocultarTodo && (
                <>
                    <div className="row g-1 align-items-end">
                        <div className="form-group col flex-grow-1 form-online">
                            <label className="form-label">Fecha de Factura</label>
                            <input type="date" autoComplete="off" value={form.fecha_factura} name="fecha_factura" className="form-control" placeholder="Fecha de Factura" />
                        </div>
                        <div className="form-group col-auto form-online">
                            <label className="form-label">Plazo</label>
                            <input autoComplete="off" value={form.plazo} name="plazo" type="text" className="form-control" placeholder="Plazo" style={{ width: "80px" }}  />
                        </div>
                        <div className="form-group col flex-grow-1 form-online">
                            <label className="form-label">Fecha de Vencimiento</label>
                            <input autoComplete="off" value={form.fecha_vencimiento} name="fecha_vencimiento" type="date" className="form-control" placeholder="Vencimiento" />
                        </div>
                    </div>

                   

                    {(documento.TIPO_DOCUMENTO && documento.TIPO_DOCUMENTO.DEVOLUCION == 0) &&
                         <div className="form-group form-online">
                            <label className='form-label'>Numero de Factura (Referencia)</label>
                            <input autoComplete="off" value={form.numero_factura} name="numero_factura" type="text" className="form-control max-80" placeholder="Numero de Factura" />
                        </div>
                    }
                </>

            )}

            <div className="form-group">
                {!ocultarTodo ? (
                    <>
                        <label class="form-label" for="exampleTextarea">Observacion</label>
                        <textarea autoComplete="off" value={form.observacion} name="observacion" class="form-control" id="exampleTextarea" rows="10" placeholder='Observacion'> </textarea>
                    </>
                ) : (
                    <input autoComplete="off" value={form.observacion} name="observacion" type="text" className="form-control form-control-sm" placeholder="Observacion" />
                )}

            </div>
        </form>
    );
}

export default React.memo(FormDocumento, () => true);