import React, { useState } from 'react';
import VerDocumento from '../../../Documentos/VerDocumento/VerDocumento';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import CambiarOperador from './CambiarOperador';
import CambiarLiquidacion from './CambiarLiquidacion';
import AnularLiquidacion from './AnularLiquidacion';



const OpcionesLiquidacion = ({ document_id, terceroId, item_id, switch_liquidacion, actualizarTabla, user }) => {
    const [verDocumentoModal, setVerDocumentoModal] = useState(false);
    const [cambiarOperadorModal, setCambiarOperadorModal] = useState(false);
    const [cambiarLiquidacionModal, setCambiarLiquidacionModal] = useState(false);
    const [facturaActual, setFacturaActual] = useState(null);
    
    const PermitirCambiarLiquidacion = (user.PERMISOS.PermitirCambiarLiquidacion) ? user.PERMISOS.PermitirCambiarLiquidacion : 0;
    const PermitirCambiarOperario = (user.PERMISOS.PermitirCambiarOperario) ? user.PERMISOS.PermitirCambiarOperario : 0;


    const abrirModalVerDocumento = (document_id) => {
        setFacturaActual(document_id);
        setVerDocumentoModal(true);
    };

    const abrirModalCambiarOperador = (item_id) => {
        setFacturaActual(item_id);
        setCambiarOperadorModal(true);
    };

    const abrirModalCambiarLiquidacion = (item_id) => {
        setFacturaActual(item_id);
        setCambiarLiquidacionModal(true);
    };

    const handleCerrarModales = () => {
        setVerDocumentoModal(false);
        setCambiarOperadorModal(false);
        setCambiarLiquidacionModal(false);
        setFacturaActual(null);
    };

    
    return (
        <div class="dropdown d-inline-block">
            <button p7-data-bs-toggle="tooltip" data-bs-placement="top" title="Opciones" className="btn btn-sm btn-subtle-secondary p-2 py-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                <i class="fa-solid fa-ellipsis" style={{ fontSize: 15 }}></i>
            </button>
            <div className="dropdown-menu  shadow-lg p-2"  >
                <span onClick={() => abrirModalVerDocumento(document_id)} className="dropdown-item btn  btn-subtle-secondary btn-sm mb-1" style={{ textAlign: 'left' }}>
                    <i className="fas fa-eye me-2 fa-lg"></i> Ver
                </span>
                {PermitirCambiarOperario == 1 && <>
                    <span onClick={() => abrirModalCambiarOperador(item_id)} className="dropdown-item btn  btn-subtle-secondary btn-sm mb-1" style={{ textAlign: 'left' }}>
                        <i class="fa-solid fa-person-walking-arrow-loop-left me-2 fa-lg"></i>Cambiar operador
                    </span>
                </>}

                {PermitirCambiarLiquidacion == 1 && <>
                    <span onClick={() => abrirModalCambiarLiquidacion(item_id)} className="dropdown-item btn  btn-subtle-secondary btn-sm" style={{ textAlign: 'left' }}>
                        <i class="fa-solid fa-dollar-sign  me-2 fa-lg"></i> Cambiar Liquidacion
                    </span>
                </>}


                <div className="dropdown-divider"></div>
                <AnularLiquidacion document_id={document_id} actualizarTabla={actualizarTabla} />
            </div>

            {/* VER FACTURA  */}
            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura." desactivar={handleCerrarModales} mostrar={verDocumentoModal}>
                <VerDocumento document_id={facturaActual} desactivar={handleCerrarModales} />
            </StandarModal>

            {/* CAMBIAR OPERADOR */}
            <StandarModal keyboard={true} headerShow={true} size="m" titulo="Cambiar Operador." desactivar={handleCerrarModales} mostrar={cambiarOperadorModal}>
                <CambiarOperador item_id={facturaActual} desactivar={handleCerrarModales} actualizarTabla={actualizarTabla} />
            </StandarModal>

            {/* CAMBIAR LIQUIDACION */}
            <StandarModal keyboard={true} headerShow={true} size="m" titulo="Cambiar Liquidacion." desactivar={handleCerrarModales} mostrar={cambiarLiquidacionModal}>
                <CambiarLiquidacion switch_liquidacion={switch_liquidacion} terceroId={terceroId} item_id={facturaActual} desactivar={handleCerrarModales} actualizarTabla={actualizarTabla} />
            </StandarModal>
        </div>
    );
};

export default OpcionesLiquidacion;
