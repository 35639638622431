import React, { useEffect, useState } from 'react';
import Form from './Form';
import StandarModal from '../../Document/PartesDocument/StandarModal';

const Add = ({ item, SetItem, cargarMensualidades }) => {

    const [showForm, seshowForm] = useState(null);


    const mostrarFormulario = () => {
        SetItem(null);
        seshowForm(true);
    }

    useEffect(() => {
        if (item) {
            seshowForm(true);
        }

    }, [item])


    const cerrarModal = () => {
        seshowForm(!showForm);
        SetItem(null);
    }

    const textoBoton = item ? "Actualizar Mensualidad" : "Registrar Mensualidad";
    const editar = item ? "1" : "0";
    return (
        <div>
            <button className='btn btn-success btn-sm' onClick={mostrarFormulario}><i class="fa-solid fa-plus fa-lg me-1"></i>Agregar Mensualidad</button>

            <StandarModal size="xl" titulo="Formulario de Registro" desactivar={cerrarModal} mostrar={showForm}  >
                <Form mostrar={seshowForm} SetItem={SetItem} item={item} textoBoton={textoBoton} editar={editar} cargarMensualidades={cargarMensualidades} />
            </StandarModal>
        </div>

    );
};

export default Add;