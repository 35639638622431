import { __app } from "../../../../../utils/myApp";

export const _liquidacion = {
    estados_liquidacion: function ({ estado, aux_serv_porcentaje_liquidar }) {
        switch (parseInt(estado, 10)) {
            case 1:
                return (
                    <>
                        %{aux_serv_porcentaje_liquidar} 
                        <span className="badge rounded-pill bg-info fs-10">Porcentaje</span>
                    </>
                );
            case 2:
                return (
                    <>
                        $ {__app.formato_decimal({ total: aux_serv_porcentaje_liquidar })}
                        <span className="badge rounded-pill bg-primary-dark fs-10"> Estimado</span>
                    </>
                );
            default:
                return <span className="badge rounded-pill bg-warning fs-10">No definido</span>;
        }
    },
    
    liquidacion: function ({ estado, SUBTOTAL_1, aux_serv_porcentaje_liquidar }) {
        let total;
    
        switch (parseInt(estado, 10)) { 
            case 1:
                total = __app.formato_decimal({ total: SUBTOTAL_1 * (aux_serv_porcentaje_liquidar / 100) });
                break;
            case 2:
                total =  __app.formato_decimal( { total: aux_serv_porcentaje_liquidar });
                break;
            default:
                total = 0;
        }
    
        return total;
    },
}
