import React from 'react';

const Alerta = ({ mensaje, title, tipo = "danger" }) => {
    return (
        <div className={`alert alert-subtle-${tipo} text-center`} role="alert">
            <div className="mx-auto mb-2">
                <i className="fa-regular fa-face-sad-tear" style={{ fontSize: "40px" }}></i>
            </div>
            <p className="fs-6 fw-bold">{title || "Error"}</p>
            <h5 className='alert-heading'>{mensaje || "Ocurrio un error inesperado."}</h5>
        </div>
    );
};

export default Alerta;