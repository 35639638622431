
import React from 'react';
import { __app, __fecha } from '../../../../../utils/myApp';

const HistorioDeConsumos = ({PAGOS_HISTORIAL_DE_CONSUMO}) => {
    return (
        <>
            
                {/*** HISTORICO DE CONSUMO
                    * Aplica en su mayoria para las devoluciones, ya que ese saldo a favor sera consumido como forma de pagos.
                    * para que el usuario vea con claridad donde una NOTA credito se consumio, desplegaremos esta opcion.
                    */}
                {  (PAGOS_HISTORIAL_DE_CONSUMO.length > 0)  && <>
                
                
                <tr >
                    <th  colSpan={8}><p className='mt-3 text-center'>Historia de Consumo.</p></th>         
                </tr>

                <tr>
                    <th className="text-center">Codigo</th>
                    <th className="text-center">Fecha</th>
                    <th className="text-right">Responsable</th>
                    <th className="text-right">Codigo del Pago Consumido</th>
                    <th className="text-right">-</th>
                    <th className="text-right">Punto de Venta</th>
                    <th className="text-right">Turno</th>
                    <th className="text-right">Total</th>

            
                </tr>
                
                {  PAGOS_HISTORIAL_DE_CONSUMO.map((item, key) => (
                
                    <tr key={key}>
                
                       
                        <td>Pago Consumido</td>
                        <td>{ __fecha.formatoFecha({$fecha:item.cr}) }</td>
                        <td>{item.forma_pagos_nombre}</td>
                        <td>----</td>
                        <td>El Saldo fue usado como forma de pago en el documento #<span className='bg-primary badge'>#{item.ctd_cun}</span>  </td>
                        <td>----</td>
                        <td>----</td>
                        <td> $ <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  item.tt , color : false , icono : false , numerosPositivos : true  } )} }></span> </td>
                        
                    </tr>
                
                ))}
            </>}
        </>
    );
};

export default HistorioDeConsumos;