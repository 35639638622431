import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';

const Empresa = ({ empresa, user }) => {
    return (
        <table cellSpacing="0" style={{ width: '100%', fontSize: '12px', borderCollapse: 'collapse' }}>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '45%' }} />
                <col style={{ width: '35%' }} />
            </colgroup>
            <thead>
                <tr style={{ background: '#E7E7E7', borderBottom: 'solid 1px black' }}>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ textAlign: 'center', fontWeight: '700', padding: '10px' }}>
                        {user.CONFIGURACIONES_TABLE.mostrar_logo_factura == 1 && (
                            <img width="160" src={empresa.LOGO} className="img-fluid rounded-start" alt="Logo Empresa" style={{ marginBottom: '10px' }} />
                        )}
                    </td>

                    <td style={{ textAlign: 'center', padding: '10px' }}>
                        <span style={{ fontSize: '14px', display: 'block' }}>
                            <b>NIT: {user.ESTACION.nit}</b>
                        </span>
                        <h4 style={{ margin: '0', fontSize: '16px' }}>{user.ESTACION.nombre}</h4>
                        <span style={{ fontSize: '12px', display: 'block' }}>
                            <b>Dirección: {user.ESTACION.direccion}</b>
                        </span>
                        <span style={{ fontSize: '12px', display: 'block' }}>
                            <b>Teléfono: {user.ESTACION.telefono}</b>
                        </span>
                        <span style={{ fontSize: '12px', display: 'block' }}>
                            <b>Correo: {user.ESTACION.correo_electronico}</b>
                        </span>
                    </td>
                    <td style={{ textAlign: 'center', padding: '10px' }}>
                        <p style={{ margin: '5px 0', fontSize: '12px' }}><b>Fecha de Impresión</b></p>
                        <p style={{ margin: '5px 0', fontSize: '12px' }}>{ __fecha.formatoFecha({$fecha :  __app.getFecha() }) }</p>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default Empresa;