import React from 'react';
import {__app , __fecha } from "../../../utils/myApp"
import {LabelEstadoPago } from "../../../Core/Html/ComponenteHtml"
import TablaDocumentos from '../../Terceros/TerceroView/TablaDocumentos';


const   ListaDocumentos = ({tipodoc}) =>  {


    const campos = [
        'Fecha',
        'PuntoDeVenta',
        'Cliente',
        '#Interno',
        '#Factura',
        'Tipo',
        'Descuento',
        'Estado',
        'Precio',
        'Pendiente',
        'Utilidad',
        'Opciones',
    ];


        if (!tipodoc.DOCUMENTOS){
            console.log("NO HAY DOCUMENTOS EN tipodoc." + tipodoc.nombre)
            return false;
          
        }
   
    return (


   
        <div>
            { tipodoc.DOCUMENTOS && <TablaDocumentos  actualizarDatos={() => {} } documentos={ tipodoc.DOCUMENTOS} campos={campos} />}
        </div>


    );
}

export default ListaDocumentos;