import prindiv from 'easyprintand';
import Carta from './Modos/Carta';
import Tirrilla from './Modos/Tirrilla';

const BotonImprimir = ({ pago }) => {
    const handlePrint = () => {
        prindiv('EasyPrintAnd' );
    }
    const handlePrint2 = () => {
        prindiv('EasyPrintAndTirilla',  { extraCss : '../tirilla.css' });
    }
    return (
        <div>
            <span onClick={handlePrint}  className='dropdown-item' roll="button" style={{ cursor: 'pointer' }}>
                <span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                    <i className="fa-solid fa-file-lines fs-9"></i>
                </span>
                Tamaño Carta
            </span>
            <span onClick={handlePrint2}  className='dropdown-item' roll="button" style={{ cursor: 'pointer' }}>
                <span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                    <i className="fa-solid fa-receipt fs-9"></i>
                </span>
                Tamaño Tikect
            </span> 
            <Carta pago={pago} />
            <Tirrilla infoPago={pago} />
        </div>
    );
};

export default BotonImprimir;