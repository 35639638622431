export const actionType = {


    VEHICULO : 'VEHICULO',

    
    
    PRODUCTOS : 'PRODUCTOS',

    
    CLIENTES : 'CLIENTES',
}


const reducerObservador = ( state , action ) => {
 
   
    // eslint-disable-next-line default-case
    switch(action.type){

       

        case actionType.VEHICULO:
            return {
                ...state , 
                vehiculo : action.vehiculo
            };

        default :
            return state;

    }
}

export default reducerObservador;