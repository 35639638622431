import React from 'react';

export const ComponenteHtml = () => {
    return (
        <div>
            
        </div>
    );
};


export const BadgeCustom  = ({ colores, etiqueta }) => {


     // Divide la cadena de colores usando ';' como delimitador
     const [fondo, colorLetra, borde] = colores ? colores.split(';') : [];

     // Define los colores predeterminados si no se proporcionan
     const defaultFondo = fondo || '#E6E6E6';
     const defaultColorLetra = colorLetra || '#333';
     const defaultBorde = borde ? `1px solid ${borde}` : '1px solid #ccc';
 
     const estiloBadge = {
         backgroundColor: defaultFondo,
         color: defaultColorLetra,
         border: defaultBorde,
         padding: '2px 2px',
         borderRadius: '5px',
        fontSize : '10px',
         display: 'inline-block',
     };

    return (
        <span  style={estiloBadge} >

           {etiqueta  }

        </span>
    );
};

export const LabelEstadoPago  = ({ estado  }) => {

    let texto = "vv";
    let etiqueta = "";

    if( estado == 1 ){
        texto = " Pagado ";
        etiqueta = "success";
    }

    if( estado ==  0 ){
        texto = " Por Cobrar ";
        etiqueta = "danger";
    }

   return (
       <span  className={'badge rounded-pill p7-badge-' + etiqueta }  >

          {texto }

       </span>
   );
};






	/*
	0=> Sin Enviar, 
	1=> sin respuesta de la DIAN
	2=> Enviado,    #Aplica para documentos Asincronos
	3=> Consultado  #Aplica para documentos Asincronos
	4=> Aceptado 
	5=> Validación en proceso  #Aplica para documentos Asincronos
	6=> Rechazado por la Dian!
	*/

export const EtiquetaEstadiDian = ({ documentoElectronica, respuesta }) => {
    
    if (documentoElectronica != 1) {
        return false;
    }


    let icono = null;

    switch (parseInt(respuesta)) {
        case -1:
            return (
                <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            );
        
        case 0:
                icono = <i title="Rechazado por pluma7" className="fa-solid fa-triangle-exclamation text-warning" aria-hidden="true"></i>
                break;

        case 1:
            icono = <i title="Sin respuesta de la DIAN." className="fa-solid fa-triangle-exclamation text-warning" aria-hidden="true"></i>
            break;
        case 4:
            icono = <i title="Aceptado por la DIAN " className="fa fa-check text-success" aria-hidden="true"></i>
            break;
        case 6:
            icono = <i title="Rechazado por la DIAN" className="fa fa-xmark text-danger" aria-hidden="true"></i>
            break;
        default:
            return 'sin caso';
    }

    return (
        icono
    );
};

