import React from 'react';
import { useState } from 'react';

const  CambiarNomDescripcion = ({fontSize , item,indexx,accionF,accionName , user , keyShow}) => {
   
    const [showinput , setShowInput ] = useState(false);

    if (item[keyShow] === undefined){
        return false;
    }

    const handleChange = (e) => {
        setShowInput(false);
        let valor = e.target.value;
        let accion = accionName;
        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        return false;   
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }

    if ( user.type_user_id != 1 && keyShow != "descripcion"    ){


        return (
            <span style={{fontSize:fontSize-2}}>
                { item[ keyShow ] } 
            </span>
        )
        
    }
    

    return (
        <>
           { !showinput && 
            
            <div onClick={ () =>  setShowInput(true) }> 

                { console.log(item[keyShow]) }
              
                { (  item[keyShow] &&   item[keyShow].trim().length > 0  ) ? <div> {item[ keyShow ]  } </div> : <div><i class="fa fa-comment fa-lg" aria-hidden="true"></i></div>   }

            </div> }
           
           {showinput && 
           
           <textarea
            onKeyDown={_handleKeyDown}
            className='form-control'
            autoFocus
            rows={5}
            onBlurCapture={handleChange}
            defaultValue={item[keyShow]}
            />

           
           }
        

        </>
    );
}

export default CambiarNomDescripcion;