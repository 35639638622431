import React from 'react';
import { NotificationManager } from 'react-notifications';
import { eliminarPago } from '../../controlador';
import { showConfirm } from '../../../Layaut/LayautDefault';

const EliminarPago = ({ pago, recargarDocumento }) => {

    const handleEliminarFactura = async () => {
        showConfirm("⚠️ ¡ALTO! ⚠️\n\n¿Estás seguro de que deseas continuar con esta acción?\n\nEsta operación no se puede deshacer.", respuestaSi);
    }

    const respuestaSi = async () => {
        const respuesta = await eliminarPago(pago);
        if (respuesta.error_pago == 0) {
            NotificationManager.success("Pago eliminado con Exito", 'Pago eliminado #' + pago);
            recargarDocumento();
        } else {
            NotificationManager.error("El pago no se ha podido eliminar", 'Error al eliminar el Pago #' + pago);
        }
    }
    return (
        <>
            <button onClick={handleEliminarFactura} className="btn btn-danger btn-xs ps-2 pe-1">
                <i className="fa fa-trash-can me-1"></i>
            </button>
        </>

    );
};

export default EliminarPago;