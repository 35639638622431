import React from 'react';

const Placa = ({ placa }) => {
    return (
        <div className="w-100 d-flex align-items-center">
            <div
                className="card border-dark border-3 rounded-3 shadow d-flex align-items-center justify-content-center position-relative m-1"
                style={{ backgroundColor: '#F9D71C', width: 100, height: 40, borderRadius: 8, textAlign: 'center' }}
            >
                <div className="position-absolute" style={{ top: 4, left: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
                <div className="position-absolute" style={{ top: 4, right: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
                <div className="position-absolute" style={{ bottom: 4, left: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
                <div className="position-absolute" style={{ bottom: 4, right: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>

                <div
                    className="card-body d-flex align-items-center justify-content-center w-100 p-0"
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    <h3 className="fw-bold text-dark mb-0 text-truncate w-100 text-center" style={{ fontSize: 14, letterSpacing: 2 }}>
                        {placa || "------"}
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default Placa;
