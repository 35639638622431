import React from 'react';
import OptionSelect from '../../../Core/Html/OptionSelect';

const CargarRetencion = ({ usuario, desactivar  , LogicaDocumento}) => {
    const impuestosLocal = usuario?.DATABASE?.impuestos || [];
    const impuestos = impuestosLocal.filter(impuesto => impuesto.tipo_tributo_id === "1");
    const retenciones = impuestosLocal.filter(impuesto => impuesto.tipo_tributo_id === "2");

    const handleChange = (e, id) => {
        const value = e.target.value;
        document.getElementById(`importe_${id}`).value = value;
    };

    const handleEnviarImpuesto = (impuesto) => {
        const importe = document.getElementById(`importe_${impuesto.id}`).value;
        impuesto.importe = importe;
        let valor = {
            renderizar_total : true 
        }

        LogicaDocumento.despachador( "agregar_tributo_todos_items_documentos" , {impuesto , valor})
        desactivar();
    };

    return (
        <form>
            <h3>Aplicar Tributo a todos los Items</h3>
            
            <h4>Impuestos</h4>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Importe</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {impuestos.map(impuesto => (
                        <tr key={impuesto.id}>
                            <td>{impuesto.nombre}</td>
                            <td>
                                <input 
                                    id={`importe_${impuesto.id}`} 
                                    type="decimal" 
                                    className="form-control"
                                    defaultValue={impuesto.importe || ''}
                                    onChange={(e) => handleChange(e, impuesto.id)}
                                />
                            </td>
                            <td>
                                <button 
                                    type="button" 
                                    className="btn btn-success"
                                    onClick={() => handleEnviarImpuesto(impuesto)}
                                >
                                    Enviar Impuesto
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h4>Retenciones</h4>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Importe</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {retenciones.map(impuesto => (
                        <tr key={impuesto.id}>
                            <td>{impuesto.nombre}</td>
                            <td>
                                <input 
                                    id={`importe_${impuesto.id}`} 
                                    type="decimal" 
                                    className="form-control"
                                    defaultValue={impuesto.importe || ''}
                                    onChange={(e) => handleChange(e, impuesto.id)}
                                />
                            </td>
                            <td>
                                <button 
                                    type="button" 
                                    className="btn btn-success"
                                    onClick={() => handleEnviarImpuesto(impuesto)}
                                >
                                    Enviar Impuesto
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
                <select className="mt-5 form-control">
                    <OptionSelect name="impuesto" defaulNombre={"-- Agregar Otro Tributo --- "} datos={impuestosLocal} />
                </select>
            </div>

            <div className="mt-3">
                <button type="button" onClick={desactivar} className="btn btn-dark">Cancelar</button>
            </div>
        </form>
    );
};

export default CargarRetencion;
