import React, { useEffect, useState } from 'react';
import Select from '../../../Documentos/Documentos/Componentes/Filtros/Filtros/Select';
import { extraerMecanicos } from '../../Core/Core';
import { enviarPeticion } from '../../../../Core/ActionDatabase';
import { fetchEmpresa } from '../../../../Core/fetchLocalStoreData';
import { NotificationManager } from 'react-notifications';

const CambiarOperador = ({ item_id, actualizarTabla }) => {
    const [formData, setFormData] = useState({});
    const [operadores, setOperadores] = useState([]);

    const ConfigEmpresa = fetchEmpresa();

    useEffect(() => {
        cargarOperadores();
    }, []);

    const cargarOperadores = async () => {
        try {
            const data = await extraerMecanicos();
            console.log("Operadores cargados:", data);
            setOperadores(data);
        } catch (error) {
            console.error("Error al cargar operadores:", error);
        }
    };

    const filtros = [
        {
            label: "Operadores",
            name: "operadores",
            placeholder: "Seleccione un operador",
            isMulti: false,
            options: operadores.map(op => ({
                id: op.id,
                nombre: op.razonSocial
            }))
        }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;

        console.log(`Cambio detectado: ${name} = ${value}`);
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const datosEnviar = { ...formData, item_id };
        console.log("Enviando datos:", formData);

        const url = `terceros/cambiar_empleado`;
        
        try {
            const respuesta = await enviarPeticion(url, ConfigEmpresa.nit, {
                body: datosEnviar,
                headers: { "Content-Type": "application/json" }
            });

            if (respuesta.respuesta == 1){
                NotificationManager.success(respuesta.mensaje  , 'Actualización exitosa');
                actualizarTabla();
            }else{
                NotificationManager.error(respuesta.mensaje  , 'Error');
            }
        } catch (error) {
            console.error("Error en la peticion:", error);
        }
    };

    return (
        <div>
            <form onChange={handleChange} onSubmit={handleSubmit} method="POST">
                {filtros.map((filtro, idx) => (
                    <div key={idx} className="form-group mb-3">
                        <Select filtro={filtro} setFormData={setFormData} formData={formData} />
                    </div>
                ))}
                <button type="submit" className="btn btn-sm btn-secondary">
                    Cambiar Operador
                </button>
            </form>
        </div>
    );
};

export default CambiarOperador;
