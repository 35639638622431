import React, { useEffect, useState } from 'react';
import { extraerInfoDocumento } from '../controlador';
import Encabezado from './Encabezado';
import Items from './Items';
import Pagos from './Pagos/Pagos';
import { __app, __pluma7 } from '../../../utils/myApp';
import Botones from './Botones';


// func : PagoExitoso como estamos viendo el documento, posiblemente se realize un abono, 
// puede que nesecitemos una funcion para recargar algun contenido cuando la pagina sea exitosa. 
const VerDocumento = ({ document_id, PagoExitoso = null }) => {

    const [documento, setDocumento] = useState();

    useEffect(() => {

        getDatos();

    }, [])

    const getDatos = async () => {
        let infoFactura = await extraerInfoDocumento(document_id);
        
        if(infoFactura == -1){
            setDocumento(-1);
            alert("Documento no encontrado, posiblemente borrado");
            return false;
        }
        setDocumento(infoFactura);
    }

    const recargarDocumento = (aux) => {

        setDocumento(null);
        getDatos();


        if (typeof PagoExitoso === 'function') {
            PagoExitoso(aux);
        }
    }

    if (documento == -1) return (<p className='text text-danger'>Documento no existe</p>);


    if (!documento) return (<p>Cargando...</p>);
    return (
        <div>

            {documento && <Botones document={documento} recargarDocumento={recargarDocumento}  />}


            {documento && <Encabezado document={documento} recargarDocumento={recargarDocumento} />}

            {documento && <Pagos document={documento} recargarDocumento={recargarDocumento} />}

            {documento && <Items document={documento} recargarDocumento={recargarDocumento} />}





        </div>
    );
};

export default VerDocumento;