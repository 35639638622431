import React, { useState } from 'react';

const CambiarPrecioParqueadero = ({CambiarPrecioParqueadero , precioAntes}) => {
    const [precio, setPrecio] = useState(precioAntes);

    // Función para manejar el cambio de precio
    const manejarCambioPrecio = () => {
       
        if(precio> 0){

            CambiarPrecioParqueadero(precio)

        }
    };

    return (
        <div className="container mt-5">
            <h3 className="mb-4">Cambiar Precio del Parqueadero</h3>

            <div className="card p-4 shadow-sm">
                <div className="form-group">
                    <label htmlFor="precioParqueadero">PRECIO MANUAL DE PARQUEADERO:</label>
                    <input 
                        id="precioParqueadero"
                        className="form-control"
                        type="number"
                        value={precio}
                        onChange={(e) => setPrecio(e.target.value)}
                        placeholder="Ingrese el nuevo precio"
                    />
                </div>

                <button
                    onClick={manejarCambioPrecio}
                    className="btn btn-primary mt-3"
                >
                    Cambiar Precio
                </button>
            </div>
        </div>
    );
};

export default CambiarPrecioParqueadero;
