import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import Pendiente from '../../Componentes/Pendiente';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';
import HistorioDeConsumos from './HelperPagos/HistorioDeConsumos';
import PagosConsumidos from './HelperPagos/PagosConsumidos';
import PagosDirectos from './HelperPagos/PagosDirectos';

const ListaPagos = ({ document, setMenu, recargarDocumento }) => {

    const { PAGOS, DOCUMENTO, PAGOS_CONSUMIDOS, PAGOS_HISTORIAL_DE_CONSUMO } = document;



    const totalDocumento = parseFloat(DOCUMENTO.CtDocumento_SUBTOTAL_3);

    const totalPagadoDirecto = PAGOS
        .filter((item) => item.estado == 1) // Seleccionar elementos con estado igual a 1
        .reduce((acumulador, item) => acumulador + parseFloat(item.total), 0); // Sumar el valor de 'total'



    const TotalConsumido = PAGOS_CONSUMIDOS
        .reduce((acumulador, item) => acumulador + parseFloat(item.tt), 0); // Sumar el valor de 'total'


    const TotalHistoricoConsumo = PAGOS_HISTORIAL_DE_CONSUMO
        .reduce((acumulador, item) => acumulador + parseFloat(item.tt), 0); // Sumar el valor de 'total'


    //// El total del pago real del coudmento
    const totalPagado = totalPagadoDirecto + TotalConsumido + TotalHistoricoConsumo;


    const pendiente = Numpositivo(totalDocumento) - Numpositivo(totalPagado);

    return (
        <div>
            <div class="row g-2 mb-2 justify-content-center ">
                <div class="col-auto mt-3  mb-2">
                    <h5 className="text-center" >
                        <i className="fa-solid fa-sack-dollar fa-lg me-2"></i> Informacion de pagos
                    </h5>
                </div>
                <div class="col-auto">
                    {pendiente > 0 && <button onClick={() => setMenu(2)} className="btn btn-sm btn-success me-2">
                        Registrar abono
                    </button>}
                </div>
            </div>

            <div className='table-responsive'>
                <table className="table table-sm border-top  text-nowrap align-middle  fs-9 mb-0 table-bordered">
                    <tr>
                        <th className="text-center">#Pago</th>
                        <th className="text-center">Fecha</th>
                        <th className="text-right">Responsable</th>
                        <th className="text-right">Forma de Pago</th>
                        <th className="text-right">Banco</th>
                        <th className="text-right">Sede</th>
                        <th className="text-right">Turno</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Opciones</th>

                    </tr>


                    <tbody>
                        <PagosDirectos PAGOS={PAGOS} DOCUMENTO={DOCUMENTO} recargarDocumento={recargarDocumento}/>



                        <PagosConsumidos PAGOS_CONSUMIDOS={PAGOS_CONSUMIDOS} />




                        <HistorioDeConsumos PAGOS_HISTORIAL_DE_CONSUMO={PAGOS_HISTORIAL_DE_CONSUMO} />
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={8} style={{ height: "20px", border: "none" }}></td>
                        </tr>

                        <tr>
                            <td colSpan={6} ></td>
                            <td style={{ borderLeft: "1px solid #dee2e6" }}>Total Pagado</td>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: totalPagadoDirecto, color: true, icono: false, numerosPositivos: true }) }}></span>
                            </th>
                        </tr>

                        <tr>
                            <td colSpan={6}></td>
                            <td style={{ borderLeft: "1px solid #dee2e6" }}>Consumo de saldos a Favor</td>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                $ <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: TotalConsumido, color: false, icono: false, numerosPositivos: true }) }}></span>
                            </th>
                        </tr>

                        <tr>
                            <td colSpan={6} ></td>
                            <td style={{ borderLeft: "1px solid #dee2e6" }}>Historico de Consumo</td>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                $ <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: TotalHistoricoConsumo, color: false, icono: false, numerosPositivos: true }) }}></span>
                            </th>
                        </tr>

                        <tr>
                            <td colSpan={6} ></td>
                            <td style={{ borderLeft: "1px solid #dee2e6" }}>Saldo Pendiente</td>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                <Pendiente total={totalDocumento} abonado={totalPagado} />
                            </th>
                        </tr>
                    </tfoot>



                </table>
            </div>
        </div>
    );
};

export default ListaPagos;