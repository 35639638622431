import React from 'react';
import Header from './subcomponents/Header';
import Tercero from './subcomponents/Tercero';
import Pagos from './subcomponents/Pagos';
import '../ImprimirPago/Imprimir/css/tirilla.css';
import BotonImprimir from './Imprimir/BotonImprimir';

const ImprimirPagos = ({ infoPago, desactivarModal }) => {
    return (
        <div class="container">
            <div className='d-flex  align-items-center'>
                <div class="dropdown d-inline-block">
                    <button class="btn btn-subtle-primary me-1 mb-1 rounded-pill dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa-solid fa-print"></i> Imprimir</button><span class="caret"> </span>
                    <div class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton">
                        <BotonImprimir pago={infoPago.serverInfoPago} />
                    </div>
                </div>
                {/* EN PROCESOOO DE PROGRAMACIONNN CORREO Y WHASAPP */}
                {/* <button onClick={() => console.log(infoPago)} class="btn btn-subtle-primary me-1 mb-1 rounded-pill" type="button"> <i class="fa-solid fa-envelope"></i> Enviar por Correo</button>
                    <button class="btn btn-subtle-primary me-1 mb-1 rounded-pill" type="button"> <i class="fa-brands fa-whatsapp"></i> Enviar por WhatsApp</button> */}
                <button onClick={desactivarModal} type="button" className="float-end btn-close  ms-auto" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div className="factura-container">
                <div className="factura">

                    <div class="col-10 mx-auto text-center" >
                        <div class="alert alert-success p-2" role="alert">
                            <div className='row  justify-content-center'>
                                <div className='mb-2'>
                                    <i class="fas fa-check-circle fs-2 me-2"></i>
                                </div>

                                <div>
                                    <h4 class="alert-heading">¡Pago Exitoso!</h4>
                                    <span>Tu pago ha sido procesado correctamente. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="factura-separator" />
                    <Header infoPago={infoPago.serverInfoPago} />
                    <Tercero infoPago={infoPago.serverInfoPago} />
                    <hr className="factura-separator" />
                    <Pagos infoPago={infoPago.serverInfoPago} />
                </div>
            </div>
        </div>
    );
};

export default ImprimirPagos;