import React, { useState, useEffect } from 'react';
import { getLabelEstadoServidor, getLabelEstadoLocalFactura } from '../controlador';
import { ExtraerTabla } from '../../../Core/Dixie'
import { __app, __fecha } from '../../../utils/myApp';
import { enviarPeticion } from "../../../Core/ActionDatabase";


import FacturaTirilla from "../../Document/PlantillasImpresion/FacturaTirilla";
import prindiv from 'easyprintand';
import { NotificationManager } from 'react-notifications';
import { useStateValue } from '../../../context/StateProvider';
import { guardarDocumentoLocal, eliminarCuentaAbiertaFireBase } from '../../Document/controlador';
import Matematicas from '../../Document/Matematicas';
import { DocumentL } from '../../Document/DocumentCore';
import PlantillaCarta from '../../Document/PlantillasImpresion/PlantillaCarta';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import VerDocumento from '../VerDocumento/VerDocumento';



/**
 * 
 * onlyTurno : Si es verdadero cargara solo los documentos del turno.
 * Si no es verdadero cargara todos los documentos.
 */
function ListaDocumentos({ opciones, funciones, onlyTurno = true }) {


    const { cargar, ver } = funciones


    const opcionesDefault = {

        filtro: [],

        campos: [],

        aux: 1,

        botones: [
            'cargar',
            'ver',
            'eliminar',
            'imprimir'
        ]

    }

    const fullOpciones = { ...opcionesDefault, ...opciones };


    const [documentos, SetDocumetos] = useState([]);



    const [facturaImprimir, SetfacturaImprimir] = useState(null);


    const [{ user }] = useStateValue();


    const [modoImpresion, SetmodoImpresion] = useState(null);

    const imprimirFactura = (copy, modo = "navegador") => {
        SetmodoImpresion('tirilla');

        if (copy && copy.id) {
            SetfacturaImprimir(__app.clonarObjeto(copy));
            handlePrint(modo, copy);
        } else {
            alert("vuelve a intentarlo")
        }
    }




    const imprimirFacturaCarta = (copy) => {
        SetmodoImpresion('carta');
        SetfacturaImprimir(__app.clonarObjeto(copy));
        handlePrint('modo', copy);
    }




    const imprimirFacturaSinDescuentos = (copy) => {
        SetmodoImpresion('tirilla');
        copy.ESTRAS.LISTA_PRECIO_ACTIVA = 0;
        copy.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL = 1;
        alert(copy.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL)
        const Ldocument = new DocumentL(__app.clonarObjeto(copy), null, null, user);
        let descuento = 0;
        let tipodescuento = 0;
        Ldocument.aplicar_descuento_globla({ valor: { descuento, tipodescuento } });

        console.log(Ldocument.DOCUMENT)
        Ldocument.renderizarTotal();


        Ldocument.DOCUMENT.FORMAS_DE_PAGO = null;

        console.log(Ldocument.DOCUMENT)
        SetfacturaImprimir(__app.clonarObjeto(Ldocument.DOCUMENT));
        handlePrint('modo', copy);
    }

    /**
     * 
     *  return false;
    
            let descuento = 0;
            let tipodescuento = 1;
            Maaaate.aplicarDescuentoGlobla( { valor: {descuento , tipodescuento }})
    
          //  Maaaate.renderizarTotal();
    
            console.log(Maaaate.DOCUMENT);
            
          //  console.log();
            //SetfacturaImprimir(   );
            //handlePrint();
     */



    const imprimirLocalmente = async (data) => {
        try {
            const response = await fetch('http://localhost:3001/imprimir', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                'body': JSON.stringify(data)
            });


            console.log(response); // Mensaje de la aplicación de escritorio

        } catch (error) {
            console.error('Error al imprimir:', error);
        }
    };

    const handlePrint = (modo = "navegador", documento) => {


        if (modo == "directa") {

            let facturaImprimir2 = documento
            let user2 = {
                ESTACION: user.ESTACION
            };
            console.log(facturaImprimir2);
            imprimirLocalmente({ facturaImprimir2, user2 });
            SetmodoImpresion('');
            return false;
        }


        setTimeout(() => {
            prindiv('EasyPrintAnd', { extraCss: './tirilla.css' })
            setTimeout(() => {
                SetmodoImpresion('');
            }, 1000)

        }, 200)
    }

    const [estadocarga, setEstadocarga] = useState(null);

    const sincronizarDocumento = async (copi) => {
        setEstadocarga(1);

        let respuestauax = await enviarPeticion("adddocumentos/nuevo", "", { body: copi });

        if (respuestauax.respuesta) {
            setEstadocarga(null);
            NotificationManager.success('Registro con Exito', 'Documento #' + respuestauax.data.id);
            finProcedimientoExitoso(respuestauax.data, copi);

        } else {
            setEstadocarga(null);

            if (respuestauax.server_error) {
                console.log("No hay red")
                localStorage.setItem('statusServer', 0);
                NotificationManager.warning('El servidor Principal no Respondio', 'Servidor');
                //fetchErrorSave(copi);
            } else {

                console.log("error de registro");
                //renderizarcodigoError(respuestauax.data);
            }
        }

    }

    /**
     * Procedimiento de registro de factura existoso
     * @respuesta = La data 
    */
    const finProcedimientoExitoso = (re, copydc) => {

        let copy = { ...copydc, EstadoServidor: 1, EstadoLocalFactura: 1, id_server: re.id, NUMERACION: re.numeracion };
        handlePrint('modo', copy);
        let copyy = guardarDocumentoLocal(copy);
        eliminarCuentaAbiertaFireBase(copy.id, user);
        SetfacturaImprimir(__app.clonarObjeto(copyy))

        actualizarobjetos();
    }


    const actualizarobjetos = () => {

        getDatos();
    }



    useEffect(() => {



        getDatos();

    }, [])


    const getDatos = async () => {



        let opciones = {};

        if (onlyTurno === true) {

            opciones = {
                condiciones: {
                    where: 'turno_id',
                    igual_a: user.TURNO.id
                }
            };
        }

        let DocumentosDixie = await ExtraerTabla('documentos', 1, opciones)

        console.log(DocumentosDixie)

        const filtro = DocumentosDixie.filter((estudiante) => {

            /*** Si esta variable termina falsa, significa que las condiciones nos e complieron */
            let auxReturnData = true;

            for (let index = 0; index < fullOpciones.filtro.length; index++) {

                const condicion = fullOpciones.filtro[index];

                if (!(estudiante[condicion.condicion] == condicion.valor)) {
                    auxReturnData = false;
                }

            }

            if (auxReturnData) {
                return estudiante
            }

        });

        SetDocumetos(filtro);
    }


    const [verDocumentoModal, SetverDocumentoModal] = useState();
    const [facturaActual, SetfacturaActual] = useState(null);

    const handleVerFactura = (CtDocumento_id) => {
        SetverDocumentoModal(true);
        SetfacturaActual(CtDocumento_id);
    };

    const handleCerrarModal = () => {
        SetverDocumentoModal(false);
        SetfacturaActual(null);
    };


    return (
        <div className=' table-responsive'>

            <table className='table  align-middle'>
                <thead>
                    <tr>
                        <th>ver</th>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>EstadoServidor</th>

                        {
                            /* <th>EstadoLocal</th> */
                        }

                        <th>Cliente</th>
                        <th>Total</th>
                        <th>opciones</th>
                    </tr>
                </thead>

                <tbody>
                    {documentos.sort((a, b) => b.fechaidFinal - a.fechaidFinal).map((docu, index) =>
                        <tr key={index}>
                            <td className='text-nowrap'>
                                {docu.id_server && <span onClick={() => handleVerFactura(docu.id_server)} className='btn  btn-xs btn-primary'> Ver  <i className="fa-solid fa-eye"></i> </span>} </td>
                            <td className='text-nowrap'> {__fecha.formatoFecha({ $fecha: docu.fechaFinal })} </td>
                            <td> <span className={'badge rounded-pill p7-badge-' + docu.TIPO_DOCUMENTO.color_documento} > <i className={docu.TIPO_DOCUMENTO.icono_documento}></i> {docu.TIPO_DOCUMENTO.etiqueta_corta} </span></td>

                            <td>
                                {getLabelEstadoServidor(docu.EstadoServidor)}

                                {(docu.EstadoServidor != 1 && !estadocarga) && <span onClick={() => sincronizarDocumento(docu)} className='btn btn-xs btn-principal' >Sincroniar</span>}

                            </td>
                            {
                                /* <td>{getLabelEstadoLocalFactura(docu.EstadoLocalFactura)}</td> */
                            }

                            {
                                /* <td>{docu.ESTRAS.estadoFactura} </td> */
                            }

                            <td style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{docu.TERCERO && docu.TERCERO.razonSocial}</td>
                            <td className='text-nowrap'> $ {__app.formato_decimal({ total: docu.MATEMATICAS.SUBTOTAL_3 })}</td>
                            <td>
                                {fullOpciones.botones.includes('cargar') &&
                                    <>
                                        {docu.EstadoLocalFactura != 1 && <span onClick={() => cargar(docu)} className='btn btn-xs btn-principal' roll="button">Cargar</span>}
                                    </>
                                }
                                {fullOpciones.botones.includes('imprimir') && (
                                    <div className="dropdown">
                                        <button className="btn btn-xs btn-subtle-info  dropdown-toggle after-dropdown-toggle" title='Opciones de impresión' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa-solid fa-print fa-lg me-1"></i>Impresión
                                        </button>
                                        <div className="dropdown-menu">
                                            {fullOpciones.botones.includes('imprimir') && <span onClick={() => imprimirFactura(docu, 'directa')} className='dropdown-item' roll="button" style={{ cursor: 'pointer' }}>
                                                <span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                    <i className="fa-solid fa-print fs-9"></i>
                                                </span>
                                                Impresora Directa
                                            </span>}
                                            {fullOpciones.botones.includes('imprimir') && <span onClick={() => imprimirFactura(docu, 'navegador')} className='dropdown-item' roll="button" style={{ cursor: 'pointer' }}>
                                                <span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                    <i className="fa-solid fa-receipt fs-9"></i>
                                                </span>
                                                Imprimir tirilla
                                            </span>}
                                            {fullOpciones.botones.includes('imprimir') && <span onClick={() => imprimirFacturaCarta(docu)} className='dropdown-item' roll="button" style={{ cursor: 'pointer' }}> <span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                <i className="fa-solid fa-file-lines  fs-9"></i>
                                            </span>
                                                Imprimir Carta
                                            </span>}
                                            {
                                                (user.PERMISOS.impresionSindescuento && user.PERMISOS.impresionSindescuento == 1) &&
                                                <>
                                                    {fullOpciones.botones.includes('imprimir') && <span onClick={() => imprimirFacturaSinDescuentos(docu)} className='dropdown-item' roll="button" style={{ cursor: 'pointer' }}><span className={`badge badge-phoenix badge-phoenix-primary me-2`} style={{ width: "20px", height: "20px", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa-solid fa-receipt fs-9"></i>
                                                    </span>Imprimir(v2) </span>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                )}

                            </td>
                        </tr>
                    )}
                </tbody>
            </table>


            {(facturaImprimir && modoImpresion == "tirilla") && <FacturaTirilla documento={facturaImprimir} user={user} />}


            {(facturaImprimir && modoImpresion == "carta") && <PlantillaCarta documento={facturaImprimir} user={user} />}



            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura." desactivar={handleCerrarModal} mostrar={verDocumentoModal}>
                <VerDocumento document_id={facturaActual} desactivar={handleCerrarModal} PagoExitoso={null} />
            </StandarModal>

        </div>
    );
}

export default ListaDocumentos;