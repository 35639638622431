




import React from 'react';
import prindiv from 'easyprintand';

import ViewCierre from './ViewCierre';



const BotonImprimir = ({data}) => {


    const handlePrint = () => {
        prindiv('EasyPrintAnd') 
    }




    return (
        <div>

            <button onClick={handlePrint}>Imprimir</button>

            <div id='EasyPrintAnd'>
              <ViewCierre cierre={data} showImprimir={0}  />
            </div>
           
        </div>
    );
};

export default BotonImprimir;