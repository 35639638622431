import { useEffect, useState } from "react";

import { pingserver } from "../../Core/ActionDatabase";
import { fetchUSer, getLocalstore, GetNombre_firebaseLastKeyProducto, setLocalStore } from "../../Core/fetchLocalStoreData";

import StandarModal from './PartesDocument/StandarModal';
import Index from "../Documentos/LocalDocumentos/Index";
import { NotificationManager } from "react-notifications";
import TurnoCerrar from "../Turnos/TurnoCerrar";
import { Link } from "react-router-dom";

const BarraEstado = ({ info }) => {

    const [estadoRed, setEstadoRed] = useState("...");


    const user = fetchUSer();

    const LAST_KEY_PRODUCTOS = GetNombre_firebaseLastKeyProducto();

    const [lastIdactual, SetlastIdactual] = useState(getLocalstore(LAST_KEY_PRODUCTOS))

    const [showModalDocumento, SetshowModalDocumento] = useState();


    const [showModalTurno, SetshowModalTurno] = useState();

    /*** Con esta variable el usuario podra controlar el estado de la aplicacion. 
     * 
     *  Si esta variable esta Activa.
     *  Esto sigfica que: no hay internet
     */
    let estado_server_manual = getLocalstore("estado_server_manual");

    if (estado_server_manual === undefined || estado_server_manual === null || estado_server_manual === "") {
        setLocalStore("estado_server_manual", true);
        estado_server_manual = true;
    }



    const [estadoServer, SetEstadoServer] = useState(estado_server_manual);


    const setBusquedaMan = () => {

        SetEstadoServer(!estadoServer)
        localStorage.setItem('estado_server_manual', !estadoServer);
    }


    const getLocalStoreSitch = () => {

        return estadoServer
    }



    useEffect(() => {

        let Intervalo = 1000;
        const obtenerPing = async () => {
            setEstadoRed("...")
            let data = await pingserver();
            if (data.server_error) {

                setEstadoRed("desconectado");
                localStorage.setItem('statusServer', 0);
                if (Intervalo < 25000) {
                    Intervalo = Intervalo + 3000;
                }
                console.log(Intervalo);
                setTimeout(() => {
                    obtenerPing();
                }, Intervalo)
            } else {
                if (estadoRed == "desconectado") {
                    Intervalo = 30000;
                }
                setEstadoRed("conectado");
                localStorage.setItem('statusServer', 1);

                if (Intervalo < 220000) {
                    Intervalo = Intervalo + 60000;
                }
                console.log(Intervalo);
                setTimeout(() => {
                    obtenerPing();
                }, Intervalo)
            }
        }


        obtenerPing();


        esperarKey();


    }, [])


    const esperarKey = async () => {

        while (Number(lastIdactual) <= 0) {


            let aux = getLocalstore(LAST_KEY_PRODUCTOS);
            SetlastIdactual(aux);

            // Tu lógica dentro del bucle, si es necesario
            NotificationManager.warning("No tenemos Versión para  la  sincronización", "Versión : " + lastIdactual)
            // Esperar 3000 milisegundos (3 segundos) antes de la siguiente iteración
            await new Promise(resolve => setTimeout(resolve, 9000));
        }
    }

    const [cierreParcial, setCierreParcial] = useState();

    const handleClickModalCerrarTurno = (data) => {
        SetshowModalTurno(true);
        setCierreParcial(data)
    }
    return (
        <div className="d-none d-sm-block">

            {/* ANDRES */}
            {/* <footer id="react-pen-footer" className=" site-footer editor-footer">
                <div className="footer-left">
                    <div className="footer-actions-menu">
                        <div className="footer-actions" data-expanded="false">

                            <Link to={'/'} className="btn btn-subtle-secondary "  >
                                Menu
                            </Link>


                            <a onClick={() => SetshowModalDocumento(true)} className="ms-2 btn  btn-subtle-secondary  rounded border-0 "  >
                                Comprobantes
                            </a>


                            <a onClick={() => handleClickModalCerrarTurno(null)} className="ms-1 btn  btn-subtle-secondary  rounded border-0 "  >
                                Cerrar Turno
                            </a>



                            <a onClick={() => handleClickModalCerrarTurno(1)} className="ms-1 btn  btn-subtle-secondary  rounded border-0 "  >
                                Cierre Parcial
                            </a>

                            <span> Usuario : {user.USER.nombre} </span>
                            <span> Punto de venta : {user.ESTACION.nombre} </span>
                            <span> | Turno #{user.TURNO.id}</span>
                            <span> | Caja #{user.TURNO.caja_id}</span>
                            <span> | Estado de Red : {estadoRed} </span>



                            <span> | Versión de  sincronización  ({lastIdactual})
                                {Number(lastIdactual) > 0 && <span className="badge bg-success">

                                </span>

                                    || <span className="badge bg-warning">
                                        sin versión
                                    </span>

                                }
                            </span>


                            <span className="" style={{ display: "inline-block", marginLeft: "10px" }}>

                                {!estadoServer && <span className="text-warning"> Trabajando sin Red </span> || <span className="text-principal"> Trabajando con Red </span>}

                                <div style={{ display: "inline-block", position: "absolute" }} className="form-check form-switch  ">
                                    <input style={{ marginTop: "5px" }} onClick={setBusquedaMan} defaultChecked={getLocalStoreSitch()} value={estadoServer} className="form-check-input  " type="checkbox" role="switch" id="flexSwitchCheckDefault2" />
                                </div>
                            </span>


                        </div>
                    </div>
                </div>
            </footer> */}


            {/* YULIIII */}
            <footer id="react-pen-footer" className="site-footer editor-footer">
                <div className="footer-left">
                    <div className="footer-actions-menu">
                        <div className="footer-actions" data-expanded="false">
                            <div className="row align-items-center g-2" >
                                <div className="col-auto d-flex flex-wrap gap-1">
                                    <Link to={'/'} className="btn btn-subtle-secondary">Menu</Link>
                                    <a onClick={() => SetshowModalDocumento(true)} className="btn btn-subtle-secondary rounded border-0">Comprobantes</a>
                                    <a onClick={() => handleClickModalCerrarTurno(null)} className="btn btn-subtle-secondary rounded border-0">Cerrar Turno</a>
                                    <a onClick={() => handleClickModalCerrarTurno(1)} className="btn btn-subtle-secondary rounded border-0">Cierre Parcial</a>
                                </div>

                                <div className="col text-truncate text-center  d-none d-md-block" >
                                    <span className="d-inline-block text-truncate" style={{ maxWidth: "100%" }}>
                                        Usuario: {user.USER.nombre} | Punto: {user.ESTACION.nombre} | Turno #{user.TURNO.id} | Caja #{user.TURNO.caja_id} | Versión ({lastIdactual})  | Estado de Red : {estadoRed}
                                    </span>
                                    {Number(lastIdactual) > 0
                                        ? <span className="badge bg-success"></span>
                                        : <span className="badge bg-warning p-0 ps-1 pe-1">sin versión</span>}
                                </div>

                                <div className="col-auto d-flex align-items-center flex-nowrap">
                                    <span className={estadoServer ? "text-principal" : "text-warning"}>
                                        {estadoServer ? "Trabajando con Red" : "Trabajando sin Red"}
                                    </span>
                                    <div className="form-check form-switch ms-2 d-flex align-items-center">
                                        <input onClick={setBusquedaMan} defaultChecked={getLocalStoreSitch()} value={estadoServer} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2"style={{ marginTop: "5px" }}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            



            {/***
        * Formulario Lista Compuesto, solo para restaurante.
        */}
            <StandarModal titulo="Comprobantes" headerShow={true} size="xl" title="Productos Compuestos" desactivar={() => SetshowModalDocumento(!showModalDocumento)} mostrar={showModalDocumento}  >
                <Index usuario={user} header={false} />
            </StandarModal>


            {/***
        * Formulario Lista Compuesto, solo para restaurante.
        */}
            <StandarModal titulo="ARQUEO DE CAJA" headerShow={true} size="md" desactivar={() => SetshowModalTurno(false)} mostrar={showModalTurno}   >
                <TurnoCerrar titulo={false} cierreParcial={cierreParcial} />
            </StandarModal>







        </div>
    );
}

export default BarraEstado;