import React from 'react';

const SelectregistroPorPagina = ({registrosPorPagina , setRegistrosPorPagina}) => {
    return (
        <div>
            <div className="mt-2">
                <label>Mostrar: </label>
                <select className="form-select form-select-sm d-inline w-auto ms-2" value={registrosPorPagina} onChange={(e) => setRegistrosPorPagina(Number(e.target.value))}>
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select> registros por página.
            </div>
        </div>
    );
};

export default SelectregistroPorPagina;