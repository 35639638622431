import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { __app } from '../../../utils/myApp';
import { _liquidacion } from '../../Terceros/TerceroView/Liquidacion/core/Controlador';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import CambiarOperador from '../../Terceros/TerceroView/Liquidacion/CambiarOperador';




const Items = ({ document, recargarDocumento }) => {

    const [cambiarOperadorModal, setCambiarOperadorModal] = useState(false);
    const [facturaActual, setFacturaActual] = useState(null);
    const { ITEMS } = document;
    const { DOCUMENTO } = document;

    console.log(ITEMS);


    let impuestos = 0;
    let subtotal3 = 0;
    let utilidad = 0;


    const abrirModalCambiarOperador = (item_id) => {
        setFacturaActual(item_id);
        setCambiarOperadorModal(true);
    };

    const handleCerrarModales = () => {
        setCambiarOperadorModal(false);
        setFacturaActual(null);
    };

    ITEMS.forEach((item) => {
        impuestos += parseFloat(item.IMPUESTOS);
        subtotal3 += parseFloat(item.SUBTOTAL_3);
        utilidad += parseFloat(item.UTILIDAD);
    })




    return (
        <div className="col-12">
            <div className={`card shadow rounded-4 border-opacity-25 border-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`}>
                <div className="card-body table-responsive">
                    <h5 className="text-center" >
                        <i class="fa-solid fa-hospital-user fa-lg me-2"></i> Productos / Servicios
                    </h5>
                    <br />
                    <table className="table table-striped  fs-9 table-bordered text-nowrap align-middle">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Referencia</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th className="text-right">Valor U.</th>
                                <th className="text-right">Cantidad</th>
                                <th className="text-right">Impuesto</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Utilidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ITEMS.map((item, key) => (
                                <tr key={key}>
                                    <td>{item.id_2}</td>
                                    <td className='text-center'>{__app.rellenarVacios(item.referencia)}</td>
                                    <td style={{ maxWidth: '300px', whiteSpace: 'wrap' }}>
                                        {item.liquidable == 1 && ( '🛠️' )}
                                        {item.nombre}


                                        {/* {item.liquidable == 1 && (
                                            item.aux_serv_operador_id > 0 ? (
                                                <>
                                                    <i className='fa-solid fa-user'></i>{item.razonSocial}
                                                    {_liquidacion.estados_liquidacion({ estado: item.switch_liquidacion, aux_serv_porcentaje_liquidar: item.aux_serv_porcentaje_liquidar })}
                                                    <br />
                                                    {
                                                        item.aux_serv_estado_liquidacion == 1 ? (
                                                            <span className="badge bg-success" >Liquidado</span>
                                                        ) : (
                                                            <>
                                                                <span className="badge bg-danger" >Sin liquidar</span>
                                                                <button onClick={() => abrirModalCambiarOperador(item.id)}>cambiar operador</button>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <button>Asignar operador</button>
                                            )
                                        )} */}

                                        {item.liquidable == 1 && (
                                            <div className={`card shadow border-opacity-25 ps-2 py-1 border-2 border-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`} >
                                                {item.aux_serv_operador_id > 0 ? (
                                                    <>
                                                        <div className="d-flex justify-content-center align-items-center gap-1">
                                                            <i className={'fa-solid fa-user text-success'}></i>
                                                            <span>{item.razonSocial}</span>  {_liquidacion.estados_liquidacion({ estado: item.switch_liquidacion, aux_serv_porcentaje_liquidar: item.aux_serv_porcentaje_liquidar })}
                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            {item.aux_serv_estado_liquidacion == 1 ? (
                                                                <span className="badge bg-success">Liquidado</span>
                                                            ) : (
                                                                <>
                                                                    <span className="badge bg-danger parpadeo">Sin liquidar</span>
                                                                    <button className="btn btn-sm btn-primary btn-xs ms-2" onClick={() => abrirModalCambiarOperador(item.id)}>   <i className="fa-solid fa-exchange-alt me-1"></i>Cambiar operador</button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <button className="btn parpadeo btn-xs btn-success" onClick={() => abrirModalCambiarOperador(item.id)}> <i className="fa-solid fa-user-plus me-2"></i> Asignar operador</button>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                    <td style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{__app.rellenarVacios(item.descripcion)}</td>
                                    <td className="text-right">${item.PRECIO}</td>
                                    <td className="text-right text-center">{item.CANTIDAD}</td>
                                    <td className="text-right text-nowrap">{item.IMPUESTO !== null && item.IMPUESTO ? '$' + item.IMPUESTO : '------'}                                    </td>
                                    <td className="text-right text-nowrap">  <td>  <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: item.SUBTOTAL_3, color: true, icono: false, numerosPositivos: true }) }}></span> </td></td>
                                    <td className="text-right"></td>
                                </tr>
                            ))}

                            <tr>
                                <th></th>
                                <th></th>
                                <th> </th>
                                <th> </th>
                                <th></th>
                                <th></th>
                                <th className="text-right text-nowrap"><span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: impuestos, color: true, icono: false, numerosPositivos: true }) }}></span> </th>
                                <th className="text-right text-nowrap">  <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: subtotal3, color: true, icono: false, numerosPositivos: true }) }}></span>  </th>
                                <td >  </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* CAMBIAR OPERADOR */}
            <StandarModal keyboard={true} headerShow={true} size="m" titulo="Cambiar Operador." desactivar={handleCerrarModales} mostrar={cambiarOperadorModal} colorHeader={"border-danger"}>
                <CambiarOperador item_id={facturaActual} desactivar={handleCerrarModales} actualizarTabla={recargarDocumento} />
            </StandarModal>
        </div>
    );
};



export default Items;