import React from 'react';
import { useState } from 'react';

const  AplicarDescuentoGlobal = ({accionF , accionCerrar , documento })  => {


    const [ estado , setEstado ] = useState();
   
    const [ tipodescuento , setTipodescuento ] = useState();

    const [ descuento , setDescuento  ] = useState("");


    const _handleClick = (tipo) => {   
        setEstado(!estado);
        setTipodescuento(tipo);
    }


    const aplicarDescuento = () => {

        let valor = {descuento , tipodescuento};
        let accion = "aplicar_descuento_globla";
        accionF({ accion ,  valor });
        accionCerrar();
    
    }

    const __handleKey = (e) => {

        if(e.keyCode === 13){
            aplicarDescuento();
            e.preventDefault();
            return false;
        }
    }
  
    return (
        <div className='d-flex  justify-content-around'  >
            
            { !estado && 
            <div >
                <button onClick={ () => _handleClick(1) } className='btn btn-primary '>
                    DESCUENTO EN PORCENTAJE 
                </button>

        
                <button onClick={() => _handleClick(2) } className='btn btn-primary' style={ {"marginTop" : "20px"} }>
                    DESCUENTO EN MANUAL 
                </button>
            </div> }

            { estado  &&  
                
                <div>
                    <div className=' form-grup'>
                        <input className='form-control' onChange={ (e) => setDescuento(e.target.value)}  onKeyDown={__handleKey}  value={descuento}   placeholder='Descuento' autoFocus  />
                    </div>
                    <span onClick={aplicarDescuento}  className=""> Descuento  </span>
                </div>
            }

           

        </div>
    );
}

export default AplicarDescuentoGlobal;