import React from 'react';
import Empresa from '../../subcomponents/Empresa';
import Tercero from '../../subcomponents/Tercero';
import Pagos from '../../subcomponents/Pagos';
import FooterCarta from '../../../../Document/PlantillasImpresion/Partes/FooterCarta';
import { fetchEmpresa, fetchUSer } from '../../../../../Core/fetchLocalStoreData';

const Carta = ({ pago }) => {
    const user = fetchUSer();
    const empresa = fetchEmpresa();
    console.log("Estilos", pago);

    let aumento = -1.5;

    const fontSize = {
        "xl": 12 + aumento,
        "md": 10 + aumento,
        "xs": 8 + aumento,
        "xxs": 7 + aumento,
    }
    return (
        <div id='EasyPrintAnd' >
            <div style={{fontFamily: `"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"` }}>
            <Empresa empresa={empresa} user={user} />
            <hr />
            <Tercero infoPago={pago} />
            <Pagos infoPago={pago} />
            <FooterCarta fontSize={fontSize} />
            </div>
        </div>


    );
};

export default Carta;