import { ExtraerTablabyIds } from "../../../../Core/Dixie";
import { fetchEmpresa } from "../../../../Core/fetchLocalStoreData";
import { __app } from "../../../../utils/myApp";


export class CLiquidacion {


    constructor(){

        this.ITEM_DE_LIQUDACION = "1";
    }



    listaDeItems = async (items) => {

        let itemReferencia  = await  this.extrarItemeDixie(  this.ITEM_DE_LIQUDACION);
        
        if(itemReferencia){

            return this.generarItemdeInsercion(itemReferencia , items);
          
        }else{  

            alert("No encontramos Item relacionado con el parqueadero")
            return [];
        }
    }


    generarItemdeInsercion = (itemReferencia, items) => {
        console.log(items);
        
        let itemALiquidar = [];

        if(items){

            items.forEach((data) => {

                let AuxItem = __app.clonarObjeto(itemReferencia)
                AuxItem.AUX = {}; 
                let valor_a_liquidar = 0;

                if(data.switch_liquidacion == 1 ){
                    valor_a_liquidar = data.SUBTOTAL_1 * (data.aux_serv_porcentaje_liquidar / 100)
                }else{
                    valor_a_liquidar = data.aux_serv_porcentaje_liquidar
                }

                AuxItem.AUX.SERVIOC_A_ANULAR = data.id;
                
                AuxItem.PRECIO_PRINCIPAL = valor_a_liquidar;
                AuxItem.nombre = data.nombre;
                AuxItem.descripcion = data.descripcion;
                AuxItem.multi_item = 1;
                
                itemALiquidar = [...itemALiquidar, AuxItem];
            })

            console.log(itemALiquidar)
        }
        
        return itemALiquidar;
    }



    extrarItemeDixie = async (id) => {
        
        this.log('extraerItemParqueadero' )
        var   data = await  ExtraerTablabyIds("productos" , [ id ]  , 'id' );
        this.log('El item encontrado es el siguiente' )
        if(data.length > 0 ){
            return data[0];
        }else{
            this.log('No tenemos producto relacionado con el codigo. ' )
            return null;
        }
    }

    extraerdocumento = () => {

        const empresa = fetchEmpresa();
        if(empresa.datos_precarga.comprobantes.TipoComprobantes){
            return empresa.datos_precarga.comprobantes.TipoComprobantes.find(data => data.id ==  300 );
        }
        return null;
    }   

    log(data){

       console.log(data);
    }
}