

import { useStateValue } from "../../context/StateProvider";

import { useStateValueObservador } from "../../context/StateObservadores";


import VerticalNav from './VerticalNav/VerticalNav';
import TopNav from './NavTop/TopNav';
import Main from './Main';
import { useLocation } from 'react-router-dom';


import '../../estilos/customBoostrap.css';
import '../../estilos/index.css';
import '../../estilos/teclado.css';
import '../../estilos/bootstrapMobil.css';
import '../../estilos/puntoVenta.css';
import { inicializarOservador, offObservador } from "../../Core/Observadores";
import { useEffect, useState } from "react";
import { verificarClientes, verificarProductos, verificarVehiculos } from "../../Core/Sincronizacion";
import { __app } from "../../utils/myApp";
import { getLocalstore } from "../../Core/fetchLocalStoreData";
import { deleteDB } from "../Document/controlador";
import { cerrarSesion, version_punto_venta } from "../user/Core/Core";
import { NotificationManager } from "react-notifications";
import { actionType } from "../../context/reducer";
import Sincronizacion from "./Sincronizacion";
import Logout from "../user/Logout";


export const showAlert = (message) => {
    window.dispatchEvent(new CustomEvent("global-alert", { detail: { type: "alert", message } }));
  };
  
  export const showConfirm = (message, onConfirm) => {
    window.dispatchEvent(new CustomEvent("global-alert", { detail: { type: "confirm", message, onConfirm } }));
  };


  
function LayautDefault(props) {

    const [{ user, KeyObservadores }, dispatch] = useStateValue();


    /**
     * 
     *  Bloquea el sistema hasta que se completen las cargas de lo siguiente
     * 
     * 
     * OBJETOS
     * VEHICULOS
     * CLIENTEs
     * 
     */

    const estadoInicial = {
        objetos: null,
        productos: null,
        clientes: null
    };


    const [statusSincronizacion, SetstatusSincronizacion] = useState(estadoInicial);

    const [statusSincronizacionFinalizado, SetstatusSincronizacionfinalizado] = useState(-1);


    console.log(KeyObservadores)

    const location = useLocation();



    useEffect(() => {
        verificarVersionDiaria();
    }, [location.pathname]); // cada que la ruta cambia 

    const verificarVersionDiaria = async () => {
        try {
            const hoy = new Date().toISOString().split("T")[0];
            const ultimaVerificacion = localStorage.getItem("ultimaVerificacionVersion");

            if (ultimaVerificacion != hoy) {
                const versionPuntoDeVenta = user?.CONFIGURACIONES_TABLE?.versionPuntoDeVenta ?? 0;
                const VERSION_API = await version_punto_venta();
                console.log("Verificando la version de Pluma7 punto de venta..." + VERSION_API);
                if (user) {
                    if (!versionPuntoDeVenta || VERSION_API != versionPuntoDeVenta) {
                        caches.keys().then((names) => {
                            names.forEach((name) => caches.delete(name));
                        });
                        cerrarSesion();
                        NotificationManager.warning("Recargando...", "Version desactualizada, cerrando sesion", 3000);
                    }
                }

                localStorage.setItem("ultimaVerificacionVersion", hoy);
            } else {
                console.log("La version ya fue verificada hoy");
            }
        } catch (error) {
            console.error("Error al obtener la version de la API:", error);
        }
    };

    useEffect(() => {
        if (user) {
            verificarVersionDiaria();
            const versionDataBaseSoftware_IndexDB = user.CONFIGURACIONES_TABLE?.versionDataBaseSoftware_IndexDB ?? 0;
            if (versionDataBaseSoftware_IndexDB !== null && Number(versionDataBaseSoftware_IndexDB) > 0) {

                if (user.CONFIGURACIONES_TABLE?.versionDataBaseSoftware_IndexDB != getLocalstore("versionDataBaseSoftware_IndexDB")) {
                    console.log("Eliminando las Bases de Datos ")
                    deleteDB();
                }

                setTimeout(() => {
                    sincronizar()
                }, 500)

            } else {

                deleteDB();
                NotificationManager.warning('Error de Base de datos', 'No tienes un version de Software por favor Comunicate con Soporte para Arreglar este Error.', 9000);
                dispatch({
                    type: actionType.SET_USER,
                    user: null,
                })
                cerrarSesion(false);
            }
        }


        // Función de limpieza que se ejecutará cuando el usuario cierre sesión
        return () => {
            if (!user) {
                // Aquí es donde debes limpiar o destruir el observador de Firebase
                // Si la función que usas tiene una forma de limpiar el observador, hazlo aquí
                offObservador();  // Suponiendo que esta función es para detener el observador
            }
        };

    }, [user?.USER?.id])



    useEffect(() => {

        if (user) {
            // Comprobar si todas las propiedades son diferentes de null
            let todasDiferentesDeNull = 1;
            // Usamos un ciclo `for...in` para recorrer las propiedades del objeto
            for (let key in statusSincronizacion) {
                if (statusSincronizacion[key] === null) {
                    todasDiferentesDeNull = 0;
                    break; // Si encontramos un valor `null`, podemos salir del ciclo inmediatamente
                }
            }
            SetstatusSincronizacionfinalizado(todasDiferentesDeNull)
        }
    }, [statusSincronizacion])


    const sincronizar = async () => {

        __app.ClearDivContent("__p7_logsVehiculos");
        __app.ClearDivContent("__p7_logsClientes");
        __app.ClearDivContent("__p7_logsProductos");

        SetstatusSincronizacion(estadoInicial)



        sincronizarTodo();

    }


    const sincronizarTodo = async () => {
        let re1 = await sincronizar1();
        if (re1 === 1 || re1 === -1) {
            let re2 = await sincronizar2();
            if (re2 === 1 || re2 === -1) {
                let re3 = await sincronizar3();
                if (re3 === 1 || re3 === -1) {



                    inicializarOservador(dispatch, KeyObservadores);


                }
            }
        }
    };



    const sincronizar1 = async () => {
        let statusProductos = await verificarProductos();
        if (statusProductos) {
            SetstatusSincronizacion(prevState => ({
                ...prevState, // Copia todas las propiedades actuales
                productos: statusProductos  // Cambia solo la propiedad 'vehiculos'
            }));

            return statusProductos;
        }
    }



    const sincronizar2 = async () => {

        let statusClientes = await verificarClientes();
        if (statusClientes) {
            SetstatusSincronizacion(prevState => ({
                ...prevState, // Copia todas las propiedades actuales
                clientes: statusClientes  // Cambia solo la propiedad 'vehiculos'
            }));
            return statusClientes;
        }
    }



    const sincronizar3 = async () => {
        let statusVehiculos = await verificarVehiculos();
        if (statusVehiculos) {
            SetstatusSincronizacion(prevState => ({
                ...prevState, // Copia todas las propiedades actuales
                objetos: statusVehiculos  // Cambia solo la propiedad 'vehiculos'
            }));
            return statusVehiculos;
        }
    }




    return (
        // TENIA LA CLASE MAIN  <button className="" onClick={() => sincronizar()}>Disparar Sincronización</button>
        <main id="top">


            {'/crearDocumento' != location.pathname &&
                <>

                    {(user && statusSincronizacionFinalizado == 1) && <>
                        <VerticalNav />
                        <TopNav />
                    </>}

                    <div className="content">
                        {(location.pathname == "/" && statusSincronizacionFinalizado === 0) && <Sincronizacion statusSincronizacion={statusSincronizacion} statusSincronizacionFinalizado={statusSincronizacionFinalizado} location={location} sincronizar={sincronizar} />}


                        {(statusSincronizacionFinalizado == 1 || statusSincronizacionFinalizado === -1) && <Main user={user} />}
                    </div>


                </>
            }

            {'/crearDocumento' == location.pathname && <Main user={user} />}



        </main>

    );
}

export default LayautDefault;