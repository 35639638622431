

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Placa from '../../../../Core/Html/Placa';
import { estadoObjeto, perfilVehiculo } from '../Core/Controlador';
import { NotificationManager } from 'react-notifications';
import { __app, __fecha } from '../../../../utils/myApp';
import Alerta from '../../../../Core/Html/Alerta';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import VerDocumento from '../../../Documentos/VerDocumento/VerDocumento';

const PerfilVehiculo = () => {

    let { placa, id } = useParams();
    const [servicios, setServicios] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [verDocumentoModal, setVerDocumentoModal] = useState(false);
    const [facturaActual, setFacturaActual] = useState(null);


    useEffect(() => {
        setServicios([]);
        cargarServicios();
    }, [])

    const cargarServicios = async () => {
        setCargando(true);
        try {
            const data = await perfilVehiculo(id);
            setServicios(data);
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar servicios:", error);
        } finally {
            setCargando(false);
        }
    }

    const abrirModalVerDocumento = (document_id) => {
        setFacturaActual(document_id);
        setVerDocumentoModal(true);
    };

    const handleCerrarModales = () => {
        setVerDocumentoModal(false);
        setFacturaActual(null);
    };

    return (
        <div className="row justify-content-center">
            <div className="col-xxl-9 col-12">
                {cargando ? (
                    <p>Cargando Historial del vehiculo...</p>
                ) : (
                    <>
                        <div className="card shadow-sm  rounded-4  border-0 mb-5">
                            <div className="card-body">
                                <h3 className="text-center mb-2 text-secondary">
                                    📃 Información del vehiculo.
                                </h3>
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <th className="w-25 align-middle">Placa</th>
                                            <td>
                                                <Placa placa={placa} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Nombre</th>
                                            <td>{__app.rellenarVacios(servicios.objeto?.nombre)}</td>
                                        </tr>
                                        <tr>
                                            <th>Categoría</th>
                                            <td>{__app.rellenarVacios(servicios.objeto?.nombre_categoria)}</td>
                                        </tr>
                                        <tr>
                                            <th>Persona</th>
                                            <td>{__app.rellenarVacios(servicios.objeto?.identificacion_persona) + " / " + __app.rellenarVacios(servicios.objeto?.razonSocial)}</td>
                                        </tr>
                                        <tr>
                                            <th>Estado Periodo</th>
                                            <td>{estadoObjeto({ estado: servicios.objeto?.estado_periodo })}</td>
                                        </tr>
                                        <tr>
                                            <th>Fecha Registro</th>
                                            <td>{__fecha.formatoFecha({ $fecha: servicios.objeto?.fecha_registro })}</td>
                                        </tr>
                                        <tr>
                                            <th>Descripción</th>
                                            <td>{__app.rellenarVacios(servicios.objeto?.descripcion)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="card shadow-sm  rounded-4  border-0">
                            <div className="card-body">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-auto mb-2">
                                        <h3 className="text-center text-secondary">
                                            🕒 Historial de la placa
                                        </h3>
                                    </div>
                                    <div className="col-auto mb-3">
                                        <Placa placa={placa} />
                                    </div>
                                </div>
                                {servicios?.info_historial?.length > 0 ? (
                                    <div className=" table-responsive">
                                        <table className="table table-hover align-middle">
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Entrada</th>
                                                    <th>Salida</th>
                                                    <th>Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {servicios.info_historial.map((registro) => (
                                                    <tr key={registro.id}>
                                                        <td>{registro.id}</td>
                                                        <td>{__fecha.formatoFecha({ $fecha: registro.FECHA_INGRESO })}</td>
                                                        <td>{__fecha.formatoFecha({ $fecha: registro.CREADO })}</td>
                                                        <td>
                                                            <button onClick={() => abrirModalVerDocumento(registro.id)} className="btn bg-light text-primary p-2 m-1 rounded-circle me-2 shadow-sm border-0" title='Ver Factura'>
                                                                <i className="fa-solid fa-eye fa-lg"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <Alerta tipo="warning" title="¡Ops! No hay historial disponible." mensaje="No hay historial disponible para este vehiculo." />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {/* VER FACTURA  */}
            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura." desactivar={handleCerrarModales} mostrar={verDocumentoModal}>
                <VerDocumento document_id={facturaActual} desactivar={handleCerrarModales} />
            </StandarModal>
        </div >
    );
};

export default PerfilVehiculo;