



import React from 'react';

const InputField = ({ label, name, value, onChange, type = 'text', dataKey, placeholder })  => {
    return (
        <div className="col-md-12">
            <label className="form-label">{label}</label>
            <input
                name={name}
                value={value || ''}
                onChange={onChange}
                type={type}
                className="form-control"
                data-math-key={dataKey}
                placeholder={placeholder}
            />
        </div>
    );
};

export default InputField;