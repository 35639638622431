import Buscaripi from "../../../utils/ripi";
import { ExtraerTabla } from "../../../Core/Dixie";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { __app } from "../../../utils/myApp";
import { Link } from "react-router-dom";


import { extraerClientesPrimeraVez, extraerObjetosPrimeraVez, extraerProductosPrimeraVez } from "../../../Core/Sincronizacion";
import BotonesPaginacion from "../../../ComponentsHtml/PaginadorDixie/BotonesPaginacion";
import SelectregistroPorPagina from "../../../ComponentsHtml/PaginadorDixie/SelectregistroPorPagina";
import InputBuscador from "../../../ComponentsHtml/PaginadorDixie/InputBuscador";

import { fetchUSer, getLocalstore, GetNombre_firebaseLastKeyClientes, GetNombre_firebaseLastKeyProducto } from "../../../Core/fetchLocalStoreData";
import { NotificationManager } from "react-notifications";
import BotonAdd from "../Add/Boton/Boton";
import Boton from "../Add/Boton/Boton";


let Ripi_prIndex = null;

const Pages = () => {
    
    /*** Observaodr para Actualizar la lista */
    const [{ KeyObservadores }] = useStateValue();


    /*** Buscado de lista */
    const [inputbuscar, setInputBuscar] = useState("");
    
    /**** Items filtrado para la tabla */
    const [filtroItems, setFiltroItems] = useState([]);
   
    
    /*** Key para buscar en el RIPI */
    const OpcionesBusqueda = ["id" , "nombre" , "referencia" , "id_2" , "descripcion" ];


    /*** Item que actualmente se esta Editando  */
    const [itemEdit, SetItemEdit] = useState();


    /*** Item que actualmente se esta Editando  */
    const [version, setversion] = useState();



    const user = fetchUSer();
    const {DATABASE } = user;
  



    /******************************************** */// 
    /*         Paginación
    /*
    /*************************f******************* */

    //Total de registros del la tabla
    const [totalRegistros, setTotalRegistros] = useState();

    //Pagina Actual
    const [paginaActual, setPaginaActual] = useState(1);

    //Registros por Pagina
    const [registrosPorPagina, setRegistrosPorPagina] = useState(10);

    //Total de Paginas
    const totalPaginas = Math.ceil(totalRegistros / registrosPorPagina);



    /*** Opservador para saber cuando ocurre un Update actualizar la lista */
    useEffect(() => {
        contarRegistros();
        setTimeout(() => {
            getDatos();
        }, 300);
    }, [KeyObservadores.PRODUCTOS]);




    /*** Observador para la pagunacion, actualizar la lista. */
    useEffect(() => {

        getDatosPaginacion();
        setversion( getLocalstore(GetNombre_firebaseLastKeyProducto()))

    }, [paginaActual , registrosPorPagina , KeyObservadores.PRODUCTOS]);


    /*** Cuenta los registros para hacer una paginacion */
    const contarRegistros = async () => {
        const contarRegistros      = await ExtraerTabla("productos", 1, {
            contarRegistros : 1
        });
        setTotalRegistros(contarRegistros);
    }



    /*** El getDatos Optiene todos los datos para guardarlos en el Buscador */
    const getDatos = async () => {

        let  datos  = await ExtraerTabla("productos");
        if (datos.length > 0) {
            console.log("Cargando desde la base de datos...");
            Ripi_prIndex = new Buscaripi(datos);
            Ripi_prIndex.fullResultado = true;
        }
    };



    /**** Optiene solo los datos de la paginacion  ***/
    const getDatosPaginacion = async () => {
      
        let paginaActualAux =  (paginaActual - 1) * registrosPorPagina;
        let  datos  = await ExtraerTabla("productos", 1, {
            paginacion : {
                iniciar:paginaActualAux ,
                limite:  registrosPorPagina ,
            }
        });
       
        if (datos.length > 0) {
            console.log("Cargando desde la base de datos...");
            setFiltroItems(datos);
        }
    };



  


    /*** Busca en RIPI */
    const buscar = () => {
        

        if( !(inputbuscar.length > 0) ){
            Ripi_prIndex.maximoResultados = registrosPorPagina;
        }

        let data = Ripi_prIndex.start(inputbuscar, OpcionesBusqueda);
        console.log(Ripi_prIndex);
        setFiltroItems(data);
    };

    

    /*** Si por alguna razon no hay datos el sistema intentara sincronizar */
    const sincronizarNuvamente = async () => {
        setLoadSincronizacionButton(1);
        let respuesta = await extraerProductosPrimeraVez();
        if (respuesta) {
            setLoadSincronizacionButton(null)
            setTimeout(() => getDatos(), 500);

            setTimeout(() => __app.ClearDivContent('__p7_logsProductos'), 5000);
        } else {
            alert("Error al sincronizar");
        }
    };




    /***  Editar Item */
    const hadleEditar = (item) => {
        SetItemEdit(item);
    };

    
   const [loadSincronizacionButton , setLoadSincronizacionButton] = useState(null)
 
    return (
        <div className="card shadow rounded-4 border-0 p-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-4">
                <div>
                    <h3 className="text-dark fw-bold mb-0"> Lista de Productos ({totalRegistros}) </h3>
                    <p style={{fontSize:"10px"}} className="text-muted mb-0">version:{version}</p>

                    <div id="__p7_logsProductos">
                    
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row gap-2">
                    <button disabled={loadSincronizacionButton} className="btn btn-outline-secondary btn-sm shadow-sm" onClick={sincronizarNuvamente}>
                        
                        {!loadSincronizacionButton && <><i className="fa-solid fa-sync me-1 fa-lg" /> Sincronizar</> }

                        {loadSincronizacionButton && <>  Cargando...</> }
                        
                    </button>
            

                   <Boton  setItemEdit={SetItemEdit} itemEdit={itemEdit} />
                   
                </div >
            </div>

            {/* Buscador */}
            <InputBuscador inputbuscar ={inputbuscar}  setInputBuscar={setInputBuscar}  buscar={buscar} />





            {/* Tabla */}
            <div className=" table-responsive">
                <table className="table table-hover table-bordered align-middle mb-0">
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Nombre</th>
                            <th>Referencia</th>
                            <th>Costo </th>
                            <th>Precio </th>
                            <th>Impuestos </th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtroItems.map((item, index) => (
                            <tr key={index}>
            
                                <td>
                                    {item.id_2}

                           
                                </td>
                                <td>{__app.rellenarVacios(item.nombre)}</td>
                                <td>{__app.rellenarVacios(item.referencia)}</td>
                                <td>${item.costo}</td>
                                <td> $ {__app.rellenarVacios(item.PRECIO_PRINCIPAL)}</td>
                                <td>impuestos</td>

                                <td className="text-nowrap">
                                    <button onClick={() => hadleEditar(item)} className="btn p-2 btn-subtle-secondary btn-sm me-1">
                                        <i className="fa-solid fa-pen fa-lg me-1 text-warning" />Editar
                                    </button>
                                    <Link className="btn p-2 btn-subtle-secondary btn-sm" to={`/TerceroPerfil/${__app.envolver(item.id)}`}>
                                        <i className="fas fa-eye me-1 fa-lg text-primary"></i>Ver Perfil
                                    </Link>
                                
                                    

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Paginador */}
            <BotonesPaginacion paginaActual={paginaActual}  totalPaginas={totalPaginas}  setPaginaActual={setPaginaActual}  />



            {/* Selección de registros por página */}
            <SelectregistroPorPagina registrosPorPagina={registrosPorPagina}  setRegistrosPorPagina={setRegistrosPorPagina} />
        </div>
    );
};

export default Pages;
