import Buscaripi from "../../../utils/ripi";
import { ExtraerTabla } from "../../../Core/Dixie";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { __app } from "../../../utils/myApp";
import { Link } from "react-router-dom";


import { extraerClientesPrimeraVez, extraerObjetosPrimeraVez } from "../../../Core/Sincronizacion";
import Placa from "../../../Core/Html/Placa";
import BotonesPaginacion from "../../../ComponentsHtml/PaginadorDixie/BotonesPaginacion";
import SelectregistroPorPagina from "../../../ComponentsHtml/PaginadorDixie/SelectregistroPorPagina";
import InputBuscador from "../../../ComponentsHtml/PaginadorDixie/InputBuscador";
import { ListaPrecios, TipoEmpleado } from "../../Elementos/Etiquetas";
import { fetchUSer, getLocalstore, GetNombre_firebaseLastKeyClientes } from "../../../Core/fetchLocalStoreData";
import ListaPrecio from "../../Document/PartesDocument/ListaPrecio";
import { NotificationManager } from "react-notifications";
import BotonAdd from "../Add/Boton/BotonAdd";
import { showAlert, showConfirm } from "../../Layaut/LayautDefault";
import { EliminarTercero } from "../Core/Core";

let Ripi_prIndex = null;

const Pages = () => {

    /*** Observaodr para Actualizar la lista */
    const [{ KeyObservadores }] = useStateValue();


    /*** Buscado de lista */
    const [inputbuscar, setInputBuscar] = useState("");

    /**** Items filtrado para la tabla */
    const [filtroItems, setFiltroItems] = useState([]);


    /*** Key para buscar en el RIPI */
    const OpcionesBusqueda = ["identificacion", "nombrecomercial", "razonSocial", "telefono", "direcion", "correoelectornico"];


    /*** Item que actualmente se esta Editando  */
    const [itemEdit, SetItemEdit] = useState();


    /*** Item que actualmente se esta Editando  */
    const [version, setversion] = useState();



    const user = fetchUSer();
    const { DATABASE } = user;




    /******************************************** */// 
    /*         Paginación
    /*
    /*************************f******************* */

    //Total de registros del la tabla
    const [totalRegistros, setTotalRegistros] = useState();

    //Pagina Actual
    const [paginaActual, setPaginaActual] = useState(1);

    //Registros por Pagina
    const [registrosPorPagina, setRegistrosPorPagina] = useState(10);

    //Total de Paginas
    const totalPaginas = Math.ceil(totalRegistros / registrosPorPagina);



    /*** Opservador para saber cuando ocurre un Update actualizar la lista */
    useEffect(() => {
        contarRegistros();
        setTimeout(() => {
            getDatos();
        }, 300);
    }, [KeyObservadores.TERCEROS]);




    /*** Observador para la pagunacion, actualizar la lista. */
    useEffect(() => {

        getDatosPaginacion();
        setversion(getLocalstore(GetNombre_firebaseLastKeyClientes()))

    }, [paginaActual, registrosPorPagina, KeyObservadores.TERCEROS]);


    /*** Cuenta los registros para hacer una paginacion */
    const contarRegistros = async () => {
        const contarRegistros = await ExtraerTabla("terceros", 1, {
            contarRegistros: 1
        });
        setTotalRegistros(contarRegistros);
    }



    /*** El getDatos Optiene todos los datos para guardarlos en el Buscador */
    const getDatos = async () => {

        let datos = await ExtraerTabla("terceros");
        if (datos.length > 0) {
            console.log("Cargando desde la base de datos...");
            Ripi_prIndex = new Buscaripi(datos);
            Ripi_prIndex.fullResultado = true;
        }
    };



    /**** Optiene solo los datos de la paginacion  ***/
    const getDatosPaginacion = async () => {

        let paginaActualAux = (paginaActual - 1) * registrosPorPagina;
        let datos = await ExtraerTabla("terceros", 1, {
            paginacion: {
                iniciar: paginaActualAux,
                limite: registrosPorPagina,
            }
        });

        if (datos.length > 0) {
            console.log("Cargando desde la base de datos...");
            setFiltroItems(datos);
        }
    };






    /*** Busca en RIPI */
    const buscar = () => {


        if (!(inputbuscar.length > 0)) {
            Ripi_prIndex.maximoResultados = registrosPorPagina;
        }

        let data = Ripi_prIndex.start(inputbuscar, OpcionesBusqueda);
        console.log(Ripi_prIndex);
        setFiltroItems(data);
    };



    /*** Si por alguna razon no hay datos el sistema intentara sincronizar */
    const sincronizarNuvamente = async () => {

        setLoadSincronizacionButton(1);
        let respuesta = await extraerClientesPrimeraVez();
        if (respuesta) {
            setLoadSincronizacionButton(null)
            setTimeout(() => getDatos(), 500);
            setTimeout(() => __app.ClearDivContent('__p7_logsClientes'), 5000);
        } else {
            alert("Error al sincronizar");
        }
    };




    /***  Editar Item */
    const hadleEditar = (item) => {
        SetItemEdit(item);
    };

    const hadleEliminar = (item) => {
        showConfirm(
            "⚠️ ¡ALTO! ⚠️\n\n¿Estás seguro de que deseas continuar con esta acción?\n\nEsta operación no se puede deshacer.",
            () => respuestaSi(item)
        );
    }

    const respuestaSi = async (id) => {
        try {
            const respuesta = await EliminarTercero(id);
            if (respuesta == 1) {
                showAlert("⚠️ " + "No se pudo borrar la persona por que tiene documentos pendientes." + " ⚠️");
            } else {
                NotificationManager.success("Tercero eliminado correctamente, sincroniza por favor.", 'Tercero Eliminado');
            }
        } catch (error) {
            console.error("Error en la petición:", error);
            NotificationManager.error('Error al eliminar', 'error');
        }
    };



    const [loadSincronizacionButton, setLoadSincronizacionButton] = useState(null)


    return (
        <div className="card shadow rounded-4 border-0 p-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-4">
                <div>
                    <h3 className="text-dark fw-bold mb-0"> Lista de Contactos ({totalRegistros}) </h3>
                    <p style={{ fontSize: "10px" }} className="text-muted mb-0">version:{version}</p>
                </div>

                <div id="__p7_logsClientes">

                </div>


                <div className="d-flex flex-column flex-md-row gap-2">

                    <button disabled={loadSincronizacionButton} className="btn btn-outline-secondary btn-sm shadow-sm" onClick={sincronizarNuvamente}>

                        {!loadSincronizacionButton && <><i className="fa-solid fa-sync me-1 fa-lg" /> Sincronizar</>}

                        {loadSincronizacionButton && <>  Cargando...</>}

                    </button>


                    <BotonAdd setItemEdit={SetItemEdit} itemEdit={itemEdit} successFunction={getDatos} />

                </div >


            </div>

            {/* Buscador */}
            <InputBuscador inputbuscar={inputbuscar} setInputBuscar={setInputBuscar} buscar={buscar} />





            {/* Tabla */}
            <div className=" table-responsive">
                <table className="table table-hover align-middle mb-0">
                    <thead>
                        <tr>
                            <th>Identificacion</th>
                            <th>Nombre</th>
                            <th>Telefono</th>
                            <th>E-mail</th>
                            <th>Dirección</th>
                            <th>Rol</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtroItems.map((item, index) => (
                            <tr key={index}>

                                <td>
                                    {item.identificacion}


                                    {item.ct_lista_precio_id > 0 && <span> <br /><ListaPrecios id={item.ct_lista_precio_id} valores={DATABASE.ct_lista_precios} /></span>}
                                    {
                                        item.per_categoria_persona_id && (
                                            <>
                                                <div>
                                                    {(
                                                        typeof item.per_categoria_persona_id === 'string'
                                                            ? item.per_categoria_persona_id.split(", ")
                                                            : Array.isArray(item.per_categoria_persona_id)
                                                                ? item.per_categoria_persona_id
                                                                : [item.per_categoria_persona_id]
                                                    )
                                                        .map((id) => String(id).trim())
                                                        .map((id) => (
                                                            <span key={id}>
                                                                <TipoEmpleado id={id} />
                                                            </span>
                                                        ))}
                                                </div>

                                            </>
                                        )
                                    }

                                </td>
                                <td>{__app.rellenarVacios(item.razonSocial)}</td>
                                <td>{__app.rellenarVacios(item.telefono)}</td>
                                <td>{__app.rellenarVacios(item.correoelectronico)}</td>
                                <td>{__app.rellenarVacios(item.direccion)}</td>
                                <td className="text-nowrap"> </td>

                                <td className="text-nowrap">
                                    <Link className="btn p-2 btn-subtle-primary btn-sm me-1" to={`/TerceroPerfil/${__app.envolver(item.id)}`}>
                                        <i className="fas fa-eye me-1 fa-lg text-primary"></i>Ver Perfil
                                    </Link>
                                    <div class="dropdown d-inline-block">
                                        <button p7-data-bs-toggle="tooltip" data-bs-placement="top" title="Opciones" className="btn btn-sm btn-subtle-secondary" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <i class="fa-solid fa-ellipsis" style={{ fontSize: 15 }}></i>
                                        </button>
                                        <div className="dropdown-menu  shadow-lg p-2"  >
                                            <button onClick={() => hadleEditar(item)} className="dropdown-item btn p-2 btn-subtle-secondary btn-sm" style={{ textAlign: 'left' }}>
                                                <i className="fa-solid fa-pen fa-lg me-1 text-warning" />Editar
                                            </button>

                                            <div className="dropdown-divider"></div>
                                            <button onClick={() => hadleEliminar(item.id)} className="dropdown-item btn p-2 btn-subtle-danger btn-sm" style={{ textAlign: 'left' }}>
                                                <i class="fa-solid fa-trash-can fa-lg me-1"></i>Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Paginador */}
            <BotonesPaginacion paginaActual={paginaActual} totalPaginas={totalPaginas} setPaginaActual={setPaginaActual} />



            {/* Selección de registros por página */}
            <SelectregistroPorPagina registrosPorPagina={registrosPorPagina} setRegistrosPorPagina={setRegistrosPorPagina} />
        </div>
    );
};

export default Pages;
