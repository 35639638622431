import { __fecha } from "../utils/myApp";

export const __pluma7 = {
    // Componente para mostrar el estado de la factura
    
    EstadoFactura : ({ estado, masterId = 1 }) => {


        if (masterId == 0) {
            return <span>No aplica</span>;
        }

        
    
        let clase;
        let texto;
        let aux = parseInt(estado)
        let icon = "";
        // Asignar clase y texto según el estado
        switch (aux) {
        case 0:
            clase = 'badge-phoenix-danger';
            texto = 'Pendiente';
            icon = <i class="fa-solid fa-ban"></i>;    
            break;
        case 1:
            clase = 'badge-phoenix-success';
            texto = 'Pagado';
           
            icon = <i class="fa-solid fa-check"></i>;  
            break;
        case 3:
            clase = 'badge-phoenix-info';
            texto = 'Cruzado';
            break;
        default:
            clase = 'badge-phoenix-dark';
            texto = 'Desconocido';
        }

        // Devolver el elemento JSX con la clase y texto apropiados
        return <span className={`badge badge-phoenix ${clase}`}>{texto} {icon}</span>;
    },


    /*** Formatea el numero de factura. */
    numeroFactura : ({numero,id}) => {
        if( numero && numero.length > 0){
        return (<span><b>FACT#</b> {numero} </span>)
        }
        return (<span><b>Auto#</b>{id} </span>)
    
    },



     /*** Formatea el numero de factura. */
     NombreMasterID : ({master_id , icono  = true}) => {
        

        let  texto = "";
      

        if(master_id == 1){
            texto =  "INGRESO";
            icono =  <i className="fas fa-arrow-up"></i>;
        }

        if(master_id == 2){
            texto = "EGRESO";
            icono =  <i className="fas fa-arrow-up"></i>;
        }

        if(master_id == 0){
            texto = "NO APLICA";
           
        }
    
        return (
           <>
                {texto}
          
                {master_id == 1  &&  <span className="badge badge-phoenix badge-phoenix-success ms-1 fs-8"><i className="fas fa-arrow-up"></i>  </span>}

                {master_id == 2 &&  <span className="badge badge-phoenix badge-phoenix-danger ms-1 fs-8"><i className="fas fa-arrow-down"></i> </span>}

          
           </>
        )
    },


    turnoDesdeHasta  : (inicio , fin) =>  {

        if(inicio && fin){
            return (
                <>
                Desde {__fecha.formatoFecha({$fecha : inicio})}  
                <br />
                Hasta {__fecha.formatoFecha({$fecha : fin})}
                </>
            )
        }

       
        return (
            <>
                {__fecha.formatoFecha({$fecha : inicio})} 

                <div title="Turno en curso" className="ms-1  spinner-border" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </>
        )
        
       

    },

    /** Recibe el nit de un cliente  y  el id del producto.*/
    pathfolderImgProductos : function (nit , id ) {
        
        return "app/public/uploads/" + nit + "/img/productos/" + id + "/";
    },


    fechaExite : function (fecha) {
        // Expresión regular para validar el formato 'YYYY-MM-DD' o 'YYYY-MM-DD HH:MM:SS'
        const regex = /^(?!0000-00-00)(\d{4}-\d{2}-\d{2})( \d{2}:\d{2}:\d{2})?$/;
    
        // Verificar que la fecha coincide con el formato
        if (!regex.test(fecha)) {
            return false; // No cumple con el formato
        }
    
        // Verificar que el año, mes y día sean válidos
        const partes = fecha.split(' ');
        const fechaSinHora = partes[0];
        const [anio, mes, dia] = fechaSinHora.split('-').map(Number);
    
        // Validar año, mes y día
        if (anio === 0 || mes === 0 || dia === 0 || mes < 1 || mes > 12 || dia < 1 || dia > 31) {
            return false;
        }
    
        // Validar la fecha real (por ejemplo, para el mes de febrero o días 30 y 31)
        const fechaValida = new Date(anio, mes - 1, dia);
        if (fechaValida.getFullYear() !== anio || fechaValida.getMonth() + 1 !== mes || fechaValida.getDate() !== dia) {
            return false;
        }
    
        // Si la fecha tiene hora, validar la hora, minuto y segundo
        if (partes.length > 1) {
            const [hora, minuto, segundo] = partes[1].split(':').map(Number);
    
            // Validar hora, minuto y segundo
            if (hora < 0 || hora > 23 || minuto < 0 || minuto > 59 || segundo < 0 || segundo > 59) {
                return false;
            }
        }
    
        return true;
    },
    
    
    formatearPlaca : (placa) => {
        // Eliminar todos los caracteres que no sean letras o números
        let placaFormateada = placa.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();;
        
        // Retornar la placa formateada
        return placaFormateada;
      },


      estadoMensualidad : (estado) => {
        if (estado == 1) {
            return (
                <span className="badge text-bg-success rounded-pill">Activo</span>
            );
        } else {
            return (
                <span className="badge rounded-pill bg-danger">Inactivo</span>
            );
        }
      },

      estadoRepetirMensualidad : (estado) => {
        if (estado == 1) {
            return (
                <span className="badge text-bg-success rounded-pill">Si</span>
            );
        } else {
            return (
                <span className="badge rounded-pill bg-danger">No</span>
            );
        }
      }

}