import React from 'react';

const Tercero = ({ infoPago }) => {
    console.log(infoPago.tercero);
    return (
        <>
            {infoPago.tercero && <div>
                <p><b>Datos del cliente</b> <br/>
                Identificación: {infoPago.tercero.CtPersona_dvnit} <br />
                Nombre: {infoPago.tercero.CtPersona_razonSocial} <br />
                Teléfono: {infoPago.tercero.CtPersona_telefono} <br />
                Dirección: {infoPago.tercero.CtPersona_direccion} <br />
                Correo: {infoPago.tercero.CtPersona_correoelectronico} <br />
                </p>
            </div>}
        </>
    );
};

export default Tercero;