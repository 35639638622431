import React  from 'react';
import { Link } from 'react-router-dom';
import { fetchUSer } from '../Core/fetchLocalStoreData';












const  HomeContainer = (props)  => {


    const user = fetchUSer();

    const VERSION = user.CONFIGURACIONES_TABLE?.versionPuntoDeVenta ?? 0;

    const handleClick = () => {
        window.location.href ='/nuevo-documento';
    }

    
    
    return (
        
       <>   
         

            <div className="container mt-5 rounded-5 p-2" >
                <div className="card bg-segundo mt-5 d-flex justify-content-center p-5 rounded-5" >
                    <div className="card-body ">
                        <h1 className="card-title text-principal text-center" style={{
                            "fontsize": "40px",
                            "lineheight": "48px"
                        }}>BIENVENIDO!  a PLUMA7 Fuera de linea</h1>
                        <p className="card-text  fs-5 text-center text-white" style={{
                            "fontsize": "20px",
                        }}>Con esta Nueva Version podras facturar sin conexion a internet</p>
                        
                        <center>
                           
                        <Link role='button' to={"/crearDocumento"}  className="btn btn-success _9vcv _a805 mb-3"  rel="noopener"><span className="_advp _aeam">
                              ENTRAR!
                            </span></Link>
                            <p style={{ fontSize: "10px" }} className="text-white mb-0"> Punto de venta Version: {VERSION} </p>
                        </center>
                       
                    </div>
                </div>
            </div>


       </>  
      
    );
}

export default HomeContainer;
