import React, { useState } from 'react';
import { enviarPeticion } from '../../../../Core/ActionDatabase';
import { NotificationManager } from 'react-notifications';
import { fetchEmpresa } from '../../../../Core/fetchLocalStoreData';

const CambiarLiquidacion = ({ switch_liquidacion, terceroId, item_id, actualizarTabla, global = false }) => {
    const textoLiquidacion = switch_liquidacion === '1' ? 'Porcentaje' : 'Valor Fijo';

    const ConfigEmpresa = fetchEmpresa();
    const [formData, setFormData] = useState({ valor_nuevo: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let datosEnviar = {};
        let condicion = 0;
        if (global) {
            condicion = 1;
            datosEnviar = { ...formData, terceroId };
        } else {
            datosEnviar = { ...formData, item_id };
        }


        console.log("Datos enviados:", datosEnviar);
        const url = `terceros/cambiar_liquidacion/${condicion}`;

        try {
            const respuesta = await enviarPeticion(url, ConfigEmpresa.nit, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(datosEnviar)
            });

            if (respuesta.respuesta === 1) {
                NotificationManager.success(respuesta.mensaje, 'Actualización exitosa');
                actualizarTabla();
            } else {
                NotificationManager.error(respuesta.mensaje, 'Error');
            }
        } catch (error) {
            console.error("Error en la petición:", error);
            NotificationManager.error('Hubo un error al enviar los datos', 'Error');
        }
    };
    return (
        <div>
            {global === false && (
                <>
                    <span className="text-center mb-2 text-warning fs-9">Señor usuario debes de poner el <strong>{textoLiquidacion}</strong> del la liquidacion que quieres cambiar</span>
                </>
            )
            }


            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label>Valor a cambiar</label>
                    <input autoComplete='off' type="text" class="form-control" name="valor_nuevo" value={formData.valor_nuevo} onChange={handleChange} placeholder="Ejemplo: 100 o 50" required />
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <button type="submit" className="btn btn-sm btn-secondary">
                        Cambiar Valor a liquidar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CambiarLiquidacion;