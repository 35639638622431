


import React from 'react';
import { __app } from '../../../../utils/myApp';

const DisplayPagosDocumento = ({documento}) => {
   
    const OTROS_PAGOS = documento.ABONOS_CUENTA_ABIERTA || null ;

    let sumaTotal = 0;
    
    if (Array.isArray(OTROS_PAGOS)) {
        sumaTotal = OTROS_PAGOS.reduce((acc, pago) => acc + parseFloat(pago.total || 0), 0);
    } else {
        console.warn("ABONOS_CUENTA_ABIERTA no es un array o no existe");
    }
    
    
    
    return (
        <div>
            <div className='saldoFavorFlotante'>
            
            { (documento.MATEMATICAS.TOTAL_SALDO_FAVOR && documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0) &&                                                   
                <span>Saldo a favor :  $ { __app.formato_decimal({total :  documento.MATEMATICAS.TOTAL_SALDO_FAVOR , decimal : documento.ESTRAS.DECIMAL })}  </span>
                
                || <></>
            }

            { sumaTotal > 0 &&                                                   
                <span className='d-block'>  ABONOS  :  $ { __app.formato_decimal({total : sumaTotal , decimal : documento.ESTRAS.DECIMAL })}  </span>         
            }
            </div> 

        </div>
    );
};

export default DisplayPagosDocumento;



