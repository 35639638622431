import React, { useEffect, useState } from 'react';
import Variantes from './Variantes';
import { cargarInventariobyProductoid, insertarCambioFirebase, setCantidadInventario } from '../../Controlador';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';

const EditarVariantes = ({variante , item , desactivar }) => {

    const user  =  fetchUSer();

    const [infoStock , setStock] = useState(null);
    const [infoVariantes , setVariantes] = useState(null);
    const [cargando , setCargando] = useState(1);
    
    const abc = async () => {
        let infoStock = await cargarInventariobyProductoid(item.id + "/" + user.ESTACION.id);
        setStock(infoStock)

        if(infoStock){
            setCargando(null)
            let Auxvariante  = null;
            try {
                Auxvariante = JSON.parse(infoStock.variantes);
                setVariantes(Auxvariante);
            } catch (error) {
    
            }
    
    
            if(typeof Auxvariante != 'object' ){
                return false;
            }
        }
    }

    useEffect( () => {
        console.log(item)
        abc();

    }, [])



    const guardarInventarioEdiccion = async (variantes) => {
        console.log(variantes)
        alert("holaa")
        desactivar();
        const cantidad = variantes.reduce((acc, variante) => acc + variante.cantidad, 0);
       

        let  producto_id       =    item.id;
        let  estacione_id      =    user.ESTACION.id;
        let  cantidad_actual  =     infoStock.cantidad; 
        let  user_id           =     user.USER.id
        let  observacion      = "xxx";
        
        let responseHttp = await setCantidadInventario({
                producto_id,estacione_id,
                cantidad,
                observacion, 
                cantidad_actual,
                user_id,
                variantes,    
            }
        );

        if(responseHttp.respuesta == 1){
            console.log(responseHttp.data.aux)
            desactivar();
            if(responseHttp.data && responseHttp.data.aux ) {
                insertarCambioFirebase(responseHttp.data.aux,  'productos')
            }
            
        }

    }

    return (
        <p>
            {cargando && <span>Cargando..</span>}
            {!cargando && <Variantes variantesAux={infoVariantes} guardarInventarioEdiccion={guardarInventarioEdiccion}   />}
        </p>
       
    );
};

export default EditarVariantes;