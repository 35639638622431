import React from 'react';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';
import BotonImprimir from './BotonImprimir';
import { __app } from '../../../../utils/myApp';


const ViewCierre = ({cierre , showImprimir = 1  }) => {

    const user = fetchUSer();
    if (!cierre) {
        return false;
    }


    const descuadresPasados = parseInt(cierre.efectivo_recibe) -  parseInt(cierre.EFECTIVO_SISTEMA_ACTUAL);
    const descuadreActual  =  parseInt( cierre.efectivo_entrega) -  parseInt(cierre.efectivo_recibe) ;
    return (
        <div>
            <div className="TirillaTextCenter">     
                <h4 className="TirillaM0">{user.ESTACION.nombre}</h4>
                <b className="Tirilla11px"> NIT : {user.ESTACION.nit}</b>
                <p style={{marginTop:'-2px'}}>
                    {user.ESTACION.telefono} <br/> 
                    {user.ESTACION.direccion}<br/>
                    {user.ESTACION.municipio} <br/>
                </p>
            </div>

            <h3>CIERRE PARCIAL </h3>
            <table className='table' border={1}>
                <tbody>
                    <tr>
                        <th>Código del Turno </th>
                        <th>#{cierre.turno_id}</th>
                    </tr>
                    <tr>
                        <th>Código del  Cuadre </th>
                        <td>#{cierre.id}</td>
                    </tr>
                    
                    <tr>
                        <th> Fecha de Ingreso </th>
                        <td>{cierre.fecha_ingreso}</td>
                    </tr>

                    <tr>
                        <th>Usuario Responsable</th>
                        <td>{cierre.user_nombre
                        }</td>
                    </tr>

                     <tr>
                        <th style={{textAlign:'center' , marginTop : '10'}} colSpan={2}> <br/>  ---Información de Efectivo---</th>
                    </tr>   

                    <tr>
                        <th>EFECTIVO DEL SISTEMA</th>
                        <th>  $ {__app.formato_decimal({total : cierre.EFECTIVO_SISTEMA_ACTUAL})} </th>
                    </tr>

                    <tr>
                        <th>Descuadres Anteriores</th>
                        <th>

                            { descuadresPasados == 0 && <> Sin Descuadres</> } 


                            { descuadresPasados < 0 && <>Faltaron   $ {__app.formato_decimal({total : descuadresPasados *-1})}  </> }  

                            { descuadresPasados > 0 && <>Sobraron   $ {__app.formato_decimal({total : descuadreActual})}  </> } 
                          </th>
                    </tr>


                    <tr>
                        <th>Efectivo a Reportar  </th>
                        <td> $ {__app.formato_decimal({total : cierre.efectivo_recibe})} </td>
                    </tr>


                    <tr>
                        <th>Efectivo Reportado</th>
                        <td>  $ {__app.formato_decimal({total : cierre.efectivo_entrega})} </td>
                    </tr>

                
                </tbody>
            </table>

            <h1>DESCUADRE  : $ {__app.formato_decimal({total : descuadreActual})}</h1>

           {showImprimir == 1 && <BotonImprimir data={cierre}  /> } 
        </div>
    );
};

export default ViewCierre;
