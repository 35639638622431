


import React, { useEffect } from 'react';

const FormasPagos1 = ({pagoDocumento , cambiarPaso , setmostrarObcPagos ,  getTotalPagado , documento , mostrarObcPagos , handleInputChange , formpagos , setFormaPagos, setPagoAutomatico , user , TOTAL_A_PAGAR}) => {

    /*** En este Constructor vamos a actualizar las forma de pago en caso tal de que ya esten establecidas */
    useEffect( () => {

        if(documento.FORMAS_DE_PAGO){
            setFormaPagos(documento.FORMAS_DE_PAGO);
            getTotalPagado(documento.FORMAS_DE_PAGO);
        }else{

            /**** SI documento.FORMAS_DE_PAGO viene null significa que nunca se ha definido la forma de pago y aqui se define */

            let FORMAS_DE_PAGO_FORMATO = formaterPago(user.FORMAS_DE_PAGO)
            




        
            /*** Asignando los bancos alas cajas */
            if (user.TURNO.caja_id){
                let copi = [...FORMAS_DE_PAGO_FORMATO];
                copi[0] = {...copi[0], banco_id : user.TURNO.caja_id };
                FORMAS_DE_PAGO_FORMATO = copi;
            }



            /** Consultar si hay pago */
            if ( TOTAL_A_PAGAR <=  0  ) {
                setmostrarObcPagos(0);
                FORMAS_DE_PAGO_FORMATO = [];
            }



            if( documento.TIPO_DOCUMENTO.signo_pago == 0 ){
                cambiarPaso(2); 
                pagoDocumento([] , 0 , 0 , 0);
            }


            
            
        
            getTotalPagado(FORMAS_DE_PAGO_FORMATO);
            setFormaPagos(FORMAS_DE_PAGO_FORMATO);
            
        }

    } , [])





    











    const  ConsultarBancoCajaxDefecto = (formaPago) => {

        if(!user.BANCOS_ESTACIONES){
            return 0;
        }

        let infoBancoDefault = user.BANCOS_ESTACIONES.filter(( banco, index) => banco.forma_pago_id ==  formaPago.id );
        
        if(infoBancoDefault.length > 0 ){
            return infoBancoDefault[0].banco_id;
        }
        return formaPago.banco_id;
    }


    const formaterPago = (forma_de_pagos) => {
        console.log(forma_de_pagos)
       
        let result = forma_de_pagos.map( (fp) => {   
            
            if( parseInt(fp.tipo_de_banco_id) === 1 ){
                

                /*** Esta variable es super importanque ya que aqui se estaplece el pago por defecto para el EFECTIVO */
                fp.total =  consultarPagoAnterior();

            }else{

                fp.total = 0;
            }

            fp.banco_id =  ConsultarBancoCajaxDefecto(fp);
            
            return fp;
        })

        console.log(result)
        return result;
    }



    /*** Esta funcion evita el bug de retroceso  */
    const consultarPagoAnterior = () => {


        /**** Si Detectamos que este Documento es una cuenta abierta entonces mandamos un pago en 0, y dejamos que el cliente lo llene manualmente */
        if( documento.TIPO_DOCUMENTO?.cuenta_abierta == 1  ){
            
            return 0;
        }



        if(documento.FORMAS_DE_PAGO){

            let aux = documento.FORMAS_DE_PAGO.find(data => data.tipo_de_banco_id == 1 );
            if(aux && aux.total > 0 ){
                return aux.total;
            }
        }

        
        return  TOTAL_A_PAGAR;
    }






    if(formpagos.length  === 0 && mostrarObcPagos ===  1 ){
        return (
            <h1> No hay formas de pagos para cargar </h1>
        );
    }


    return (
        <div>
             {mostrarObcPagos == 1 && <div className="">
                <form className='row' onChange={handleInputChange} >     
                {formpagos.map( (formp,index) =>
                    
                    
                    
                    <div key={index} >   
                    
                  
                        <div className="p-3  row justify-content-center"> 
                            
                            <div className="col-7">
                                <label data-bs-toggle="button" aria-pressed="true" onClick={() =>setPagoAutomatico(index,'total', TOTAL_A_PAGAR  ) } htmlFor="" className='btn btn-subtle-success  btn-sm text-md text-uppercase fs-8'>{formp.nombre}  </label>
                                <input  onChange ={(e) => {console.log(e.target.value)} }  key={index} data-index={index}   data-name="total"  className='form-control'   value={formp.total} />
                            </div>
                            


                            {/* Aqui se selecciona el banco: Tenga en cuanta que
                                Para guardar el efectivo se debe enviar a que caja va ese efectivo,
                                Si el turno ya esta abierto. Entonces la caja DE EFECTIVO por defecto sera 
                                la del turno. En caso de que no exista turno. puede elejir solo entre las cajas de efectivo isponible
                            */}
                            <div className="col-5">
                            {   
                                (user.TURNO.caja_id && formp.id == 1)  &&
                                <>  
                                    Caja: {user.TURNO.caja_id}
                                </>
                                ||
                                <>  
                                    {/*Si la forma de pago es diferente de uno cargamos los bancos. Si no las cajas*/}
                                    {
                                        formp.id != 1 && 
                                       
                                       <>
                                           <label htmlFor="">Banco : {ConsultarBancoCajaxDefecto(formp)}   </label>
                                            
                                            {/* Si es el administrador le cargamso todos los bancos */}
                                            {
                                                user.type_user_d == 1 && 
                                                <>
                                                <select  defaultValue={ConsultarBancoCajaxDefecto(formp)}   data-index={index} data-name="banco_id" className="form-select"  >
                                                    <option value="0">Seleccionar una Opción</option>
                                                    { user.BANCOS.map((banco,index) => 
                                                        <option key={index} value={banco.id}>{banco.nombre} / / {banco.id} </option>
                                                    )}
                                                    <option value="25">25</option>                           
                                                </select>              
                                                </>
                                            }
                                        </>
                                        ||
                                        <>
                                            
                                            <label htmlFor="">Caja</label>
                                            <select defaultValue={formp.banco_defecto_id} data-index={index} data-name="banco_id" className="form-select" aria-label="Default select example">
                                                <option value="0">Seleccionar una Opción</option>
                                                 { user.CAJAS.map((banco,index) => 
                                                    <option key={index} value={banco.id}>{banco.nombre } </option>
                                                )}
                                            </select>
                                        </> 
                                    }
                                  
                                </>           
                            }
                            </div>








                            {
                                false  && 
                                <>
                                    <label htmlFor="">Referencia de Venta</label>
                                    <input   name="referencia"  placeholder='REFERENCIA DE VENTA' className='form-control'  />
                                </>
                            }
                            
                        </div>



                    </div>            
                )}
                </form>
            </div> }  
        </div>
    );
};

export default FormasPagos1;