


import React, { useEffect, useState } from 'react';
import { AutoDocumento } from './AutoDocumento';
import { useContextDocumentValue , actionTypeDocument } from '../../../Document/context/ContextDocument';
import { CLiquidacion } from './Core';
import { FormasPago, Tercero } from '../../../Document/PartesDocument';
import { __app } from '../../../../utils/myApp';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import { NotificationManager } from 'react-notifications';

let ClassAutoDocument = new AutoDocumento;
let liquidacion = new CLiquidacion;

const Liquidacion = ({cargarServicios , items , tercero_id , valorALiquidar}) => {
    
   const [ {documento}  , dispatch ] = useContextDocumentValue();
   
   const [itemLiquidacion , setItemLiquidacion] = useState();

   const [modal , setModal] = useState();

    useEffect(() => {
        
        
        
        const TIPO_DOCUMENTO = liquidacion.extraerdocumento();
        let aux  = {dispatch , actionTypeDocument }

        ClassAutoDocument.setTipoDocumento(TIPO_DOCUMENTO);
        ClassAutoDocument.setDispatch(aux);
        ClassAutoDocument.renderizar();
        ClassAutoDocument.setTercero(tercero_id);
       


    } , [])

    const [pagos , setPagos ]  =  useState(null);

    const Ejecutar =  async () =>  {
        if(items.length === 0){
            alert("Selecciona los servicios a liquidar")
            return;
        }
        let data =  await liquidacion.listaDeItems(items);
        if(data.length > 0 ){
            ClassAutoDocument.insertarMultiItems(data);
            setModal(true)
        }
   
    };

    const finalizarProcedimiento = () => {
        
       
        setModal(false);
        cargarServicios();   
    }

    return (
        <div>
            <button onClick={ ()=> console.log(ClassAutoDocument) } >Log</button>

           {
            !modal && 
            <div class="col-auto">
                <button className='btn btn-success btn-sm' onClick={() => Ejecutar()}><i class="fa-solid fa-money-bill-1-wave fa-lg me-1"></i>Liquidar servicios: $ {__app.formato_decimal({ total: valorALiquidar })}</button>
            </div>
           }

            <StandarModal keyboard={true} headerShow={true} size="m" titulo="LIQUIDAR SERVICIOS" desactivar={() => setModal(false)} mostrar={modal}>
               <div>
              

                    {documento && <p >NOMBRE DEL DOCUMENTO :   {documento.TIPO_DOCUMENTO.nombre} </p>}
                    {documento &&  <Tercero bloquear={true} />}




                    {documento &&  <table>
                        <tr>
                        
                            <h3>Total a pagar :  $ {documento.MATEMATICAS.SUBTOTAL_3}  </h3>
                        </tr>
                    </table>}

                    {!pagos &&  <button onClick={() => setPagos(true)}> PAGAR </button> }


                    {pagos && <FormasPago desactivar={finalizarProcedimiento}  OcultarPagaCon={true}  /> }
               </div>
            </StandarModal>
           
            
        </div>
    );
};

export default Liquidacion;