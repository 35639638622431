


import React, { useEffect, useState } from 'react';

import { __app } from '../../../../../utils/myApp';
import { fetchUSer } from '../../../../../Core/fetchLocalStoreData';
import OptionSelect from '../../../../../Core/Html/OptionSelect';
import { FN } from '../../../../../Core/MatematicasGlobales';



export const ImpuestosTable = ({ impuestos, onImpuestoChange, base = 0 , SetImpuesto , suprimirImpuesto }) => {
  
    const user = fetchUSer();

    const impuestosLocal = (user && user.DATABASE && user.DATABASE.impuestos)  ? user.DATABASE.impuestos : null;

    console.log(impuestosLocal);

    const [tempImpuestos, setTempImpuestos] = useState([]);

 
   
    useEffect(() => {
        let copy = impuestos.map((data) => {
            data.valorImpuesto = (base * parseFloat(data.importe)) / 100;
            return data;
        })
    
        setTempImpuestos(copy);
    }, [impuestos, base]);  // Ahora se ejecutará cada vez que los impuestos o base cambien

 

    // Función para manejar los cambios en los inputs
    const handleInputChange = (index, field, value) => {

      
        const updatedTempImpuestos = __app.clonarObjeto(tempImpuestos);
        const parsedValue = parseFloat(value) || 0;
     
        if (field === "importe") {
            // Si se modifica el porcentaje, recalcula el valor del impuesto
            updatedTempImpuestos[index].importe = parsedValue;
            updatedTempImpuestos[index].valorImpuesto = (base * parsedValue) / 100;
        } else if (field === "valorImpuesto") {
            // Si se modifica el valor del impuesto, recalcula el porcentaje
            updatedTempImpuestos[index].valorImpuesto = parsedValue;
            updatedTempImpuestos[index].importe = (parsedValue / base) * 100;
        }

    
        //Local 
       setTempImpuestos(updatedTempImpuestos);
       onImpuestoChange(index,  updatedTempImpuestos[index].importe);
    };


    const agregarNuevoImpuesto = (e) => {
        const valorSeleccionado = e.target.value;
        
        const impuestoNuevo = impuestosLocal.find(data => data.id == valorSeleccionado);
        
        SetImpuesto(impuestoNuevo);
    }



    return (
        <table className="table table-bordered mt-3">
            <thead>
                <tr>
                    <th>Nombre Impuesto</th>
                    <th>Base</th>
                    <th>Importe %</th>
                    <th>Valor Impuesto</th>
             
                </tr>
            </thead>
            <tbody>
                {tempImpuestos.map((impuesto, index) => {
                  

                    return (
                        <tr key={index}>
                            <td>{impuesto.nombre}</td>
                            <td>$ {base}</td>
                            <td>
                                <input
                                    type="number"
                                    name="importe"
                                    value={impuesto.importe || ""}
                                    onChange={(e) => handleInputChange(index, "importe", e.target.value)}
                                    className="form-control"
                                    placeholder="Ingrese el porcentaje"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    name="valorImpuesto"
                                    value={ FN(impuesto.valorImpuesto) || ""}
                                    onChange={(e) => handleInputChange(index, "valorImpuesto", e.target.value)}
                                    className="form-control"
                                    placeholder="Ingrese el valor del impuesto"
                                />
                            </td>
                            <td>
                                <span className='btn btn-danger' onClick={() => suprimirImpuesto(index) }>Eliminar </span>
                            </td>
                     
                        </tr>
                    );
                })}
            </tbody>

            <tr>
                <th colSpan={4}>

                    <select className='mt-5 form' onChange={ (e) => agregarNuevoImpuesto(e)}>
                        <OptionSelect name="impuesto" defaulNombre={"-- Agregar Otro Tributo --- "} datos={impuestosLocal} />
                    </select>

                </th>
               
                
                </tr>
        </table>
    );
};


export default ImpuestosTable;