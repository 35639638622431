import React, {createContext , useContext , useReducer } from "react";

export const StateObservadores = createContext();

export const StateObservadoresProvider = ( { reducer , initialState , children }  ) => (
        <StateObservadores.Provider value={  useReducer(reducer, initialState)  } >
            {children}
        </StateObservadores.Provider>

)
export const useStateValueObservador = () =>  useContext(StateObservadores);