


import React, { useEffect, useState } from 'react';
import StandarModal from '../../../Document/PartesDocument/StandarModal';
import NuevoTercero from '../Formulario/NuevoTercero';


const BotonAdd = ({ itemEdit, setItemEdit  ,  successFunction = null  , textoBoton="Agregar Contacto"  , showBotonAdd=true } ) => {


    const [showForm, seshowForm] = useState(null);



    const mostrarFormulario = () => {

        if(setItemEdit){
            setItemEdit(null);
        }
        
        seshowForm(true);
    }

    useEffect(() => {

        if (itemEdit) {

            seshowForm(true);
        }

    }, [itemEdit])


    const cerrarModal = () => {
        seshowForm(!showForm);
        if(setItemEdit){
            setItemEdit(null);
        }
    }

    const registroConExito = (tercero) => {

        if(successFunction){
            successFunction(tercero)   
        }
       
        cerrarModal()
    }

    const campos = itemEdit ? "full" : "";

    return (
        <div>

            {showBotonAdd &&  <button className='btn btn-success btn-sm' onClick={mostrarFormulario}><i class="fa-solid fa-plus fa-lg me-1"></i>{textoBoton}</button>} 

            <StandarModal size="xl" desactivar={cerrarModal} mostrar={showForm}  >

                <NuevoTercero modoForm={campos} defaultValues={itemEdit}  registroConExito={registroConExito} />

            </StandarModal>
        </div>
    );
};

export default BotonAdd;