import React from 'react';
import ListaPrecio from "../ListaPrecio";


const TerceroView = ({tercero, eliminar, editar , bloquear}) => {
    console.log(tercero)
    return (
        
        <small className="  fw-semibold bg-opacity-10 border border-success border-opacity-10 rounded-2">
            <div className='d-flex flex-row'>
            <span><i class="me-1 ms-2 fa-solid fa-fingerprint"></i> {tercero.identificacion} </span>

             <span>   <i class="me-1 ms-2 fa-solid fa-user-tie"></i>
                {tercero.razonSocial}    </span>
                
            
                
            </div>
            <div className='d-flex flex-row'>
                
                <ListaPrecio tercero={tercero} /> 
                {
                    bloquear == 0  &&

                    <>
                        <span onClick={ eliminar}  title='Quitar Cliente' className='ms-5 text-danger'><i className="fa-solid fa-trash"></i> </span>
                        <span onClick={ editar} title='Editar Clientes' className='ms-2 text-dark bg-linght'>Editar</span>
                    </>
                }
            </div>  
           
            
        </small>
    );
}

export default TerceroView;