/** Se guardaran funciones que suelen usarse globalmente a nivel general  */


/*
* Esta funcion neutraliza la variable USER que contiene mucha informacion
* Haciendola mas liviana para que el servidor pueda resivirla limpia

    @params : Una valiable user
    @return : Variable USer limpiar
*/
export const filtrarUserSendServer =  (user = {}) => {
    if ( user ) { 
        const { USER,TURNO, ESTACION }  = user;
        return  {USER,TURNO, ESTACION};         
    }
    return null;
}



