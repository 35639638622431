import { ExtraerTablabyIds } from "../../../../Core/Dixie";
import { fetchEmpresa, fetchUSer, getLocalstore, getNit } from "../../../../Core/fetchLocalStoreData";
import { generarDocumentoFantasma } from "../../../Document/controlador";
import { DocumentL } from "../../../Document/DocumentCore";


export class AutoDocumento{

    constructor() {
        
        this.USER = fetchUSer();
        

        this.NIT = getNit();
        
        if(!this.USER){
            console.log("No hay usuario cargado")
        }
        


        /*** El tipo de documento  es el documento que por defecto a parecera el parqueadero */
        this.TIPO_DOCUMENTO = null ;

    
   



        /*
        Este Codigo es el servicio del parqueadero que por defecto ira incluido en todas las facturas de tipo parqueadero
        */
        this.ITEMS = [];




        this.DOCUMENTO = {

        };


        /**** La key donde se almacenara en la memoria */
        this.KEY_LOCAL_STORE =  this.NIT + "_AutoDocumento";



        this.KEYDOCUMENT = 
        {
            "keyLocalStogere": this.KEY_LOCAL_STORE ,
            "precio":0,
            "tabKey":0,
            "color_documento":"ninguno",
            "etiqueta_corta":"Auto Documento"
            
        }
        


        this.DISPATCH       = null;

        /**
         * La logica del documento principal que con tiene todas las matemaricas
         */
        this.LOGICADOCUMENTO = null ;


    } 

    



    renderizar(){
        
        
        this.DOCUMENTO  =   generarDocumentoFantasma(this.TIPO_DOCUMENTO ,  this.USER );
        this.DOCUMENTO.KEYDOCUMENT  = this.KEYDOCUMENT; 
    }


    async setTercero (tercero_id){

        let tercero = await  this.extrarTerceroDixie(tercero_id);
        if(tercero){
            this.DOCUMENTO.TERCERO  =  tercero;
        }else{
            alert("Error tercero no encontrado")
        }
    }   



    limpiarDocumento = () => {
        this.DOCUMENTO  =   generarDocumentoFantasma( null ,  this.USER );
        this.LOGICADOCUMENTO  =  new DocumentL(this.DOCUMENTO , this.KEY_LOCAL_STORE , this.DISPATCH  , this.USER );
    }



    setTipoDocumento = (documento) => {
       
        this.TIPO_DOCUMENTO = documento;
       
    }


    

   
    

    setDispatch(dispatch){

        this.DISPATCH = dispatch;
    }

    
    loadDocumentoLocalStore(){
            
        this.DOCUMENTO  = getLocalstore(this.KEY_LOCAL_STORE);;

        console.log("El documento de Localsoter es el sgieuinte")

        console.log(this.DOCUMENTO)
    }

    insertarMultiItems(items){
        this.instanciarDocumentoGlobal();

        items.forEach(data => {
            
            this.insertarImtes(data);


        })
    }

    insertarImtes(item){
       
        const datos = 
        {   
            item: item,   options: { 
                cantidad: 1,  
                PRECIO : item.PRECIO_PRINCIPAL
            } 
        } ;

        
    
        
        console.log(datos);

        this.LOGICADOCUMENTO.despachador('insertarItem', datos);
    }

    instanciarDocumentoGlobal(){
        
        this.LOGICADOCUMENTO  =  new DocumentL(this.DOCUMENTO , this.KEY_LOCAL_STORE , this.DISPATCH  , this.USER );
    }

    async extrarItemeDixie(id){
        this.log('extraerItemParqueadero' )
        var   data = await  ExtraerTablabyIds("productos" , [ id ]  , 'id' );
        this.log('El item encontrado es el siguiente' )
        if(data.length > 0 ){
            return data[0];
        }else{
            this.log('No tenemos producto relacionado con parqueadero. ' )
            return null;
        }
    }

    async extrarTerceroDixie(id){
        this.log('extraerItemParqueadero' )
        var   data = await  ExtraerTablabyIds("terceros" , [ id ]  , 'id' );
        this.log('El item encontrado es el siguiente' )
        if(data.length > 0 ){
            return data[0];
        }else{
            this.log('No tenemos producto relacionado con parqueadero. ' )
            return null;
        }
    }

    log(data){

        console.log(data);
     }
}