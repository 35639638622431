import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import { __pluma7 } from '../../../../Core/pluma7';

const Header = ({ infoPago }) => {
    const master = (infoPago && infoPago.pagos && infoPago.pagos[0].ct_master_id) ? infoPago.pagos[0].ct_master_id : 0;
    return (
        <div className="text-center">
            <div className='d-inline-flex'>
                <h5 className='text-secondary'>
                    COMPROBANTE DE {__pluma7.NombreMasterID({ master_id: master })}
                </h5>
            </div>

            <p className="mb-0">
                Fecha de Impresión: {__fecha.formatoFecha({ $fecha: __app.getFecha() })}
            </p>
        </div>

    );
};

export default Header;