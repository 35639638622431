import { enviarPeticion } from "../../../../Core/ActionDatabase";


// EXTRAER LA LIQUIDACION DE LOS OPERARIOS
export const getServiciosXLiquidar = async () => {
    let responseHttp = await enviarPeticion('terceros/cargar_operadores_x_liquidar');
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}