import React from 'react';
import { __app } from '../../../utils/myApp';
import { Link } from 'react-router-dom';

const ListaOperadores = ({ servicios }) => {
    let subtotalAcumulado = 0;
    let devengadoAcumulado = 0;
    return (
        <div className=" table-responsive">
            <table className="table table-sm  table-hover  border-translucent">
                <thead>
                    <tr>
                        <th className="text-nowrap">Operador</th>
                        <th className="text-nowrap">Acumulado</th>
                        <th className="text-nowrap">Devengado</th>
                    </tr>
                </thead>
                <tbody>
                    {servicios.map((servicio, index) => {
                        subtotalAcumulado += servicio.SUBTOTAL_3 || 0;
                        devengadoAcumulado += servicio.devengado || 0;

                        return (
                            <tr key={index}>
                                <td className="align-middle">
                                    <div className='d-flex align-items-center'>
                                        {servicio.empleado_id ? (
                                            <Link className="d-flex align-items-center text-primary text-decoration-none hover-effect" to={`/TerceroPerfil/${servicio.empleado_id}`} >
                                                <i className="fa-solid fa-eye bg-light text-primary p-2 rounded-circle me-2 shadow-sm" />
                                                <span className="fw-semibold">{__app.rellenarVacios(`${servicio.Empleado_cedula} / ${servicio.Empleado_nombre}`)} </span>
                                            </Link>
                                        ) : (
                                            <span className="fs-9">
                                                ------
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td className=" text-nowrap align-middle">$ {__app.formato_decimal({ total: servicio.SUBTOTAL_3 })}</td>
                                <td className="text-nowrap align-middle">$ {__app.formato_decimal({ total: servicio.devengado })}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <th></th>
                        <th className="text-nowrap"> $ {__app.formato_decimal({ total: subtotalAcumulado })}</th>
                        <th className="text-nowrap"> $ {__app.formato_decimal({ total: devengadoAcumulado })}</th>
                    </tr>
                </tbody>
            </table>

        </div>
    );
};

export default ListaOperadores;