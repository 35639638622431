

import React from 'react';
import { __app } from '../../../utils/myApp';

const CerrarTurno = ({titulo , cierreParcial , user ,paso, listaDenominaciones ,setPaso , finalizarTurno , load , updateDemo , Total , Base , setBase }) => {


    const stylos = {

        textAlign: 'end',
        fontSize: '40px',
        color: 'red',
        marginLeft: '10px',
        paddingRight : "8px"        
    }
    return (
        <div>
            









            {titulo && <h1>Cerrar Turno</h1> }

           

            { cierreParcial &&  
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <i className="bi bi-exclamation-triangle-fill"></i> {/* Ícono de advertencia */}
                    <h4 className="alert-heading">Cierre Parcial de Caja</h4>
                    <p>Esta operación solo cerrará la caja temporalmente, pero no finalizará el turno.</p>
                </div>
            }

            { !cierreParcial &&  
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    <i className="bi bi-clock-fill"></i> {/* Ícono de fin de turno */}
                    <h4 className="alert-heading">CIERRE DE TURNO</h4>
                    <p>Esta operación solo cerrará la caja temporalmente, pero no finalizará el turno.</p>
                </div>
            }


            <p>Cuenta cada denominación de Billete y escribe la cantidad. </p>

           {user.TURNO && 

           <>
           
           { paso == 1 && <div className='col bg-white '>



            <table className='table '>
                <thead>
                    <tr>
                        <th>Denominacion</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                    </tr>
                    </thead>

                    <tbody>
                    {listaDenominaciones.map( (demo,index) => 
                        
                        <tr key={index}>
                         
                            {
                                (demo.sumar == 1)  && 
                            
                                    
                                        <>
                                        <td>
                                            
                                                {/*<input  type='checkbox'  value={demo.ayudaBase} onChange={ (e) => sumarBase( e , index )  } />*/}

                                            {demo.nombre}
                                           
                                        </td>
                                        <td><input style={{maxWidth:80}} className='form-control' type="number" onChange={ (e) =>  updateDemo(e , index ) } /> </td>
                                        <td style={{textAlign: 'end'}}>{ __app.formato_decimal( {"total": demo.valor_total} ) }</td>
                                    </>
                                    

                            }
                         </tr>  

                    )}
                    <tr >
                        <th>Total en Caja</th>
                        <th></th>
                        <th style={{textAlign: 'end'}}> $ {  __app.formato_decimal({"total":Total}) }</th>
                    </tr>

                    <tr className='class="d-flex"'>
                        <th className='text-danger ' > <br/>BASE </th>
                        <td style={stylos}><span>-</span></td>
                        <th colSpan={1} className='d-flex justify-content-end'>  <p className=''> <br/>  <input style={{maxWidth:120, textAlign:'end'}} className=' form-control form-control-base ' type="number"  value={Base}  onChange={ (e) => setBase(e.target.value)} /></p>    </th>
                    </tr>
                    <tr>
                    <th>Total a  Reportar Turno</th>
                        <th></th>
                        <th style={{textAlign: 'end'}}> $ {  __app.formato_decimal({"total":Total - Base }) }</th>
                    </tr>

                    </tbody>
                    
                </table>
                <div className='d-flex justify-content-end'>
                    <button  className="btn btn-principal btn-lg" onClick={ () => setPaso(2) }> Siguiente Paso </button>        
                </div>
                           
                {/** <p>Seleccion Actual : {  __app.formato_decimal({"total":total_seleccion }) } </p>*/}
           </div> }

           {
            paso == 2 &&
            
                <div className='col-6 bg-white p-5'>
                
                <h3>Vamos a Entregar</h3>

                <h1 className='text-success'>$ {  __app.formato_decimal({"total":Total - Base }) } </h1>

                <p>¿Estas Seguro?</p>
                <button  className="btn btn-light btn-lg" onClick={ () => setPaso(1) }> No </button>       

                <button  className="btn btn-principal btn-lg" onClick={ () => setPaso(3) }> Si</button>       
                
                </div>


           }

           {
            paso == 3
            
            
            && <div className='col-6 bg-white p-5'>
                
                { !cierreParcial && <h5 className='alert alert-warning'>Una vez Finalices el turno no podras modificar ni vender.</h5>}
                
                <button  className="btn btn-light btn-lg" onClick={ () => setPaso(2) } disabled={load} > Atras </button>   


                 <button onClick={ finalizarTurno } className="btn btn-principal" type="button" disabled={load} >

                    {load 
                    
                        && <>
                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            <span role="status"> Cargango ...</span>
                        </>

                        || 
                        <>
                           
                            { !cierreParcial &&  <span role="status"> Finalizar mi Turno</span>  }

                            { cierreParcial &&  <span role="status">CERRAR PARCIALMENTE EL TURNO </span>  }
                        </>
                    }
                    
                </button>    

                
            </div>
           }
           </> ||

                <p>No hay turno para Cerrar.</p>
           }  







        </div>
    );
};

export default CerrarTurno;