
export const    ListaPrecios  = ({id , valores} ) => {
    let data = valores.find((item) => item.id == id);
    return ( 
        <span class="badge ms-1 badge-phoenix badge-phoenix-dark"> 
            {data && data.nombre && data.nombre} 
        </span> )

}


export const CategoriaObjeto = ({ id, valores, etiqueta = true }) => {
    // Find the data for the provided id
    let data = valores.find((item) => item.id === id);

    // Use default value for `nombre` if it's not available
    const nombre = data?.nombre || 'Sin categoria';

    // If etiqueta is false, return the name directly
    if (!etiqueta) {
        return nombre;
    }

    // Render the badge with the name
    return (
        <span className="badge ms-1 badge-phoenix badge-phoenix-dark">
            {nombre}
        </span>
    );
};


export const TipoEmpleado = ({ id, check = 0 }) => {
    const tipos = {
        1: { clase: "p7-bg-primary-cliente", nombre: "Cliente" },
        2: { clase: "p7-bg-primary-empleado", nombre: "Empleado" },
        3: { clase: "p7-bg-primary-proveedor", nombre: "Proveedor" },
    };

    const { clase, nombre } = tipos[id] || { clase: "", nombre: "Desconocido" };

    return check ? (
        <label htmlFor={id} className={`badge rounded-pill ${clase}`}>
            {nombre}
        </label>
    ) : (
        <span className={`ms-1 badge rounded-pill ${clase}`}>
            {nombre}
        </span>
    );
};

