

import React, { useEffect, useState } from 'react';
import {enviarPeticion} from "../../../Core/ActionDatabase";
import { __app , __fecha } from '../../../utils/myApp';
import StandarModal from './StandarModal';
import VerDocumento from '../../Documentos/VerDocumento/VerDocumento';
import { NotificationManager } from 'react-notifications';



  const CargarCuentaAbierta = ({cargarDocumento , desactivar , actualizarTab , LogicaDocumento }) => {

    const [cuentasAbiertas, setCuentasAbiertas] = useState([]);
    
    useEffect(() => {
        cargarCuentasAbiertas();
    }, []);

    const cargarCuentasAbiertas = async () => {
        let http = await enviarPeticion('ventas/cargar_cuentas_abiertas');
        
        if (http?.respuesta === 1) {
            setCuentasAbiertas(http.data);
        }
    };


    const formatoDecimal = (numero) => {
        return parseFloat(numero).toFixed(2); // Formato con dos decimales
    };

    const [verDocumentoModal , SetverDocumentoModal] = useState();

    const [facturaActual , SetfacturaActual] = useState();
    
    const handleModalFactura = (documento_id) => {
        
        SetverDocumentoModal(1);
        SetfacturaActual(documento_id)
    }

    const handleCerrarModal = () => {
        
        SetverDocumentoModal(null);
        SetfacturaActual(null)
    }
    
    const consultarFactura = async (numero) => {

        //LogicaDocumento.cancelarCuentaActual();

     //  LogicaDocumento.despachador( "eliminar_todos_productos" )

        let respuesta =  await enviarPeticion( "ventas/cargar_documento_by_id"  , numero  );
        console.log(respuesta)
        if(respuesta.status && respuesta.respuesta == 1  ){

            if(respuesta.data?.documento){


                if(respuesta.data.documento.JSON_PTVP7){
                    
                    console.log(respuesta.data.documento.JSON_PTVP7.TIPO_DOCUMENTO)
                    //actualizarTab(respuesta.data.documento.JSON_PTVP7.TIPO_DOCUMENTO);




                    let documentoCopy = __app.clonarObjeto(respuesta.data.documento.JSON_PTVP7);
                    
                    documentoCopy.ABONOS_CUENTA_ABIERTA  = respuesta.data.infoPagos;
                    documentoCopy.id_server  = respuesta.data.documento.documento_id
                    documentoCopy.cuenta_abierta_server  = 1;
               

                    console.log(documentoCopy)
                    cargarDocumento(documentoCopy)
                    desactivar()

                }else{
                    NotificationManager.error(respuesta.mensaje)
                    console.log("Error no se pudo dar formato al Json")
                }
                        
         
       
            }else{
                NotificationManager.error(respuesta.mensaje)
              
            }
            
        
        }else{
            NotificationManager.error(respuesta.mensaje)
            alert("No tenemos conexion con el Servidor.");
        }


    }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-12 mb-3 mt-1">
                    <div className="card shadow-sm rounded-4 border-0">
                        <div className="card-body">
                            <h3 className="text-center text-body-quaternary">Listado de cuentas abiertas.</h3>
                            <p className="text-center" style={{ fontSize: '13px' }}>Visualiza cada una de las cuentas abiertas.</p>
                            <div className="table-responsive">
                                <table className="table table-sm table-hover">
                                    <thead>
                                        <tr>
                                            <th className="text-nowrap">Codigo</th>
                                            <th className="text-nowrap">Fecha</th>
                                            <th className="text-nowrap">Placa</th>
                                            <th className="text-nowrap">Descripcion</th>
                                            <th className="text-nowrap">Observacion</th>
                                            <th className="text-nowrap">Operario</th>
                                            <th className="text-nowrap">Cliente</th>
                                            <th className="text-nowrap">SubTotal</th>
                                            <th className="text-nowrap">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cuentasAbiertas.map((value) => {
                                            const cliente = JSON.parse(value.JSON_CAMPOS_JOINS || '{}');
                                            return (
                                                <tr key={value.id}>
                                                    <td className="align-middle">{value.id}</td>
                                                    <td className="align-middle">{__fecha.formatoFecha({$fecha: value.CREADO})}</td>
                                                    <td className="align-middle">{value.PLACA}</td>
                                                    <td className="align-middle">{value.descripcion}</td>
                                                    <td className="align-middle">{value.periodo_observacion}</td>
                                                    <td className="align-middle">{value.aux_operadores_servicio}</td>
                                                    <td className="align-middle">
                                                        {cliente.p_nb ? `${cliente.p_i} / ${cliente.p_nb}` : 'Sin cliente asignado'}
                                                    </td>
                                                    <td className="align-middle"> $ {formatoDecimal(value.SUBTOTAL_3)}</td>
                                                    <td className="align-middle text-nowrap">
                                                       
                                                        <button onClick={() => consultarFactura(value.id)}  className="btn btn-success">
                                                            Seguir Cuenta <i className="fa-solid fa-person-walking-arrow-right ms-2"></i>
                                                        </button>

                                                        <button onClick={() => handleModalFactura(value.id)}  className="btn btn-primary">
                                                            Ver  <i className="fa-solid fa-eye ms-2"></i>
                                                        </button>


                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura."   desactivar={handleCerrarModal}  mostrar={verDocumentoModal}> 
                <VerDocumento   desactivar={handleCerrarModal}  document_id={facturaActual} />
            </StandarModal>

            
        </div>
    );
};

export default CargarCuentaAbierta;