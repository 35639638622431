import Buscaripi from "../../../utils/ripi";
import { ExtraerTabla } from "../../../Core/Dixie";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../context/StateProvider";
import { __app } from "../../../utils/myApp";
import { Link } from "react-router-dom";
import Add from "./Add/Add";
import { extraerObjetosPrimeraVez } from "../../../Core/Sincronizacion";
import Placa from "../../../Core/Html/Placa";
import BotonesPaginacion from "../../../ComponentsHtml/PaginadorDixie/BotonesPaginacion";
import SelectregistroPorPagina from "../../../ComponentsHtml/PaginadorDixie/SelectregistroPorPagina";
import InputBuscador from "../../../ComponentsHtml/PaginadorDixie/InputBuscador";
import { fetchUSer } from "../../../Core/fetchLocalStoreData";
import { CategoriaObjeto } from "../../Elementos/Etiquetas";
import { NotificationManager } from "react-notifications";

let Ripi_prIndex = null;

const Vehiculos = () => {
    
    /*** Observaodr para Actualizar la lista */
    const [{ KeyObservadores }] = useStateValue();


    /*** Buscado de lista */
    const [inputbuscar, setInputBuscar] = useState("");
    
    /**** Items filtrado para la tabla */
    const [filtroItems, setFiltroItems] = useState([]);
   
    
    /*** Key para buscar en el RIPI */
    const OpcionesBusqueda = ["identificacion", "nombre", "descripcion", "nombre_categoria"];


    /*** Item que actualmente se esta Editando  */
    const [itemEdit, SetItemEdit] = useState();



  

    /******************************************** */// 
    /*         Paginación
    /*
    /*************************f******************* */

    //Total de registros del la tabla
    const [totalRegistros, setTotalRegistros] = useState();

    //Pagina Actual
    const [paginaActual, setPaginaActual] = useState(1);

    //Registros por Pagina
    const [registrosPorPagina, setRegistrosPorPagina] = useState(10);

    //Total de Paginas
    const totalPaginas = Math.ceil(totalRegistros / registrosPorPagina);


    const user = fetchUSer();
    const categorias = user.DATABASE.objetos_categorias;

    /*** Opservador para saber cuando ocurre un Update actualizar la lista */
    useEffect(() => {
        contarRegistros();
        setTimeout(() => {
            getDatos();
        }, 300);
    }, [KeyObservadores.OBJETOS]);




    /*** Observador para la pagunacion, actualizar la lista. */
    useEffect(() => {

        getDatosPaginacion();

    }, [paginaActual , registrosPorPagina , KeyObservadores.OBJETOS]);


    /*** Cuenta los registros para hacer una paginacion */
    const contarRegistros = async () => {
        const contarRegistros      = await ExtraerTabla("objetos", 1, {
            contarRegistros : 1
        });
        setTotalRegistros(contarRegistros);
    }



    /*** El getDatos Optiene todos los datos para guardarlos en el Buscador */
    const getDatos = async () => {
      
        let  datos  = await ExtraerTabla("objetos");
        if (datos.length > 0) {
       
            console.log("Cargando desde la base de datos para RIPI...");
           

            if(!Ripi_prIndex){
                Ripi_prIndex = new Buscaripi(datos);
            }else{
                Ripi_prIndex.ArrayPrincipal = datos;
            }
            Ripi_prIndex.fullResultado = true;
        }
    };



    /**** Optiene solo los datos de la paginacion  ***/
    const getDatosPaginacion = async () => {
        setFiltroItems([]);
        let paginaActualAux =  (paginaActual - 1) * registrosPorPagina;
        let  datos  = await ExtraerTabla("objetos", 1, {
            paginacion : {
                iniciar:paginaActualAux ,
                limite:  registrosPorPagina ,
            }
        });
       console.log(datos);
        if (datos.length > 0) {
            console.log("Cargando desde la base de datos para LISTA...");
            setFiltroItems(datos);
        }
    };



  


    /*** Busca en RIPI */
    const buscar = () => {
        let data = Ripi_prIndex.start(inputbuscar, OpcionesBusqueda);
        console.log(Ripi_prIndex);
        setFiltroItems(data);
    };



    /*** Si por alguna razon no hay datos el sistema intentara sincronizar */
    const sincronizarNuvamente = async () => {
        let respuesta = await extraerObjetosPrimeraVez();
        if (respuesta) {
            NotificationManager.success("Completado con Exito!")
      
            getDatos();
        } else {
            alert("Error al sincronizar");
        }
    };




    /***  Editar Item */
    const hadleEditar = (item) => {
        SetItemEdit(item);
    };

    
   
 
    return (
        <div className="card shadow rounded-4 border-0 p-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-4">
                <div>
                    <h3 className="text-dark fw-bold mb-0">🚗 Lista de Vehículos</h3>
                    <p className="text-muted mb-0">Gestiona y visualiza el listado de vehículos registrados</p>
                </div>
                <div className="d-flex flex-column flex-md-row gap-2">
                    <button className="btn btn-outline-secondary btn-sm shadow-sm" onClick={sincronizarNuvamente}>
                        <i className="fa-solid fa-sync me-1 fa-lg" /> Sincronizar
                    </button>
                    <Add SetItemEdit={SetItemEdit} itemEdit={itemEdit} successFunction={getDatos} />
                </div>
            </div>

            {/* Buscador */}
            <InputBuscador inputbuscar ={inputbuscar}  setInputBuscar={setInputBuscar}  buscar={buscar} />





            {/* Tabla */}
            <div className=" table-responsive">
                <table className="table table-hover align-middle mb-0">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Placa</th>
                            <th>Descripción</th>
                            <th>Categoría</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtroItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{<Placa placa={item.identificacion} />}</td>
                                <td>{__app.rellenarVacios(item.nombre)}</td>
                                
                                <td> <span> <br/><CategoriaObjeto id={item.objetos_categoria_id} valores={categorias} etiqueta={false} /></span> </td>
                                <td className="text-nowrap">
                                    <button onClick={() => hadleEditar(item)} className="btn p-2 btn-subtle-secondary btn-sm me-1">
                                        <i className="fa-solid fa-pen fa-lg me-1 text-warning" />Editar
                                    </button>
                                    <Link className="btn p-2 btn-subtle-secondary btn-sm" to={`/perfil_vehiculos/${__app.envolver(item.identificacion)}/${__app.envolver(item.id)}`}>
                                        <i className="fas fa-eye me-1 fa-lg text-primary"></i>Ver Perfil
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Paginador */}
            <BotonesPaginacion paginaActual={paginaActual}  totalPaginas={totalPaginas}  setPaginaActual={setPaginaActual}  />



            {/* Selección de registros por página */}
            <SelectregistroPorPagina registrosPorPagina={registrosPorPagina}  setRegistrosPorPagina={setRegistrosPorPagina} />
        </div>
    );
};

export default Vehiculos;
