import React from 'react';
import { __app, __fecha } from '../../../utils/myApp';
import InfoTercero from './InfoTercero';
import { __pluma7 } from '../../../Core/pluma7';
import StatusCodeWebService from './Dian/StatusCodeWebService';
import { confirmAlert } from 'react-confirm-alert';
import { enviarPeticion } from '../../../Core/ActionDatabase';
const Encabezado = ({ document, recargarDocumento }) => {

    const { DOCUMENTO, ITEMS } = document;
    console.log(ITEMS);
    console.log(DOCUMENTO);


    const cambiarEstadoDianManual = () => {
        confirmAlert({
            title: 'Confirmar acción',
            message: '¿Estás seguro de que deseas cambiar el estado del documento?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => cambiarEstadoDian()
                },

                {
                    label: 'No',

                },
            ]
        });
    };

    const cambiarEstadoDian = async () => {
        const httpResponse = await enviarPeticion("documentos/actualizar_estado_dian", DOCUMENTO.CtDocumento_id + "/" + 4)
        console.log(httpResponse)
        if (httpResponse) {

            if (httpResponse.data == true) {
                recargarDocumento()
            }
        }
    }

    return (
        <div className=' row'>
            <div className='col-12  col-xl-6 mb-2'>
                <div className={`card shadow rounded-4 border-opacity-25 border-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`}>
                    <div className='card-body'>
                        <div className="d-flex justify-content-center d-inline-block">
                            <div className={`alert fw-bold fs-6 border-2 shadow-lg rotar  text-center py-1 alert-subtle-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`} role="alert">
                                <i className={`${DOCUMENTO.CtTipoComprobanteFiscal_icono_documento} me-2`}></i>
                                {DOCUMENTO.CtTipoComprobanteFiscal_nombre}
                            </div>
                        </div>
                        <div className="text-center mb-3">
                            <h4>
                                NUMERO DE FACTURA <strong>{__app.rellenarVacios(DOCUMENTO.CtDocumento_ct_numero_factura)}</strong>
                                <br />
                                <small className="text-muted">Codigo unico interno #{DOCUMENTO.CtDocumento_id}</small>

                            </h4>
                        </div>
                        <table style={{ width: '100%' }} className=" ">
                            <tr>
                                <th colSpan={1}>Fecha Factura </th>
                                <td colSpan={3} className="text-start">
                                    {__fecha.formatoFecha({ $fecha: DOCUMENTO.CtDocumento_ct_fecha_factura })}
                                </td>
                            </tr>

                            <tr>
                                <th colSpan={1}>Fecha Automatica </th>
                                <td colSpan={3} className="text-start">
                                    {__fecha.formatoFecha({ $fecha: DOCUMENTO.CREADO })}
                                </td>
                            </tr>

                            {DOCUMENTO.CtTipoComprobanteFiscal_ct_master_id !== 0 && (
                                <tr>
                                    <th>Estado de Factura</th>
                                    <td>
                                        {__pluma7.EstadoFactura({ estado: DOCUMENTO.CtDocumento_ct_documento_estado_id, masterId: DOCUMENTO.CtTipoComprobanteFiscal_ct_master_id })}
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <th>Tipo de Crédito</th>
                                <td>
                                    {DOCUMENTO.tipo_credito_nombre}
                                </td>
                            </tr>

                            <tr>
                                <th>Observación</th>
                                <td>{__app.rellenarVacios(DOCUMENTO.periodo_observacion)}</td>
                            </tr>

                            {DOCUMENTO.observacion_trasferencia && (
                                <tr>
                                    <th>Transferencia</th>
                                    <td>{DOCUMENTO.observacion_trasferencia}</td>
                                </tr>
                            )}

                            <tr>
                                <th>Vendedor</th>
                                <td>{__app.rellenarVacios(DOCUMENTO.vendedor)}</td>
                            </tr>

                            <tr>
                                <th>Cajero</th>
                                <td>{DOCUMENTO.Cajero}</td>
                            </tr>
                            <tr>
                                <th>Turno</th>
                                <td>#{DOCUMENTO.CtDocumento_turno_id}</td>
                            </tr>



                            {(DOCUMENTO.DIAN_documento_electornico == 1) &&
                                <>
                                    <tr>
                                        <th colSpan={2} className="text-center bg-gray-100">Información Electrónica</th>
                                    </tr>

                                    <tr>
                                        <th>CUFE</th>
                                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal' }}>
                                            {DOCUMENTO.DIAN_CUFE}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <StatusCodeWebService estado={DOCUMENTO.DIAN_repuesta_dian} mensaje={DOCUMENTO.DIAN__responsexml} />
                                        </td>
                                    </tr>

                                    {DOCUMENTO.DIAN_repuesta_dian == 1 &&
                                        <tr>
                                            <th>Verificación Manual</th>
                                            <td className="p-2">
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-sm">
                                                        Pluma7 no puede verificar automáticamente si este documento fue aceptado por la DIAN.
                                                        Si usted ha confirmado que el documento fue aceptado, puede actualizar el estado manualmente.
                                                    </p>
                                                    <button
                                                        onClick={() => cambiarEstadoDianManual()}
                                                        className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded text-sm w-max">
                                                        Confirmar Aceptación DIAN
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                        </table>
                    </div>
                </div>
            </div>

            <div className='col-12  col-xl-6 mb-3'>
                <InfoTercero DOCUMENTO={DOCUMENTO} />
            </div>
        </div>
    );
};

export default Encabezado;