import React from 'react';
import { useEffect , useRef, useState } from 'react';
import { __app } from '../../../../utils/myApp';
import { NotificationManager } from 'react-notifications';
import InfoPagosP1 from './Componentes/InfoPagosP1';
import ListaPagosP1 from './Componentes/ListaPagosP1';
import FormasPagos1 from './Componentes/FormasPagos1';
import Propinas from './Componentes/Propinas';


const  FormaPagoPaso1 = ({OcultarPagaCon , ABONOS_TOTAL_DOCUMENTO , desactivar , ModoAbonar , documento,tab , cambiarPaso  , cambiarEstado , pagoDocumento , actualizartotalpagado, user}) => {


    
    


    let initialPropina = (documento.MATEMATICAS.PROPINA) ? documento.MATEMATICAS.PROPINA : 0;




    const [ propina , setPropina ] = useState(initialPropina);


  

    
    const lisTipoCreditos  = user.DATABASE.tipo_creditos;



    
    /*** La suma de los pagos que ya fueron hechos al documentos, aplica solo para cuentas abierta  */
    const TOTAL_PAGOS_ABONOS =  ABONOS_TOTAL_DOCUMENTO;



    /*** Aqui se suman todos los pagos adelantados del cocumento dales como saldo a favor o abonos  */
    const TOTAL_TODOS_LOS_PAGOS_ADELANTADOS  = TOTAL_PAGOS_ABONOS +  documento.MATEMATICAS.TOTAL_SALDO_FAVOR 


    /*** VERDADERO TOTAL A PAGAR  */
    const TOTAL_A_PAGAR = Math.round((documento.MATEMATICAS.SUBTOTAL_3 + propina) - TOTAL_TODOS_LOS_PAGOS_ADELANTADOS);





 





   
    const [ formpagos , setFormaPagos ] = useState([]);
    


    const [ pagacon , setPagacon ] = useState("")




    const [ totalpagado , setTotalpagado ] = useState( 0  );
    

    const [ totalCredito , settotalCredito ] = useState();



    /*** Esta variable muestra las obciones de pago en caso de que el documento tenga pagos.
     * 
     *  Hay documentos que no tienen pagos: cotizaciones y entre otros.
     *  Hay cortesia.
     * Y tambien saldo a favor que anulan.
     */
    const [ mostrarObcPagos , setmostrarObcPagos ] = useState(1);

    
    const [ tipoCredito , SetTipoCredito ] = useState(1);





    const handleInputChange = (event) => {   
    
        let index  = parseInt(event.target.getAttribute("data-index"));
        let name   = event.target.getAttribute("data-name");
        let value = event.target.value;
        console.log("DIPARADOOO!!!")
        setPagoLvlDos(index,name, value)
    }

    const setPagoLvlDos = (index, name, aux ) => {   


  
        let copi = [...formpagos];
        
        /*** Aqui eliminamos letras */
        aux = aux.length  == 0  ? 0 : aux; 
        
        aux   =  isNaN(aux) ? 0 : parseFloat(aux) ; 
        /* Manera Obsoleta de modificar javascrpit : 
        copi[ index ][ name ] = 10;*/
        
        copi[index] = {...copi[index], [name]: aux};


        console.log(  copi[index] );
        console.log(  copi[index] );
        console.log(  copi[index] );

        setFormaPagos(copi); 
        
        getTotalPagado(copi);
    }

    const setPagoAutomatico = (index, name, total) => {
        
        let indexx = formpagos.findIndex((pago) => pago.total >  0 )
        console.log(indexx)

        if(indexx ==index ) {
            
            handleGuardarPago();
            return false;
        }

        let copi = formpagos.map( (pago) => {
            pago.total = 0;
            return pago;
        } )
        setFormaPagos(copi); 
        setPagoLvlDos(index, name, total);
           
        // handleGuardarPago();
    }

    const enviarCreditodirecto = () => {

        let copi = formpagos.map( (pago) => {
            pago.total = 0;
            return pago;
        } )
        setFormaPagos(copi);
        getTotalPagado(copi);
        handleGuardarPago();
    }


    const getTotalPagado  = (copi) => {

        let total = copi.reduce(function(sum, current) {
            return sum + current.total;
        }, 0); // 43 + 32 + 21 = 96

       
        setTotalpagado(total);
        actualizartotalpagado(total);
    }

    const handlePagacon = (event) => {
        setPagacon(Number(event.target.value));
    }
    
    




    useEffect( () => {
        console.log("useEffect")
        
        let total = TOTAL_A_PAGAR - totalpagado;

        console.log("TOTAL_A_PAGAR:"  + TOTAL_A_PAGAR  + "___totalpagado:" + totalpagado )
        let estado = (total > 0 ) ? 0 : 1 ;
        cambiarEstado(estado);


        settotalCredito( ( TOTAL_A_PAGAR ) - totalpagado );

    }  , [totalpagado , documento.MATEMATICAS , propina])




    const handleGuardarPago = () => {
       
        getTotalPagado(formpagos);
       
        pagoDocumento(formpagos , pagacon , propina , tipoCredito);


        if(ModoAbonar === 1 ){
            desactivar();
            NotificationManager.success('Abono Registrado con Existo.');
        }else{
            cambiarPaso(2); 
        }
    }





    const DispararkeyPress = (e) => {
        

        if(e.key === 'Enter'){
            handleGuardarPago();
        } 
    }








    return (
        <div className='d-flex justify-content-center'> 
 
        

        <div className='row'>   
            
        { mostrarObcPagos == 1 && <div className="d-flex flex-row-reverse bd-highlight">
                <button onClick={enviarCreditodirecto} className='p-2 mb-3 bd-highlight btn-lg btn btn-subtle-warning '> <i class="fa-regular fa-credit-card"></i> Enviar como  Credito </button>
        </div>}


            

            <Propinas user={user}  setFormaPagos={setFormaPagos}  setPropina={setPropina}  setPagoLvlDos={setPagoLvlDos}  documento={documento} formpagos={formpagos} />

            
            <InfoPagosP1 
          
            documento={documento} 
            TOTAL_PAGOS_ABONOS={TOTAL_PAGOS_ABONOS} 
            TOTAL_TODOS_LOS_PAGOS_ADELANTADOS={TOTAL_TODOS_LOS_PAGOS_ADELANTADOS} 
            TOTAL_A_PAGAR={TOTAL_A_PAGAR}
             propina={propina} 
             user={user} />



                
            <div className='row'>
                <div className="col">
                    <ListaPagosP1   OcultarPagaCon={OcultarPagaCon} tipoCredito={tipoCredito}  SetTipoCredito={SetTipoCredito} lisTipoCreditos={lisTipoCreditos} mostrarObcPagos={mostrarObcPagos} DispararkeyPress={DispararkeyPress} handlePagacon={handlePagacon} pagacon={pagacon} formpagos={formpagos}  totalpagado={totalpagado} totalCredito={totalCredito} TOTAL_A_PAGAR={TOTAL_A_PAGAR} />
                </div>
                
                <div className="col">
                  <FormasPagos1 pagoDocumento={pagoDocumento}  cambiarPaso={cambiarPaso} setmostrarObcPagos={setmostrarObcPagos}  getTotalPagado={getTotalPagado} documento={documento}  mostrarObcPagos={mostrarObcPagos}  handleInputChange={handleInputChange}  formpagos={formpagos}  setFormaPagos={setFormaPagos} setPagoAutomatico={setPagoAutomatico}  user={user}  TOTAL_A_PAGAR={TOTAL_A_PAGAR}  />      
                </div>
            </div>
           
            { !isNaN(totalpagado)  &&   <button onClick={ handleGuardarPago } className={'btn d-flex  justify-content-center  btn-lg p7-btn-' + documento.TIPO_DOCUMENTO.color_documento  }> Siguiente Paso   </button>}
        </div>
        </div>
    );
}

export default FormaPagoPaso1;