import { fetchUSer , fetchLocalconfig} from "../Core/fetchLocalStoreData"

const localconfig = fetchLocalconfig();


const userInfo = fetchUSer();



export const initialState = {

    user : userInfo,

    infodatabase : {

        TIPO_DE_DOCUMENTO :  null
        
    },

    localconfig : localconfig,


    KeyObservadores : {
               
        VEHICULOS : 1,

        PRODUCTOS : 1,

        TERCEROS : 1,
    }

}

export const initialStateObservador = {

    
    VEHICULOS : 1,

    PRODUCTOS : 1,

    CLIENTES : 1,



}