

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImpuestosTable from './ImpuestosTable';
import InputField from './InputField';


// Componente principal EditarLinea
const EditarLinea = ({ item, Accion, indexx, operadores }) => {
 
    const [fomritems, setFomritems] = useState(() => ({
        ...item,
        IMPUESTOS_RAIZ: item.IMPUESTOS_RAIZ || [],
        MATEMATICAS: item.MATEMATICAS || { PRECIO: '', CANTIDAD: '', DESCUENTO_UNITARIO_PORCENTUAL: '' },
    }));







    const handleInputChange = (event) => {
        
        const { name, value, dataset } = event.target;
      
       
        let copia = { ...fomritems };

        if(!name || name == "impuesto" || name == ""  || name == null ){
            return false;
        }
        
        if (dataset.mathKey) {
            
            copia.MATEMATICAS = {
                ...copia.MATEMATICAS,
                [name]: parseFloat(value) || '',
            };
        } else if (name === 'operador') {
          
            const selectedIndex = event.target.selectedIndex;
            const nombre_operador = event.target.options[selectedIndex]?.text || '';

            copia = { ...copia, [name]: value, nombre_operador };
        } else {
            copia = { ...copia, [name]: value };
        }

        if (
            item.nombre.trim() !== copia.nombre.trim() ||
            item.descripcion.trim() !== copia.descripcion.trim()
        ) {
            copia.DOCUMENTO = { ...copia.DOCUMENTO, OTRO_PRODUCTO: true };
        }

        setFomritems(copia);
    };

    const handleImpuestoChange = (index, newValue) => {

        const copiaImpuestos = [...fomritems.IMPUESTOS_RAIZ];

        copiaImpuestos[index].importe = parseFloat(newValue) || 0;


        setFomritems((prev) => ({
            ...prev,
            IMPUESTOS_RAIZ: copiaImpuestos,
        }));
    };




    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Desde handleSubmit de EditarLinea imprimo el item a enviar:', fomritems);
        Accion(fomritems, indexx);
    };



    const agregarNuevoImpuesto = (impuestoNuevo) => {
      
        
        if (impuestoNuevo) {
            console.log("El nuevo impuesto fue encontrado")
            console.log(impuestoNuevo);
            // Añadir el nuevo impuesto a IMPUESTOS_RAIZ

            console.log(setFomritems)
            setFomritems((prev) => ({
                ...prev,
                IMPUESTOS_RAIZ: [...prev.IMPUESTOS_RAIZ, impuestoNuevo],
            }));
        }else{
            alert("no encontramos impiest prar: " + impuestoNuevo)
        }
    }

    const suprimirImpuesto = (index) => {
        // Verifica que el índice sea válido
        if (index >= 0 && index < fomritems.IMPUESTOS_RAIZ.length) {
          
            const nuevaListaDeImpuestos = fomritems.IMPUESTOS_RAIZ.filter((_, i) => i !== index);
    
            console.log('Impuestos después de eliminación:', nuevaListaDeImpuestos);
    
            // Actualiza el estado con la nueva lista de impuestos
            setFomritems((prev) => ({
                ...prev,
                IMPUESTOS_RAIZ: nuevaListaDeImpuestos,
            }));
        }
    };
    

    return (
        <div>
            <h3 className="text-center">Editar Item</h3>

            <span onClick={() => console.log(fomritems)}>Log</span>

            <form onChange={handleInputChange} onSubmit={handleSubmit} className="row g-3 mt-5">
                {/* Sección Izquierda */}
                <div className="col-6">
                    <InputField
                        label="Nombre"
                        name="nombre"
                        value={fomritems.nombre}
                        onChange={handleInputChange}
                        placeholder="Ingrese el nombre"
                    />

                    <div className="mt-3">
                        <label className="form-label">Descripción</label>
                        <textarea
                            name="descripcion"
                            value={fomritems.descripcion || ''}
                            onChange={handleInputChange}
                            className="form-control"
                            rows="3"
                            placeholder="Ingrese la descripción"
                        ></textarea>
                    </div>
                </div>

                {/* Lista de Impuestos */}
                <div className="col-12">
                    <h4>Lista de Impuestos</h4>
                    <ImpuestosTable
                        impuestos={fomritems.IMPUESTOS_RAIZ || []}
                        onImpuestoChange={handleImpuestoChange}
                        base={fomritems.MATEMATICAS.PRECIO_2}
                        SetImpuesto={agregarNuevoImpuesto}
                        suprimirImpuesto={suprimirImpuesto}
                    />
                </div>

                {/* Botón de Guardar Cambios */}
                <div className="col-12 mt-5 mb-3">
                    <center>
                        <button type="submit" className="btn btn-lg btn-primary">
                            Guardar Cambios
                        </button>
                    </center>
                </div>
            </form>
        </div>
    );
};



























EditarLinea.propTypes = {
   
   
    item: PropTypes.shape({
        nombre: PropTypes.string,
        descripcion: PropTypes.string,
        MATEMATICAS: PropTypes.shape({
            PRECIO: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            CANTIDAD: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            DESCUENTO_UNITARIO_PORCENTUAL: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        
        tipo_producto: PropTypes.number,
        liquidable: PropTypes.number,
        switch_liquidacion: PropTypes.number,
        servicio_bloqueo_liquidacion: PropTypes.number,
        liquidacion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        operador: PropTypes.string,
        nombre_operador: PropTypes.string,
       
        IMPUESTOS_RAIZ: PropTypes.arrayOf(
            PropTypes.shape({
                nombre: PropTypes.string.isRequired,
                importe: PropTypes.number.isRequired,
            })
        ),
        DOCUMENTO: PropTypes.object,
    }).isRequired,
    Accion: PropTypes.func.isRequired,
    indexx: PropTypes.number.isRequired,
    operadores: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            razonSocial: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default EditarLinea;
