import React from 'react';
import { NotificationManager } from 'react-notifications';
import { enviarPeticion } from '../../../../Core/ActionDatabase';
import { fetchEmpresa } from '../../../../Core/fetchLocalStoreData';

const AnularLiquidacion = ({ document_id, actualizarTabla }) => {
    const ConfigEmpresa = fetchEmpresa();
    const anular = async (e) => {
        e.preventDefault();
        const url = `terceros/anular_liquidacion/${document_id}`;

        try {
            const respuesta = await enviarPeticion(url, ConfigEmpresa.nit);

            if (respuesta.respuesta == 1) {
                NotificationManager.success(respuesta.mensaje, 'Anulado con exito');
                actualizarTabla();
            } else {
                NotificationManager.error(respuesta.mensaje, 'Error');
            }
        } catch (error) {
            console.error("Error en la petición:", error);
            NotificationManager.error('Hubo un error al enviar los datos', 'Error');
        }
    }
    return (
        <div>
            <span onClick={(e) => anular(e)} className='dropdown-item btn btn-subtle-danger btn-sm' style={{ textAlign: 'left' }}>
                <i className="fa-solid fa-ban me-2 fa-lg"></i>Anular
            </span>
        </div>
    );
};

export default AnularLiquidacion;