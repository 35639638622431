

import React, { useEffect, useState } from 'react';
import { extraerLiquidacion } from '../../Core/Core';

import TablaLiquidacion from './TablaLiquidacion';
import Alerta from '../../../../Core/Html/Alerta';
import { NotificationManager } from 'react-notifications';


const Liquidacion = ({ terceroId }) => {

    const [servicios, setServicios] = useState([]);
    const [cargando, setCargando] = useState(true);


    useEffect(() => {
        setServicios([]);
        cargarServicios();
    }, [])

    const cargarServicios = async () => {
        setCargando(true);
        try {
            const data = await extraerLiquidacion(terceroId);
            setServicios(data);
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar servicios:", error);
        } finally {
            setCargando(false);
        }
    }



    return (
        <div className='card shadow-sm rounded-4 border-0'>
            <div className="card-body">

                {cargando ? (
                    <p>Cargando servicios...</p>
                ) : servicios.length === 0 ? (
                    <Alerta tipo="warning" title="¡Ops! No hay servicios por liquidar." mensaje="No hay servicios pendientes de liquidar en este momento." />
                ) : (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="mb-0">Servicios por liquidar</h4>
                        </div>
                        
                        <TablaLiquidacion cargarServicios={cargarServicios} servicios={servicios} terceroId={terceroId} />
                    </>
                )}
            </div>
        </div>
    );
};

export default Liquidacion;