
import Dexie from 'dexie';
import { setLocalStore } from './fetchLocalStoreData';





const extraerNombreDataBase = () => {
    const infoData =  JSON.parse( localStorage.getItem("__Ah#d7aWF___") );
    if(infoData){
        return infoData.nit;
    }
    return null;
}

const  nit =  extraerNombreDataBase();



let db = null;

if( nit ){
    db = new Dexie(nit);

}else{
    console.log("La DB no fue creada:" + nit);
    console.log(!nit);
}




const estructuraDB = {
    productos: 'id',
    terceros: 'id,identificacion',
    documentos: 'id,fechaidFinal,turno_id,estado_patio,placa',
    actualizaciones: 'id',
    objetos : 'id,identificacion',
};




/***
 * 
 * 
 * opciones : {
 *      condiciones : 
 *      paginacion : {
 *          iniciar : 
 *          limite : 
 *      }
 *      contarRegistros : 0  // solo se usa para contrar el numero de registros
 *  }
 * 
 */
export const ExtraerTabla = async (tabla, version = 1, opciones = {}) => {
    // Verifica si la base de datos existe
    if (!db) {
        console.log("Base de datos no inicializada");
        return [];
    }

    // Verifica si la versión actual es diferente a la proporcionada
    if (db.verno !== version) {
        db.version(version).stores(estructuraDB);
    }

    // Verifica si la tabla existe en la base de datos
    if (!db[tabla]) {
        alert("La tabla no existe");
        return false;
    }


    if(opciones?.contarRegistros == 1 ){
        let numero_de_registros =  await db[tabla].count();
        return numero_de_registros;
    }


    let query = db[tabla];

    if (opciones.condiciones && opciones.condiciones.where && opciones.condiciones.igual_a !== undefined) {
        query = query.where(opciones.condiciones.where).equals(opciones.condiciones.igual_a);
    }



    if (opciones.paginacion?.limite) {
        query = query.offset(opciones.paginacion?.iniciar || 0).limit(opciones.paginacion.limite);
    }

    let data = await query.toArray();

    return data ;
};







// Función para obtener el último registro ingresado
export const  getUltimoRegistro  = async (tabla , key = 'id') => {
    // Ordenar por 'createdAt' en orden descendente y obtener el primer registro
    return    await db[tabla].orderBy(key).reverse().first();

}




/* Resibe como paarametro una tabla y devuelve sus datos  
   

    La Clave de consulta puede Varias dependiendo la tabla.
    ejemplo para la tabla vehiculos puede que queramos consultar por PLACA
        
*/
export const ExtraerTablabyIds =  async (tabla , ids  ,  clave = 'id'  , version = 1   )  => {
    console.log(nit);
    db = new Dexie(nit);


    if(!db){
        console.log("no debe");
        return [];
    }
    
 

    if (!db.verno == version  ) {
  
        db.version(version).stores(estructuraDB);
    }

    if(!db[tabla]){
        alert("la tabla no existe")
        return false;
    }


	let data = await db[tabla]
    .where(clave)
    .anyOf(  ids )
    .limit( ids.length )
    .toArray()
    .then((results) => {
        
        return results;
    });

    
    return data;
}



/*
*
*  reloadProductos = datos a ingresar en el DXE
*  Table = Tabla del DIXE
    Nuevo = Si es verdadero primero elimina los objetos actuales
*/
export const reloadDatabases = async (reloadDatos, table = null, nuevo = 0, version = 1) => {
    console.log("Desde ----------reloadDatabases----------");
    console.log("tabla;", table);
    console.log("Datos:")
    console.log(reloadDatos)
    if (!db) {
        console.log("la db no estaba definida, definiendo...");
        db = new Dexie(extraerNombreDataBase());
    }

    if (!table) {
        alert("No definió una tabla para actualizar la base de datos local");
        return { success: 0, message: "error: No se definió una tabla." };
    }

    if (db.verno !== version) {
        console.log("La versión no era la misma, dando estructura");
        db.version(version).stores(estructuraDB);
    }

    console.log(db);
    console.log(db[table]);

    if (db[table]) {
        try {
            if (nuevo === 1) {
                console.log("Limpiamos toda la base de datos:", nuevo);
                await db[table].clear();
                console.log("Deleted: " + table);
                console.log("Datos a insertar en " + table);
                console.log(reloadDatos);

                await db[table].bulkPut(reloadDatos);
                console.log("Datos insertados correctamente en la tabla " + table);
                return { success: 1, message: "Nueva Base de Datos Creada Localmente" }; // Devuelve éxito con código 1
            } else {
                console.log("Agregamos uno nuevo");
                await db[table].bulkPut(reloadDatos);
                console.log("Datos insertados correctamente en la tabla " + table);
                return { success: 1, message: "Se insertaron nuevos datos en la base de datos local." }; // Devuelve éxito con código 1
            }
        } catch (error) {
            console.error("Error en la operación:", error.message);
            return { success: 0, message: "error: " + error.message }; // Devuelve error con código 0
        }
    } else {
        return { success: 0, message: "error: La tabla " + table + " no existe" }; // Devuelve error con código 0
    }
};


//const refIds = [1, 2, 3];
export const  deleteIdsDixie  =    ( refIds , table = null   ) => {

    if(!db){
        console.log(db);
        return false;
    }

    if(!table){
        alert("No definio una tabla para actualziar la base de datos local");
        return false;
    }
    
    
    //db.productos.bulkPut(reloadDatos);

    let promise;


    if(db[table]){

     

        refIds.forEach(async (ref_id) => {
            try {
                await db[table].where('id').equals(ref_id).delete();
                console.log(`Registros eliminados de la tabla ${table} con id ${ref_id}`);
            } catch (error) {
                console.error(`Error al eliminar registros de la tabla productos con id ${ref_id}:`, error);
            }
        });
                
    
        
    }else{
        return "error La tabla : " + table + " No existe";
    }
    
    return promise;

}









export const updateDixieOne = (id, itemReload , table ) => {


    if(!db){
        console.log(db);
        return false;
    }

    if(!table){
        alert("No definio una tabla para actualziar la base de datos local");
        return false;
    }
    
    
    //db.productos.bulkPut(reloadDatos);
    if(db[table]){

      
        db[table].update(id , itemReload).then(function(updated) {

            if  (updated){
                console.log("Exito al Actualizar.");
                setLocalStore('DixieItemsReload' , "1");
            }else{
                
                console.log("Fallo la Actualizaicone")
            }

        })
    }
    else{
        alert("La tabla : " + table + " No existe");
    }
}




