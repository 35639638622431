import React, { useEffect, useState } from 'react';
import LayautDefault from "./components/Layaut/LayautDefault";
import AlertasInventario from "./components/Productos/AlertaMaximosMinimos/Pages";
import { NotificationProvider } from './ComponentsHtml/NotificationProvider';

const App = () => {
  const [isAppActive, setIsAppActive] = useState(true);  // Estado para controlar si la app está activa o no

  useEffect(() => {
    // Al abrir la aplicación, se marca la página como abierta
    localStorage.setItem('openpages', Date.now().toString());

    // Función que se ejecuta cuando detecta cambios en el localStorage
    const onLocalStorageEvent = (e) => {
      if (e.key === 'openpages') {
        // Si hay otra página abierta, marcar esta como no activa
        localStorage.setItem('page_available', Date.now().toString());
      }
      if (e.key === 'page_available') {
        // Si otra página está activa, se muestra un mensaje
        setIsAppActive(false);  // Desactivar esta pestaña
       
      }
    };

    // Escuchar eventos del localStorage
    window.addEventListener('storage', onLocalStorageEvent);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener('storage', onLocalStorageEvent);
      localStorage.removeItem('openpages');  // Eliminar el valor de 'openpages' al cerrar esta pestaña
    };
  }, []);

  // Función para recargar la página
  const handleReload = () => {
    window.location.reload();
  };
  //isAppActive
  if (true) {
    return (
      <>
     
      <NotificationProvider>
        <AlertasInventario />
        <LayautDefault />
      </NotificationProvider>
        
      </>
    );
  }

  return (
    <>
      <p>Otra pestaña está activa. Esta pestaña se desactivará.</p>
      <button onClick={handleReload}>Recargar página</button>
    </>
  );
};

export default App;
