import { cerrarSesion } from "./Core/Core";




const Logout = ()  => {


    cerrarSesion();
   

}



export default Logout;