
import { __app, __fecha } from '../../../../../utils/myApp';


import React from 'react';
import EliminarPago from '../EliminarPago';
import { fetchUSer } from '../../../../../Core/fetchLocalStoreData';

const PagosDirectos = ({ PAGOS, DOCUMENTO, recargarDocumento }) => {
    const user = fetchUSer();

    const ELIMINARPAGO = (user.PERMISOS.PermitirEliminarPago) ? user.PERMISOS.PermitirEliminarPago : 0;
    const TURNO_ACTUAL = (user.TURNO.id) ? user.TURNO.id : 0;
    const ESTADO_TURNO = (user.TURNO.estado) ? user.TURNO.estado : 0;
    const FECHA_HOY = __app.getFecha("dia");

    return (
        <>
            {PAGOS.map((item, key) => {

                
                const FECHA_PAGO = item.CREADO.split(" ")[0];

                const puedeEliminar =
                    ELIMINARPAGO == 1 &&
                    (
                        (item.turno_id == TURNO_ACTUAL && ESTADO_TURNO == 1 && FECHA_PAGO == FECHA_HOY) ||
                        (item.turno_id == 0 && FECHA_PAGO == FECHA_HOY)
                    );

                return (

                    
                    (item.estado == 1 ) && <>

                        <tr key={key}>
                            <td>{item.id}</td>
                            <td>{__fecha.formatoFecha({ $fecha: item.CREADO })}</td>
                            <td>{item.nombre_user}</td>
                            <td>{item.forma_pagos_nombre}</td>
                            <td>{item.banco_nombre}</td>
                            <td>{item.estacione_nombre}</td>
                            <td>#{item.turno_id}</td>
                            <td>  <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal({ total: item.total, color: true, icono: false, numerosPositivos: true }) }}></span> </td>
                            <td>

                                {puedeEliminar && <EliminarPago pago={item.id} recargarDocumento={recargarDocumento} />}
                            </td>
                        </tr>

                    </>
                );
            })}

        </>
    );
};

export default PagosDirectos;