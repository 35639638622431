import React, { useEffect } from 'react';
import { useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import { campos_lista_documento , configuracionesLocales } from '../../Core/VariablesGlobales'; 
import { GetNombre_firebaseLastKeyClientes, GetNombre_firebaseLastKeyObjetos, GetNombre_firebaseLastKeyProducto, setLocalStore } from '../../Core/fetchLocalStoreData';
import CamposConfiguracionLocal from './Componentes/CamposConfiguracionLocal';
import { deleteDB, listaNegra } from '../Document/controlador';
import { NotificationManager } from 'react-notifications';




const  CamposPuntoVenta = (props) =>  {

    const [ {user , localconfig }  , dispatch ] = useStateValue();

    const estadoApp = user.CONFIGURACIONES_TABLE.estado_aplicacion == 1 ? 1:null;

    const agregardatos = (event,infoTr) => {

        /*
        const estraerInfoTr = (KeysPermitidas) => {
            return   campos_lista_documento.label.filter( (Infolabel) => KeysPermitidas.includes(Infolabel.clave))   ;
        }
        */


  
        let newlist = null;
        let config = {...user.CONFIGURACIONES};
        
        if (event.target.checked) {

            newlist =  [...user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA , infoTr];
            
  
        } else {
            newlist = user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA.filter((lista) => lista.clave !=  infoTr.clave  );
    
        }
        console.log(newlist)
        
       let copy  = {...user , CONFIGURACIONES : {...user.CONFIGURACIONES , CAMPOS_A_MOSTRAR_LISTA : newlist  }  }; 
        
        dispatch ({
            type : 'SET_USER',
            user: copy ,
        })    
        setLocalStore("user" , copy );
        setLocalStore("user_localConfig" , {CAMPOS_A_MOSTRAR_LISTA : newlist} );
        
     
        
    }

    useEffect( () => {
        console.log(user)
    } , [user])
 
    const cambiarModoAplicacion = (val) => {

        let configguraciones_table = { ...user.CONFIGURACIONES_TABLE , estado_aplicacion : val};
     

 

        configguraciones_table = {...user ,CONFIGURACIONES_TABLE :  configguraciones_table   };
      

 
        dispatch ({
            type : 'SET_USER',
            user: configguraciones_table,
        })   

        localStorage.setItem('user', JSON.stringify(configguraciones_table));
        
    }



    
    const arreglarSistema = () =>{

        /*** Initial State Activa TAB  */
        let initialActivaTab = {
            
            keyLocalStogere: "documente_0", 
            precio: 0, 
            tabKey: 0,
            color_documento : "principal",
            etiqueta_corta : "Factura POS"
        }

        let  listdocument = localStorage.getItem('listdocument');


        if( listdocument ){
            listdocument =  JSON.parse(listdocument);   
        }

        /** Iniciamos El Active tab en Local Store al Inicial  */
        localStorage.setItem('activeTab' , JSON.stringify(initialActivaTab) );

        listdocument.forEach( (data) => {
            localStorage.removeItem(data.keyLocalStogere);
        } )
     
    }


    const eliminarBaseDeDatos = () => {

        deleteDB();
        NotificationManager.success("Base de datos locales eliminadas");

        
    }

    return (
        <div>
            <h2>Campos Mostrar</h2>

            <div>
              
                {campos_lista_documento.label.map(( campo , index ) => 
                    <div key={index} className="form-check form-switch">
                        <input onChange={(e) => agregardatos(e,campo)} defaultChecked={user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA.findIndex( (data) => data.clave == campo.clave  ) >= 0 } className="form-check-input" type="checkbox" role="switch" id={campo.clave + "flexSwitchCheckDefault"} />
                       
                        <label className="form-check-label" htmlFor={campo.clave +"flexSwitchCheckDefault"}>
                            {estadoApp && <p>{campo.label}</p> } 
                            {!estadoApp && <p>{campo.clave}  <span className='text-helper'>{campo.ayuda}</span> </p> } 
                        </label>
                    </div>
                )}
           </div>

           Modo de Aplicacion: { user.CONFIGURACIONES_TABLE.estado_aplicacion == 1 && <span onClick={ () =>  cambiarModoAplicacion(2) } className='badge bg-success'>Producción</span>  || <span onClick={() => cambiarModoAplicacion(1) } className='badge bg-warning'> En Pruebas</span> }
            <br/>
           Resetear Ventas Actuales : <button onClick={arreglarSistema} className='btn btn-warning' >Resetear Pestaña</button>
           <br/>
           Resetear Configuracion Global (BORRA LOS DOCUMENTOS EN CACHE ) : <button onClick={listaNegra} className='btn btn-danger' >Resetear todo</button>     


            Elimina las base de Datos Locales : <button onClick={eliminarBaseDeDatos} className='btn btn-danger' >REINICIAR  BASES DE DATOS LOCALES </button>     
            <CamposConfiguracionLocal />

            
        </div>
    );
}

export default CamposPuntoVenta;