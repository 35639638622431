import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select'
import { extraerSoloImpuestoTotal, FiltrarImpuestosByOperacion, obtenerValorAntesDeImpuestos, redondearAlMultiploDe100, sumarImporteImpuesto } from '../../../Core/MatematicasGlobales';
import InputValidation from './InputValidation';
import { __app } from '../../../utils/myApp';

const PrecioImpuestos = ({user , formData , setFormData , btnbmitLoad , formDataValidations}) => {

    
    const impuestos = (user && user.DATABASE && user.DATABASE.impuestos)  ? user.DATABASE.impuestos : null;

    
    const [totalImpuesto , SetTotalImpuesto ] = useState();


    const [totalImporte , SetTotalImporte ] = useState();


    
    const REDONDEAR_PRECIOS =  user.CONFIGURACIONES_TABLE?.redondear_precios ?? 0;


    const handleTaxSelection = (selectedOptions) => {
             
        setFormData((prevData) => (
            {
            ...prevData,
            IMPUESTOS_RAIZ : selectedOptions 
            }
        ));
    };

    
    const precioFinalRef = useRef(null);

    const [totalFinal , SettotalFinal ] = useState(); 

    useEffect( () => {
        
        let a = extraerSoloImpuestoTotal(formData.PRECIO_PRINCIPAL ,   FiltrarImpuestosByOperacion (1 , formData.IMPUESTOS_RAIZ) );
        
        SetTotalImpuesto(a); 
        

        SetTotalImporte(   sumarImporteImpuesto( FiltrarImpuestosByOperacion (1 , formData.IMPUESTOS_RAIZ))    )
                        
        let totalFinall = redondearAlMultiploDe100(parseFloat(formData.PRECIO_PRINCIPAL) + a , REDONDEAR_PRECIOS  ) ;
        
        precioFinalRef.current.value = totalFinall;

        SettotalFinal( totalFinall )
   

    }  , [formData] )


    const cambiarPrecioFinal = (e) => {
        
        let totalBruto = obtenerValorAntesDeImpuestos( e.target.value , formData.IMPUESTOS_RAIZ )
        totalBruto =  totalBruto;
        setFormData((prevData) => ({ ...prevData, PRECIO_PRINCIPAL :totalBruto }));   
    }

    const cambioEvento = (event) => {

        if (event.key === 'Enter') {
           
            event.preventDefault();
            cambiarPrecioFinal(event);
            return false;
        }
       
    }

    return (
        <div className=' bg-light border p-3'>
          

            {!impuestos && <p>No hay impuestos! Desactiva esta Opcion. </p>}
            

            {impuestos && <>
                
                <InputValidation  formData={formData}  label={"Precio de Venta"}  formDataValidations={formDataValidations}   autoComplete='off' btnbmitLoad={btnbmitLoad} type="decimal" id="PRECIO_PRINCIPAL" className="form-control" nameForm="PRECIO_PRINCIPAL" placeholder="Precio sin impuestos"  />   

                

                <div className="form-group mb-3 mt-3">
                    <div className="col">
                        <label htmlFor=""><b>Impuestos</b></label>
                        <Select 
                        isMulti 
                        name="compuesto_producto_id"
                        getOptionLabel ={(impuestos)=>impuestos.nombre}
                        getOptionValue ={(impuestos)=>impuestos.id}
                        options={impuestos} 
                        onChange={handleTaxSelection}
                        value={formData.IMPUESTOS_RAIZ} // Establecer las opciones seleccionadas por defecto
                        placeholder="Seleccione los impuestos" 
                        />
                    </div>
                </div>
                <p className='text-primary'> Porcentaje  :  {__app.formato_decimal({total:totalImporte})} % </p>
                <p className='text-primary'> Impuestos :  ${__app.formato_decimal({total:totalImpuesto})} </p>
               
      

                <div className="form-group mb-3">
                    <label htmlFor=""><b>Precio de Venta con impuestos.</b></label>
                    <input  name="off"  onKeyPress={ (e) => cambioEvento(e) } onBlur={(e) => cambiarPrecioFinal(e) }  type="decimal"  ref={precioFinalRef} className='form-control' />
                </div>
             </>}
        </div>
    );
};

export default PrecioImpuestos;