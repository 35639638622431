import React, { useEffect, useState } from 'react';
import Add from './Add/Add';
import { NotificationManager } from 'react-notifications';
import { eliminarTarifa, extraerTarifas } from './Core/Controlador';
import Alerta from '../../../Core/Html/Alerta';
import { __app } from '../../../utils/myApp';

const Tarifas = () => {
    const [item, SetItem] = useState();
    const [tarifas, setTarifas] = useState([]);
    const [cargando, setCargando] = useState(true);

    useEffect(() => {
        setTarifas([]);
        cargarTarifas();
    }, [])

    const cargarTarifas = async () => {
        setCargando(true);
        try {
            const data = await extraerTarifas();
            setTarifas(data);
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar tarifas:", error);
        } finally {
            setCargando(false);
        }
    }

    const hadleEditar = (item) => {
        SetItem(item)
    }

    const auxHeader = () => {
        return Array.from({ length: 12 }, (_, i) => <th key={i}>aux{i + 1}</th>);
    };

    const auxTd = (tarifa) => {
        return Array.from({ length: 12 }, (_, i) => (
            <td key={i}>{__app.rellenarVacios(tarifa[`aux${i + 1}`])}</td>
        ));
    };
    
    const hadleEliminar = async (id) => {
        try{
            const respuesta = await eliminarTarifa(id);
            if (respuesta.respuesta == 1) {
                NotificationManager.success(respuesta.mensaje, 'Eliminado con exito');
                cargarTarifas();
            } else {
                NotificationManager.error(respuesta.mensaje, 'Error');
            }
        }
        catch(error){
            console.error("Error en la petición:", error);
            NotificationManager.error('Error al eliminar la tarifa', 'Error');
        }
    }


    return (
        <div className="card shadow rounded-4 border-0 p-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-4">
                <div>
                    <h3 className="text-dark fw-bold mb-0">🏷️ Lista de Tarifas</h3>
                    <p className="text-muted mb-0">Gestiona y visualiza el listado de tarifas registrados</p>
                </div>

                <div className="d-flex flex-column flex-md-row gap-2">
                    <Add item={item} SetItem={SetItem} cargarTarifas={cargarTarifas} />
                </div>
            </div>


            <div className=" table-responsive">

                {cargando ? (
                    <p>Cargando tarifas...</p>
                ) : tarifas.length === 0 ? (
                    <Alerta tipo="warning" title="¡Ops! No hay informacion." mensaje="No hay tarifas de parqueadero registradas en este momento." />
                ) : (
                    <>
                        <table className="table table-hover align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Algoritmo</th>
                                    <th>Nombre</th>
                                    {auxHeader()}
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tarifas.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.nombre_categoria}</td>
                                        <td>{item.algoritmos}</td>
                                        <td>{item.nombre}</td>
                                        {auxTd(item)}
                                        <td className='text-nowrap'>
                                            <button onClick={() => hadleEditar(item)} className="btn p-2 btn-subtle-secondary btn-sm m-1">
                                                <i className="fa-solid fa-pen fa-lg me-1 text-warning" />Editar
                                            </button>

                                            <button onClick={() => hadleEliminar(item.id)} className="btn p-2 btn-subtle-danger btn-sm m-1">
                                                <i class="fa-solid fa-trash-can fa-lg me-1"></i>Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </div>
    );
};

export default Tarifas;