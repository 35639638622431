import React , {useState} from 'react';

import PropTypes from 'prop-types';

import { useContext } from 'react';

import {enviarPeticion,changeServidor} from  "../../Core/ActionDatabase";

import {  fetchEmpresa , fetchUSer, setLocalStore , getLocalstore  } from "../../Core/fetchLocalStoreData";
import {  servidores  } from "../../Core/VariablesGlobales";
import { actionType } from '../../context/reducer';
import { useStateValue } from '../../context/StateProvider';
import { campos_lista_documento } from '../../Core/VariablesGlobales';


/*** ESTILOS */
import './css/index.css';
import { HttpGetEmpresa } from './css/Core/Core';
import { CargarConfiguracion  } from './Core/login';


function MyLogin(props) {

  


    const initialEmpresa =  fetchEmpresa();

    const initialUSer = fetchUSer();


    const [ empresa , setEmpres ] = useState(initialEmpresa);

    const [ userLocal , setUserLocal ] = useState(initialUSer);

   
    const [ {user , localconfig }  , dispatch ] = useStateValue();
    

    const [ nit , setNit ] = useState("");
    
    const [ statusResponse , SetstatusResponse  ] = useState(null);

    const [ estadocarga  , setEstadocarga] = useState(0);


    const [ formuser , Setformuser ] = useState({
        user : "",
        pass : "",
        estacione_id : null,
        recordar : true
    })


    

    const [ recordar , setRecordar] = useState(true);


    const estilos = {
        "background": "",
        "minHeight": "300px",
    }

    const caja = {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "minHeight": "300px",
    }


    const HandleBuscarNit = (e) => {
        e.preventDefault();
        SetstatusResponse(null);
        if (nit.length < 2 ){
            SetstatusResponse("Escribe un nit valido para iniciar.");
            return false;
        }
        extraerDatos2();
       
        return false;
    }

    const extraerDatos2  = async ( ) => {
        
        const respuestaHttp = await HttpGetEmpresa(nit);
        console.log(respuestaHttp)
        if(respuestaHttp.status) {
    
            SetstatusResponse(respuestaHttp.mensaje);
    
            if(respuestaHttp.data){
                setEmpres(respuestaHttp.data);
                changeServidor(respuestaHttp.data.nit);
                SetstatusResponse(null)
            }
    
        }else{
            SetstatusResponse("Error, No hay conexión con el Servidor");
        }

    }

    const handleCambiarEmpresa = () => {
    
        localStorage.removeItem('__Ah#d7aWF___');
        setEmpres(null);
        SetstatusResponse(null);
     
    }

    const hadleIninisiarSession = (e) => {
        e.preventDefault();

        console.log(formuser.estacione_id)
        if( !formuser.pass || !formuser.user   ){
            alert("Llene los datos para continuar")
            return false;
        }

        if(!formuser.estacione_id){
            alert("Seleccione el punto de Venta");
            return false;
        }
        iniciarSession();
    }


    const handleChangeFormUSer = (e) => {

        if (e.target.name){
            Setformuser({
                ...formuser,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handleRecordar = () => {
        
        Setformuser({
            ...formuser,
            recordar: !recordar,
        });

        setRecordar(!recordar) ;
        
    }


    
    const iniciarSession = async () => {
        
        let options = {
            body : formuser
        };

        if( ! empresa.nit ){
            alert("No hay Nit no podemos continuar");
            return false;
        }
        SetstatusResponse(null);
        setEstadocarga(1);

        const respuesta = await enviarPeticion("users/iniciar_session", empresa.nit  , options , true , null , {
            reladNit : true
        } );


        
        if(respuesta.status) {

            let cargarConfiguraicionInicial = CargarConfiguracion();

            setEstadocarga(0);
            SetstatusResponse(respuesta.respuesta)
            if ( respuesta.status === 5  ){
                
                
                console.log(cargarConfiguraicionInicial);
                respuesta.data = { ...respuesta.data ,
                    
                    ...cargarConfiguraicionInicial
                }

                


                localStorage.setItem('user', JSON.stringify(respuesta.data));            
               
                setUserLocal(respuesta.data);
               
                dispatch ({
                    type : actionType.SET_USER,
                    user: respuesta.data,
                })   
                


                const licenciap7v1_key = "licenciap7v1_Ak852ajkakj";
                // Obtener el valor de PHP
                const nitUsuario = empresa.nit;

                // Combinar ambas variables
                localStorage.setItem(licenciap7v1_key , nitUsuario);
                localStorage.removeItem("licenciap7v1_documentos");
                    
              
            }   

        }else{
            setEstadocarga(0);
            SetstatusResponse("Error, No hay conexión con el Servidor");
        }


    }



    const handleClickCerrarSesion = ()  => {

        localStorage.removeItem('user');
        setUserLocal(null);

    }

    const [showSelect , setShoSelect] = useState(false);
    const cambiarservidor = () => {

    }

    const handlechangeServidor =  (event ) => {
 
        setShoSelect(!showSelect)

        let data = {

            global : {

                servidor : event.target.value

            }
        }
        
        localStorage.setItem('Df45G' , JSON.stringify(data) )
        window.location.reload();
    }








    const precargardatos =  () => {
        categoriaProductos();
    }

    const  categoriaProductos = async() => {
        const HttpResponse = await enviarPeticion("productos/get_tabla_aux_productos");
        if(HttpResponse.respuesta == 1 ){            
            setLocalStore("Listcategorias" ,HttpResponse.data.grupos );
        }
    }

    
    return (       
        <div className="login-page bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                    <h3 className="mb-3 texto"> Inisiar Sesión  </h3>
                        <div className="bg-white shadow rounded">
                            <div className="row">
                                <div className="col-md-7 pe-0">
                                    <div className="form-left h-100 py-5 px-5">
                                        
                                        
                                        {
                                            !userLocal && 
                                        

                                            <>
                                                { empresa  &&  
                                                    <form onChange={handleChangeFormUSer} onSubmit={hadleIninisiarSession}  style={estilos} className="row ">
                                                            
                                                            <div className=" mb-3" style={{"maxWidth": "540px"}}>
                                                                <div className="row g-0">
                                                                    <div className="col-md-4 d-flex justify-content-center">
                                                                        <img src={empresa.LOGO} className="img-fluid rounded-start" alt="..." />
                                                                    </div>
                                                                    <div className="col-md-8 d-flex justify-content-center ">
                                                                        <div className="card-body">
                                                                            <h5 className="card-title d-flex justify-content-center">{ empresa.nombre }</h5>
                                                                            <p className="card-text d-flex justify-content-center"><small className="text-muted">NIT { empresa.nit}</small></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    

                                                            <div className="col-12 mt-5">
                                                                <label>Usuario<span className="text-danger">*</span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-text"><i className="fa-solid fa-user"></i></div>
                                                                    <input onChange={ () => {}} value={formuser.user} name='user' type="text" className="form-control" placeholder="Ingrese Usuario" />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mt-3">
                                                                <label>Contraseña<span className="text-danger">*</span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-text"><i className="fa-solid fa-lock"></i></div>
                                                                    <input onChange={ () => {}} value={formuser.pass} name='pass' type="password" className="form-control" placeholder="Ingrese  Contraseña" />
                                                                </div>
                                                            </div>

                                                            {
                                                                (empresa.estaciones && empresa.estaciones.length > 0) &&

                                                                <div className="col-12 mt-3">
                                                                    <label>Punto de Venta <span className="text-danger">*</span></label>
                                                                    <div className="input-group">
                                                                        <select onChange={ () => {}} name='estacione_id'  value={formuser.estacione_id} className='form-control'>
                                                                            <option>--- Selecione --- </option>
                                                                            { empresa.estaciones.map( (data , index ) => 
                                                                                <option key={index} value={data.id}  >{data.nombre}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                ||

                                                                <div className='alert alert-danger mt-3'>
                                                                    
                                                                    No hay punto de venta creado...
                                                                </div>
                                                            }
                                                            


                                                            <div className="col-sm-6 ">
                                                                <div className="form-check">
                                                                    <input  className="form-check-input" type="checkbox" id="inlineFormCheck"   onChange={ handleRecordar }  defaultChecked={recordar}  />
                                                                    <label className="form-check-label" htmlFor="inlineFormCheck"  >Recordar estos datos</label>
                                                                </div>
                                                            </div>

                                                        { statusResponse && <div 
                                                                        className="mt-3 alert alert-warning d-flex align-items-center" role="alert">
                                                            
                                                                <div dangerouslySetInnerHTML={{__html: statusResponse}} > 
                                                                
                                                                </div>
                                                            </div>
                                                            }
                                                    
                                                            <div className="col-12">
                                                            
                                                                { !estadocarga && <button  type="submit" className="btn btn-principal px-4 float-end mt-4 btn-lg">Entrar</button>}
                                                                { estadocarga && <button   className="btn  btn-principal px-4 float-end mt-4 btn-lg" disabled >Espere por favor...</button>}
                                                                <span rol="button"   type="button" onClick={ handleCambiarEmpresa  } className="px-4 text-primary float-end mt-5 btn-lg link">No es tu empresa?</span>
                                                            </div>
                                                    </form>
                                                    }   


                                                    {

                                                        !empresa && 

                                                        <form  onSubmit={HandleBuscarNit} style={caja}   className="row g-4">
                                                            
                                                            <div className="col-12">
                                                                <label>NIT de su empresa  <span className="text-muted" style={{"fontSize":"11px"}} >Sin Digito de verificación</span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-text"><i className="fa-sharp fa-solid fa-building"></i></div>
                                                                    <input onChange={ (e) => setNit(e.target.value)} value={nit} type="text" className="form-control" placeholder="Ingrese NIT " />
                                                                </div>
                                                              
                                                                { statusResponse && 
                                                                    <div className="mt-3 alert alert-warning d-flex align-items-center" role="alert">    
                                                                        { statusResponse}
                                                                    </div>
                                                                }
                                                            </div>
                                                                
                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-principal px-4 float-end mt-4 btn-lg">Entrar</button>
                                                            </div>
                                                        </form>

                                                    }

                                            </>
                                        }    


                                        {userLocal && 
                                            <>
                                               <h6>Hola <b>{userLocal.USER.nombre}</b> Ya te encuentras Autenticado en nuestro sistema.</h6>
                                               
                                                <div className='mt-4'>
                                                    <button onClick={handleClickCerrarSesion} type="button" className="m-2 btn btn-outline-secondary" >Cerrar Sessión</button>
                                                    <button className='btn btn-principal' > Continuar </button> 
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className="col-md-5 ps-0 d-none d-md-block  ">
                                    <div className=" form-right h-100 bg-principal text-white text-center pt-5 ">
                                    <i className="fa-solid fa-feather"></i>
                                        <h2 className="fs-1">PLUMA 7 </h2>
                                        <p>Punto de Venta</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p className="   mt-3"> 
                            <span className=" ">Servidor  : {localconfig.global.servidor} </span> 
                            
                            {!showSelect && <span onClick={() => setShoSelect(!showSelect)} style={{textDecoration:"underline", color:"blue" , cursor : "pointer"}}>cambiar</span> }
                            {showSelect && <select onChange={ handlechangeServidor } value={localconfig.global.servidor} >
                                {servidores.map( (data,key) =>
                                    <option key={key} value={data}>{data}</option>
                                )}
                            </select>}
                            <span className="text-end float-end text-secondary"> SOFTWARE EXPERTOS S.A.S  © Copyright 2022 </span>           
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default MyLogin;