
import {  off, onValue  } from "firebase/database";
import { NotificationManager } from 'react-notifications';
import { recentPostsRef } from "./FireBase";
import { fetchNombreDispositivo, getLocalstore, GetNombre_firebaseLastKeyClientes, GetNombre_firebaseLastKeyObjetos, GetNombre_firebaseLastKeyProducto } from "./fetchLocalStoreData";
import audioFile from '../recursos/sincronizado.mp3';
import { SincronizarNivelBajo, verificarVehiculos } from "./Sincronizacion";
import { __app } from "../utils/myApp";


let isPageFocused = true;

// Detectar cuando la página gana el foco
window.addEventListener('focus', () => {
  isPageFocused = true;
});

// Detectar cuando la página pierde el foco
window.addEventListener('blur', () => {
  isPageFocused = false;
})










export const ObservadorDB = () => {
    
    onValue(recentPostsRef(), (snapshot) => {
        
        const obj = snapshot.val();  
        console.log(obj)
           
    })
}

//*** Apaga el observador Activo  */
export const  offObservador  = () => {
    off(recentPostsRef())
 
}

export const   inicializarOservador =  (dispatch , KeyObservadores) => {
    

        onValue(recentPostsRef(), async (snapshot) => {
            
            if (!isPageFocused) {
                console.log('La página no tiene el foco, no se realizará ninguna acción.');

            }

            console.log("Observador instanciado" )
            const obj = snapshot.val();


            console.log(obj?.dispositivo + fetchNombreDispositivo() )
            
          


            /***** Este Observador solo aplica para Productos */
            if(obj.tabla == "productos"){
                
                let lastIdactual = getLocalstore(GetNombre_firebaseLastKeyProducto());
                if ( ! (Number(lastIdactual) >= 0) ){
                    NotificationManager.warning("No tenemos key para Sincronizar Productos");
                    /// Se recomienda iniciar una sincronizacion aqui  
                    return false;
                }

                if(obj?.dispositivo == fetchNombreDispositivo()){
                    NotificationManager.info("Actualizacion ignorada");
                    return false;
                }


                if(  obj.lastId  >  lastIdactual && obj?.dispositivo != fetchNombreDispositivo() ) {
                    
                   
                   
                    let httpRespuesta = await  SincronizarNivelBajo({obj ,lastKey : lastIdactual }); 
                    console.log(httpRespuesta);
                   
                    if(httpRespuesta.respuesta ==  1 ){
                        reproducirSonido();
                        dispatch ({
                            type : 'SET_KeyObservadores',
                            KeyObservadores : {...KeyObservadores , PRODUCTOS : __app.numeroRandom()},
                        })    
                        
                    }
                }   
            }










            /***** Este Observador solo aplica para Productos */
            if(obj.tabla == "objetos"){
  
                
                let lastIdactual = getLocalstore(GetNombre_firebaseLastKeyObjetos());
                if ( ! (Number(lastIdactual) >= 0) ){
                    NotificationManager.warning("No tenemos key para Sincronizar Objetos");
                    /// Se recomienda iniciar una sincronizacion aqui  
                    return false;
                }

                if(obj?.dispositivo == fetchNombreDispositivo()){
                    NotificationManager.info("Actualizacion ignorada");
                    return false;
                }


                if(  obj.lastId  >  lastIdactual && obj?.dispositivo != fetchNombreDispositivo() ) {
                    
                   
                   
                    let httpRespuesta = await  SincronizarNivelBajo({obj ,lastKey : lastIdactual }); 
                    console.log(httpRespuesta);
                   
                    if(httpRespuesta.respuesta ==  1 ){
                        reproducirSonido();
                        dispatch ({
                            type : 'SET_KeyObservadores',
                            KeyObservadores : {...KeyObservadores , OBJETOS : __app.numeroRandom()},
                        })    
                        
                    }
                }   
            }








            
            /***** Este Observador solo aplica para Productos */
            if(obj.tabla == "terceros"){

                console.log("Disparador Activo por terceros")
               
                let lastIdactual = getLocalstore(GetNombre_firebaseLastKeyClientes());

                
                if ( ! (Number(lastIdactual) >= 0) ){
                    NotificationManager.warning("No tenemos key para Sincronizar los Clientes"); 
                    /// Se recomienda iniciar una sincronizacion aqui                 
                    return false;
                }


                console.log(  Number(lastIdactual) +  ">" + obj.lastId )     

                if(obj?.dispositivo == fetchNombreDispositivo()){
                    NotificationManager.info("Actualizacion ignorada");
                    return false;
                }


                if(  obj.lastId  >  lastIdactual && obj?.dispositivo != fetchNombreDispositivo() ) {
                    
                   
                   
                    let httpRespuesta = await  SincronizarNivelBajo({obj ,lastKey : lastIdactual }); 
                    console.log(httpRespuesta);
                   
                    if(httpRespuesta.respuesta ==  1 ){
                        reproducirSonido();
                        dispatch ({
                            type : 'SET_KeyObservadores',
                            KeyObservadores : {...KeyObservadores , TERCEROS : __app.numeroRandom()},
                        })    
                        
                    }
                }   
            }
                  
        })
  
    }



   

    
    const reproducirSonido = async () => {    
        try {
            const audio = new Audio(audioFile);
            await audio.play();
        } catch (error) {
            console.error('Error al reproducir el audio:', error);
        }
    };

