import React, { useEffect, useState } from 'react';
import { setLocalStore } from "../../../Core/fetchLocalStoreData";

const FiltroBuscador = ({ filtroBusquedaRipi, SetfiltroBusquedaRipi }) => {
    const opcionesIniciales = ['nombre'];

    const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState(
        filtroBusquedaRipi && filtroBusquedaRipi.length > 0 ? filtroBusquedaRipi : opcionesIniciales
    );

    // Actualizar almacenamiento local cuando opcionesSeleccionadas cambia
    useEffect(() => {
        setLocalStore("ConfiguracionBuscadorRipiPrincipal", opcionesSeleccionadas);
    }, [opcionesSeleccionadas]);

    const toggleOpcionSeleccionada = (opcion) => {
        let copy = [...opcionesSeleccionadas];
        const index = copy.indexOf(opcion);

        if (index === -1) {
            copy.push(opcion);
        } else {
            // Evitar deselección de todas las opciones
            if (copy.length === 1) {
                return; // No se puede deseleccionar la única opción restante
            }
            copy.splice(index, 1);
        }

        setOpcionesSeleccionadas(copy);
        SetfiltroBusquedaRipi(copy);
    };

    return (
        // ANDRESSSS
        // <div className='me-5 text-center'>
        //     Buscar por:
        //     {['nombre', 'id_2', 'referencia'].map(opcion => (
        //         <React.Fragment key={opcion}>
        //             <input
        //                 checked={opcionesSeleccionadas.includes(opcion)}
        //                 className='ms-2 click'
        //                 id={`Aias${opcion}`}
        //                 type="checkbox"
        //                 onChange={() => toggleOpcionSeleccionada(opcion)}
        //             />
        //             <label className='click' htmlFor={`Aias${opcion}`}>
        //                 {opcion === 'id_2' ?
        //                     'Codigo' :
        //                     opcion
        //                 }
        //             </label>
        //         </React.Fragment>
        //     ))}
        // </div>

        // YULIIII
        // <div className="col-auto">
        //     <div className="bg-dark bg-opacity-25 rounded py-1 px-2 d-flex  text-white">
        //         <span className="small  me-2">Buscar:</span>
        //         {['nombre', 'id_2', 'referencia'].map(opcion => (
        //             <div key={opcion} className="form-check form-check-inline mb-0 d-flex align-items-center ">
        //                 <input
        //                     checked={opcionesSeleccionadas.includes(opcion)}
        //                     className="form-check-input me-1"
        //                     id={`Aias${opcion}`}
        //                     type="checkbox"
        //                     onChange={() => toggleOpcionSeleccionada(opcion)}
        //                 />
        //                 <label className="form-check-label small text-white" htmlFor={`Aias${opcion}`} style={{ marginTop: "1px", cursor: "pointer" }}>
        //                     {opcion === 'id_2' ? 'Codigo' : opcion}
        //                 </label>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div className="col-auto">
            <div className="bg-dark bg-opacity-25 rounded py-0 px-2 d-flex flex-wrap align-items-center text-white">
                <span className="small me-2">Buscar:</span>
                <div className="d-flex flex-wrap">
                    {['nombre', 'id_2', 'referencia'].map(opcion => (
                        <div key={opcion} className="form-check form-check-inline me-2">
                            <input
                                checked={opcionesSeleccionadas.includes(opcion)}
                                className="form-check-input form-check-input-sm me-1"
                                id={`Aias${opcion}`}
                                type="checkbox"
                                onChange={() => toggleOpcionSeleccionada(opcion)}
                            />
                            <label className="form-check-label small text-white" htmlFor={`Aias${opcion}`} style={{ cursor: "pointer" }}>
                                {opcion === 'id_2' ? 'Codigo' : opcion}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FiltroBuscador;
