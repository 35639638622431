import React from "react";

const PlacaInput = ({ value, onChange }) => {
    return (
        <div className="card border-dark border-3 rounded-3 shadow position-relative m-1" style={{ backgroundColor: '#F9D71C', width: 160, height: 60, borderRadius: 8, textAlign: 'center', display: 'flex',alignItems: 'center', justifyContent: 'center' }} >
            <div className="position-absolute" style={{ top: 4, left: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
            <div className="position-absolute" style={{ top: 4, right: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
            <div className="position-absolute" style={{ bottom: 4, left: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
            <div className="position-absolute" style={{ bottom: 4, right: 4, width: 5, height: 5, backgroundColor: '#333', borderRadius: '50%' }}></div>
            <input type="text" className="form-control border-0 text-center fw-bold" placeholder="PLACA" value={value} onChange={onChange} required  style={{ backgroundColor: "transparent", fontSize: 20, letterSpacing: 2,textAlign: "center", outline: "none", boxShadow: "none", width: "100%", textTransform: "uppercase" }} />
        </div>
    );
};

export default PlacaInput;
