import React from 'react';
import { fetchEmpresa, fetchUSer, getLocalstore } from '../../../../../Core/fetchLocalStoreData';
import { disenoFactura } from '../../../../../Core/VariablesGlobales';
import { __app, __fecha } from '../../../../../utils/myApp';
import { __pluma7 } from '../../../../../Core/pluma7';
import { Numpositivo } from '../../../../../Core/MatematicasGlobales';
import Tercero from '../../subcomponents/Tercero';
import Footer from '../../subcomponents/Footer';

const Tirrilla = ({ infoPago, estilos }) => {
    const user = fetchUSer();
    const empresa = fetchEmpresa();


    const suma = (a, b) => a + Numpositivo(b.total);

    const sumarTotal = infoPago.pagos.reduce(suma, 0);

    if (!estilos) {
        let estiloGuardado = getLocalstore('disenoFacturaInitialstate');
        if (estiloGuardado) {
            estilos = estiloGuardado;
        } else {
            estilos = disenoFactura;
        }
    }
    return (

        <div className='EasyPrintAnd' id='EasyPrintAndTirilla'>
            <div style={estilos.Tirillacontenedor} >



                <div style={estilos.TirillaSubcontenedor}>

                    {/* EMPRESA */}
                    <div className="TirillaTextCenter">
                        {(user.CONFIGURACIONES_TABLE.mostrar_logo_factura && user.CONFIGURACIONES_TABLE.mostrar_logo_factura == 1) &&
                            <>
                                <img src={empresa.LOGO} className="img-fluid rounded-start" alt="..." />
                            </>
                        }
                        <h4 className="TirillaM0">{user.ESTACION.nombre}</h4>
                        <b className="Tirilla11px"> NIT : {user.ESTACION.nit}</b>
                        <p style={{ marginTop: '-2px' }}>
                            {user.ESTACION.telefono} <br />
                            {user.ESTACION.direccion}<br />
                            {user.ESTACION.municipio} <br />
                        </p>
                    </div>

                    <span style={{ fontSize: '12px' }}><b>Fecha de Impresión</b></span><br />
                    <span style={{ fontSize: '12px' }}>{__fecha.formatoFecha({ $fecha: __app.getFecha() })}</span>
                    
                    {/* TERCERO */}
                    <Tercero infoPago={infoPago} />


                    {/* TABLAA */}
                    <div style={{ marginBottom: '5px' }} className="TirillaTextCenter">
                        <b>Documentos Abonados</b>
                    </div>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ borderBottom: '2px solid #ccc', fontSize: '11px' }}>
                                <th style={{ padding: '4px', textAlign: 'left', fontWeight: 'bolder' }}>#Pago</th>
                                <th style={{ padding: '4px', textAlign: 'left', fontWeight: 'bolder' }}>Num Factura</th>
                                <th style={{ padding: '4px', textAlign: 'left', fontWeight: 'bolder' }}>Comprobante</th>
                                <th style={{ padding: '4px', textAlign: 'right', fontWeight: 'bolder' }}>Abonado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {infoPago.pagos.map((pag, index) => (
                                <tr key={index} style={{ borderBottom: '1px solid #ccc', fontSize: '12px' }}>
                                    <td style={{ padding: '4px' }}>{pag.id}</td>
                                    <td style={{ padding: '4px', fontWeight: 'bold' }}>
                                        {__pluma7.numeroFactura({ numero: pag.ct_numero_factura, id: pag.ct_documento_id })}
                                    </td>
                                    <td style={{ padding: '4px', fontSize: '11px' }}>
                                        {pag.etiqueta_nombre}
                                    </td>
                                    <td style={{ padding: '4px', textAlign: 'right' }}>
                                        $ {__app.formato_decimal({ total: Numpositivo(pag.total), numerosPositivos: true })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="TirillaTextCenter Ti-mt block">
                        <p><b>Total Pagado</b>     $ {__app.formato_decimal({ total: Numpositivo(sumarTotal), numerosPositivos: true })}</p>

                        {infoPago.saldo_pendiente && infoPago.saldo_pendiente.pendiente > 0 && (
                            <p><b>Nuevo saldo Pendiente</b> $ {__app.formato_decimal({ total: Numpositivo(infoPago.saldo_pendiente.pendiente), numerosPositivos: true })}</p>
                        )}
                    </div>

                    <Footer estilos={estilos} />
                </div>
            </div>

        </div>

    );
};

export default Tirrilla;