
import React, { useEffect, useState } from 'react';
import DatosPersonales from './DatosPersonales';
import Menu from './Menu';
import { __app } from '../../../utils/myApp';
import { extraerInfoCliente } from '../Core/Core';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import AgregarPago from '../../Pagos/AgregarPago/AgregarPago';
import { useContextLocalValue } from './Core/ContextTercero';
import ImprimirPagos from '../../Pagos/ImprimirPago/Pages';



const TerceroPerfil2 = ({id , modalClose}) => {


    
    const [ {info} , dispatch ]  = useContextLocalValue();

    

    let visita = __app.numeroFecha();


    const initialInfoCliente = {
        ct_persona : null,
        Ventacobrar: null,
        Ventapagar: null,
        Compracobrar: null,
        Comprapagar: null,
        liquidacion_empleado: null,
        SaldoAdelantadoVenta : null,
        SaldoAdelantadoCompra : null,
    }

    const [infoCliente , setInfoCliente ] = useState(initialInfoCliente);

    /**
     * Muestro o Oculta el formulario de recibo de pago.
     * Se utiliza cuando se realiza un abono global o un abono directo sobre un documento.
     */
    const [ShowModalRecibo , SetShowModalRecibo ] = useState(false);
    

    /**
     * Guarda la informacion que es recibida cuando se realiza un pago
     * 
     */
    const [httpResponserInfoPago , SetHttpResponserInfoPago ] = useState(false);
    
    useEffect(() => {
        //extraerTodo();
    }, [])

 


    useEffect(() => {
        TotalDeudaVentas();
    }, [info.actualizarTotalVentas])



    useEffect(() => {
        TotalDeudaCompras();
    }, [info.actualizarTotalCompras])

    

    useEffect(() => {
        SaldosAdelantados();
    }, [info.actualizarTotalSaldoAdelantado])

    



    const extraerInfo = async () => {

       
        setInfoCliente( prev  =>  {
            return {...prev , ct_persona :  null }
        });

      
        let data = await extraerInfoCliente(id , 'view_terceros'); 
        if(data !== -1){
            setInfoCliente( prev  =>  {
                return {...prev , ct_persona : data.ct_persona}
            });
        }

    }   





    /*** Cuentas por Pagar y cuentas por conbrar
     * 
     *  POR MODULO DE VENTAS
     * 
     */
    const TotalDeudaVentas = async () => {
        setInfoCliente( prev  =>  {
            return {...prev ,
                Ventacobrar : null,
                Ventapagar : null
                }
        });
        let data = await extraerInfoCliente(id , 'view_terceros_deudas_ventas'); 
        if(data !== -1){    
            setInfoCliente( prev  =>  {
                return {...prev ,
                    Ventacobrar : data.Ventacobrar,
                    Ventapagar : data.Ventapagar
                    }
            });
        }

    }  



    const TotalDeudaCompras = async () => {
        setInfoCliente( prev  =>  {
            return {...prev ,
                Compracobrar : null,
                Comprapagar : null
                }
        });
        let data = await extraerInfoCliente(id , 'view_terceros_deudas_compras'); 
        if(data !== -1){    
            setInfoCliente( prev  =>  {
                return {...prev ,
                    Compracobrar : data.Compracobrar,
                    Comprapagar : data.Comprapagar
                    }
            });
        }

    }  





    /*****Cargar Saldo Adelantado */
    const SaldosAdelantados  = async () => {
       
        setInfoCliente( prev  =>  {
            return {...prev ,
                SaldoAdelantadoVenta : null,
                }
        });


        let data = await extraerInfoCliente(id , 'cargar_saldos_adelantados'); 


        console.log(data);


        

        /*********** SALDO A FAVOR */
        if(data.SaldoAdelantado && data.SaldoAdelantado.length > 0 ){    
            

            const totalDisponible = data.SaldoAdelantado.reduce(
                (total, item) => total + parseFloat(item.disponible || 0), 
                0
            )
            setInfoCliente( prev  =>  {
                return {...prev ,
                    SaldoAdelantadoVenta :totalDisponible,
                    }
            });
        }



         /*********** SALDO A FAVOR */
         if(data.infoCuentasxPagarVenta && data.infoCuentasxPagarVenta.length > 0 ){    
            

            const totalDisponible = data.infoCuentasxPagarVenta.reduce(
                (total, item) => total + parseFloat(item.disponible || 0), 
                0
            )
            setInfoCliente( prev  =>  {
                return {...prev ,
                    Ventapagar :totalDisponible,
                    }
            });
        }


    }  

    const extraerTodo = () => {
   
        extraerInfo()
        .then(() => TotalDeudaVentas())
        .then(() => TotalDeudaCompras());
    }

    const [ abonoGlobal, SetabonoGlobal ] = useState(null);

    const abonalGlobalFinalizar = () => {
        SetabonoGlobal(null)
    }

    const [configuracionesPago , SetconfiguracionesPago ]  = useState();



    const mostrarFormAbonoGlobal = (data1) => {
       
        let data = {
            document : null , 
            categoria_pago_id : 1 , 
            tipo_de_pago : 1 , 
            ct_persona_id : id,
        
        }      
        SetconfiguracionesPago({...data , ...data1}); 
        SetabonoGlobal(1)      
    }


    useEffect(() => {
        extraerInfo();
    }, [info.actualizarPerfil ])




    const actualizarDatos  = (aux) => {
        console.log("entree")
        SetHttpResponserInfoPago(aux)
        let operacion = '';
       
        if(aux.master_id == 1 ){
            operacion = "actualizarTotalVentas"
        }
        if(aux.master_id == 2 ){
            operacion = "actualizarTotalCompras"
        }

        if(aux.tipo_de_pago == 2 ){
            operacion = "actualizarTotalSaldoAdelantado"
        }
        
        dispatch({
            type : 'SET_DATA',
            data : {...info  , 
                [operacion] : __app.numeroFecha() ,
                menu :  __app.numeroFecha() ,
            }, 
        })
        setTimeout(()=>{  
            SetShowModalRecibo(true);

        },1000)
    }

    return (
        <div>
            {/* */}
          
            <div className="align-items-center justify-content-between g-3 mb-3 row">
                <div className="col-md-auto col-12">
                    <h4 className="mb-0 text-body-secondary">Perfil de Tercero </h4>
                        
                   
                   
                </div>
                <div className="col-md-auto col-12">
                    <div class="d-flex gap-2">
                        <div className="flex-1 d-md-none">
                            <button type="button" className="px-3 text-body-tertiary btn btn-phoenix-secondary">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="svg-inline--fa fa-bars " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"></svg>
                            </button>
                        </div>
                    </div>
                    {modalClose && <button className=' btn float-end btn-dark' onClick={modalClose}>Cerrar Perfil</button>}
                </div>
            
            </div>



            <div className="g-0 g-md-4 g-xl-6 row">

                <div className="d-none d-md-block  col-xl-3  col-md-4">
                
                    <DatosPersonales mostrarFormAbonoGlobal={mostrarFormAbonoGlobal} infoTercero={infoCliente}  />
              
                </div>
                
                <div className="col-xl-9 col-md-8">
                
                    <div className="lead-details-container">
          
                       <Menu id={id} actualizarDatos={actualizarDatos} infoTercero={infoCliente} mostrarFormAbonoGlobal={mostrarFormAbonoGlobal} />
                </div>
            </div>
            </div>

            {/***
            * Alerta de Inventarios  <button onClick={() => vertificadorDeInventario()}>adas</button>
            */}
            
            <StandarModal   keyboard={true}  headerShow={true} size="md"   titulo="Registrar Abono Global"  desactivar={ abonalGlobalFinalizar }  mostrar={ abonoGlobal }  > 
                <AgregarPago pagoExito={actualizarDatos}  configuraciones={configuracionesPago}  cancelar={ abonalGlobalFinalizar } />
            </StandarModal>
            
            <StandarModal   keyboard={true}  headerShow={false} size="md"   titulo="Registrar Abono Global"  desactivar={ () => SetShowModalRecibo(false) }  mostrar={ ShowModalRecibo }  > 
                <ImprimirPagos infoPago={httpResponserInfoPago}  desactivarModal={ () => SetShowModalRecibo(false) }  />
            </StandarModal>
          
        </div>
    );
};

export default TerceroPerfil2;