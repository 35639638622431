import React, { useEffect, useState } from 'react';
import Add from './Add/Add';
import { fetchUSer } from '../../Core/fetchLocalStoreData';
import { eliminarMensualidad, extraerMensualidades } from './Core/Controlador';
import { NotificationManager } from 'react-notifications';
import { __app, __fecha } from '../../utils/myApp';
import Placa from '../../Core/Html/Placa';
import Alerta from '../../Core/Html/Alerta';
import StandarModal from '../Document/PartesDocument/StandarModal';
import View from './View/View';
import { __pluma7 } from '../../Core/pluma7';
import { showAlert, showConfirm } from '../Layaut/LayautDefault';
import { Link } from 'react-router-dom';
import MensualidadBar from './Lista/MensualidadBar';


const Mensualidades = () => {
    const [item, SetItem] = useState();
    const [totalMensulidades, SetTotalMensulidades] = useState();
    const [selectItem, SetSelectItem] = useState();
    const [cargando, setCargando] = useState(true);
    const [mensualidad, setMensualidades] = useState([]);
    const [viewMensualidadModal, setViewMensualidad] = useState(false);
    const user = fetchUSer();     // PERMISOS DEL USUARIO
    
    const abrirModalView = (item) => {
        SetSelectItem(item);
        setViewMensualidad(true);
    };

    const handleCerrarModales = () => {
        setViewMensualidad(false);
        SetItem(null);

    };
    const MODO_PARQUEDAERO = (user.CONFIGURACIONES_TABLE.MODO_parqueadero) ? user.CONFIGURACIONES_TABLE.MODO_parqueadero : 0;


    useEffect(() => {
        setMensualidades([]);
        cargarMensualidades();
    }, [])

    const cargarMensualidades = async () => {
        setCargando(true);
        try {
            const data = await extraerMensualidades();
            console.log(data)
            const totalSum = data.reduce((acc, item) => acc + parseFloat(item.total) || 0, 0);
            SetTotalMensulidades(totalSum)
            setMensualidades(data);
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar mensualidades:", error);
        } finally {
            setCargando(false);
        }
    }

    const hadleEditar = (item) => {
        SetItem(item)
    }

    const hadleEliminar = async (id) => {

        showConfirm(
            "⚠️ ¡ALTO! ⚠️\n\n¿Estás seguro de que deseas continuar con esta acción?\n\nEsta operación no se puede deshacer.",
            () => respuestaSi(id)
        );
    }

    const respuestaSi = async (id) => {
        try {
            const respuesta = await eliminarMensualidad(id);
            if (respuesta.respuesta == 1) {
                showAlert("✅" + respuesta.mensaje + " \n\n⚠️Señor Usuario NO se eliminaron las facturas relacionas a este contrato #" + id);
                cargarMensualidades();
            } else {
                NotificationManager.error(respuesta.mensaje, 'Error');
            }
        } catch (error) {
            console.error("Error en la petición:", error);
            NotificationManager.error('Error al eliminar', 'Error');
        }
    };


/*** Estrae la clave del json dado */
    const getJson = (json , key ) => {
    
        try {
            return JSON.parse(json)[key];
        } catch (error) {
            console.log(error)
        }
    }

    
    return (
        <div className="card shadow rounded-4 border-0 p-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3 mb-4">
                <div>
                    <h3 className="text-dark fw-bold mb-0">💵 Lista de Mensualidades </h3>
                    <p className="text-muted mb-0">Gestiona y visualiza el listado de las mensualidades registradas</p>
                </div>

                <div className="d-flex flex-column flex-md-row gap-2">
                    <Add item={item} SetItem={SetItem} cargarMensualidades={cargarMensualidades} />
                </div>
            </div>


            <div className=" table-responsive">

                {cargando ? (
                    <p>Cargando mensualidades...</p>
                ) : mensualidad.length === 0 ? (
                    <Alerta tipo="warning" title="¡Ops! No hay informacion." mensaje="No hay mensualidades registradas en este momento." />
                ) : (
                    <>
                        <table className="table table-hover align-middle mb-0">
                            <thead>
                                <tr className='table-success'>
                                    <th>Contrato</th>
                                    <th>Fecha de inicio</th>
                                    <th>Tercero</th>
                                    {MODO_PARQUEDAERO == 1 && <>
                                        <th>Vehiculo</th>
                                    </>}
                                    <th>Barra</th>
                                    <th>Fact. Pendientes</th>
                                    <th>Total</th>
                                    <th>Estado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mensualidad.map((item, index) => (
                                    <tr key={index} >
                                        <td># {item.id}</td>
                                        <td>{__fecha.formatoFecha({ $fecha: item.fecha_inicio })}</td>
                                        <td>

                                           
                                            <Link className="card bg-primary-subtle ps-2 fs-9 m-1 d-flex flex-column text-decoration-none  card-hover" title='Clic para ver el perfil de este tercero' style={{ maxWidth: '200px' }} to={`/TerceroPerfil/${__app.envolver(item.MensuContrato_ct_persona_id)}`}  >
                                                <div className="d-flex align-items-center">
                                                    <i className="fa-solid fa-fingerprint me-2"></i>
                                                    <span>{ getJson(item.JSON_CAMPOS_JOINS , 'p_i') }</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <i className="fa-solid fa-user me-2"></i>
                                                    <span>{__app.rellenarVacios( getJson(item.JSON_CAMPOS_JOINS , 'p_nb')  )}</span>
                                                </div>
                                              
                                            </Link>



                                        </td>

                                        {MODO_PARQUEDAERO == 1 && <>
                                            <td>
                                                <Link  title='Clic para ver el perfil de esta placa' className='text-decoration-none' to={`/perfil_vehiculos/${__app.envolver(item.Objeto_identificacion)}/${__app.envolver(item.objeto_id)}`}  >
                                                    <Placa placa={item.Objeto_identificacion} />
                                                </Link>
                                            </td>
                                        </>}

                                     
                                        <td> <MensualidadBar fecha1={item.fecha1}  fecha2={item.fecha2} /> </td>
                                        <th  className={`text-center p-2 text-dark bg-opacity-25 ${
                                            item.numero_facturas_pendientes == 0 ? 'bg-success' :
                                            item.numero_facturas_pendientes == 1 ? 'bg-warning' :
                                            'bg-danger' 
                                            }`}>
                                            <h3> {__app.formato_decimal({ total: item.numero_facturas_pendientes })}</h3>        
                                        </th>
                                        <td>{__app.formato_decimal({ total: item.total })}</td>   

                                        <td>{__pluma7.estadoMensualidad(item.MensuContrato_contrato_estado)}</td>
                                        <td className='text-nowrap'>
                                            <button onClick={() => abrirModalView(item)} className="btn p-2 btn-subtle-primary btn-sm m-1">
                                                <i className="fa-solid fa-eye fa-lg me-1" />Ver
                                            </button>
                                            <div class="dropdown d-inline-block">
                                                <button p7-data-bs-toggle="tooltip" data-bs-placement="top" title="Opciones" className="btn btn-sm btn-subtle-secondary" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                    <i class="fa-solid fa-ellipsis" style={{ fontSize: 15 }}></i>
                                                </button>
                                                <div className="dropdown-menu  shadow-lg p-2"  >
                                                    <button onClick={() => hadleEditar(item)} className="dropdown-item btn p-2 btn-subtle-secondary btn-sm" style={{ textAlign: 'left' }}>
                                                        <i className="fa-solid fa-pen fa-lg me-1 text-warning" />Editar
                                                    </button>
                                                    <div className="dropdown-divider"></div>
                                                    <button onClick={() => hadleEliminar(item.id)} className="dropdown-item btn p-2 btn-subtle-danger btn-sm" style={{ textAlign: 'left' }}>
                                                        <i class="fa-solid fa-trash-can fa-lg me-1"></i>Eliminar
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    {MODO_PARQUEDAERO == 1 && <>
                                        <th>Vehiculo</th>
                                    </>}
                                    <td>{__app.formato_decimal({ total:totalMensulidades })}</td>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                        </table>
                    </>
                )}
            </div>
            {/* VER CONTRATO */}
            <StandarModal size="xl" titulo={"Ver contrato 👀"} desactivar={handleCerrarModales} mostrar={viewMensualidadModal}>
                <View desactivar={handleCerrarModales} item={selectItem} SetItem={SetItem} cargarMensualidades={cargarMensualidades} />
            </StandarModal>
        </div>
    );
};

export default Mensualidades;