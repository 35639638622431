import React, { useState } from 'react';
import { useEffect } from 'react';

import {HelpHttp} from "../../../../Core/https";
import Buscaripi  from '../../../../utils/ripi';
import { useStateValue } from '../../../../context/StateProvider';
import {fetchEmpresa} from "../../../../Core/fetchLocalStoreData";
import { ExtraerTabla , reloadDatabases } from '../../../../Core/Dixie';
import { NotificationManager} from 'react-notifications';
import StandarModal from '../StandarModal';
import TerceroView from './TerceroView';
import TerceroPerfil from '../../../Terceros/TerceroView/TerceroPerfil';
import { HttplistarClientes } from '../../../Terceros/Core/Core';
import BotonAdd from '../../../Terceros/Add/Boton/BotonAdd';
let  Ripi_prIndex  = null;
const  BuscarTercero = ({seleccionarTercero , ocultarform}) =>  {



    const [ {user , localconfig } ] = useStateValue();
    const ConfigEmpresa = fetchEmpresa();

    const [ terceros , setTerceros ] = useState([]);
    const [ tercerosfiltro , setTercerosfiltro ] = useState([]);
    const [ buscar , setBuscar ] = useState('');


    const  [ showFormPerfilTercero , SetShowFormPerfilTercero  ]  = useState(null);

    const handleChange = (e) => {
        console.log(e)
    }



    useEffect( () => {
    


        /** Prepara los datos para el ripi.js  */
        const renderizar = (data) => {
            Ripi_prIndex  = new Buscaripi(data);
            setTercerosfiltro([]);
            Ripi_prIndex.fullResultado = true;
            setTerceros(data)
        }

        const getDatos = async () => {

            let tercerosDixie = await   ExtraerTabla('terceros'  );
            if(tercerosDixie.length > 0){
                console.log("...loading from localstore");
                renderizar(tercerosDixie);
            }else{
               alert("No encotramos Tercero")
            }
            
        }
        
        getDatos();

    } , [])


    const  [cargando , setCargando] = useState(null);

























    const handleChangeTercero = (tercero) => {
        setTercerosfiltro([]);
        seleccionarTercero(tercero);
    }

    const focusInput = () => {
        ocultarform(false);
    }



    useEffect( () => {
        
        if (Ripi_prIndex){
    
            if(buscar.length > 0 ){



                let data = Ripi_prIndex.start(buscar , ["identificacion" , "nombrecomercial" , "razonSocial"   ] );
                
                
                setTercerosfiltro(data);
            
            }else{

                setTercerosfiltro([]);
            }
           
        }
        
    }  , [buscar ]);

    const actualizarTodo = ( ) => {
        
       alert("inabilitado")
    }

    const [idTercero , SetidTercero] = useState();
    const verPerfil = (id) => {
        SetShowFormPerfilTercero(true);
        SetidTercero(id)

    }


     /*** Item que actualmente se esta Editando  */
    const [itemEdit, SetItemEdit] = useState();

     /***  Editar Item */
    const hadleEditar = (item) => {
        SetItemEdit(item);
    };


    const successFunction =  (tercero) => {
        handleChangeTercero(tercero)
    }

    return (
        <div className='margin-5'>
            
            <div style={{display: 'flex'}}>
                <input   onFocus={focusInput}  autoComplete='off' value={buscar} onChange={ (e) => setBuscar(e.target.value) } className="min-width-400 form-control  form-control-sm" type="search" placeholder="Buscar un Tercero" id="example-search-input" />
             
                
            </div>
            <BotonAdd itemEdit={itemEdit} textoBoton={"Editar"} showBotonAdd={false} successFunction={successFunction} />


            <ul className="list-group resultado-clientes" style={{'zIndex': 1031}}>
                {tercerosfiltro.map((tercero, index) => (
                    <li
                        key={index}
                       
                        className="list-group-item d-flex justify-content-between align-items-center"
                        style={{ cursor: 'pointer', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                    >
                        <div  title='Click para Seleccionar' onClick={() => handleChangeTercero(tercero)} >
                            <strong>{tercero.razonSocial}</strong><br />
                            <small>NIT: {tercero.identificacion} - Tel: {tercero.telefono}</small>
                        </div>
                        
                        <button onClick={() => verPerfil(tercero.id)} title='Conoce el Perfil Completo de este Tercero' className='btn btn-link'>
                            <i className="fas fa-user"></i> Ver Perfil
                        </button>

                        <button onClick={() => hadleEditar(tercero)} title='Conoce el Perfil Completo de este Tercero' className='btn btn-link'>
                           editar
                        </button>
                        
                 
                    </li>
                ))}
            </ul>


            {/***
             * Formulario Guardad Documento
            */}
            <StandarModal keyboard={true} headerShow={false} size="xl"  titulo="Guardar Documento"  desactivar={() => SetShowFormPerfilTercero(false) } mostrar={showFormPerfilTercero}  > 
                <TerceroPerfil id_directo={idTercero} modalClose={() => SetShowFormPerfilTercero(false) } />
            </StandarModal> 



        </div>

       
    );
}

export default BuscarTercero;