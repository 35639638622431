

import React, { useState } from 'react';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import VerDocumento from './VerDocumento';

const BotonVerDocumento = ({id}) => {

    const [facturaActual, SetfacturaActual] = useState(null);

    const [verDocumentoModal , SetverDocumentoModal  ] = useState();

    const handleVerFactura = (CtDocumento_id) =>{
        SetfacturaActual(CtDocumento_id);
        SetverDocumentoModal(true);
    };

    
    const handleCerrarModal = () => {
        SetverDocumentoModal(false);
        SetfacturaActual(null);
    };

    return (
    
        
        <>
        
            <span onClick={() => handleVerFactura(id)} className=''> Ver  <i className="fa-solid fa-eye"></i> </span>
        
            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura." desactivar={handleCerrarModal} mostrar={verDocumentoModal}> 
                <VerDocumento document_id={facturaActual} desactivar={handleCerrarModal}  />
            </StandarModal>
        </>
    );
};

export default BotonVerDocumento;