import { __app } from "../utils/myApp";

export const  calcularUtilidad = (precio, costo, formula = 1 ) =>  {

    precio = parseInt(precio);
    costo = parseInt(costo);
    if(formula === 1){
        
        // Calcular la ganancia
        let ganancia = precio - costo;

        // Calcular la utilidad en porcentaje
        let utilidadPorcentaje = (ganancia / costo) * 100;
        if (utilidadPorcentaje === Infinity){
            utilidadPorcentaje = 100;
        }
        return { ganancia, utilidadPorcentaje };

    }
    
    let utilidadPorcentaje = ((precio - costo) / precio) * 100;
    let ganancia = precio - costo; 
    return { ganancia, utilidadPorcentaje };
   
}

export const calcularPrecio = (costo, utilidadPorcentaje , formula = 1 ) => {
    
    costo = parseInt(costo);
    utilidadPorcentaje = parseInt(utilidadPorcentaje);

    
    if(formula===1){
        const precio =  ((costo * utilidadPorcentaje) /  100) + costo;
      
        return Math.round(precio,2);
    }

    let precio = costo / (1 - (utilidadPorcentaje / 100));
    return Math.round(precio,2);;
}


export const SumarImpuestosPrecio = (precio_entrada  , impuesto    ) => {


    if(impuesto && impuesto.length > 0){


        let precioFinal = parseFloat(precio_entrada);
        let porcentajeTotalImpuestos = 0;
       
        impuesto.forEach(impuesto => {
            if( impuesto.tipo_tributo_id == 1){
                const porcentajeAumento = parseFloat(impuesto.importe);
                porcentajeTotalImpuestos += porcentajeAumento;
            }
        });

        precioFinal += precioFinal * (porcentajeTotalImpuestos / 100);
        return precioFinal;
    }
    return precio_entrada; 
}


/// Devido a que los impuestos pueden aplicar solo a compras. solo a ventas, 
// o a ambas, entonces debemos filtrar ese producto de acuerdo a la operacion en la que estamos
/***
 *  
 * Parametros Recibidos : 
 * 
 *  OPERACION_DOCUMENTO_ID : La operacion actual del documeno
 *  IMPUESTO RAIZ          : Los impuesto que pertenecen al documento 
 *  
 */
export const FiltrarImpuestosByOperacion = (OPERACION_DOCUMENTO_ID  = 1 , IMPUESTOS ) => {
 
    const operacionID = Number(OPERACION_DOCUMENTO_ID);


    let CopyImpuesto = __app.clonarObjeto(IMPUESTOS);

    if(operacionID > 0 && CopyImpuesto.length > 0  ){
        
        CopyImpuesto = CopyImpuesto.filter(impuesto => {

            if(!impuesto.ct_operacione_id){
                return impuesto;
            }
            
            // Operaciones permitidas para este contexto
        
            let OperacionesPermitidas = impuesto.ct_operacione_id.split(',').map(Number);




            // Verificar si la operación actual está permitida y coincide
            let data =  OperacionesPermitidas.includes(operacionID);
        

            if(data){
                return impuesto
            }
        });

        

    }
   
    
  
    return CopyImpuesto;


}

export const obtenerValorAntesDeImpuestos = (precioBruto, impuestos) => {
    // Verificar si hay impuestos definidos

    precioBruto = parseFloat(precioBruto);
    if (impuestos && impuestos.length > 0) {

        let impuesto_descontar = 0;
        // Iterar sobre cada impuesto en la lista
        for (let impuesto of impuestos) {
            // Verificar si el impuesto es del tipo porcentaje
            if (impuesto.tipo_tributo_id == 1) {
                // Convertir el porcentaje de impuesto a un decimal
                const decimalImpuesto = parseFloat(impuesto.importe) / 100;
                // Sumar 1 al decimal obtenido
                const factor = 1 + decimalImpuesto;
                // Dividir el precio bruto por el factor obtenido
            
               impuesto_descontar += precioBruto/ factor
            }
        }


        return  parseFloat(impuesto_descontar.toFixed(2)); ;
    }
    // Devolver el valor antes de impuestos
    return precioBruto;
}


export const extraerSoloImpuestoTotal = (Precio   , impuesto) => {
    
    if( (parseFloat( Precio) > 0 )  ){
        
    }else{
        return 0;
    }

    if(impuesto && impuesto.length > 0){ 
        let porcentajeTotalImpuestos = 0;
        impuesto.forEach(imp => {
            if( imp.tipo_tributo_id == 1){
                const porcentajeAumento = parseFloat(imp.importe);
                porcentajeTotalImpuestos += porcentajeAumento;
            }
        });    
      
        let data = (parseFloat(Precio) *  porcentajeTotalImpuestos ) / 100 ;
        return  parseFloat(data.toFixed(2));
    }
    return  0; 
}


export const redondearAlMultiploDe100 = (numero , REDONDEAR_PRECIOS  )   => {

    if ( REDONDEAR_PRECIOS == 0){
        return parseFloat(numero);
    }

    
   // return Math.round(numero / 100) * 100;
    

   if  (numero > 3000){
        return Math.round(numero / 100) * 100;
   }
     // Redondear hacia arriba
     let numeroRedondeado = Math.round(numero);

     // Ajustar al múltiplo de 10 más cercano
     if (numeroRedondeado % 10 !== 0) {
         numeroRedondeado = Math.round(numeroRedondeado / 10) * 10;
     }

     return numeroRedondeado;
}


export const redondearAlMultiploDe100FINAL_SUBTOTAL_3 = (numero ,  decimal = 0)   => {

    numero = Math.round(numero);

    if(decimal == 1 ){

        return numero;
    }

    if( numero > 1000 ){
        return Math.round(numero / 100) * 100;
    }

    return numero;
     
     
 }

export const sumarImporteImpuesto = (impuesto) => {

    let TotalImporte = 0

    if(impuesto && impuesto.length > 0){ 
 
        impuesto.forEach(imp => {
            if( imp.tipo_tributo_id == 1){
                const porcentajeAumento = parseFloat(imp.importe);
                TotalImporte += porcentajeAumento;
            }
        });    
      
       
        return TotalImporte.toFixed(2)
    }
    return  0; 
}






/**
 * Calcula el porcentaje basado en un precio del 100% y otro valor (puede ser el descuento).
 */
export const CalcularPorcentaje = (descuento , precio100 ) => {
    // Asegúrate de que ambos valores se conviertan a número
    const precioOriginal = Numpositivo(precio100);
    const descuentoValue = Numpositivo(descuento);
  
    // Calcula el porcentaje de descuento
   
    const porcentajeDescuento = (descuentoValue / precioOriginal) * 100;
    

    // Devuelve el porcentaje con dos decimales
    return porcentajeDescuento.toFixed(2);
  };

  export const Numpositivo = (num) => {
        if(!num) return 0;
        return  Math.abs(parseFloat(num)); // Devuelve 10
  }


  export const FN = (num) => {
   
    let num2 =  Number.parseFloat(num).toFixed(3);
    return parseFloat(num2);
}