

import React, { useEffect, useRef } from 'react';
import { __app } from '../../../../../utils/myApp';
const ListaPagosP1 = ({OcultarPagaCon , tipoCredito , SetTipoCredito , lisTipoCreditos , mostrarObcPagos , DispararkeyPress , handlePagacon , pagacon , formpagos , totalpagado  , totalCredito , TOTAL_A_PAGAR}) => {

    
    const inputEl = useRef(null);
    
    useEffect( () => {
        setTimeout(() => {
            inputEl.current.focus();
            }, 300);
    } , [] )
        
    return (
        <div>
            
            { mostrarObcPagos == 1 && <div>

                {OcultarPagaCon == 0 &&
                    <>
                     <div className='form-group'>
                            <label htmlFor="">Paga Con</label>
                            <input autoComplete='off' onKeyUpCapture={DispararkeyPress}  ref={inputEl}  onChange={handlePagacon} value={pagacon} name="pagacon" style={ {"height":" 150px" }} placeholder="$" className="fs-3 form-control text-center" />
                            </div>

                        <div>    
                        {formpagos[0]?.total &&  <p className='p-2  text-center'>  <span  className=' fs-6 text-center '>Devuelves </span>    {  pagacon - formpagos[0].total >= 0 && <span className='fs-3 text-success'> {  __app.formato_decimal( {total :  pagacon - formpagos[0].total }) }</span> }   </p> }
                        </div>
                    </>
                }
                <div className=''>

                    <p className=' badge badge-phoenix fs-8 d-block badge-phoenix-success  fs-5'>Total Pagado:   {totalpagado}  </p>
                    
                    { totalCredito  > 0 &&  <p className=' badge badge-phoenix fs-8 d-block badge-phoenix-danger  fs-5'>Credito :  ${ __app.formato_decimal({total : totalCredito}) }  </p>} 


                    { totalpagado - TOTAL_A_PAGAR  > 0 && <p  className='text-success' >Saldo a Favor:  { totalpagado - TOTAL_A_PAGAR  }   </p> } 

                    {
                        totalCredito  > 0   && 
                        <>
                            <label htmlFor="">Tipo de Credito:</label>
                            <select onChange={ (e) => SetTipoCredito(e.target.value)} name="banco" defaultValue={tipoCredito}  className="form-select" aria-label="Default select example">
                            
                        
                                {lisTipoCreditos.map(data => 
                                    
                                    <option value={data.id}>{data.nombre}</option>

                                )}
                            </select> 
                            <br/>
                        </>
                    } 

                </div>

                </div>}
        </div>
    );
};

export default ListaPagosP1;