import { NotificationManager } from "react-notifications";
import { enviarPeticion } from "../../../Core/ActionDatabase";

/** Esta funcion intenta extraer los clientes  desde indexDB, en caso tal no existe los trae directamente de la base de datos*/
export const extraerUsuarios = async () => {
   
    let httpresponse =   await enviarPeticion( "users/listaUsuarios" );
    if(httpresponse == -1){
        NotificationManager.error('Error'  , 'Error de internet');
        return -1;
    }else{
        return httpresponse.data
    }
    

}


export const version_punto_venta = async () =>{
    let httpresponse =  await enviarPeticion( "configuraciones/version_punto_venta" );
    if(httpresponse.respuesta && httpresponse.respuesta == 1 ){
        return httpresponse.version
    }else{
        return -1;
    }
}


export const  cerrarSesion = (redirect = true) => {
    localStorage.removeItem('user');
    if(redirect === true ){
        window.location.href ='/'
    }
   
}
