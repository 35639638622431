import React from 'react';
import {useNavigate} from "react-router-dom"
import {denominaciones} from './Controlador';
import { useState } from 'react';
import { __app } from '../../utils/myApp';
import { getLocalstore } from '../../Core/fetchLocalStoreData';
import {enviarPeticion} from "../../Core/ActionDatabase";
import {filtrarUserSendServer} from "../../Core/controlador";
import { useEffect } from 'react';
import { NotificationManager} from 'react-notifications';

import { actionType } from '../../context/reducer';
import { useStateValue } from '../../context/StateProvider';
import ViewCierre from './CierraParcial/ViewCierre/ViewCierre';
import CerrarTurno from './CerrarTurno/CerrarTurno';



const  TurnoCerrar = ({titulo ,cierreParcial=null}) =>  {

    const navigate = useNavigate();
    
    const [ {user , localconfig }  , dispatch ] = useStateValue();

    useEffect(() => {
        let user = getLocalstore("user");
        if (!user){   
            navigate('/error404');
        }
    }, [ ] )

    const [listaDenominaciones , setlistaDenominaciones ]  = useState( denominaciones())

    const [Total, setTotal] = useState(0);

    const [Base , setBase] = useState(0);

    const [total_seleccion , settotal_seleccion] = useState(0);

    const [load, setLoad] = useState(0);

    
    const [paso , setPaso]  = useState(1);

    const [infoCierreParcial , SetinfoCierreParcial] = useState();

    const updateDemo = (e , index ) => {
        e.preventDefault();

        
        let copi = [   ...listaDenominaciones  ];

        copi[ index ].cantidad = Number(e.target.value);
        
        copi[ index ].valor_total = copi[ index ].cantidad * copi[ index ].valor;
        

        setlistaDenominaciones( copi );


        let total = copi.reduce(function(sum, current) {
            if (current.sumar == 1 ){
                return sum + current.valor_total;
            }
            return sum;
        }, 0); // 43 + 32 + 21 = 96


        setTotal(total);

        resumarAyudaBase();

    }

    const sumarBase = (event ,index) => {
     
        let copi = [   ...listaDenominaciones  ];
        copi[ index ].ayudaBase = event.target.checked;
        setlistaDenominaciones( copi );

        resumarAyudaBase();
    }

    const resumarAyudaBase = () => {
        let copi = [   ...listaDenominaciones  ];
        let total = copi.reduce(function(sum, current) {
            if (current.ayudaBase){
                return sum + current.valor_total;
            }
            return sum;
        }, 0); // 43 + 32 + 21 = 96

        settotal_seleccion(total);
    }




    const finalizarTurno = async () => {
        setLoad(1);

        if(cierreParcial){

            return CierreParcial();
        }


        let userFiltrer = filtrarUserSendServer(getLocalstore("user"));
        
        let total_reportado = Total - Base;
        
        const requestResponse  = await enviarPeticion( "turnos/finalizar_turno/" , total_reportado  , {body : userFiltrer} ) 

        if ( requestResponse ){
        
            setLoad(0);
            console.log(requestResponse);
            if (requestResponse.status){

                if (requestResponse.data.respuesta_turno == 1 ){

                    cerrarTurnoLocal(requestResponse.data);

                }else if( requestResponse.data.respuesta_turno == 0){
                    
                    cerrarTurnoLocal(requestResponse.data);
                }

            }else{
                NotificationManager.error('No hay conexión con el servidor.  ', 'Deconectado' ); 
            }
        }

    } 


    const CierreParcial = async  () => {
        let userFiltrer = filtrarUserSendServer(getLocalstore("user"));
        
        let total_reportado = Total - Base;
        
        const requestResponse  =  await enviarPeticion( "turnos/cerrar_turno_parcial/" , total_reportado  , {body : userFiltrer} ) 

        if ( requestResponse ){
            setLoad(0);
            console.log(requestResponse);
            if (requestResponse.status){

                
                SetinfoCierreParcial(requestResponse.data.cierre_parcial);

            }else{
                NotificationManager.error('No hay conexión con el servidor.  ', 'Deconectado' ); 
            }
        }
    }








    const cerrarTurnoLocal = (respuesta) => {

        const { TURNO , ...Newuser } = user;

        let fiNewuser = {...Newuser , FINALIZAR_TURNO : respuesta }
        
        dispatch ({
            type : actionType.SET_USER,
            user: fiNewuser,
        })   

        
        localStorage.setItem('user', JSON.stringify(fiNewuser)); 
    }
    

    return (
        <div>

            {infoCierreParcial && <ViewCierre cierre={infoCierreParcial.slice(-1)[0]} />}   

          {!infoCierreParcial && <CerrarTurno 
            titulo={titulo}
            cierreParcial={cierreParcial}  
            user={user}  
            paso={paso} 
            listaDenominaciones={listaDenominaciones} 
            setPaso={setPaso}  
            finalizarTurno={finalizarTurno}  
            load={load}  
            updateDemo={updateDemo}  
            Total={Total}  
            Base={Base}  
            setBase={setBase}
            />}

           
        </div>
    );
}

export default TurnoCerrar;