import React, { useEffect, useState } from 'react';
import { actualizarMensualidad, listarProductosMensualidad, registrarMensualidad } from '../Core/Controlador';

import { listarCientesv2 } from '../../Terceros/Core/Core';
import { ExtraerTabla } from '../../../Core/Dixie';
import { __app } from '../../../utils/myApp';
import Select from '../../Documentos/Documentos/Componentes/Filtros/Filtros/Select';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import { NotificationManager } from 'react-notifications';

const Form = ({ mostrar, SetItem, item, textoBoton, editar, cargarMensualidades }) => {


    console.log("llega de editar", item);
    console.log(" editar", editar);
    const [cargando, setCargando] = useState(false);
    const [formData, setFormData] = useState({
        referencia: item?.referencia || '',
        fecha_inicio: item?.fecha_inicio || __app.getFecha('dia'),
        dias: item?.dias || '30',
        total: item?.total || '0',
        dias_de_antelacion: item?.dias_de_antelacion || '5',
        repetir_automaticamente: item?.repetir_automaticamente || '1',
        id: item?.id,
        ct_persona_id: item?.ct_persona_id || '',
        producto_id: item?.producto_id || '',
        objeto_id: item?.objeto_id || '',
        numero_meseses_repeticion: item?.numero_meseses_repeticion || '0',
    });
    const [datosFiltro, SetDatosfiltro] = useState({ terceros: [], vehiculos: [], productos: [] });
    const user = fetchUSer();

    const MODO_PARQUEDAERO = (user.CONFIGURACIONES_TABLE.MODO_parqueadero) ? user.CONFIGURACIONES_TABLE.MODO_parqueadero : 0;

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const terceros = await listarCientesv2();
            const productos = await listarProductosMensualidad();

            let vehiculos = [];

            if (MODO_PARQUEDAERO == 1) {
                vehiculos = await ExtraerTabla('objetos');
            }
            SetDatosfiltro({ terceros, vehiculos, productos });


        } catch (error) {
            console.error("Error:", error);
        }
    };

    const filtros = [
        {
            label: "Persona",
            name: "ct_persona_id",
            placeholder: "Seleccione una persona ",
            options: datosFiltro.terceros,
            ShowOptions: {
                nombre: 'razonSocial',
            }
        },

        {
            label: "Productos",
            name: "producto_id",
            placeholder: "Seleccione un Producto",
            options: datosFiltro.productos,
        },


        {
            label: "Vehiculos",
            name: "objeto_id",
            placeholder: "Seleccione un vehiculo",
            options: datosFiltro.vehiculos,
            ShowOptions: {
                nombre: 'identificacion',
            }
        }

    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Datos del formulario:", formData);

        if (!formData.ct_persona_id || !formData.producto_id) {
            NotificationManager.error('Por favor, seleccione un cliente y un producto', 'Error');
            return;
        }

        guadarMensualidad();
    }

    const guadarMensualidad = async () => {

        setCargando(true);
        try {
            let result;
            if (editar == 1) {
                result = await actualizarMensualidad(formData);
            } else {
                result = await registrarMensualidad(formData);
            }

            if (result.respuesta == 1) {
                SetItem(null);
                mostrar(false);
                NotificationManager.success(result.mensaje, 'Mensualidad');
                cargarMensualidades();
            }
        } catch (error) {
            console.error("Error al enviar el formulario", error);
            NotificationManager.error("Error al enviar el formulario", 'Error');
        }
        finally {
            setCargando(false);
        }
    }
    return (
        <div className="container mt-2">
            <h3 className="mb-2 text-center">✍️Formulario de Registro</h3>
            <form onSubmit={handleSubmit} className="rounded">
                <div className="row mb-3">
                    {filtros
                        .filter(filtro => filtro.options && filtro.options.length > 0)
                        .map((filtro, idx) => (
                            <div key={idx} className="col-md-6 mb-2">
                                <Select filtro={filtro} setFormData={setFormData} formData={formData} />
                            </div>
                        ))
                    }
                    <div className="col-md-6 mb-2">
                        <label htmlFor="referencia" className='form-label'>Referencia</label>
                        <input type="text" id='referencia' placeholder='Escribe una referencia' value={formData.referencia} onChange={(e) => setFormData({ ...formData, referencia: e.target.value })} className="form-control" />
                    </div>

                    <div className="col-md-6 col-lg-3  mb-2">
                        <label htmlFor="fecha_inicio" className='form-label'>Fecha de inicio</label>
                        <input type="date" id='fecha_inicio' className="form-control" value={formData.fecha_inicio} onChange={(e) => setFormData({ ...formData, fecha_inicio: e.target.value })} required />
                    </div>
                    <div className="col-md-6 col-lg-3 mb-2">
                        <label htmlFor="dias" className='form-label'>Dias</label>
                        <input type="number" id='dias' className="form-control" required value={formData.dias} onChange={(e) => setFormData({ ...formData, dias: e.target.value })} />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-2">
                        <label htmlFor="precio" className='form-label'>Precio Mensual</label>
                        <input type="number" id='precio' className="form-control" required value={formData.total} onChange={(e) => setFormData({ ...formData, total: e.target.value })} />
                    </div>

                    <div className="col-md-6 col-lg-3 mb-2">
                        <label htmlFor="dias_de_antelacion" className='form-label'>Dias de antelacion</label>
                        <input type="number" id='dias_de_antelacion' className="form-control" value={formData.dias_de_antelacion} onChange={(e) => setFormData({ ...formData, dias_de_antelacion: e.target.value })} />
                    </div>


                    <div className="row gap-0 col-md-6 mb-2">
                        <span>¿Quieres que se repita automáticamente?</span>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="d-flex align-items-center">
                                <input id="checkbox-1" type="radio" name="repetir_automaticamente" value="1" checked={formData.repetir_automaticamente == 1} onChange={(e) => setFormData({ ...formData, repetir_automaticamente: e.target.value })} />
                                <label htmlFor="checkbox-1">
                                    <span className="badge rounded-pill text-bg-success form-check-label ms-1"> Si </span>
                                </label>
                            </div>
                            <div className="d-flex align-items-center">
                                <input id="checkbox-0" type="radio" name="repetir_automaticamente" value="0" checked={formData.repetir_automaticamente == 0} onChange={(e) => setFormData({ ...formData, repetir_automaticamente: e.target.value })} />
                                <label htmlFor="checkbox-0">
                                    <span className="badge rounded-pill text-bg-danger form-check-label ms-1"> No </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='d-flex justify-content-center'>
                    <button type="submit" className="btn btn-primary" disabled={cargando}>
                        {cargando ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2"></span>
                                Espere...
                            </>
                        ) : (
                            textoBoton
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Form;