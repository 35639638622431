import React, { useEffect, useRef, useState } from 'react';
import { fetchEmpresa, fetchUSer } from '../../../../../Core/fetchLocalStoreData';
import { HttpGetEmpresa } from '../../../../user/css/Core/Core';
import ListarTipos from './ListarTipos';
import ListarOpereaciones from './ListarOpereaciones';

import ListarDocumentos from '../ListarDocumentos';
import ListarItemsSum from '../../../ListarItems/Pages';



const Pages = ({datosFiltro}) => {

    const user = fetchUSer();


    const [ empresa , SetEmpresa ] = useState(fetchEmpresa())

    
    const $btnFiltrar = useRef();


    useEffect(() => {
        
        if(!empresa.datos_precarga.comprobantes){
            getDatos();
        }else{
    
        }

   
    }, [])




    const  getDatos =  async () => {
       
        let hppresponse = await HttpGetEmpresa();
       
        if(hppresponse){
            SetEmpresa( fetchEmpresa() );
        }
    }
    

    const [modulo , setModulos] = useState();

    const [documentos , setDocumentos] = useState(null);

    const [itemssum , setItemssum] = useState(null);
    
    const handleClickSetMoudulo = (data) => {
        
        setModulos(data)
      
        console.log(data);
        if(data && data.id){
          
          // listarDocumentos(data.id);
        }else{

            setDocumentos(null);
        }
    }

    

    const ResultadoDatosfiltro = (data) => {
        console.log(data)
        setDocumentos(data);
    }

    const ResultadoDatosfiltroItems = (data) => {
        console.log(data)
        setItemssum(data);
    }

    
    

    return (
        <div>
            
            { !modulo  && <h1>Documentos</h1>    } 

            { !empresa.datos_precarga.comprobantes && <p className='alert alert-warning'>No hay comprobantes </p> }
         


            
            { (empresa.datos_precarga.comprobantes && !modulo) &&  <ListarOpereaciones accion={handleClickSetMoudulo}   operaciones={empresa.datos_precarga.comprobantes.operaciones} /> }
            

            {modulo && (
                <h3 className="fs-8 mt-3 d-flex align-items-center justify-content-between">
                    <span
                        onClick={() => handleClickSetMoudulo(null)}
                        className="btn btn-link px-2 text-body copy-code-btn d-flex align-items-center"
                    >
                        <i className="fa-solid fa-arrow-left me-1"></i> Atrás
                    </span>
                    <span className="mx-2">MODULO DE {modulo.nombre}</span>
                    <button 
                        ref={$btnFiltrar}
                        className="btn btn-sm btn-secondary d-flex align-items-center"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                    >
                        <i className="fa-solid fa-filter me-1"></i> Filtrar Información
                    </button>
                </h3>
            )}

            { modulo &&  <ListarTipos $btnFiltrar={$btnFiltrar} empresa={empresa} modulo={modulo} datosfiltro={datosFiltro}  ResultadoDatosfiltro={ResultadoDatosfiltro}  ResultadoDatosfiltroItems={ResultadoDatosfiltroItems}  /> }

            
            

            { modulo &&<div className='card p-1'>
                <ListarDocumentos  empresa={empresa} modulo={modulo} datosfiltro={datosFiltro} documentosaux={documentos}  /> 
            </div>}


            { modulo &&<div className='card p-4'>
              
                
                <ListarItemsSum  itemssum={itemssum}  /> 
            </div>}


            

                            
        </div>
    );
};

export default Pages;