export const actionType = {


    SET_USER : 'SET_USER',

    
    
    SET_ITEM : 'SET_ITEM',

    
    SET_CONFIG : 'SET_CONFIG',
}


const reducer = ( state , action ) => {
 
   
    // eslint-disable-next-line default-case
    switch(action.type){

       

        case actionType.SET_USER:
            return {
                ...state , 
                user : action.user
            };

        case actionType.SET_CONFIG:
                return {
                    ...state , 
                    infodatabase : action.infodatabase
                };
        case 'SET_KeyObservadores':
            return {
                ...state , 
                KeyObservadores : action.KeyObservadores
            };
                    
                    
        default :
            return state;

    }
}

export default reducer;