import React, { useEffect, useState } from 'react';
import {__app} from "../../../utils/myApp"
import CuerpoCtaPendientes from './CuerpoCtaPendientes';
function AcordionCuentasPendientes({cuentasPendientes , titulo, indexx, id ,  signo=null , operacion }) {



    let idAcordion = indexx + id  ;

    const [ totalPendiente ,  settotalPendiente ] = useState(0);

    const [ Tituloemergencia ,  setTituloemergencia ] = useState(null);


    const [ contador , setContador ] = useState(0); 

    const [ nombreTotal , setnombreTotal ] = useState("Pendiente"); 


    useEffect( () => {

        let sumaPositivos = 0;
        let sumaNegativos = 0;
        let contadorAux = 0;

        // Iterar sobre cada objeto en el array
        cuentasPendientes.forEach(objeto => {
            // Verificar si 'total' es un número positivo
            if (objeto.total > 0) {
                // Sumar a 'sumaPositivos'
                sumaPositivos += parseFloat(objeto.total);
                

                
                if(signo == "+"){

                    contadorAux++;
        
                }

             
            } else if (objeto.total < 0) {
                // Sumar a 'sumaNegativos'
                sumaNegativos += parseFloat(objeto.total);
               
                
                if(signo == "-"){

                    contadorAux++;
        
                }
            }


            
            
        });


        setContador(contadorAux );

        if(signo == "-"){

            settotalPendiente( sumaNegativos * -1 );

        }


        
        if(signo == "+"){

            settotalPendiente( sumaPositivos  );

        }



        /* Agregamos el tirulo de Emerjencia */

        if(operacion && operacion.id == 1 &&  signo == "-"){

            setTituloemergencia("OJO!! Revisar. Saldo a favor de clientes. ")
        }

        if(operacion && operacion.id == 1 &&  signo == "+"){

            setTituloemergencia(" CREDITOS A CLIENTES ")
        }


        if( signo ==  "null"){
            setContador(cuentasPendientes.length );
            setnombreTotal("Total");
        }

    } , [])

    

    if(totalPendiente == 0 && signo != "null"){
        return false;
    }



    return (
        
        <div className="accordion" id="accordionPanelsStayOpenExampleCxP">
        <div  className="accordion-item">
               
            <h2 className="accordion-header">
            
                <button  className={'bg-opacity-10  accordion-button collapsed bg-dark' } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + idAcordion } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            
                <span className={'fw-bold'}>  {titulo}  
                    {
                         Tituloemergencia && 

                          <>
                              <span className="text-danger">{Tituloemergencia}</span>
                                
                                {signo == "-" && <p className='fs-10 mt-2 ' style={{"fontSize" : "11px"}} >Tenga cuidado con este valor por que luego el cliente podra usarlo como medio de pago.</p> }
                          </>
                    }
                </span> 
                
                <span className={ 'bg-opacity-75  m-3 badge text-bg-primary '  }> {contador} </span>



                {totalPendiente > 0 &&  <small className={"d-inline-flex fw-bold  fs-6 px-2 py-1 fw-semibold  border  rounded-2"}>$ { __app.formato_decimal( {total : totalPendiente})} </small> } 

                </button>



            </h2>
            <div id={'panelsStayOpen-collapse' + idAcordion } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                <div className="accordion-body">
                    
                    <table className='table'>
                       <thead>
                       <tr>
                            <th>CoPago</th>
                            <th>Fecha</th>
                            <th>Cajero</th>
                            <th>Tercero</th>
                            <th>TipoCombro.</th>
                            <th>TipoCredito</th>
                            <th>Numero de Factura</th>
                            <th>{nombreTotal}</th>
                        </tr>
                       </thead>

                        <tbody>
                        {cuentasPendientes.map( (cuenta, index) => 

                            <>   
                            {  (cuenta.total > 0  && signo == "+") &&

                                <CuerpoCtaPendientes key={index} cuenta={cuenta} />
                              
                            }

                            { (cuenta.total < 0  && signo == "-") &&

                                <CuerpoCtaPendientes  key={index} cuenta={cuenta} />
                           
                            }



                            { (signo == "null") &&

                                <CuerpoCtaPendientes  key={index} cuenta={cuenta} />

                            }

                            </>

                            )}
                        </tbody>
                    </table>

                </div>
            </div>
    </div>

     </div>
    );
}

export default AcordionCuentasPendientes;