





import React, { useEffect, useState } from 'react';
import { fetchUSer, getLocalstore } from '../../../Core/fetchLocalStoreData';
import { Parqueadero } from '../Panel/Core/Parqueadero';
import { FormasPago, Tercero } from '../../Document/PartesDocument';
import { useContextDocumentValue , actionTypeDocument } from '../../Document/context/ContextDocument';
import TipoDocumento from '../../Document/PartesDocument/TipoDocumento';
import CambiarPrecioParqueadero from './CambiarPrecioParqueadero';


const ClassParqueadero = new Parqueadero;

const Salida = ({resetearPanel}) => {

    

    const [ {documento}  , dispatch ] = useContextDocumentValue();

    const user = fetchUSer();


    useEffect(() => {
    
        let aux  = {dispatch , actionTypeDocument }
        ClassParqueadero.setDispatch(aux);
        ClassParqueadero.rederizarSalida()

        setTimeout(() => {
            setPagos(true);
        }, 200)

    } , [])

    const finalizarProcedimiento = () => {

        resetearPanel();
    }

    const HandleCambiarPrecioParqueadero = (precio) => {
        ClassParqueadero.cambiarPrecioParqueadero(precio , 0);
    }

    const [pagos , setPagos ]  =  useState(null);
    return (
        <div className='card p-4'>
            <h3>Salida de parqueadero</h3>

      
           {/* <button onClick={() => console.log(documento)} > log _salida_documento  </button>
            <button onClick={() => console.log(ClassParqueadero)} > log Calse Parqueadero  </button>
            <button onClick={() =>setPagos(true)} >Iniciar Pagos   </button>
            */}


            {documento &&  <Tercero  />}

            
            
            {documento  &&  <TipoDocumento   LogicaDocumento={ClassParqueadero.LOGICADOCUMENTO}   />   }
            {documento &&  <table>
                <tr>
                    <p>Placa : {documento.placa}</p>
                    <p>Descripcion : {documento.placa}</p>
                    <p>Fecha de Ingreso : {documento.fechaFinal } </p>
                    <h3>Total a pagar :  $ {documento.MATEMATICAS.SUBTOTAL_3}  </h3>

                    {user?.PERMISOS?.CambiarPrecioParqueadero == 1 &&  <CambiarPrecioParqueadero  precioAntes={documento.MATEMATICAS.SUBTOTAL_3} CambiarPrecioParqueadero={HandleCambiarPrecioParqueadero} /> }
                </tr>
            </table>}

                
            {pagos && <FormasPago desactivar={finalizarProcedimiento}  /> }
                
        </div>
    );
};

export default Salida;