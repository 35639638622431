import React from 'react';
import BuscarTercero from "./BuscarTercero";
import TerceroView from "./TerceroView.jsx";

import { useContextDocumentValue, actionTypeDocument } from "../../context/ContextDocument.js";

import { useState } from 'react';
import { useEffect } from 'react';


import { useStateValue } from '../../../../context/StateProvider.js';

import { DocumentL } from "../../DocumentCore.js";

import { enviarPeticion } from "../../../../Core/ActionDatabase.js";

import StandarModal from '../StandarModal.jsx';

import InfoTerceroDocumentos from "../PartesItems/SubPartesItems/InfoTerceroDocumentos.jsx";
import ConfirmarCambioPrecio from '../ConfirmarCambioPrecio.jsx';
import { NotificationManager } from 'react-notifications';
import NuevoTercero from '../../../Terceros/Add/Formulario/NuevoTercero.jsx';

let Tercero_receroShow521 = null;

const Tercero = ({ tab, bloquear = 0 }) => {

    const [{ documento }, dispatch] = useContextDocumentValue();


    const [showcuentaTerceroPendiente, SetshowcuentaTerceroPendiente] = useState();

    const [TotalFavor, setTotalfavor] = useState();

    const [infosaldofavor, setInfosaldofavor] = useState([]);

    const [showformfast, setShowformfast] = useState(null);

    const [{ user }] = useStateValue();

    const LogicaDocumento = new DocumentL(documento, null, { dispatch, actionTypeDocument }, user);

    const [showForm, setShowForm] = useState(false);
    /* Esta variable sera usada para indicarle a nuestro formulario si es nuevo o si es para editar **/
    const [accionForm, setAccionForm] = useState(null);


    /*** Este modal pregunta si quiere activar la lista de precio */
    const [showModal, setShowModal] = useState(false);




    useEffect(() => {

        setShowformfast(false);

    }, [tab])

    const extraerTercero = (tercero) => {

        Tercero_receroShow521 = tercero;

       

        if (Tercero_receroShow521.ct_lista_precio_id > 0 && user.USER.type_user_id == 1) {
            setShowModal(true);
        } else {
            consultarCuentasPendientes(tercero);
            asignarCliente(null)
        }

    }


    const respuesta = (Respuestaresultado) => {


        setShowModal(false);
        asignarCliente(Respuestaresultado)
        consultarCuentasPendientes(Tercero_receroShow521);
    }

    const asignarCliente = (Respuestaresultado) => {
        /***
         *  1=> Activar porcentaje
         *  2=> Activar precio  
         */
        let SWITCH_LISTA_PRECIO = 1;
        let copiaDC = documento;
        copiaDC.TERCERO = Tercero_receroShow521;
        try {
            if (user.CONFIGURACIONES_TABLE.switch_modo_precio && user.CONFIGURACIONES_TABLE.switch_modo_precio == 2) {

                SWITCH_LISTA_PRECIO = 2;
            }
        } catch (error) {
            console.log(error);
        }

        LogicaDocumento.actualizar_lista_precio(Tercero_receroShow521.ct_lista_precio_id, SWITCH_LISTA_PRECIO, 'agregarTercero', Tercero_receroShow521, Respuestaresultado);
    }

    const consultarCuentasPendientes = async (tercero) => {


        if (documento.TIPO_DOCUMENTO?.SALDO_A_FAVOR == 1) {

            NotificationManager.info("Este Documento generara un saldo a Favor")
            return false;
        }
        let respuesta = await enviarPeticion("ventas/consultar_cuentas_pendientes", null, {
            body: {
                tercero_id: tercero.id,
                ct_master_id: documento.TIPO_DOCUMENTO.ct_master_id,
                ct_operacione_id: documento.TIPO_DOCUMENTO.ct_master_id,
                SALDO_A_FAVOR: documento.TIPO_DOCUMENTO.SALDO_A_FAVOR
            },
        });

        if (respuesta.respuesta && respuesta.respuesta == 1) {
            console.log(respuesta.data);

            if (respuesta.data.infoCuentasxPagarVenta || respuesta.data.SaldoAdelantado) {

                if (respuesta.data.infoCuentasxPagarVenta.length > 0 || respuesta.data.SaldoAdelantado.length > 0) {

                    // Método 2: Usando la función reduce
                    //let sumaTotal2 = respuesta.data.infoCuentasxPagarVenta .reduce((acumulador, objeto) => acumulador + parseInt(objeto.total)  , 0);

                    // setTotalfavor(sumaTotal2)

                    setInfosaldofavor(respuesta.data);

                    SetshowcuentaTerceroPendiente(true);
                }

            }
        }
    }


    const eliminarTercero = () => {

        LogicaDocumento.actualizar_lista_precio(0, null, 'quitarCliente');


    }






    const editarDatos = () => {
        setAccionForm(2);
        setShowformfast(true);
    }



    const handleSubmitMini = (tercero) => {

        // extraerTercero(tercero);   
        // setShowformfast(false);

        extraerTercero(tercero);
        setShowForm(false);
    }



    const ocultarForm = () => {

        // setShowformfast(false);
        setShowForm(false);
    }

    const handleClickNuevo = () => {
        // setShowformfast(!showformfast);
        // setAccionForm(1);

        setAccionForm(1);
        setShowForm(true);
    }

    const handleClickEditar = () => {
        setAccionForm(2);
        setShowForm(true);
    };

    const cerrarModal = () => {
        setShowForm(false);
    };

    /* <button onClick={mostrarFormulario} className='btn btn-primary btn-small' title="Registrar un nuevo Contacto"> <i class="fa-solid fa-user-plus"></i> </button>  */
    /*<StandarModal  titulo=" Nuevo Tercero" desactivar={mostrarFormulario}  mostrar={showFormulario} >  <FormTercero handleSubmitPadre={handleSubmit} terceroDefaul={ null } />  </StandarModal>  */

    return (
        <div className='' >
            <div className='d-flex '>

                {/* { ( true ) &&  
                    
                    <div style={{"position":" relative"}} >
                        
                        { !documento.TERCERO &&
                            <button   onClick={handleClickNuevo} className='btn btn-principal btn-sm' title="Registrar un nuevo Contacto"  data-bs-placement="bottom" data-bs-toggle="tooltip" > 
                                {!showformfast && <i className="fa-solid fa-user-plus"></i> || <i className="fa-solid fa-xmark"></i>  }
                            </button> 
                        }  

                        {
                            showformfast &&  <FormTercero terceroDefaul={documento.TERCERO} accionForm={accionForm}  handleSubmitPadre={handleSubmitMini} handleClickCerrar={ocultarForm}  /> 
                        }

                        
                    </div>
               }
              
                





                { (!documento ||   !documento.TERCERO) 
                    ? <BuscarTercero seleccionarTercero={extraerTercero}  ocultarform={ocultarForm} /> 
                    : <TerceroView bloquear={bloquear} tercero={documento.TERCERO}  eliminar={eliminarTercero} editar={editarDatos}  />
                } */}

                {!documento.TERCERO && (
                    <button onClick={handleClickNuevo} className='btn btn-principal btn-sm' title="Registrar un nuevo Contacto">
                        <i className="fa-solid fa-user-plus"></i>
                    </button>
                )}

                {!documento.TERCERO ? (
                    <BuscarTercero seleccionarTercero={extraerTercero} ocultarform={ocultarForm} />
                ) : (
                    <TerceroView bloquear={bloquear} tercero={documento.TERCERO} eliminar={eliminarTercero} editar={handleClickEditar} />
                )}
            </div>



            {/***
            *  Modal 
            */}
            <StandarModal headerShow={false} size="md" title="Productos Compuestos" desactivar={() => SetshowcuentaTerceroPendiente(!showcuentaTerceroPendiente)} mostrar={showcuentaTerceroPendiente}  >
                <InfoTerceroDocumentos TotalFavor={TotalFavor} info_sado={infosaldofavor} respuestaSi={LogicaDocumento.AgregarSaldoAFavor} cerrarModal={SetshowcuentaTerceroPendiente} />
            </StandarModal>

            <StandarModal size="xl" desactivar={cerrarModal} mostrar={showForm}>
                <NuevoTercero defaultValues={documento.TERCERO} registroConExito={handleSubmitMini} cerrar={cerrarModal} />
            </StandarModal>

            <ConfirmarCambioPrecio show={showModal} respuesta={respuesta} />
        </div>
    );
}

export default Tercero;