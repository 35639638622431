import React from 'react';
import { NotificationManager } from 'react-notifications';
import { eliminarFactura } from '../controlador';
import { showConfirm } from '../../Layaut/LayautDefault';

const EliminarFactura = ({ documento  , recargarDocumento }) => {
    const handleEliminarFactura = async () => {

        
        showConfirm("⚠️ ¡ALTO! ⚠️\n\n¿Estás seguro de que deseas continuar con esta acción?\n\nEsta operación no se puede deshacer.", respuestaSi);



    }

    const respuestaSi = async () =>{
        
        const respuesta = await eliminarFactura(documento);
        NotificationManager.success(respuesta, 'Factura eliminada #' + documento);
        recargarDocumento();
    }
    return (
        <div className='col-auto'>
            <button onClick={handleEliminarFactura} className="btn btn-danger btn-sm me-2">
                <i className="fa fa-trash-can me-1"></i>Eliminar Factura
            </button>
        </div>
    );
};

export default EliminarFactura;