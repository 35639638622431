

import React from 'react';

const Propinas = ({user , setFormaPagos , setPropina , setPagoLvlDos , documento, formpagos}) => {

    
    const handlePropina = (e) => {

        let copi = formpagos.map( (pago) => {
            pago.total = 0;
            return pago;
        } )
        setFormaPagos(copi);
        setPropina(   documento.MATEMATICAS.SUBTOTAL_3   *   e.target.value);
        setPagoLvlDos( 0 , 'total' , documento.MATEMATICAS.SUBTOTAL_3 + ( documento.MATEMATICAS.SUBTOTAL_3 *   e.target.value ));

    }

    return (
        <div>
           { user.CONFIGURACIONES_TABLE.propinas == 1 &&  <div>    
                Propina<select   onChange={handlePropina} >
                    <option value={0}>0%</option>
                    <option value={0.05}>5%</option>
                    <option value={0.10}>10%</option>
                </select>
            </div>} 
        </div>
    );
};

export default Propinas;