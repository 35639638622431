


import React from 'react';

const InputBuscador = ({inputbuscar , setInputBuscar , buscar}) => {

      /*** disparadoe del buscador */
      const DispararkeyPress = (e) => {
        if (e.key === "Enter") {
            buscar();
        }
    };


    return (
        <div>
             <div className="row g-2 mb-4">
                <div className="col-auto">
                    <input onKeyUp={DispararkeyPress} value={inputbuscar} onChange={(e) => setInputBuscar(e.target.value)} type="text" placeholder="🔍 Buscar..." className="form-control shadow-sm rounded-3 search-input" />
                </div>
                <div className="col-auto">
                    <button onClick={buscar} className="btn btn-success btn-sm shadow rounded-3">
                        <i className="fa-solid fa-search me-1" /> Buscar
                    </button>
                </div>
            </div>

        </div>
    );
};

export default InputBuscador;