
import React from 'react';

const BotonesPaginacion = ({paginaActual , totalPaginas , setPaginaActual }) => {

    
    /*** Cambiar la pagina */
    const cambiarPagina = (nuevaPagina) => {
        if (nuevaPagina >= 1 && nuevaPagina <= totalPaginas) {
            setPaginaActual(nuevaPagina);
        }
    };

    
    return (
        <div>
             {/* Paginador */}
             <div className="d-flex justify-content-between align-items-center mt-3">
                <button className="btn btn-sm btn-outline-primary" disabled={paginaActual === 1} onClick={() => cambiarPagina(paginaActual - 1)}>
                    ← Anterior
                </button>
                <span>Página {paginaActual} de {totalPaginas}</span>
                <button className="btn btn-sm btn-outline-primary" disabled={paginaActual === totalPaginas} onClick={() => cambiarPagina(paginaActual + 1)}>
                    Siguiente →
                </button>
            </div>
        </div>
    );
};

export default BotonesPaginacion;