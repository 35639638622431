import React from 'react';
import Pages from '../../Pagos/Lista/Pages';

const Abonos = ({terceroId}) => {
    return (
        <div>
            <h3 className='mb-3'>Historia de pagos</h3>
            <Pages terceroId={terceroId} />
        </div>
    );
};

export default Abonos;