
import { __app, __fecha } from '../../../../../utils/myApp';

import React from 'react';

const PagosConsumidos = ({PAGOS_CONSUMIDOS}) => {
    return (
        <>
              {/*** PAGOS <CONSUMIDOS styleName={
                             * 
                             * Aqui se muestras los pagos que se usaron para tomarlos como forma de pago de este documento
                             * 
                             * }></CONSUMIDOS> */}
                            {  (PAGOS_CONSUMIDOS.length > 0)  && <>
                            
                            
                            <tr >
                                <td  colSpan={8}><p className='mt-3 text-center'>Consumo de Saldo a Favor u otros Pagos</p></td>         
                            </tr>

                            <tr>
                                <th className="text-center">Metodo</th>
                                <th className="text-center">Fecha</th>
                                <th className="text-right">Responsable</th>
                                <th className="text-right">Codigo del Pago Consumido</th>
                                <th className="text-right">Tipo</th>
                                <th className="text-right">Punto de Venta</th>
                                <th className="text-right">Turno</th>
                                <th className="text-right">Total</th>
                            
                        
                            </tr>
                            
                            {  PAGOS_CONSUMIDOS.map((item, key) => (
                            
                                <tr key={key}>
                            
                                   
                                    <td>Saldo Consumido</td>
                                    <td>{ __fecha.formatoFecha({$fecha:item.cr}) }</td>
                                    <td>{item.forma_pagos_nombre}</td>
                                    <td>Pago#{item.pid}</td>
                                    <td> <span className='bg-primary badge'>Consumo</span>  </td>
                                    <td>PtVenta#{item.etid}</td>
                                    <td>Turno#{item.tnoid}</td>
                                    <td>  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  item.tt , color : false , icono : false , numerosPositivos : true  } )} }></span> </td>
                                    
                                </tr>
                            
                            ))}
                        </>} 
        </>
    );
};

export default PagosConsumidos;