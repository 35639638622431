import { enviarPeticion } from "../../../Core/ActionDatabase";


export const listarProductosMensualidad = async () => {

    let responseHttp = await enviarPeticion('parqueaderos/listar_productos_mensualidad', null);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

} 

export const extraerMensualidades = async () => {

    let responseHttp = await enviarPeticion('mensualidades/lista_mensualidades', null);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

} 

export const extraerContratos = async (id) => {

    let responseHttp = await enviarPeticion('documentos/listar_documentos_by_contratos', id);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp.data
    } else {
        return -1;
    }

} 


export const registrarMensualidad = async (formData) => {

    let responseHttp = await enviarPeticion('mensualidades/crear_mensualidad', null, {
        body: {
            POST: formData
        }
    });
    console.log(responseHttp)
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp;
    } else {
        return -1;
    }
}

export const actualizarMensualidad = async (formData) => {

    let responseHttp = await enviarPeticion('api_extensiones/actualizar_mensualidad', null, {
        body: {
            POST: formData
        }
    });
    console.log(responseHttp)
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp
    } else {
        return -1;
    }
}


export const eliminarMensualidad = async (id) => {

    let responseHttp = await enviarPeticion('mensualidades/eliminar_mensualidad', id);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp
    } else {
        return -1;
    }
}

export const mesSiguiente = async (id) => {
    let responseHttp = await enviarPeticion('mensualidades/repetir_mes_siguiente', id);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp
    } else {
        return -1;
    }
}

export const cambiarEstadoMensualidad = async (id, estado) => {
    let responseHttp = await enviarPeticion(`mensualidades/activar_desactivar_mensualidad/${estado}`, id);
    if (responseHttp.respuesta && responseHttp.respuesta == 1) {
        return responseHttp
    } else {
        return -1;
    }
}