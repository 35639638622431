import React, { useEffect, useState } from 'react';
import { getDocumentosbyTerceroid } from '../Core/Core';
import TablaDocumentos from './TablaDocumentos';
import ErrorPeticionHttp from '../../../Core/Html/ErrorPeticionHttp';
import { filtrarTipoDocumento } from '../../Documentos/controlador';
import FiltroTipoDocumentos from '../../Documentos/Componentes/FiltroTipoDocumentos';
import { useContextLocalValue } from './Core/ContextTercero';
import { __app } from '../../../utils/myApp';

const Documentos = ({terceroId , actualizarDatos}) => {

    const [ documentos , setDocumentos ] = useState(null);

    const [tipoDocumento , setTipoDocumentos] = useState(null);
    
    const [tipoDocumentoFiltro , settipoDocumentoFiltro] = useState([]);


    const [ {info} , dispatch ]  = useContextLocalValue();


    useEffect(() => {
        setDocumentos(null);
        consutlarDocumentos();
    } , [info.menu])


    const consutlarDocumentos = async () => {

        let data = await getDocumentosbyTerceroid(terceroId);
        let filtroTipoDocumento = filtrarTipoDocumento(data);
        setTipoDocumentos(filtroTipoDocumento);
        settipoDocumentoFiltro(filtroTipoDocumento);


        console.log(data)
        setDocumentos(data);

    }

    const handleChangeFiltro = (data) => {
        console.log(data)
         settipoDocumentoFiltro(data)
    }

    const campos = [
        'Fecha',
        'PuntoDeVenta',
        '#Factura',
        '#Interno',
        'Tipo',
        'Descuento',
        'Estado',
        'Precio',
        'Utilidad',
        'Opciones',
    ]


  
 
    return (
        <div>
           
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="fs-5 mb-0">Lista de todos los  documentos</h2>
            </div>

            <div className="d-flex  mb-4 card">
                <div className='card-body'>
                    
                    <div className='mb-4'>
                        { tipoDocumento && <FiltroTipoDocumentos tipoDocumento={tipoDocumento} handleChangeFiltro={handleChangeFiltro} /> }
                    </div>


                    { (documentos && Array.isArray(documentos) )  && <TablaDocumentos actualizarDatos={actualizarDatos} documentos={documentos} filtro={tipoDocumentoFiltro}  campos={campos}/> }

                    { !documentos  && <>Cargando...</> }

                    { documentos === -1  && <ErrorPeticionHttp /> }

                </div>
            </div>
            <div>
         
              

            </div>
        </div>
    );
};

export default Documentos;