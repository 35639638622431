import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import Alerta from '../../../Core/Html/Alerta';
import { cambiarEstadoMensualidad, extraerContratos, mesSiguiente } from '../Core/Controlador';
import { __app, __fecha } from '../../../utils/myApp';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import VerDocumento from '../../Documentos/VerDocumento/VerDocumento';
import Placa from '../../../Core/Html/Placa';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import { __pluma7 } from '../../../Core/pluma7';
import { EtiquetaEstadiDian } from '../../../Core/Html/ComponenteHtml';
import { Link } from 'react-router-dom';

const View = ({ desactivar, item, SetItem, cargarMensualidades }) => {
    const [verDocumentoModal, setVerDocumentoModal] = useState(false);
    const [btnCargando, setBtnCargando] = useState(false);
    const [facturaActual, setFacturaActual] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [contratos, setContratos] = useState([]);
    const user = fetchUSer();     // PERMISOS DEL USUARIO
    const MODO_PARQUEDAERO = (user.CONFIGURACIONES_TABLE.MODO_parqueadero) ? user.CONFIGURACIONES_TABLE.MODO_parqueadero : 0;

    useEffect(() => {
        setContratos([]);
        cargarContratos();
    }, [])

    const abrirModalVerDocumento = (document_id) => {
        setFacturaActual(document_id);
        setVerDocumentoModal(true);
    };
    const handleCerrarModales = () => {
        setVerDocumentoModal(false);
        setFacturaActual(null);
    };

    const cargarContratos = async () => {
        setCargando(true);
        try {
            const data = await extraerContratos(item.id);
            setContratos(data);
            console.log(data);
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar:", error);
        } finally {
            setCargando(false);
        }
    }

    const generarElMesSiguiente = async (e) => {
        e.preventDefault();
        setBtnCargando(true);
        try {

            let result = await mesSiguiente(item.id);

            if (result.respuesta == 1) {

                NotificationManager.success(result.mensaje, 'Factura generada');
                cargarContratos();
            }
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar:", error);
        } finally {
            setBtnCargando(false);
        }
    }

    const cambiarEstado = async (e, estado) => {
        e.preventDefault();
        setBtnCargando(true);
        try {

            let result = await cambiarEstadoMensualidad(item.id, estado);

            if (result.respuesta == 1) {
                SetItem(null);
                desactivar(false);
                NotificationManager.success(result.mensaje, 'Estado Cambiado');
                cargarMensualidades();
            }
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar:", error);
        } finally {
            setBtnCargando(false);
        }
    }

    const getJson = (json, key) => {
        try {
            return JSON.parse(json)[key];
        } catch (error) {
            console.log(error)
        }
    }

    const totalPendiente = contratos
        .filter(item => item.destd_id == 0)
        .reduce((acc, curr) => acc + parseFloat(curr.sub3 || 0), 0);

    return (
        <div >
            <div className="row mb-3">
                <h3 className="text-center mb-3">🪪 Información del contrato #{item.id}</h3>
                <div className="col-lg-8 col-md-10">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2 flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Tipo de documento:</strong>
                                <span className={`text-center badge rounded-pill p7-badge-${item.color_documento}`} >{item.ct_tipo_comprobante_fiscals_nombre}</span>
                            </div>

                            <div className="mb-2">
                                <Link className="card bg-primary-subtle ps-2 fs-9 m-1 d-flex flex-column text-decoration-none  card-hover" title='Clic para ver el perfil de este tercero' style={{ maxWidth: '200px' }} to={`/TerceroPerfil/${__app.envolver(item.ct_persona_id)}`}  >
                                    <div className="d-flex align-items-center">
                                        <i className="fa-solid fa-fingerprint me-2"></i>
                                        <span>{item.CtPersona_identificacion}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="fa-solid fa-user me-2"></i>
                                        <span>{__app.rellenarVacios(item.CtPersona_nombre)}</span>
                                    </div>
                                    {item.CtPersona_telefono && item.CtPersona_telefono.trim().length > 0 && (
                                        <>
                                            <div className="d-flex align-items-center">
                                                <i className="fa-solid fa-phone me-2"></i>
                                                <span>{__app.rellenarVacios(item.CtPersona_telefono)}</span>
                                            </div>
                                        </>
                                    )}
                                </Link>
                            </div>


                            {MODO_PARQUEDAERO == 1 && (
                                <div className="mb-2 d-flex flex-wrap">
                                    <strong className="text-muted me-2 text-nowrap">Vehículo:</strong>
                                    <span className="text-truncate">   
                                    <Link title='Clic para ver el perfil de esta placa ' className='text-decoration-none' to={`/perfil_vehiculos/${__app.envolver(item.Objeto_identificacion)}/${__app.envolver(item.objeto_id)}`}  >
                                        <Placa placa={item.Objeto_identificacion} />
                                    </Link></span>
                                </div>
                            )}

                            <div className="mb-2 d-flex flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Referencia:</strong>
                                <span className="text-truncate">{__app.rellenarVacios(item.referencia)}</span>
                            </div>

                            <div className="mb-2 d-flex flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Fecha de Inicio:</strong>
                                <span className="text-truncate">{__fecha.formatoFecha({ $fecha: item.fecha_inicio })}</span>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-2 flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Estado del Contrato:</strong>
                                {__pluma7.estadoMensualidad(item.MensuContrato_contrato_estado)}
                            </div>

                            <div className="mb-2 flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Repetir Automáticamente:</strong>
                                {__pluma7.estadoRepetirMensualidad(item.repetir_automaticamente)}
                            </div>

                            <div className="mb-2 d-flex flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Días de Antelación:</strong>
                                <span className="text-truncate">{item.dias_de_antelacion}</span>
                            </div>

                            <div className="mb-2 d-flex flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Creado:</strong>
                                <span className="text-truncate">{item.MensuContrato_CREADO}</span>
                            </div>
                            <div className="mb-2 d-flex flex-wrap">
                                <strong className="text-muted me-2 text-nowrap">Días:</strong>
                                <span className="text-truncate">{item.dias} dias</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div className="text-center mb-4">
                <button onClick={(e) => cambiarEstado(e, item.MensuContrato_contrato_estado == 1 ? 2 : 1)} className="btn btn-subtle-secondary btn-sm me-2 mb-2" disabled={btnCargando}>
                    {btnCargando ? (
                        <><span className="spinner-border spinner-border-sm me-2"></span>Procesando...</>
                    ) : (
                        <span className={item.MensuContrato_contrato_estado == 2 ? "text-success" : "text-danger"}>
                            {item.MensuContrato_contrato_estado == 1 ? "🚫 Desactivar Contrato" : "✅ Activar Contrato"}
                        </span>
                    )}
                </button>

                <button onClick={generarElMesSiguiente} className="btn btn-success btn-sm" disabled={btnCargando}>
                    {btnCargando ? (
                        <><span className="spinner-border spinner-border-sm me-2"></span>Genernado facatura...</>
                    ) : (
                        <span>Generar el Mes Siguiente</span>
                    )}
                </button>
            </div>








            <h4 className="mb-3 text-center">📃 Lista de Facturas del Contrato</h4>
            {cargando ? (
                <p>Cargando facturas...</p>
            ) : contratos.length === 0 ? (
                <Alerta tipo="warning" title="¡Ops! No hay informacion." mensaje="No hay facturas registradas en este momento." />
            ) : (
                <>
                    <div className=" table-responsive">
                        <table className="table table-hover align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#Factura</th>
                                    <th>Emisión</th>
                                    <th>Tipo de Documento</th>
                                    <th>Fecha Inicio</th>
                                    <th>Fecha Fin</th>
                                    <th>Precio</th>
                                    <th>Estado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contratos.map((item, index) => (
                                    <tr key={index} className={item.destd_id == 0 ? "border border-danger" : "border border-success"}>
                                        <td>{item.id}</td>
                                        <td>{__fecha.formatoFecha({ $fecha: item.ff })}</td>
                                        <td>
                                            <span className={`text-center badge bg-${getJson(item.jcj, 'ctd_cl')}`}>{getJson(item.jcj, 'ctd_ec')}</span>
                                        </td>
                                        <td>{__fecha.formatoFecha({ $fecha: item.pf1 })}</td>
                                        <td>{__fecha.formatoFecha({ $fecha: item.pf2 })}</td>
                                        <td>{__app.formato_decimal({ total: item.sub3 })}</td>
                                        <td>{__pluma7.EstadoFactura({ estado: item.destd_id })} </td>
                                        <td className='text-nowrap'>
                                            <button onClick={() => abrirModalVerDocumento(item.id)} className="btn p-2 btn-subtle-primary btn-sm m-1">
                                                <i className="fa-solid fa-eye fa-lg me-1" />Ver
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                <td colSpan="5"></td>
                                <td>
                                    <strong className={totalPendiente > 0 ? "text-danger" : "text-success"}>
                                        {totalPendiente > 0
                                            ?
                                            "Total: " + __app.formato_decimal({ total: totalPendiente })
                                            : "✅ Al día con tu mensualidad"}
                                    </strong>
                                </td>
                                <td></td>
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {/* VER FACTURA  */}
            <StandarModal keyboard={true} headerShow={true} size="xl" titulo="Ver Factura." desactivar={handleCerrarModales} mostrar={verDocumentoModal}>
                <VerDocumento document_id={facturaActual} desactivar={handleCerrarModales} PagoExitoso={cargarContratos} />
            </StandarModal>
        </div>

    );
};

export default View;