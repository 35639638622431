import React, { useEffect, useState } from 'react';
import { getServiciosXLiquidar } from './Core/core';
import Alerta from '../../../Core/Html/Alerta';
import ListaOperadores from './ListaOperadores';
import { NotificationManager } from 'react-notifications';

const PendientesLiquidar = () => {
    const [servicios, setServicios] = useState([]);
    const [cargando, setCargando] = useState(true);


    useEffect(() => {
        setServicios([]);
        cargarServicios();
    }, [])

    const cargarServicios = async () => {
        setCargando(true);
        try {
            const data = await getServiciosXLiquidar();
            console.log(data);
            setServicios(data);
        } catch (error) {
            NotificationManager.error("Error revisa la consola", 'Error');
            console.error("Error al cargar servicios:", error);
        } finally {
            setCargando(false);
        }
    }
    return (
        <div className='card shadow-sm rounded-4 border-0'>
            <div className="card-body">
                {cargando ? (
                    <p>Cargando Operadores...</p>
                ) : servicios.length === 0 ? (
                    <Alerta tipo="warning" title="¡Ops! No hay operadores por liquidar." mensaje="No hay operadores pendientes de liquidar en este momento." />
                ) : (
                    <>
                        <div className="d-flex justify-content-center align-items-center mb-2 text-secondary">
                            <h4 className="mb-2"> 🪙 Pendientes por liquidar</h4>
                        </div>
                        <ListaOperadores servicios={servicios} />
                    </>
                )}
            </div>
        </div>
    );
};

export default PendientesLiquidar;