const ValidacionesDocumento = ({ mensaje_errores }) => {
    return (
        <div className="container mt-4">
            <div className="alert alert-danger">
                <h4 className="alert-heading">
                    <i className="fa fa-exclamation-triangle"></i> No podemos continuar
                </h4>
                <p>Compruebe los siguientes errores antes de continuar con la creación del documento:</p>
                <hr />
                {mensaje_errores && mensaje_errores.length > 0 ? (
                    <ul className="list-group">
                        {mensaje_errores.map((error, index) => (
                            <li key={index} className="list-group-item list-group-item-danger">
                                <i className="fa fa-times-circle"></i> {error}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="alert alert-success">
                        <i className="fa fa-check-circle"></i> No hay errores.
                    </div>
                )}
            </div>
        </div>
    );
};

export default ValidacionesDocumento;