import React from 'react';
import { useState } from 'react';

import {__app} from "../../../../utils/myApp";
import { sumarImpuestos } from '../../../../Core/MatematicasGlobales';
import DisplayNumber from './DisplayNumber';

const CambiarPrecioDocumento = ({item,indexx,accionF,accionName , user ,keyShow, decimal , ct_operacion_id , fontSize}) => {
    
    const [showinput , setShowInput ] = useState(false);


    const handleChange = (e) => {
        setShowInput(false);
        let valor = e.target.value;
        let accion = accionName;

     

        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }
    /***
     *  1 => Si puede modificar el precio
     *  0 => No podra modificar el precio
     */
    let  TIENE_PERMISO = null;

    /*bloquear_precio = 0     es   bloqueado */
    if ( user.USER.type_user_id  >  1  ){
        

        if( user.PERMISOS?.CambiarPrecioVenta == 0   ){

            TIENE_PERMISO = 0; 

        }else{

            // TENDRA PERMISO SI EL USUARIO LE ASIGNA EL PERMISO
            TIENE_PERMISO = 1; 


            /// Si el precio esta BLOQUEADO NO TENDRA PERMISO
            if( (item.bloquear_precio == 1   )    ){

                TIENE_PERMISO  = 0;
            }
        }
           
    }else{

        TIENE_PERMISO = 1
    }
    

       
    if(  TIENE_PERMISO !=  1 ){

        return (
            <>    
                <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({"total" : item.MATEMATICAS[keyShow]  , decimal : decimal})}  />

                <i className="fa-sharp font-s-candado fa-solid fa-lock"></i>
            </>
        )
    }
       
   
    return (
        <>
           { !showinput && 
            <div onClick={ () =>  setShowInput(true) }>   
                <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({"total" : item.MATEMATICAS[keyShow]  , decimal : decimal})}  />
            </div> }
           
           {showinput && <input   onKeyDown={_handleKeyDown} className='max-60' autoFocus onBlurCapture={ handleChange}  defaultValue={item.MATEMATICAS[keyShow]} />}
        

        </>
    );
}

export default CambiarPrecioDocumento;