


import React from 'react';
import { __app } from '../../../../../utils/myApp';

const InfoPagosP1 = ({documento, TOTAL_PAGOS_ABONOS , TOTAL_TODOS_LOS_PAGOS_ADELANTADOS , TOTAL_A_PAGAR, propina, user }) => {

    return (
        <div>
        
        <div className='col-5'>

                            
            <p className='fs-5'>Total Factura:   { __app.formato_decimal({total :  documento.MATEMATICAS.SUBTOTAL_3}) } </p>




            {/*** Mostramos si hay SALDO a FAVOR!! */}
            { documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0  && <p>Saldo a Favor :  { __app.formato_decimal({total :  documento.MATEMATICAS.TOTAL_SALDO_FAVOR}) } </p> }


            {/*** Abonos Anteriores al Documentos, Aplica para cuentas abierta o plan Separe */}
            { TOTAL_PAGOS_ABONOS > 0  && <p>Abonos Anteriores  :  { __app.formato_decimal({total : TOTAL_PAGOS_ABONOS}) } </p> }




            {/***  Estado de propinas   */}
            { user.CONFIGURACIONES_TABLE.propinas == 1 && <p className='fs-2'>Propina:   {  propina } </p> }



            { user.CONFIGURACIONES_TABLE.propinas == 1 ||  TOTAL_TODOS_LOS_PAGOS_ADELANTADOS > 0     && 


                <span>
                    { TOTAL_A_PAGAR  > 0 && 
                    
                    <span> 
                        <p className='fs-2'>Total a Pagar:   { __app.formato_decimal( {total :  TOTAL_A_PAGAR }) } </p>
                    </span> 
                    
                    ||

                    <span> 
                        <span> TOTAL A PAGAR : $ 0 </span>
                        <p  className='badge bg-success' >Nuevo saldo a Favor de  : { TOTAL_A_PAGAR * -1  }   </p>
                        
                    </span>
                    }
                </span>

            }


            </div>    
        </div>
    );
};

export default InfoPagosP1;