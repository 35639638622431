import { getLocalstore } from "../../../Core/fetchLocalStoreData";
import { campos_lista_documento } from '../../../Core/VariablesGlobales';




const extraConfiguraciones = () => {

    let data = getLocalstore('user_localConfig') ;
    
    if (data && data.GENERALES ){

        return data.GENERALES;
    }

  
    return  {
        
        "habilitarImpresionNavagador" : 1, 
        "habilitarImpresionLocal": 0,
        "PreguntarSiImprimir": 0,

    } 
}


const estraerInfoTr = (KeysPermitidas) => {

    let data = getLocalstore('user_localConfig') ;

    if (data){
        console.log(data.CAMPOS_A_MOSTRAR_LISTA)
        return data.CAMPOS_A_MOSTRAR_LISTA;
    }

    return   campos_lista_documento.label.filter( (Infolabel) => KeysPermitidas.includes(Infolabel.clave))   ;
}





export const  CargarConfiguracion = () => {

    return {
        "CONFIGURACIONES" : {
                            
            "LISTA_PRECIOS" : null,

            "GENERALES"  : extraConfiguraciones(),

            /*** Devido a que la tabla del documento es muy larga, aqui se deben marcar los campos que se quieren mostrar */
            "CAMPOS_A_MOSTRAR_LISTA" : estraerInfoTr(campos_lista_documento.activos)
        },
    }
} 