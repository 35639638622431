

export const servidores = [

    'http://localhost/apipuntoventav1/' ,



    'https://pruebasapip7.nubep7.com/',


    'http://192.168.137.1/apipuntoventav1/',


    'https://apipuntoventav1.nubep7.com/',

    'https://apip7.pluma7.com/',


    

]


export const disenoFactura = {

    Tirillacontenedor  : {
        'background': 'white',
        'width' : '302.36px',
        'position' : 'absolute' ,
        'fontFamily' : 'Arial, sans-serif',
      
    },

    Cartacontenedor  : {
        'background': 'white',
        'width' : '21.6cm',
        'height' : '27.9cm',
        'position' : 'absolute' ,
        'fontFamily' : 'Arial, sans-serif',
      
    },

    TirillaSubcontenedor : {
        'marginTop' : 5 ,
        'marginLeft' : 5,
        'marginRight'  :5,
    },

    LineaProductos : {
        titulo : {
            fontSize  : 12,
        },

        subtitulo : {
            fontSize  : 10,
        },
        codigo : {
            fontSize  : 10,
        },
        nombre : {
            fontSize  : 12,
        },
        precio : {
            fontSize  : 10,
        },
        cantidad : {
            fontSize  : 10,
        },
        subtotal : {
            fontSize  : 12,
        },
      
    },

    informacion : {


        /**** Este teamaño de fuente es usada para el estulo del pie de pagina */
        font9 : {
            fontSize  : 9,
        },

        font10 : {
            fontSize  : 10,
        },
        font11 : {
            fontSize  : 11,
        },
        font12 : {
            fontSize  : 12,
        },
        font13 : {
            fontSize  : 13,
        },
        font14 : {
            fontSize  : 14,
        },
        font15 : {
            fontSize  : 15,
        },


        /*** Font 16 es usada para la tirilla en TOTAL a PAGAR  */
        font16 : {
            fontSize  : 16,
        },
        font17 : {
            fontSize  : 17,
        },

        font18 : {
            fontSize  : 18,
        },

        font19 : {
            fontSize  : 19,
        }
    },

    SWITCH_SHOW_FIELD : {

        nombre : 1,
        subtotal : 1,
    }

}


/** Estos son los campos que se mostraran en la lista de los items seleccionados para crear un documento */
export const campos_lista_documento = 
{

    claves : [
        "key" , 
        "id", 
        "referencia",
        "nombre" , 
        "descripcion" ,  
        "PRECIO" , 
        "DESCUENTO_UNITARIO" ,
        "DESCUENTO_UNITARIO_PORCENTUAL" , 
        "PRECIO_2" ,
        "IMPUESTO_UNITARIO" ,
        "PRECIO_3" ,
        "CANTIDAD" ,
        "IMPUESTOS" , 
        "RETENCIONES" , 
        "SUBTOTAL_1",
        "DESCUENTO_LINEA", 
        "SUBTOTAL_2" , 
        "IMPUESTO_LINEA" , 
        "RETENCION_LINEA" , 
        "SUBTOTAL_3",
        "OPCIONES",
        "SHOW_CAMBIO_PRECIO" 
    ],


    label : [
        {clave : "key" , label : "llave" }, 
       
        {clave : "id" , label : "Codigo" },

        {clave : "referencia" , label : "referencia" },  
        
        {clave : "nombre" , label : "Nombre" },  
        
        {clave : "descripcion" , label : "Descripcion" },  
       
        {clave : "PRECIO" , label : "Precio" , ayuda : "Precio Antes de Impuestos"}, 
       
       
        {clave : "DESCUENTO_UNITARIO" , label : "Descuento" , ayuda : "Descuento manual al Precio unitario" }, 
       
        {clave : "DESCUENTO_UNITARIO_PORCENTUAL" , label : "Dto. en %" },
        
       
        {clave : "PRECIO_2" , label : "precio2" ,  ayuda : "Precio con descuento antes de los impuestos"},
       
        {clave : "IMPUESTO_UNITARIO" , label : "Impuesto" ,  ayuda : "Impuesto por Producto" }, 
       
        {clave : "PRECIO_3" , label : "PrecioFinal" ,  ayuda : "Precio Final del producto aplicando impuesto más descuentos "  }, 
       
        {clave : "CANTIDAD" , label : "Ctd." ,  ayuda : "Cantidad" }, 
       
        {clave : "IMPUESTOS" , label : "ImpuestoLista" , ayuda : "Lista de Impuestos Aplicados" }, 
        
        {clave : "RETENCIONES" , label : "RetencionLista" , ayuda : "Lista de Retenciones Aplicadas" },

       
        {clave : "SUBTOTAL_1" , label : "SubTotal" , ayuda : "Lista de Impuestos Aplicados"}, 
       
        {clave : "DESCUENTO_LINEA" , label : "Dto.Total" , ayuda : "Lista de Impuestos Aplicados"}, 
       
        {clave : "SUBTOTAL_2" , label : "SUBTOTAL_2", ayuda : "Precio de toda la línea sin" }, 
       
        {clave : "IMPUESTO_LINEA" , label : "ImpuestoTotal", ayuda : "Impuesto total por linea" }, 

        {clave : "RETENCION_LINEA" , label : "RetencionTotal", ayuda : "Impuesto total por linea" }, 
       
        {clave : "SUBTOTAL_3" , label : "Total", ayuda : "Total Neto por cada línea" },
       
        {clave : "OPCIONES" , label : "Opc." , ayuda : "Botones de ayuda"}, 
       
        {clave : "SHOW_CAMBIO_PRECIO" , label : "llave" , ayuda : "Lista de Impuestos Aplicados"}, 
    ],


    activos : [
        "id", 
        "nombre",                            
        "PRECIO", 
        "CANTIDAD",
        "SUBTOTAL_3",
        "OPCIONES"
    ]
    
    
}

export const  configuracionesLocales =  [

    {clave : "habilitarImpresionNavagador" , label : "Habilitar impresion del navegador" , ayuda : ""},

    {clave : "habilitarImpresionLocal"     ,  label : "Habilitar impresion directa" , ayuda : ""},

    {clave : "PreguntarSiImprimir"  ,  label : "Pregunta al Usuario antes de imprimir." , ayuda : "Solo en caso de que la impresión no sea directa"},


    {clave : "PreguntarSiempreCantidad"     ,  label : "Pregunta Siempre la cantidad al registrar un producto." , ayuda : ""},


    {clave : "MostrarTeclasRapido"     ,  label : "Muestra las teclas Rapidas como ayuda" , ayuda : ""},
]







export const estadosDian = [
    { id: 0, nombre: "Sin Enviar" },
    { id: 1, nombre: "Sin respuesta de la DIAN" },
    { id: 4, nombre: "Aceptado" },
    { id: 6, nombre: "Rechazado por la DIAN" }
];