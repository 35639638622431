
import { useEffect } from "react";
import { fetchUSer } from "../../../Core/fetchLocalStoreData";
import ListaDocumentos from "./ListaDocumentos";


const Index = ({ header = true }) => {

    const user = fetchUSer();


    return (
        <div>

            {/* ANDRESS */}
            {/* {
                header && <>
                    <h2 className='mt-2' >Lista de Documentos </h2>
                    <hr />
                </>
            }
            <h2>Solo se listaran los Documentos del turno Actual.</h2>
            <h1>Codigo del turno : {user.TURNO.id}</h1> */}
            {/* YULIII */}
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center g-2">
                    {header && (
                        <div className="col-auto">
                            <h5 className="fw-bold text-light bg-primary p-2 rounded-pill shadow">
                                📢 Lista de Documentos
                            </h5>
                        </div>
                    )}

                    <div className="col-auto">
                        <div className="anuncio-card d-flex align-items-center">
                            <h5 className="fw-bold text-danger mb-0 me-2">🎟️ Turno:</h5>
                            <h4 className="fw-bold text-success mb-0">{user.TURNO.id}</h4>
                        </div>
                    </div>

                    <div className="col-auto">
                        <p className="text-dark fw-bold mb-0 animate-fade-in">
                            🚨 ¡Atencion! Solo se listarán los documentos del turno actual. 🚨
                        </p>
                    </div>
                </div>
            </div>

            <div className="">
                <ul className="nav nav-underline" align="center">
                    <li className="nav-item">
                        <a className="click nav-link active" aria-current="page" >Todos</a>
                    </li>
                    {
                        /*
                        <li className="nav-item">
                        <a className="click nav-link" >Sincronizadas</a>
                    </li>
                    <li className="nav-item">
                        <a className="click nav-link" >Por Sincronizar</a>
                    </li>

                    <li className="nav-item">
                        <a className="click nav-link" >Guardados</a>
                    </li>
                        */
                    }
                </ul>


                <div className='mt-2 shadow p-2 mb-5 bg-body rounded ' style={{ "maxWidth": "1200px" }} >
                    <ListaDocumentos opciones={{}} funciones={() => { }} />
                </div>
            </div>
        </div>
    );
}

export default Index;