
import { Dropdown } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import StandarModal from '../PartesDocument/StandarModal';

import {enviarPeticion} from "../../../Core/ActionDatabase";
import ViewProductos from '../../Productos/ViewProductos';
import Add from '../../Productos/Add';
import AjustarInventario from '../../Productos/AjustarInventario';
import VerInventarioVariable from '../../Productos/PartesFormAdd/Inventario/VerInventarioVariable';



const ProductContextMenu = ({ user, position , onClose, show , item }) => {

    
    
    const verProducto = ( user.PERMISOS.verProducto ) ? user.PERMISOS.verProducto : 0 ;
    const editarProducto = ( user.PERMISOS.editarProducto ) ? user.PERMISOS.editarProducto : 0 ;
    const ModificarInventario = ( user.PERMISOS.ModificarInventario ) ? user.PERMISOS.ModificarInventario : 0 ;

    

    useEffect(() => {
        
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);
    

    useEffect(() => {
        setinfoStock([]);
        setshowstockInventario(null);

    },[item]); 
    
    const handleClickOutside = (e) => {

        if (!e.target.classList.contains('click')) {
            console.log("Cerrando...");
            onClose();
        }
 
    
     };


    const [ showstockInventario , setshowstockInventario ] = useState(null);

    const [ showstockInventarioVariable , SetshowCargaInventarioVariable ] = useState(null);
    


    const [ showCargaInventario , SetshowCargaInventario ] = useState(null);


    const [ showFormView , SetshowFormView ] = useState(null);


    const [ showFormEdit , SetshowFormEdit ] = useState(null);


    const [ showFormAjustar , SetshowFormAjustar ] = useState(null);
    

    const [infoStock , setinfoStock ] = useState([]);


    
    const cargarInventario = async  () => {

        SetshowCargaInventario(true);
        setshowstockInventario(true);
        

        let respuesta =  await enviarPeticion( "inventarios/info_inventario"  , item.id  , { });

        if (respuesta){
            
            SetshowCargaInventario(null);

            if(respuesta.data){

                setinfoStock(respuesta.data);
            }
        }   
            

    } 
    

    const cargarInventarioVariable = async  () => {

        SetshowCargaInventarioVariable(true);
        let respuesta =  await enviarPeticion( "inventarios/info_inventario"  , item.id  , { });
        if (respuesta){
            if(respuesta.data){
                setinfoStock(respuesta.data);
            }
        } 

    } 





  return (

        
        <>
            { show    && 
            // ANDRESS
            // <ul className="list-group"

            //     style={{
            //         position: 'absolute',
            //         top: position.y,
            //         left: position.x,
            //         zIndex: 1000,
            //     }}
            //     >

            
               
            //     { verProducto == 1 &&  <button type="button" className="click list-group-item list-group-item-action" onClick={() => SetshowFormView(true)} > Ver Producto </button> }
            //     { editarProducto == 1 && <button type="button" className="click list-group-item list-group-item-action" onClick={() => SetshowFormEdit(true)} > Editar Producto  </button>}
            //     { ModificarInventario == 1 && <button type="button" className="click list-group-item list-group-item-action" onClick={() => SetshowFormAjustar(true)} > Ajustar Inventario  </button>}


            //     <button type="button"  onClick={cargarInventario} className="click list-group-item list-group-item-action "> Ver Inventario </button>
                
            //     <button type="button"  onClick={cargarInventarioVariable} className="click list-group-item list-group-item-action "> Ver Inventario Variable </button>

            //     {
            //         showstockInventario && 

            //             <>

            //                 {showCargaInventario && <li className='list-group-item'>Cargando...</li>}

            //                 <li className="pt-4 list-group-item list-group-item-title"> <b>Stock de Inventarios</b></li>



            //                 {
            //                     infoStock.map((data, index) => 
            //                     <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            //                         {data.nombre}
            //                         <span className="badge text-bg-primary rounded-pill"> {data.cantidad}</span>
            //                     </li>
            //                     )

            //                 }
        

            //             </>
            //     }

              
                
            // </ul>


            // YULIIIII
              <ul className="list-group"

                style={{
                    position: 'absolute',
                    top: position.y,
                    left: position.x,
                    zIndex: 1000,
                }}
                >

            
               
                { verProducto == 1 &&  <button type="button" className="click list-group-item list-group-item-action" onClick={() => SetshowFormView(true)} > Ver Producto </button> }
                { editarProducto == 1 && <button type="button" className="click list-group-item list-group-item-action" onClick={() => SetshowFormEdit(true)} > Editar Producto  </button>}
                { ModificarInventario == 1 && <button type="button" className="click list-group-item list-group-item-action" onClick={() => SetshowFormAjustar(true)} > Ajustar Inventario  </button>}


                <button type="button"  onClick={cargarInventario} className="click list-group-item list-group-item-action "> Ver Inventario </button>
                
                <button type="button"  onClick={cargarInventarioVariable} className="click list-group-item list-group-item-action "> Ver Inventario Variable </button>

                {
                    showstockInventario && 

                        <>

                            {showCargaInventario && <li className='list-group-item'>Cargando...</li>}

                            <li className="pt-4 list-group-item list-group-item-title"> <b>Stock de Inventarios</b></li>



                            {
                                infoStock.map((data, index) => 
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    {data.nombre}
                                    <span className="badge text-bg-primary rounded-pill"> {data.cantidad}</span>
                                </li>
                                )

                            }
        

                        </>
                }

              
                
            </ul>


            
            }

            {/***
             * Formualrio para Extraer todos los productos...
            */}
            <StandarModal  size="lg" titulo="Configuración  del Producto"  desactivar={() => SetshowFormView(!showFormView)}  mostrar={showFormView}  > 
                <ViewProductos  item={item}  desactivar={ () => SetshowFormView(false)}   /> 
            </StandarModal> 


             {/***
             * Formualrio para Extraer todos los productos...
            */}
            <StandarModal  size="lg" titulo="Configuración  del Producto"  desactivar={() => SetshowFormEdit(!showFormEdit)}  mostrar={showFormEdit}  > 
                <Add editar={1} item={item}  desactivar={ () => SetshowFormEdit(false)}   /> 
            </StandarModal> 



            {/***
             * Formualrio para Extraer todos los productos...
            */}
            <StandarModal   titulo="Editar Stock de Inventario"  desactivar={() => SetshowFormAjustar(!showFormAjustar)}  mostrar={showFormAjustar}  > 
                <AjustarInventario   item={item}  desactivar={ () => SetshowFormAjustar(false)}   /> 
            </StandarModal> 
            


             {/***
             * Formualrio para Extraer todos los productos...
            */}
            <StandarModal   titulo="Consulta de inventario"  desactivar={() => SetshowCargaInventarioVariable(false)}  mostrar={showstockInventarioVariable}  > 
                <VerInventarioVariable  infoInventario={infoStock}  item={item}  desactivar={ () => SetshowCargaInventarioVariable(false)}   /> 
            </StandarModal> 

            
        </>
    
    );
}

export default ProductContextMenu;