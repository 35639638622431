import React, { useState } from 'react';
import { __pluma7 } from '../../../../Core/pluma7';
import { ExtraerTablabyIds, reloadDatabases } from '../../../../Core/Dixie';
import ListaCategorias from '../../Categorias/ListaCategorias';
import { fetchUSer, GetNombre_firebaseLastKeyObjetos } from '../../../../Core/fetchLocalStoreData';
import { registrarVehiculo } from '../Core/Controlador';
import { NotificationManager } from 'react-notifications';
import { insertarCambioFirebase } from '../../../Productos/Controlador';
import PlacaInput from '../Core/Html/PlacaInput';
import { useStateValue } from '../../../../context/StateProvider';
import { __app } from '../../../../utils/myApp';

const Form = ({ mostrar, item, SetItemEdit, textoBoton , successFunction=null}) => {
    console.log(mostrar)

    const [ {  KeyObservadores}  , dispatch ] = useStateValue();

    const [placa, setPlaca] = useState(item?.identificacion);
    const [descripcion, setDescripcion] = useState(item?.nombre);
    const [categoria, setCategoria] = useState(item?.objetos_categoria_id);
    const id = item?.id

    const user = fetchUSer();

    const categorias = user.DATABASE.objetos_categorias;

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validación de los campos obligatorios
        if (!placa || !categoria) {
            alert('La Placa y la Categoría son obligatorias');
            return;
        }

        guardarVehiculo();

    };


    const guardarVehiculo = async () => {
        // Aquí puedes hacer lo que necesites con los datos del formulario, por ejemplo, enviarlos a una API.
        let datos = { placa, descripcion, categoria };

        let infoVehiculo = {

            identificacion: __pluma7.formatearPlaca(datos.placa),
            nombre: descripcion,
            objetos_categoria_id: categoria,
            nombreCategoria: categorias.find((cate) => cate.id == categoria)?.nombre,
            id: id,
        };



        let data = await ExtraerTablabyIds("objetos", [infoVehiculo.identificacion], 'identificacion');

        if (data.length > 0) {

            if (!infoVehiculo?.id) {
                alert("Esta placa ya se encuentra en el Sistema");
                return false;
            }
        }



        let ressHttp = await registrarVehiculo(infoVehiculo);
        console.log(ressHttp)
        if (ressHttp.estado == 1 || ressHttp.estado == 2) {
            
            SetItemEdit(null)
            mostrar(false);
            NotificationManager.success(ressHttp.mensaje);

            reloadDatabases([ressHttp.objeto], 'objetos');
            
            localStorage.setItem(GetNombre_firebaseLastKeyObjetos(), ressHttp.versionDatabase.aux.lastId)
            insertarCambioFirebase(ressHttp.versionDatabase.aux, 'objetos')
         
            setTimeout(() => {
                dispatch ({
                    type : 'SET_KeyObservadores',
                    KeyObservadores : {...KeyObservadores , OBJETOS : __app.numeroRandom()},
                }) 
            } , 300)
            
        } else {
            NotificationManager.error("Error al Crear Vehiculo");
        }

        //
        //alert('Formulario guardado correctamente!');
    }









    

    return (
        <div className="container mt-2">
            <h3 className="mb-2 text-center">✍️Formulario de Registro</h3>
            <form onSubmit={handleSubmit} className="rounded">
                <div className='row'>

                    <div className="d-flex align-items-center justify-content-center">
                        <PlacaInput value={placa} onChange={(e) => setPlaca(e.target.value)} />
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="descripcion" className="form-label">Descripción:</label>
                            <input
                                type="text"
                                id="descripcion"
                                className="form-control"
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="categoria" className="form-label">Categoría:</label>
                            <select
                                id="categoria"
                                className="form-select"
                                value={categoria}
                                onChange={(e) => setCategoria(e.target.value)}
                                required
                            >
                                <option value="">Selecciona una categoría</option>
                                {categorias.map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                        {cat.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" className="btn btn-primary">{textoBoton}</button>
                </div>
            </form>
        </div>
    );
};

export default Form;
