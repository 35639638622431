import React from 'react';
import { __app, __fecha } from '../../../utils/myApp';
import Placa from '../../../Core/Html/Placa';

const InfoTercero = ({ DOCUMENTO }) => {

    if (!DOCUMENTO) return false

    console.log("DOCUMENTO VIEW", DOCUMENTO);
    return (
        <>
            <div className={`card shadow rounded-4 mb-2 border-opacity-25 border-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`}>
                <div className="card-body">
                    <table className='table'>
                        <tr>
                            <th colSpan="2">
                                <h5 className="text-center mb-2">
                                    <i className="fa-solid fa-user-tie fa-lg me-2"></i> Información del tercero.
                                </h5>
                            </th>
                        </tr>

                        <tr>
                            <th>Nombre</th>
                            <td className="text-">
                                {__app.rellenarVacios(DOCUMENTO.razonSocial)}
                            </td>
                        </tr>

                        <tr>
                            <th>Nit/Identificación</th>
                            <td className="text-">
                                {__app.rellenarVacios(DOCUMENTO.identificacion)}
                            </td>
                        </tr>

                        <tr>
                            <th>Teléfono</th>
                            <td className="text-">
                                {__app.rellenarVacios(DOCUMENTO.telefono)}
                            </td>
                        </tr>

                        <tr>
                            <th>E-mail</th>
                            <td className="text-">
                                {__app.rellenarVacios(DOCUMENTO.correoelectronico)}
                            </td>
                        </tr>

                    </table>
                </div>
            </div>

            {/* SI TIENE UN CONTRATO VINCULADO MUESTRA LA INFOMACION AQUI  */}
            {DOCUMENTO.periodo_fecha_1 && DOCUMENTO.periodo_fecha_2 && (
                <div className={`card shadow rounded-4 border-opacity-25 border-${DOCUMENTO.CtTipoComprobanteFiscal_color_documento}`}>
                    <div className='p-2'>
                        <div className="card bg-primary-subtle p-3">
                            <div className='row'>
                                <div className='col-md-6 mb-2'>
                                    <i className="fa-solid fa-briefcase me-2 fa-lg"></i>
                                    <span><strong className='parpadeo'>Contrato Asociado #{DOCUMENTO.periodo_serie}</strong></span>
                                    <div className='mt-1'>
                                        <span><strong>Fecha de inicio:</strong> {__fecha.formatoFecha({ $fecha: DOCUMENTO.periodo_fecha_1 })}</span>
                                        <span><strong>Fecha Final:</strong> {__fecha.formatoFecha({ $fecha: DOCUMENTO.periodo_fecha_2 })}</span>
                                    </div>
                                </div>


                                {DOCUMENTO.periodo_objeto_id > 0 && (
                                    <>
                                        <div className='col-md-6'>
                                            <div className="mb-1">
                                                <i className="fa-solid fa-car me-2 fa-lg"></i>
                                                <span><strong>Vehículo Asociado</strong></span>
                                            </div>
                                            <Placa placa={DOCUMENTO.objetos_identificacion} />
                                            <span><strong>Descripción:</strong> {DOCUMENTO.objetos_nombre}</span>
                                        </div>
                                    </>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

export default InfoTercero;