

import React from 'react';


//(user?.PERMISOS?.EliminarItemCuentaAbierta == 1 )

const BotonEliminarItem = ({eliminarLinea , user , documento ,item , index}) => {

    if(user.USER.type_user_id == 1  || user?.PERMISOS?.EliminarItemDocumento == 1  ){

       
    }else{
        return <> </>
    }

  
    if(  (user?.PERMISOS?.EliminarItemCuentaAbierta != 1 && item.fechaid !== documento.fechaid )  ){
        if(! user.USER.type_user_id == 1){
            return false;
        }
    }



    return (
       
        <span onClick={() => eliminarLinea(index, item)} className='btn btn-principal btn-xs m-2'> <i title="Eliminar Producto " className="fa fa-trash" aria-hidden="true"></i> </span>
    );
};

export default BotonEliminarItem;






