import React, { useEffect, useState } from 'react';
import { getDocumentosbyTerceroidPendiente } from '../Core/Core';
import TablaDocumentos from './TablaDocumentos';
import ErrorPeticionHttp from '../../../Core/Html/ErrorPeticionHttp';
import { filtrarTipoDocumento } from '../../Documentos/controlador';
import FiltroTipoDocumentos from '../../Documentos/Componentes/FiltroTipoDocumentos';
import Pendiente from '../../Documentos/Componentes/Pendiente';
import { useContextLocalValue } from './Core/ContextTercero';


const DocumentosPendientesCompra = ({terceroId , actualizarDatos}) => {
    const [ documentos , setDocumentos ] = useState(null);

    const [tipoDocumento , setTipoDocumentos] = useState(null);
    
    const [tipoDocumentoFiltro , settipoDocumentoFiltro] = useState([]);

 
    const [ {info}  ]  = useContextLocalValue();


    useEffect(() => {
        setDocumentos(null);
        consutlarDocumentos();
    } , [info.menu])


    const consutlarDocumentos = async () => {
        let ct_opetacion_id = 2;
        let data = await getDocumentosbyTerceroidPendiente(terceroId , ct_opetacion_id  );
        let filtroTipoDocumento = filtrarTipoDocumento(data);
        setTipoDocumentos(filtroTipoDocumento);
        settipoDocumentoFiltro(filtroTipoDocumento);
        setDocumentos(data);

    }

    const handleChangeFiltro = (data) => {
        console.log(data)
        settipoDocumentoFiltro(data)
    }

    const campos = [
        'Fecha',
        'PuntoDeVenta',
        '#Factura',
        'Tipo',
        'Descuento',
        'Precio',
        'Abono',
        'Pendiente',
        'Opciones',
    ]


    const [Totales, SetTotales ] = useState({
        descuentos : 0,
        precio  : 0,
        abonado : 0, 
      });

    const getTotales = (totales) => {
       
        SetTotales(totales);
    }

    return (
        <div>
           
         

            <div className="d-flex  mb-4 card">
                <div className='card-body'>
                    
                { (documentos && Array.isArray(documentos) )  && 
                
                    <p>
                        
                        <br />
                        <div className=" card bg-warning bg-opacity-25">
                        <div className="card-body">
                            <p className="card-text">
                            Lo que ves aquí son las deudas a tus <b>proveedores</b>. Recuerda pagarlas a tiempo para tener siempre un buen crédito.
                            <br />
                            <br />
                            <span className='mt-2 fw-bold'>Pendiente por Pagar : <b>  <Pendiente  total={Totales.precio} abonado={Totales.abonado} /> </b> </span>

                            </p>
                        </div>
                        </div>

                       
                    </p>
                }

                    <div className='mb-4'>
                        { tipoDocumento && <FiltroTipoDocumentos tipoDocumento={tipoDocumento} handleChangeFiltro={handleChangeFiltro} /> }
                    </div>


                    { (documentos && Array.isArray(documentos) )  && <TablaDocumentos actualizarDatos={actualizarDatos} documentos={documentos} filtro={tipoDocumentoFiltro} campos={campos}  getTotales={getTotales} /> }

                    { !documentos  && <>Cargando...</> }

                    { documentos === -1  && <ErrorPeticionHttp /> }

                </div>
            </div>
            <div>
         
              

            </div>
        </div>
    );
};

export default DocumentosPendientesCompra;